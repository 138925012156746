<template>
  <div
    class="w-12/12 bg-sky-900 mx-auto"
    data-v-6c5d7da5=""
    :class="{ 'mt-4': belowSearch, 'mt-8': !belowSearch }"
  >
    <div class="ml-4 text-white py-1 lg:py-2 lg:text-xl font-bold flex">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: ["belowSearch"],
};
</script>
