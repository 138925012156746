<template>
  <header-bar>
    <div class="flex items-center">
      Create New Project
      <input
        ref="projectName"
        placeholder="Project Name"
        type="text"
        class="w-auto mb-0 mx-4"
      />
      <button class="btn-blue bg-emerald-600" @click="createProject">
        Create Project
      </button>
    </div></header-bar
  >

  <header-bar>Manage Projects</header-bar>
  <div v-if="$store.state.projects.length > 0" class="grid gap-x-8 grid-cols-2">
    <div
      v-for="(project, pindex) in $store.state.projects"
      :key="project._id"
      class="flex flex-col mt-4 bg-emerald-600 p-4 pt-2 rounded-xl"
    >
      <div class="flex justify-between mb-2">
        <span class="font-semibold text-white text-xl">{{ project.name }}</span>
        <button
          @click="deleteProject(project._id)"
          class="bg-red-700 rounded-md px-2 text-white ml-4"
        >
          Delete Project
        </button>
      </div>

      <div class="flex font-semibold text-white cursor-pointer">
        <div
          :class="!projectTabState[pindex] ? 'bg-sky-900' : 'bg-gray-500'"
          class="rounded-t-md px-2 pt-1 border-sky-900 relative"
          style="border-width: 1px 1px 0 1px"
          @click="projectTabState[pindex] = null"
        >
          Boxes ( {{ project.boxes.length || 0 }} )
        </div>
        <div
          :class="
            projectTabState[pindex] == 'pucks' ? 'bg-sky-900' : 'bg-gray-500'
          "
          class="rounded-t-md px-2 pt-1 border-sky-900 relative"
          style="border-width: 1px 1px 0 1px"
          @click="projectTabState[pindex] = 'pucks'"
        >
          Pucks ( {{ project.pucks?.length || 0 }} )
        </div>
        <div
          :class="
            projectTabState[pindex] == 'racks' ? 'bg-sky-900' : 'bg-gray-500'
          "
          class="rounded-t-md px-2 pt-1 border-sky-900 relative"
          style="border-width: 1px 1px 0 1px"
          @click="projectTabState[pindex] = 'racks'"
        >
          Racks ( {{ project.racks?.length || 0 }} )
        </div>
        <div
          :class="
            projectTabState[pindex] == 'add' ? 'bg-sky-900' : 'bg-gray-500'
          "
          class="rounded-t-md px-2 pt-1 border-sky-900 relative"
          style="border-width: 1px 1px 0 1px"
          @click="projectTabState[pindex] = 'add'"
        >
          <span class="block" style="transform: translate(0, -1px)"
            >Add User</span
          >
        </div>
        <div
          :class="
            projectTabState[pindex] == 'edit' ? 'bg-sky-900' : 'bg-gray-500'
          "
          class="rounded-t-md px-2 pt-1 border-sky-900 relative"
          style="border-width: 1px 1px 0 1px"
          @click="
            () => {
              getProjectUsers(project._id);
              projectTabState[pindex] = 'edit';
            }
          "
        >
          <span class="block" style="transform: translate(0, -1px)"
            >Edit Users ( {{ users[project._id]?.length || 0 }} )</span
          >
        </div>
      </div>

      <div class="bg-sky-900 p-2">
        <div class="bg-gray-300 p-4 rounded">
          <div v-if="projectTabState[pindex] == 'add'">
            <custom-user-access
              @useradded="getProjectUsers(project._id)"
              container="project"
              :containerobj="{
                title: project.name,
                _id: project._id,
                labId: project.labId,
              }"
              :index="pindex"
            ></custom-user-access>
          </div>
          <div
            v-else-if="projectTabState[pindex] == 'edit'"
            class="grid grid-cols-[max-content,max-content] gap-y-2"
          >
            <div v-if="users[project._id].length == 0">
              No users added to project.
            </div>
            <template
              :key="pindex + '_' + user.userId"
              v-for="user in users[project._id]"
            >
              <div class="bg-sky-50 pl-2 py-1 rounded-l">{{ user.email }}</div>
              <div class="flex pl-4 bg-sky-50 pr-2 rounded-r py-1">
                <input
                  type="radio"
                  :checked="user.accesslevel != 'readonly'"
                  value="readwrite"
                  :id="user.userId + 'accesslevelrw' + project._id"
                  :name="user.userId + 'accesslevels' + project._id"
                  @click="editUserAccess($event, project._id, user.userId)"
                /><label
                  class="relative top-px"
                  :for="user.userId + 'accesslevelrw' + project._id"
                  >R+W</label
                ><input
                  style="margin-left: 0.5rem"
                  type="radio"
                  :checked="user.accesslevel == 'readonly'"
                  value="readonly"
                  :id="user.userId + 'accesslevelread' + project._id"
                  :name="user.userId + 'accesslevels' + project._id"
                  @click="editUserAccess($event, project._id, user.userId)"
                /><label
                  class="relative top-px"
                  :for="user.userId + 'accesslevelread' + project._id"
                  >Read</label
                ><input
                  style="margin-left: 0.5rem"
                  type="radio"
                  :id="user.userId + 'accessleveldel' + project._id"
                  :name="user.userId + 'accesslevels' + project._id"
                  value="delete"
                  @click="editUserAccess($event, project._id, user.userId)"
                /><label
                  class="relative top-px"
                  :for="user.userId + 'accessleveldel' + project._id"
                  >Remove User</label
                >
              </div>
            </template>
          </div>

          <div
            v-else-if="projectTabState[pindex] == 'racks'"
            class="grid grid-cols-2 gap-y-4 items-"
            style="grid-template-columns: max-content max-content"
          >
            <div v-if="!project.racks?.length">No racks added to project.</div>
            <template
              :key="rack._id + '-' + project.name"
              v-for="rack in project.racks"
            >
              <div
                class="siblingremove flex items-center bg-blue-50 rounded-l pl-2 pr-2 py-4"
              >
                <div class="bg-sky-50 rounded-l pl-2">
                  <div class="flex items-center">{{ rack.customId }}</div>
                  <div v-if="rack.title" class="text-xs">
                    ({{ rack.title }})
                  </div>
                </div>
              </div>
              <div
                class="flex items-center bg-sky-50 rounded-r pl-2 py-4 elemtoremove"
              >
                <div
                  class="grid grid-cols-2 items-stretch justify-center gap-x-4"
                >
                  <!-- used to be 3rd argument box.puckId -->
                  <div
                    @click="
                      removeBoxFromProject(
                        $event,
                        rack._id,
                        project._id,
                        null,
                        null,
                        'rack'
                      )
                    "
                    @mouseover="
                      (ev) => {
                        ev.target.classList.add('bg-red-800');
                      }
                    "
                    @mouseout="
                      (ev) => {
                        ev.target.classList.remove('bg-red-800');
                      }
                    "
                    class="rounded bg-red-700 border-red-900 border text-white px-1 text-xs text-center font-semibold cursor-pointer"
                  >
                    Remove Rack<br />From Project
                  </div>
                </div>
              </div>
            </template>
          </div>
          <div
            v-else-if="projectTabState[pindex] == 'pucks'"
            class="grid grid-cols-2 gap-y-4 items-"
            style="grid-template-columns: max-content max-content"
          >
            <div v-if="!project.pucks?.length">No pucks added to project.</div>
            <template
              :key="puck._id + '-' + project.name"
              v-for="puck in project.pucks"
            >
              <div
                class="siblingremove flex items-center bg-blue-50 rounded-l pl-2 pr-2 py-4"
              >
                <div class="bg-sky-50 rounded-l pl-2">
                  <div class="flex items-center">
                    {{ puck.customId ?? puck.serialNo }}
                  </div>
                  <div v-if="puck.title" class="text-xs">
                    ({{ puck.title }})
                  </div>
                </div>
              </div>
              <div
                class="flex items-center bg-sky-50 rounded-r pl-2 py-4 elemtoremove"
              >
                <div
                  class="grid grid-cols-2 items-stretch justify-center gap-x-4"
                >
                  <!-- used to be 3rd argument box.puckId -->
                  <div
                    @click="
                      removeBoxFromProject(
                        $event,
                        puck._id,
                        project._id,
                        null,
                        null,
                        'puck'
                      )
                    "
                    @mouseover="
                      (ev) => {
                        ev.target.classList.add('bg-red-800');
                      }
                    "
                    @mouseout="
                      (ev) => {
                        ev.target.classList.remove('bg-red-800');
                      }
                    "
                    class="rounded bg-red-700 border-red-900 border text-white px-1 text-xs text-center font-semibold cursor-pointer"
                  >
                    Remove Puck<br />From Project
                  </div>
                </div>
              </div>
            </template>
          </div>

          <div
            v-else
            class="grid grid-cols-2 gap-y-4 items-"
            style="grid-template-columns: max-content max-content"
          >
            <div v-if="project.boxes.length == 0">
              No boxes added to project.
            </div>
            <template
              :key="box._id + '-' + project.name"
              v-for="box in project.boxes"
            >
              <div
                class="siblingremove flex items-center bg-blue-50 rounded-l pl-2 pr-2 py-4"
              >
                <div class="bg-sky-50 rounded-l pl-2">
                  <div class="flex items-center">{{ box.customId }}</div>
                  <div v-if="box.title" class="text-xs">({{ box.title }})</div>
                </div>
              </div>
              <div
                class="flex items-center bg-sky-50 rounded-r pl-2 py-4 elemtoremove"
              >
                <div
                  class="grid grid-cols-2 items-stretch justify-center gap-x-4"
                >
                  <!-- used to be 3rd argument box.puckId -->
                  <div
                    @click="
                      removeBoxFromProject(
                        $event,
                        box._id,
                        project._id,
                        box.wellNo,
                        box.puckId
                      )
                    "
                    @mouseover="
                      (ev) => {
                        ev.target.classList.add('bg-red-800');
                      }
                    "
                    @mouseout="
                      (ev) => {
                        ev.target.classList.remove('bg-red-800');
                      }
                    "
                    class="rounded bg-red-700 border-red-900 border text-white px-1 text-xs text-center font-semibold cursor-pointer"
                  >
                    Remove Box<br />From Project
                  </div>

                  <!-- <div
                    @mouseover="
                      (ev) => {
                        ev.target.classList.add('bg-green-800');
                      }
                    "
                    @mouseout="
                      (ev) => {
                        ev.target.classList.remove('bg-green-800');
                      }
                    "
                    class="rounded flex items-center justify-center border border-green-900 bg-green-700 text-white px-1 text-xs text-center font-semibold cursor-pointer"
                  >
                    Go to Box
                  </div> -->
                </div>
              </div>
            </template>
            <!-- <template
                v-for="projectforbox in arrayOfProjectsForBoxes"
                :key="projectforbox.projid"
              >
                <template v-if="projectforbox.projid == project._id">
                  <template v-for="box in projectforbox.boxes" :key="box._id">
                    <div
                      class="siblingremove flex items-center bg-gray-300 rounded-l pl-2 pr-2 py-4"
                    >
                      <div>
                        <div class="flex items-center">{{ box.customId }}</div>
                        <div v-if="box.title" class="text-xs">
                          ({{ box.title }})
                        </div>
                      </div>
                    </div>
                    <div
                      class="flex items-center bg-gray-300 rounded-r pl-2 py-4 elemtoremove"
                    >
                      <div
                        class="grid grid-cols-2 items-stretch justify-center gap-x-4"
                      >
                        <div
                          @click="
                            removeBoxFromProject($event, box._id, box.puckId)
                          "
                          @mouseover="
                            (ev) => {
                              ev.target.classList.add('bg-red-800');
                            }
                          "
                          @mouseout="
                            (ev) => {
                              ev.target.classList.remove('bg-red-800');
                            }
                          "
                          class="rounded bg-red-700 border-red-900 border text-white px-1 text-xs text-center font-semibold cursor-pointer"
                        >
                          Remove Box<br />From Project
                        </div>

                        <div
                          @mouseover="
                            (ev) => {
                              ev.target.classList.add('bg-green-800');
                            }
                          "
                          @mouseout="
                            (ev) => {
                              ev.target.classList.remove('bg-green-800');
                            }
                          "
                          class="rounded flex items-center justify-center border border-green-900 bg-green-700 text-white px-1 text-xs text-center font-semibold cursor-pointer"
                        >
                          Go to Box
                        </div>
                      </div>
                    </div>
                  </template>
                </template>
              </template> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>No projects added.</div>
</template>
<script>
import HeaderBar from "./manage-products-sections/HeaderBar.vue";
import CustomUserAccess from "./manage-products-sections/CustomUserAccess.vue";

export default {
  data() {
    return {
      users: {
        // "619ce1bffe649471fa4082b2": [
        //   { _id: 124422, email: "wang@wong.com", accesslevel: "readwrite" },
        // ],
      },
      labprojects: [],
      projectTabState: [],
      arrayOfProjectsForBoxes: [],
    };
  },
  mounted() {
    this.$store.dispatch("getAdminStuff", {
      url: "projects",
    });
    // this.getProjectBoxes();
    // this.getProjectUsers();
  },
  components: { HeaderBar, CustomUserAccess },
  methods: {
    async getProjectUsers(projectid) {
      const data = await this.$store.dispatch("getAdminStuff", {
        projusers: projectid,
      });

      this.users[projectid] = data;
      console.log(this.users[projectid]);
    },
    async editUserAccess(ev, projectid, userid) {
      if (ev.currentTarget.value == "delete") {
        const ok = confirm(
          "Are you sure you want to remove this user's access?"
        );
        if (!ok) return;
      }
      const body = {
        userid,
        projectid,
      };

      if (ev.currentTarget.value == "delete") body.delete = true;
      else body.customaccess = ev.currentTarget.value;

      console.log(body);

      const data = await this.$store.dispatch("updateItem", {
        objid: projectid,
        item: "projects",
        body,
        linkuser: true,
      });

      console.log(data);
      if (data == "deleted") {
        this.users[projectid] = this.users[projectid].filter(
          (obj) => obj.userId != userid
        );
      }
    },
    async getProjectBoxes() {
      this.arrayOfProjectsForBoxes = [];
      await this.$store.dispatch("updateDewarData");
      const dewars = this.$store.state.dewarData[0].data;
      for (let i = 0; i < dewars.length; i++) {
        const racks = dewars[i].racks;
        if (racks.length == 0) break;

        for (let j = 0; j < racks.length; j++) {
          const pucks = racks[j].pucks;

          if (pucks.length == 0) break;

          for (let k = 0; k < pucks.length; k++) {
            for (let i2 = 1; i2 <= 12; i2++) {
              if (pucks[k]["well" + i2]) {
                if (pucks[k]["well" + i2].project) {
                  const found = this.arrayOfProjectsForBoxes.some(
                    (el) => el.projid === pucks[k]["well" + i2].project
                  );
                  if (!found)
                    this.arrayOfProjectsForBoxes.push({
                      projid: pucks[k]["well" + i2].project,
                      boxes: [],
                    });

                  this.arrayOfProjectsForBoxes.forEach((proj, index) => {
                    if (proj.projid == pucks[k]["well" + i2].project) {
                      const box = pucks[k]["well" + i2];
                      box.puckId = pucks[k]._id;
                      this.arrayOfProjectsForBoxes[index].boxes.push(box);
                    }
                  });

                  // project = this.$store.state.projects.find((obj) => {
                  //   //this is a proj mongo id
                  //   return obj._id === pucks[k]["well" + i2].project;
                  // });
                }
              }
            }
          }
        }
      }
      console.log(this.arrayOfProjectsForBoxes);
    },

    removeBoxFromProject(ev, boxid, projectid, wellno, puckid, item = "box") {
      const deleteUser = confirm(
        `Are you sure you want to remove this ${item}?`
      );
      if (!deleteUser) {
        return;
      }
      const theobj = {
        item: "projects",
        objid: projectid,
        body: {
          pullvalue: {
            [item == "rack" ? "racks" : item == "puck" ? "pucks" : "boxes"]:
              boxid,
          },
        },
      };

      if (item == "box") {
        theobj.body.wellNo = wellno;
        theobj.body.puckId = puckid._id;
      }

      console.log(
        ev.target.closest(".elemtoremove").previousElementSibling,
        theobj
      );
      this.$store.dispatch("updateItem", theobj).then(() => {
        // ev.target.closest(".elemtoremove").previousElementSibling.remove();
        // ev.target.closest(".elemtoremove").remove();
        this.$store.dispatch("getAdminStuff", {
          url: "projects",
        });
      });
    },
    // removeBoxFromProject(ev, boxid, puckId) {
    //old
    //   const deleteUser = confirm("Are you sure you want to remove this box?");
    //   if (!deleteUser) {
    //     return;
    //   }
    //   const theobj = {
    //     item: "boxes",
    //     objid: boxid,
    //     body: {
    //       project: null,
    //       puckId,
    //     },
    //   };
    //   console.log(
    //     ev.target.closest(".elemtoremove").previousElementSibling,
    //     theobj
    //   );
    //   this.$store.dispatch("updateItem", theobj).then(() => {
    //     ev.target.closest(".elemtoremove").previousElementSibling.remove();
    //     ev.target.closest(".elemtoremove").remove();
    //   });
    // },
    createProject() {
      if (this.$refs.projectName.value)
        this.$store.dispatch("createItem", {
          item: "projects",
          body: { name: this.$refs.projectName.value },
        });
    },
    deleteProject(id) {
      const deleteUser = confirm(
        "Are you sure you want to delete this project?"
      );
      if (!deleteUser) {
        return;
      }
      this.$store
        .dispatch("deleteItem", {
          item: "projects",
          objid: id,
        })
        .then(() => {
          this.$store.dispatch("getAdminStuff", {
            url: "projects",
          });
        });
    },
  },
};
</script>
