<template>
  <div
    id="searchbarheight"
    ref="topcontainer"
    class="sticky w-full bg-white pb-4"
    :style="{ top: topRowHeight }"
    style="z-index: 48"
  >
    <div class="absolute z-50 -top-14 left-1/2 -translate-x-1/2 inline-block">
      <div id="savefailid"></div>
    </div>
    <div
      class="bg-sky-900 flex justify-between items-center mx-auto"
      :class="size"
    >
      <input
        class="ml-2 my-2 mr-0 p-1"
        type="text"
        id="searchbarforloggedin"
        placeholder="Find puck by serial number or item keyword"
        ref="searchbar"
        @input="keywordSearch($event.target.value)"
        @paste="$emit('pasted', $event.clipboardData.getData('text/plain'))"
        @keyup.enter="$emit('pasted', $event.target.value)"
      />
      <div
        class="flex-1 text-center cursor-pointer"
        @click="$emit('pasted', $refs.searchbar.value)"
      >
        <span class="material-symbols-outlined mx-auto text-2xl text-white">
          search
        </span>
        <!-- <i class="mx-auto text-2xl text-white fas fa-search"></i> -->
      </div>
    </div>
    <div class="relative mx-auto" :class="size">
      <div
        ref="keywordresults"
        class="border-blue-900 border-2 p-2 absolute w-full z-10 bg-gray-300 hidden"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: "w-11/12",
    },
  },
  inject: ["topRowHeight"],
  methods: {
    keywordSearch(searchTerm) {
      this.$refs.keywordresults.innerHTML = "";
      if (!searchTerm) return this.$refs.keywordresults.classList.add("hidden");
      this.$refs.keywordresults.classList.remove("hidden");
      console.log(searchTerm);
      const allLabs = this.$store.state.dewarData;
      let activedewar;
      let activerack;
      // let activepuck;
      const arrayOfMatchingPucks = [];
      const arrayOfMatchingBoxes = [];
      const arrayOfMatchingGrids = [];
      for (const lab of allLabs) {
        if (!lab.data.length > 0) {
          continue;
        }

        for (const dewar of lab.data) {
          activedewar = dewar._id;
          console.log(activedewar, dewar.customId);
          if (!dewar.racks.length > 0) {
            continue;
          }
          for (const [rackindex, rack] of dewar.racks.entries()) {
            activerack = "papaBgChange3-" + rackindex;
            console.log(activerack);
            if (!rack.pucks.length > 0) {
              continue;
            }

            for (const puck of rack.pucks) {
              console.log("current puck in rack", puck.serialNo);
              // activepuck = "puck-" + puck._id;
              if (
                puck.title
                  ?.trim()
                  .toUpperCase()
                  .includes(searchTerm.trim().toUpperCase()) ||
                puck.serialNo
                  ?.trim()
                  .toUpperCase()
                  .includes(searchTerm.trim().toUpperCase()) ||
                puck.description
                  ?.trim()
                  .toUpperCase()
                  .includes(searchTerm.trim().toUpperCase()) ||
                puck.customId
                  ?.trim()
                  .toUpperCase()
                  .includes(searchTerm.trim().toUpperCase())
              ) {
                console.log("puck matchfound");
                arrayOfMatchingPucks.push({
                  item: "puck",
                  name: puck.title,
                  customId: puck.customId,
                  navId: puck.serialNo,
                  description: puck.description,
                  color: puck.color,
                });
              }

              for (let n = 1; n < 13; n++) {
                if (puck["well" + n]) {
                  const box = puck["well" + n];
                  // console.log("has well " + n);
                  if (
                    puck["well" + n].title
                      ?.trim()
                      .toUpperCase()
                      .includes(searchTerm.trim().toUpperCase()) ||
                    puck["well" + n].description
                      ?.trim()
                      .toUpperCase()
                      .includes(searchTerm.trim().toUpperCase()) ||
                    puck["well" + n].customId
                      ?.trim()
                      .toUpperCase()
                      .includes(searchTerm.trim().toUpperCase())
                  ) {
                    arrayOfMatchingBoxes.push({
                      item: "box",
                      name: box.title,
                      customId: box.customId,
                      navId: puck.serialNo,
                      wellNo: n,
                      description: box.description,
                      color: box.color,
                    });
                  }

                  if (
                    puck["well" + n].gridTopLeft?.name
                      ?.trim()
                      .toUpperCase()
                      .includes(searchTerm.trim().toUpperCase()) ||
                    puck["well" + n].gridTopLeft?.description
                      ?.trim()
                      .toUpperCase()
                      .includes(searchTerm.trim().toUpperCase())
                  ) {
                    arrayOfMatchingGrids.push({
                      item: "grid",
                      name: puck["well" + n].gridTopLeft?.name,
                      navId: puck.serialNo,
                      wellNo: n,
                      description: puck["well" + n].gridTopLeft?.description,
                    });
                  }
                  if (
                    puck["well" + n].gridTopRight?.name
                      ?.trim()
                      .toUpperCase()
                      .includes(searchTerm.trim().toUpperCase()) ||
                    puck["well" + n].gridTopRight?.description
                      ?.trim()
                      .toUpperCase()
                      .includes(searchTerm.trim().toUpperCase())
                  ) {
                    arrayOfMatchingGrids.push({
                      item: "grid",
                      name: puck["well" + n].gridTopRight?.name,
                      navId: puck.serialNo,
                      wellNo: n,
                      description: puck["well" + n].gridTopRight?.description,
                    });
                  }
                  if (
                    puck["well" + n].gridBottomLeft?.name
                      ?.trim()
                      .toUpperCase()
                      .includes(searchTerm.trim().toUpperCase()) ||
                    puck["well" + n].gridBottomLeft?.description
                      ?.trim()
                      .toUpperCase()
                      .includes(searchTerm.trim().toUpperCase())
                  ) {
                    arrayOfMatchingGrids.push({
                      item: "grid",
                      name: puck["well" + n].gridBottomLeft?.name,
                      navId: puck.serialNo,

                      wellNo: n,

                      description: puck["well" + n].gridBottomLeft?.description,
                    });
                  }
                  if (
                    puck["well" + n].gridBottomRight?.name
                      ?.trim()
                      .toUpperCase()
                      .includes(searchTerm.trim().toUpperCase()) ||
                    puck["well" + n].gridBottomRight?.description
                      ?.trim()
                      .toUpperCase()
                      .includes(searchTerm.trim().toUpperCase())
                  ) {
                    arrayOfMatchingGrids.push({
                      item: "grid",
                      name: puck["well" + n].gridBottomRight?.name,
                      navId: puck.serialNo,
                      wellNo: n,
                      description:
                        puck["well" + n].gridBottomRight?.description,
                    });
                  }
                }
              }
            }
          }
        }
      }
      let limit = 0;

      const parentContainerMain = document.createElement("div");
      parentContainerMain.classList.add(
        "flex",
        "cursor-pointer",
        "border",
        "rounded",
        "border-black",
        "items-center",
        "bg-white",
        "mb-2"
      );
      arrayOfMatchingPucks.forEach((puck) => {
        if (limit == 10) return;
        const parentContainer = parentContainerMain.cloneNode();

        const itemType = document.createElement("div");
        itemType.style = `background-color:${this.$store.state.transformPuckColor(
          puck.color
        )}`;
        itemType.classList.add("rounded", "px-2", "py-1", "mr-4");
        itemType.textContent = "Puck";
        parentContainer.appendChild(itemType);
        const nameDiv = document.createElement("div");
        nameDiv.textContent = puck.name;
        parentContainer.appendChild(nameDiv);
        const customId = document.createElement("div");
        customId.textContent = `(${puck.customId ?? puck.navId})`;
        parentContainer.appendChild(customId);

        const description = document.createElement("div");
        description.classList.add("ml-4", "text-sm", "truncate");
        description.textContent = puck.description;
        parentContainer.appendChild(description);
        // const color = document.createElement("div");
        // color.innerHTML = `<span style="color:${puck.color}">${puck.color}</span>`;
        // parentContainer.appendChild(color);
        parentContainer.addEventListener("click", () => {
          this.$emit("pasted", puck.navId);
          this.$refs.keywordresults.classList.add("hidden");
          if (document.getElementById("searchbarforloggedin")) {
            document.getElementById("searchbarforloggedin").value = "";
          }
        });
        this.$refs.keywordresults.appendChild(parentContainer);
        limit++;
      });
      arrayOfMatchingBoxes.forEach((box) => {
        if (limit == 10) return;
        const parentContainer = parentContainerMain.cloneNode();
        const itemType = document.createElement("div");
        itemType.style = `background-color:${this.$store.state.transformBoxColor(
          box.color
        )}`;
        itemType.classList.add("rounded", "px-2", "py-1", "mr-4");
        itemType.textContent = "Box";
        parentContainer.appendChild(itemType);
        const nameDiv = document.createElement("div");
        nameDiv.textContent = box.name;
        parentContainer.appendChild(nameDiv);
        if (box.customId) {
          const customId = document.createElement("div");

          customId.textContent = `(${box.customId})`;
          parentContainer.appendChild(customId);
        }

        const description = document.createElement("div");
        description.classList.add("ml-4", "text-sm", "truncate");
        description.textContent = box.description;
        parentContainer.appendChild(description);
        // const color = document.createElement("div");
        // color.innerHTML = `<span style="color:${puck.color}">${puck.color}</span>`;
        // parentContainer.appendChild(color);
        console.log(box.navId, box.wellNo);
        parentContainer.addEventListener("click", () => {
          this.$emit("pasted", box.navId, box.wellNo);
          this.$refs.keywordresults.classList.add("hidden");
          if (document.getElementById("searchbarforloggedin")) {
            document.getElementById("searchbarforloggedin").value = "";
          }
        });
        this.$refs.keywordresults.appendChild(parentContainer);
        limit++;
      });

      arrayOfMatchingGrids.forEach((grid) => {
        if (limit == 10) return;
        const parentContainer = parentContainerMain.cloneNode();
        const itemType = document.createElement("div");

        itemType.classList.add(
          "rounded",
          "px-2",
          "py-1",
          "mr-4",
          "bg-gray-300"
        );
        itemType.textContent = "Grid";
        parentContainer.appendChild(itemType);
        const nameDiv = document.createElement("div");
        nameDiv.textContent = grid.name;
        parentContainer.appendChild(nameDiv);

        const description = document.createElement("div");
        description.textContent = grid.description;
        description.classList.add("ml-4", "text-sm", "truncate");
        parentContainer.appendChild(description);
        // const color = document.createElement("div");
        // color.innerHTML = `<span style="color:${puck.color}">${puck.color}</span>`;
        // parentContainer.appendChild(color);
        parentContainer.addEventListener("click", () => {
          console.log("clickity", grid.navId, grid.wellNo);
          this.$emit("pasted", grid.navId, grid.wellNo, true);

          this.$refs.keywordresults.classList.add("hidden");
          if (document.getElementById("searchbarforloggedin")) {
            document.getElementById("searchbarforloggedin").value = "";
          }
        });
        this.$refs.keywordresults.appendChild(parentContainer);
        limit++;
      });

      if (
        !arrayOfMatchingPucks.length &&
        !arrayOfMatchingBoxes.length &&
        !arrayOfMatchingGrids.length
      ) {
        const aDiv = document.createElement("div");
        aDiv.textContent = "No results found";
        this.$refs.keywordresults.appendChild(aDiv);
      }

      this.$refs.keywordresults.lastElementChild.style = "margin-bottom:0";
    },
  },

  mounted() {
    console.log("fromsearch", this.topRowHeight);
    this.$refs.searchbar.focus();
  },
};
</script>

<style scoped>
input {
  width: calc(100% - 4rem);
}

.truncate {
  flex: 1;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
