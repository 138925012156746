<template>
  <div>
    <Line
      id="my-chart-graph-id"
      :options="chartOptions"
      :data="chartData"
      :plugins="plugins"
    />
  </div>
</template>

<script>
import { Line } from "vue-chartjs";
import {
  Chart,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

Chart.register(
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Title,
  Tooltip,
  Legend
);

export default {
  name: "LineChart",
  components: {
    Line,
  },
  props: ["allsensordata"],
  data() {
    return {
      chartData: {
        labels: [],
        datasets: [
          {
            label: "Mean",
            data: [],
            borderColor: "red",
            fill: false,
          },
          {
            label: "Median",
            data: [],
            borderColor: "green",
            fill: false,
          },
          {
            label: "Max",
            data: [],
            borderColor: "blue",
            fill: false,
          },
          {
            label: "Min",
            data: [],
            borderColor: "orange",
            fill: false,
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            type: "category",
          },
        },
      },

      plugins: [Tooltip, Legend],
    };
  },
  mounted() {
    const groupedByHour = this.groupByHour(this.allsensordata);
    const labels = Array.from(groupedByHour.keys());
    const meanValues = [];
    const medianValues = [];
    const maxValues = [];
    const minValues = [];

    for (const hour of labels) {
      const hourData = groupedByHour.get(hour);
      const numbers = hourData.map((item) => item.number);
      meanValues.push(this.calculateMean(numbers));
      medianValues.push(this.calculateMedian(numbers));
      maxValues.push(Math.max(...numbers));
      minValues.push(Math.min(...numbers));
    }

    this.chartData.labels = labels;
    this.chartData.datasets[0].data = meanValues;
    this.chartData.datasets[1].data = medianValues;
    this.chartData.datasets[2].data = maxValues;
    this.chartData.datasets[3].data = minValues;
  },
  methods: {
    groupByHour(data) {
      const groupedData = new Map();
      for (const item of data) {
        const hour = new Date(item.dateTime).getHours();
        if (!groupedData.has(hour)) {
          groupedData.set(hour, []);
        }
        groupedData.get(hour).push(item);
      }
      return groupedData;
    },
    calculateMean(numbers) {
      return numbers.reduce((sum, num) => sum + num, 0) / numbers.length;
    },
    calculateMedian(numbers) {
      const sorted = [...numbers].sort((a, b) => a - b);
      const middle = Math.floor(sorted.length / 2);
      if (sorted.length % 2 === 0) {
        return (sorted[middle - 1] + sorted[middle]) / 2;
      }
      return sorted[middle];
    },
  },
};
</script>
