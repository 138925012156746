<template>
  <div
    @click="doShowForm(color)"
    class="text-2xl py-2 portrait:py-4 portrait:text-4xl "
    :class="buttonClass"
  >
    <slot></slot>
  </div>
</template>

<script>
import { computed, inject } from "vue";

export default {
  props: ["color"],
  setup(props) {
    const buttonClass = computed(function() {
      if (props.color == "blue") return "bg-sky-900";
      else return "bg-green-700";
    });

    const doShowForm = inject("doShowForm");

    return { buttonClass, doShowForm };
  },
};
</script>

<style scoped>
div {
  font-size: clamp(10px, 30px, 50px);
  cursor: pointer;
  max-width: 225px;
  max-height: 63px;
  width: 50%;
  padding: clamp(10px, 2vh, 30px);
  text-align: center;
  position: relative;
  color: white;
  box-sizing: content-box;
}

div:hover {
  opacity: 0.9;
}
</style>
