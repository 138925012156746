<template>
  <div class="relative">
    <div
      ref="titlebar"
      class="absolute"
      style="top: -200px; left: 0; z-index: -1"
    ></div>
  </div>
  <div class="w-12/12 sm:px-4 mx-auto">
    <div class="mx-auto w-full sm:w-11/12 mt-4">
      <div class="grid grid-cols-1 sm:grid-cols-2 gap-2">
        <div v-for="(rack, index) in dewar" :key="rack._id">
          <div
            :class="{
              useraccessbottomtab:
                $store.state.activeUser.role == 'lab-admin' &&
                rack.labId == $store.state.activeUser.labId._id,
            }"
          >
            <form
              @submit.prevent="saveRackEdit"
              class="w-full flex flex-col custom-gray-color rounded-xl border-2 px-2 pt-4 border-gray-600 papaBgChange2 cursor-pointer theparentrackelem"
              :ref="'papaBgChange3-' + index"
              @mousemove="showRackExpandMessage($event, false)"
              @mouseleave="showRackExpandMessage($event, true)"
              @click="recordHighlightedRack($event, rack.pucks, index)"
            >
              <fieldset :disabled="!Object.values(rackEditBox).includes(index)">
                <div
                  @click="recordHighlightedRack($event, rack.pucks, index)"
                  class="grid grid-cols-2 gap-4 mb-1"
                  style="grid-template-columns: 1fr max-content"
                >
                  <div class="text-xs">
                    <!-- <div class="grid grid-cols-2 items-stretch gap-x-2"> -->
                    <div class="flex flex-col">
                      <div>Rack ID</div>

                      <input
                        @input="
                          saveData(
                            $event,
                            rack._id,
                            'racks',
                            'customId',
                            false,
                            { rackIndex: index }
                          )
                        "
                        @blur="
                          saveData(
                            $event,
                            rack._id,
                            'racks',
                            'customId',
                            true,
                            { rackIndex: index }
                          )
                        "
                        @click.stop=""
                        class="text-red-700 text-lg font-bold"
                        type="text"
                        v-model="idsInput[index]"
                      />
                    </div>
                    <div>Rack Title</div>
                    <input
                      @input="
                        saveData($event, rack._id, 'racks', 'title', false, {
                          rackIndex: index,
                        })
                      "
                      @blur="
                        saveData($event, rack._id, 'racks', 'title', true, {
                          rackIndex: index,
                        })
                      "
                      @click.stop=""
                      class="text-black text-sm"
                      type="text"
                      v-model="titlesInput[index]"
                    />
                    <div>Rack Details</div>
                    <div>
                      <textarea
                        @input="
                          saveData(
                            $event,
                            rack._id,
                            'racks',
                            'description',
                            false,
                            { rackIndex: index }
                          )
                        "
                        @blur="
                          saveData(
                            $event,
                            rack._id,
                            'racks',
                            'description',
                            true,
                            { rackIndex: index }
                          )
                        "
                        @click.stop=""
                        class="border-2 textarea-dewaredit text-sm"
                        v-model="descriptionsInput[index]"
                      ></textarea>
                      <div class="mt-4">Project ID</div>

                      <select
                        ref="projectselect"
                        :disabled="$store.state.activeUser.role != 'lab-admin'"
                        @change="
                          saveData($event, rack._id, 'racks', 'project', true, {
                            rackIndex: index,
                          })
                        "
                        @click.stop=""
                        class="rounded w-auto"
                      >
                        <option value="" :selected="!rack?.project" disabled>
                          Select a project
                        </option>

                        <option
                          :selected="rack?.project == project._id"
                          v-for="project in $store.state.projects"
                          :key="project._id"
                          :value="project._id"
                        >
                          {{ project.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="flex-1 flex flex-col">
                    <div
                      class="flex flex-col"
                      :class="{
                        invisible: editRackBox[index],
                        'flex-0': editRackBox[index],
                        'h-0': editRackBox[index],
                        visible: !editRackBox[index],
                        'flex-1': !editRackBox[index],
                      }"
                    >
                      <div class="mt-4">
                        <select
                          v-model="typeInput[index]"
                          name="rackSelectBox"
                          @change="
                            saveData(
                              $event,
                              rack._id,
                              'racks',
                              'rackType',
                              true,
                              { rackIndex: index }
                            )
                          "
                          class="py-1 font-bold rounded text-sm"
                        >
                          <option disabled value="undefined">
                            Select your option
                          </option>
                          <option value="Storage">Storage</option>
                          <option value="Transport">Transport</option>
                        </select>
                        <!-- <select
                          v-model="typeInput[index]"
                          name="rackSelectBox"
                          @change="addSelectToArray($event, index)"
                          class="py-1 font-bold rounded text-sm"
                        >
                          <option disabled value="undefined">
                            Select your option
                          </option>
                          <option value="Storage">Storage</option>
                          <option value="Transport">Transport</option>
                        </select> -->
                      </div>
                      <div
                        @click.stop
                        class="flex justify-end flex-1 flex-col puckslist"
                      >
                        <!--- old svg thing -->

                        <div
                          class="flex-1 bg-white rounded-lg px-2 py-1 overflow-y-scroll relative"
                        >
                          <div class="absolute pr-2">
                            <ul class="flex flex-col items-stretch w-full">
                              <li
                                class="font-bold text-sm text-gray-800 flex-1"
                                v-for="(puck, indexPuck) in sortPucks(
                                  rack.pucks
                                )"
                                :key="puck._id"
                              >
                                <div
                                  class="flex items-stretch mt-1 mb-1 rounded text-white px-2 py-1 break-words"
                                  :style="{
                                    background:
                                      this.$store.state.transformPuckColor(
                                        puck.color
                                      ),
                                  }"
                                >
                                  <div>
                                    <input
                                      class="cursor-pointer w-4 h-full block mb-0 pb-0"
                                      type="checkbox"
                                      :key="puck._id"
                                      checked="true"
                                      @click="
                                        addAPuckToRack(
                                          $event,
                                          puck._id,
                                          null,
                                          puck.labId._id,
                                          indexPuck
                                        )
                                      "
                                    />
                                  </div>
                                  <div class="ml-1">
                                    {{ puck.serialNo }}
                                    <div v-if="puck.title != 'Puck'">
                                      ({{ puck.title }})
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      @click.stop
                      class="text-xs flex flex-col justify-end flex-1"
                      v-show="editRackBox[index]"
                    >
                      <div class="flex">
                        <div>Unassigned Pucks</div>
                        <div
                          class="flex-1 flex justify-end cursor-pointer custom-x-position"
                          @click="closeUnassignedPucks(index)"
                        >
                          <span
                            class="bg-red-700 text-center mb-1 w-4 h-4 text-white font-semibold flex items-center justify-center"
                            style="border-radius: 50%"
                            ><span class="relative -top-px">x</span></span
                          >
                        </div>
                      </div>

                      <div
                        class="flex-1 relative overflow-y-scroll bg-white rounded-lg px-2 py-1"
                      >
                        <div class="absolute pr-2">
                          <ul class="flex flex-col items-stretch w-full">
                            <li
                              v-for="(puck, indexPuck) in $store.state
                                .unassignedPucks"
                              :key="puck._id"
                              class="mt-1 mb-1 rounded text-white p-2 text-sm font-bold py-1"
                              :style="{
                                background:
                                  this.$store.state.transformPuckColor(
                                    puck.color
                                  ),
                              }"
                            >
                              <div class="flex items-stretch break-words">
                                <div>
                                  <input
                                    class="cursor-pointer w-4 h-full block mb-0 pb-0"
                                    :value="puck._id"
                                    v-model="addPuckBox[indexPuck]"
                                    type="checkbox"
                                    @click="
                                      addAPuckToRack(
                                        $event,
                                        puck._id,
                                        rack._id,
                                        puck.labId._id,
                                        indexPuck
                                      )
                                    "
                                  />
                                </div>

                                <span>&nbsp;{{ puck.serialNo }}</span>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div></div>
                  <div v-show="!editRackBox[index]">
                    <div
                      class="w-full rounded bg-green-700 border-2 border-green-800 text-white font-bold text-center cursor-pointer"
                      :class="{
                        buttondisabled:
                          !Object.values(rackEditBox).includes(index),
                      }"
                    >
                      <div v-if="rack.pucks.length >= 10">Rack full</div>

                      <div v-else @click="addAPuck(index)" @click.stop="">
                        Add a puck
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
              <div class="flex-1 flex items-end edit-dewar">
                <div class="grid grid-cols-2 items-end" v-if="!rack.readOnly">
                  <div
                    class="flex items-stretch place-content-end"
                    @click.stop=""
                  >
                    <div>
                      <input
                        class="cursor-pointer w-4 h-full block mb-0 pb-0"
                        name="rackEditBox"
                        :value="index"
                        v-model="rackEditBox"
                        type="checkbox"
                        :id="'edit-rack-box-' + index"
                      />
                    </div>

                    <label
                      class="cursor-pointer"
                      :for="'edit-rack-box-' + index"
                      >&nbsp;Edit Rack</label
                    >
                  </div>

                  <!-- <button
                    :class="{
                      buttondisabled:
                        !Object.values(rackEditBox).includes(index),
                    }"
                    @click="updateRack(rack._id, index)"
                    :disabled="!Object.values(rackEditBox).includes(index)"
                    type="submit"
                    class="my-0 ml-4 h-full bg-green-700 border-green-900 border-0 rounded"
                  >
                    Save
                  </button> -->
                </div>
              </div>
              <div class="text-xs mt-4">
                Date modified: {{ cleanDate(rack.dateModified) }}
              </div>
              <div class="text-xs mt-2">
                Rack Serial Number: {{ rack.serialNo }}
              </div>
              <div class="flex justify-center">
                <div
                  class="bg-emerald-600 text-white font-semibold rounded-t-lg px-4 pt-2 pb-1 mt-1 flex items-end"
                  @click="recordHighlightedRack($event, rack.pucks, index)"
                >
                  <span class="material-symbols-outlined">
                    keyboard_double_arrow_down
                  </span>
                  Display Pucks
                  <span class="material-symbols-outlined">
                    keyboard_double_arrow_down
                  </span>

                  <!-- <i class="fa-solid fa-chevrons-down"></i> Display Pucks
                  <i class="fa-solid fa-chevrons-down"></i> -->
                </div>
              </div>
            </form>
          </div>
          <custom-user-access
            container="rack"
            :containerobj="rack"
            :index="index"
          ></custom-user-access>
        </div>
      </div>
      <div
        class="skyblue mt-4 p-2"
        ref="activerackpucksauto"
        v-if="activeRack && activePuckData.length > 0"
      >
        <div class="bg-white p-2 grid grid-cols-5 text-black gap-2">
          <!-- all the pucks in a rack displayed-->
          <div
            class="cursor-pointer relative"
            @mousemove="showRackExpandMessage($event, false)"
            @mouseleave="showRackExpandMessage($event, true)"
            :ref="'puck-' + puck._id"
            v-for="(puck, index) in activePuckData"
            @click="setActivePuck(puck, 'puck-' + puck._id, index)"
            :key="puck._id"
          >
            <div class="text-center break-all">{{ puck.serialNo }}</div>
            <div
              v-if="puck.title != 'Puck'"
              class="pucktitle text-center absolute leading-none mx-auto w-full break-all"
            >
              &nbsp;({{ puck.title }})
            </div>
            <div
              style="
                background-image: url('assets/images/silver-puck-fade.svg');
                background-repeat: no-repeat;
                background-position: center top;
              "
              class="pt-1"
            >
              <svg
                v-if="puck.puckType != 'Cartridge'"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                version="1.1"
                :id="'pucksvg-' + index"
                x="0px"
                y="0px"
                viewBox="0 0 407.54 435.08"
                style="enable-background: new 0 0 407.54 435.08"
                xml:space="preserve"
              >
                <svg:style>
                  #pucksvg-{{index}} .st0 {

                    fill: {{  this.$store.state.transformPuckColor(puck.color)}};
                  }
                  #pucksvg-{{index}} .st1 {
                               fill: {{this.$store.state.transformPuckColor(puck.color,true)}};

                  }
                  #pucksvg-{{index}} .st2 {
                    fill: #ffffff;
                  }
                  #pucksvg-{{index}} .st3 {
                    fill: none;
                  }
                </svg:style>
                <path
                  class="st0"
                  d="M396.39,213.01c-8.42-7.48-19.68,0.3-25.88-5.9c-6.21-6.21-11.58-56.84-17.13-74.56  c-5.55-17.72-16.33-11.93-25.59-13.62c-6.08-1.11-11.29-5.19-14.1-24.76c-3.53-24.59-9.52-36.2-9.52-36.2s-7.89-4.6-25.02-12.64  c-35.08-16.46-74.96-15.21-74.96-15.21h-0.15V30.1c-0.11,0-0.19,0-0.26,0.01c-0.06,0-0.14,0-0.26-0.01v0.01h-0.15  c0,0-39.88-1.25-74.96,15.21c-17.14,8.04-25.02,12.64-25.02,12.64s-5.99,11.61-9.52,36.2c-2.81,19.57-8.02,23.65-14.1,24.76  c-9.26,1.69-20.05-4.11-25.59,13.62c-5.55,17.72-10.92,68.35-17.13,74.56c-6.21,6.21-17.47-1.57-25.88,5.9  c-6.31,5.61-24.31,21.1,5.6,95.19c12.14,30.07,30.78,55.5,47.64,74.16c25.75,28.49,43.22,37.05,62.66,23.34  c11.33-7.99,28.9-9.35,36.52-3.25c6.56,5.25,5.94,13.11-1.74,13.92c-7.68,0.82-12.52,5.55-9.89,11.46  c2.66,5.99,19.68,7.44,29.6,7.23c10.04-0.21,14.3-3.16,14.3-3.16s0.15-15.18,2-21.78c1.16-4.16,5.16-4.22,5.16-4.22h0.54h0.51h0.54  c0,0,4,0.06,5.16,4.22c1.85,6.6,2,21.78,2,21.78s4.26,2.95,14.3,3.16c9.91,0.2,26.94-1.25,29.6-7.23  c2.63-5.91-2.22-10.64-9.89-11.46c-7.68-0.82-8.3-8.67-1.74-13.92c7.61-6.1,25.19-4.74,36.52,3.25  c19.43,13.71,36.91,5.15,62.66-23.34c16.86-18.66,35.5-44.09,47.64-74.16C420.7,234.1,402.7,218.62,396.39,213.01z"
                />
                <g>
                  <path
                    class="st1"
                    d="M153.31,131.42c17.65,0,32.73-10.96,38.83-26.44h23.24c6.1,15.48,21.18,26.44,38.83,26.44   c23.05,0,41.74-18.69,41.74-41.74s-18.69-41.74-41.74-41.74c-17.65,0-32.73,10.96-38.83,26.44h-23.24   c-6.1-15.48-21.18-26.44-38.83-26.44c-23.05,0-41.74,18.69-41.74,41.74C111.57,112.73,130.26,131.42,153.31,131.42z"
                  ></path>

                  <path
                    class="st1"
                    d="M347.3,218.42c-17.65,0-32.73,10.96-38.83,26.44h-18.24c-6.1-15.48-21.18-26.44-38.83-26.44   s-32.73,10.96-38.83,26.44h-17.56c-6.1-15.48-21.18-26.44-38.83-26.44s-32.73,10.96-38.83,26.44H99.08   c-6.1-15.48-21.18-26.44-38.83-26.44c-23.05,0-41.74,18.69-41.74,41.74S37.2,301.9,60.25,301.9c17.65,0,32.73-10.96,38.83-26.44   h18.24c6.1,15.48,21.18,26.44,38.83,26.44s32.73-10.96,38.83-26.44h17.56c6.1,15.48,21.18,26.44,38.83,26.44   s32.73-10.96,38.83-26.44h18.24c6.1,15.48,21.18,26.44,38.83,26.44c23.05,0,41.74-18.69,41.74-41.74   C389.04,237.11,370.35,218.42,347.3,218.42z"
                  />
                  <path
                    class="st1"
                    d="M105.25,216.66c17.65,0,32.73-10.96,38.83-26.44h20.85c6.1,15.48,21.18,26.44,38.83,26.44   s32.73-10.96,38.83-26.44h20.85c6.1,15.48,21.18,26.44,38.83,26.44c23.05,0,41.74-18.69,41.74-41.74s-18.69-41.74-41.74-41.74   c-17.65,0-32.73,10.96-38.83,26.44H242.6c-6.1-15.48-21.18-26.44-38.83-26.44s-32.73,10.96-38.83,26.44h-20.85   c-6.1-15.48-21.18-26.44-38.83-26.44c-23.05,0-41.74,18.69-41.74,41.74C63.51,197.97,82.2,216.66,105.25,216.66z"
                  />
                  <path
                    class="st1"
                    d="M302.29,303.66c-17.65,0-32.73,10.96-38.83,26.44H242.6c-6.1-15.48-21.18-26.44-38.83-26.44   s-32.73,10.96-38.83,26.44h-20.85c-6.1-15.48-21.18-26.44-38.83-26.44c-23.05,0-41.74,18.69-41.74,41.74s18.69,41.74,41.74,41.74   c17.65,0,32.73-10.96,38.83-26.44h20.85c6.1,15.48,21.18,26.44,38.83,26.44s32.73-10.96,38.83-26.44h20.85   c6.1,15.48,21.18,26.44,38.83,26.44c23.05,0,41.74-18.69,41.74-41.74C344.03,322.34,325.34,303.66,302.29,303.66z"
                  />
                </g>
                <g>
                  <path
                    class="st0"
                    d="M163.94,102.3v7.25h-21.58v-7.25h5.82V74.45h-6.21V67.2h16.52v35.1C158.49,102.3,163.94,102.3,163.94,102.3z"
                  />
                </g>
                <g>
                  <path
                    class="st0"
                    d="M270.58,78.36c0,2.15-0.48,4.03-1.45,5.62c-0.96,1.59-2.26,3.04-3.89,4.34s-4,2.89-7.11,4.76   c-5.06,3.11-7.86,6.18-8.4,9.22h12.79v-5.48h8.57l-0.51,12.73h-30.86c0-3.77,0.45-6.87,1.35-9.3c0.9-2.44,2.32-4.67,4.26-6.7   c1.94-2.03,4.79-4.44,8.56-7.24c1.99-1.46,3.5-2.88,4.54-4.24c1.04-1.37,1.56-2.77,1.56-4.22c0-1.42-0.41-2.5-1.24-3.23   c-0.82-0.73-2-1.1-3.54-1.1c-1.99,0-3.46,0.69-4.43,2.07s-1.54,3.62-1.73,6.73l-8.8-0.34c0.11-5.23,1.45-9.12,4.02-11.69   s6.41-3.85,11.52-3.85c4.59,0,8.2,1.05,10.83,3.16C269.26,71.71,270.58,74.63,270.58,78.36z"
                  />
                </g>
                <g>
                  <path
                    class="st0"
                    d="M118.56,163.26c0,4.25-2.13,7.37-6.38,9.36c5.32,1.82,7.98,5.18,7.98,10.09c0,2.53-0.68,4.77-2.04,6.73   c-1.36,1.96-3.25,3.47-5.66,4.54c-2.42,1.07-5.14,1.6-8.18,1.6c-10.14,0-15.57-4.62-16.3-13.85l9.22-0.76   c0.04,4.95,2.07,7.42,6.1,7.42c1.85,0,3.36-0.58,4.52-1.74s1.74-2.59,1.74-4.3c0-1.76-0.61-3.19-1.84-4.29   c-1.23-1.1-2.96-1.64-5.21-1.64c-0.36,0-1.14,0.05-2.36,0.14v-7.73c2.6-0.04,4.64-0.5,6.1-1.38s2.19-2.15,2.19-3.79   c0-1.31-0.49-2.38-1.48-3.2c-0.98-0.82-2.23-1.24-3.72-1.24c-3.37,0-5.06,1.88-5.06,5.65l-8.91-0.76c0.3-3.84,1.74-6.83,4.33-8.98   s5.99-3.22,10.23-3.22c4.48,0,8.05,1.02,10.72,3.05C117.23,156.99,118.56,159.76,118.56,163.26z"
                  />
                </g>
                <g>
                  <path
                    class="st0"
                    d="M218.46,176.61v6.88h-4.5v4.83h3.93v6.69h-18.77v-6.69h5.11v-4.83h-17.48c-0.94-2.6-1.71-4.48-2.3-5.62   l20.37-25.97h9.13v24.7L218.46,176.61L218.46,176.61z M194.46,176.61h9.78v-12.22L194.46,176.61z"
                  />
                </g>
                <g>
                  <path
                    class="st0"
                    d="M310.12,163.01l-0.76-3.09h-11.58l-0.84,8.96c2.34-1.22,4.81-1.83,7.42-1.83c3,0,5.63,0.6,7.91,1.81   c2.28,1.21,4.04,2.89,5.28,5.03c1.25,2.15,1.87,4.48,1.87,7.01c0,2.92-0.67,5.51-2.01,7.76s-3.25,3.96-5.72,5.14   s-5.36,1.77-8.66,1.77c-4.46,0-8.09-1.08-10.89-3.25c-2.8-2.16-4.47-5.18-5.02-9.06l9.05-1.1c0.66,4.1,2.73,6.15,6.21,6.15   c2.08,0,3.66-0.58,4.75-1.74s1.63-2.82,1.63-4.97c0-4.76-2.05-7.14-6.15-7.14c-2.42,0-4.45,1.13-6.1,3.4l-7.48-3.68l1.49-21.53   h26.78v10.34h-7.18L310.12,163.01L310.12,163.01z"
                  />
                </g>
                <g>
                  <path
                    class="st0"
                    d="M102.38,357.69v7.25H80.8v-7.25h5.82v-27.85h-6.21v-7.25h16.52v35.1H102.38z"
                  />
                  <path
                    class="st0"
                    d="M134.2,343.64c0,7.14-1.36,12.57-4.07,16.28c-2.72,3.72-6.63,5.58-11.75,5.58c-5.13,0-9.05-1.86-11.76-5.58   s-4.06-9.15-4.06-16.28c0-6.97,1.34-12.35,4.03-16.13s6.62-5.68,11.79-5.68c5.23,0,9.17,1.85,11.83,5.55S134.2,336.5,134.2,343.64z    M113.21,343.64c0,4.91,0.42,8.54,1.26,10.9c0.84,2.36,2.16,3.54,3.96,3.54s3.11-1.18,3.93-3.54c0.82-2.36,1.24-6,1.24-10.9   c0-9.67-1.72-14.5-5.17-14.5c-1.8,0-3.12,1.21-3.96,3.63C113.63,335.18,113.21,338.81,113.21,343.64z"
                  />
                </g>
                <g>
                  <path
                    class="st0"
                    d="M202.81,357.69v7.25h-21.58v-7.25h5.82v-27.85h-6.21v-7.25h16.52v35.1H202.81z"
                  />
                  <path
                    class="st0"
                    d="M228.7,357.69v7.25h-21.58v-7.25h5.82v-27.85h-6.21v-7.25h16.52v35.1H228.7z"
                  />
                </g>
                <g>
                  <path
                    class="st0"
                    d="M298.58,357.69v7.25H277v-7.25h5.82v-27.85h-6.21v-7.25h16.52v35.1H298.58z"
                  />
                  <path
                    class="st0"
                    d="M332.14,333.75c0,2.15-0.48,4.03-1.45,5.62s-2.26,3.04-3.89,4.34s-4,2.89-7.11,4.76   c-5.06,3.11-7.86,6.18-8.4,9.22h12.79v-5.48h8.57l-0.51,12.73h-30.86c0-3.77,0.45-6.87,1.35-9.3s2.32-4.67,4.26-6.7   s4.79-4.44,8.56-7.24c1.99-1.46,3.5-2.88,4.54-4.24c1.04-1.37,1.56-2.77,1.56-4.21c0-1.42-0.41-2.5-1.24-3.23   c-0.82-0.73-2-1.1-3.54-1.1c-1.99,0-3.46,0.69-4.43,2.07s-1.54,3.62-1.73,6.73l-8.8-0.34c0.11-5.23,1.45-9.12,4.02-11.69   c2.57-2.57,6.41-3.85,11.52-3.85c4.59,0,8.2,1.05,10.83,3.16C330.83,327.1,332.14,330.02,332.14,333.75z"
                  />
                </g>
                <g>
                  <path
                    class="st0"
                    d="M75.65,249.67l-8.35,0.73c-0.96-3.52-2.8-5.28-5.54-5.28c-2.02,0-3.65,0.93-4.88,2.8s-1.96,4.52-2.21,7.97   c2.27-1.46,4.88-2.19,7.84-2.19c2.68,0,5.05,0.59,7.12,1.76c2.07,1.17,3.68,2.76,4.83,4.78c1.15,2.01,1.73,4.29,1.73,6.81   c0,4.53-1.3,8.11-3.91,10.73c-2.6,2.62-6.19,3.93-10.76,3.93c-5.83,0-10.12-1.73-12.88-5.2c-2.76-3.47-4.15-8.84-4.15-16.13   c0-7.33,1.49-12.88,4.48-16.66s7.39-5.68,13.19-5.68C70.24,238.03,74.73,241.91,75.65,249.67z M54.63,262.79   c0,4.14,0.57,7.12,1.7,8.94s2.85,2.73,5.16,2.73c1.5,0,2.72-0.59,3.65-1.79c0.94-1.19,1.4-2.87,1.4-5.04   c0-2.04-0.52-3.62-1.57-4.74c-1.05-1.11-2.5-1.67-4.36-1.67C58.85,261.22,56.86,261.74,54.63,262.79z"
                  />
                </g>
                <g>
                  <path
                    class="st0"
                    d="M157.78,273.89h6.1v7.25h-22.62v-7.25h5.82c0.62-4.87,2.06-9.82,4.33-14.85s5.12-9.42,8.57-13.17h-13.94v5.84   h-7.76v-12.93h31.75v5.45c-2.17,2.08-4.19,4.82-6.06,8.23c-1.86,3.41-3.34,7.03-4.44,10.85   C158.44,267.15,157.85,270.67,157.78,273.89z"
                  />
                </g>
                <g class="relative">
                  <path
                    class="st0"
                    d="M266.11,249.13c0,4.07-2.07,7.31-6.21,9.72c4.98,2,7.48,5.48,7.48,10.43c0,3.95-1.45,7.01-4.34,9.17   s-6.8,3.25-11.7,3.25c-3.15,0-5.92-0.51-8.32-1.52s-4.25-2.47-5.55-4.37s-1.95-4.13-1.95-6.7c0-4.68,2.53-8.1,7.59-10.26   c-4.22-2.34-6.32-5.53-6.32-9.55c0-2.25,0.64-4.23,1.92-5.94s3.04-3.03,5.25-3.95c2.22-0.92,4.7-1.38,7.43-1.38   c2.79,0,5.31,0.45,7.56,1.35c2.25,0.9,4,2.18,5.27,3.85C265.47,244.9,266.11,246.87,266.11,249.13z M251.49,274.65   c1.72,0,3.13-0.59,4.23-1.78c1.1-1.19,1.64-2.67,1.64-4.45c0-1.76-0.56-3.25-1.67-4.48s-2.53-1.84-4.26-1.84   c-1.65,0-3.02,0.61-4.12,1.84c-1.1,1.23-1.64,2.72-1.64,4.48c0,1.78,0.55,3.26,1.64,4.45   C248.42,274.06,249.81,274.65,251.49,274.65z M251.38,255.71c1.54,0,2.76-0.48,3.68-1.45c0.92-0.96,1.38-2.27,1.38-3.92   c0-1.61-0.44-2.88-1.32-3.82s-2.15-1.41-3.79-1.41c-1.46,0-2.64,0.44-3.54,1.32s-1.35,2.22-1.35,4.02c0,1.54,0.46,2.8,1.38,3.78   C248.73,255.22,249.92,255.71,251.38,255.71z"
                  ></path>
                </g>
                <g>
                  <path
                    class="st0"
                    d="M366.19,259.78c0,14.61-5.77,21.92-17.31,21.92c-3.92,0-7.09-1.04-9.53-3.11c-2.44-2.07-3.79-4.96-4.07-8.67   l8.23-0.82c0.58,1.87,1.29,3.26,2.14,4.15c0.84,0.89,1.9,1.33,3.18,1.33c1.99,0,3.58-0.92,4.79-2.77c1.21-1.84,2.01-4.53,2.4-8.05   c-1.2,0.64-2.51,1.14-3.92,1.5s-2.85,0.55-4.31,0.55c-2.57,0-4.85-0.6-6.84-1.81c-2-1.21-3.55-2.84-4.66-4.89   c-1.12-2.05-1.67-4.27-1.67-6.65c0-2.79,0.63-5.28,1.9-7.47c1.26-2.19,3.04-3.9,5.33-5.13c2.29-1.23,4.93-1.84,7.92-1.84   C360.71,238.03,366.19,245.28,366.19,259.78z M350.09,258.52c1.72,0,3.74-0.55,6.04-1.66v-0.17c0-3.58-0.62-6.38-1.87-8.42   c-1.25-2.03-3-3.05-5.27-3.05c-1.35,0-2.49,0.61-3.43,1.84s-1.41,2.82-1.41,4.79c0,2.02,0.55,3.64,1.64,4.85   C346.9,257.92,348.33,258.52,350.09,258.52z"
                  />
                </g>
                <g>
                  <g>
                    <g>
                      <path
                        class="st2"
                        d="M157.34,147.56h-6.79v-1.78h2.15v-5.38h-2.15v-1.66c0.33,0,0.64-0.02,0.94-0.06c0.3-0.04,0.55-0.11,0.75-0.2     c0.24-0.11,0.41-0.26,0.53-0.44s0.19-0.41,0.21-0.68h2.26v8.43h2.1V147.56z"
                      />
                    </g>
                    <g>
                      <path
                        class="st2"
                        d="M256.86,147.56h-7.99v-1.68c0.61-0.44,1.22-0.91,1.84-1.41c0.61-0.5,1.1-0.92,1.47-1.29     c0.55-0.53,0.94-1,1.18-1.39s0.35-0.79,0.35-1.18c0-0.46-0.15-0.82-0.45-1.08c-0.3-0.25-0.73-0.38-1.29-0.38     c-0.42,0-0.86,0.09-1.32,0.26c-0.46,0.17-0.89,0.39-1.29,0.66h-0.22v-2.26c0.32-0.14,0.8-0.28,1.43-0.42     c0.63-0.14,1.26-0.21,1.9-0.21c1.28,0,2.25,0.27,2.92,0.8c0.67,0.54,1,1.29,1,2.27c0,0.64-0.16,1.25-0.48,1.83     s-0.81,1.18-1.47,1.79c-0.41,0.38-0.83,0.73-1.25,1.06c-0.42,0.32-0.72,0.54-0.9,0.67h4.57V147.56z"
                      />
                    </g>
                    <g>
                      <path
                        class="st2"
                        d="M108.66,228.14c0.22,0.19,0.4,0.42,0.53,0.67s0.2,0.59,0.2,1.02c0,0.48-0.09,0.92-0.28,1.34     s-0.48,0.77-0.87,1.07c-0.38,0.29-0.83,0.51-1.35,0.66c-0.52,0.15-1.15,0.23-1.88,0.23c-0.84,0-1.57-0.07-2.17-0.2     s-1.09-0.28-1.47-0.44v-2.24h0.27c0.39,0.24,0.86,0.44,1.4,0.62s1.04,0.26,1.49,0.26c0.26,0,0.55-0.02,0.86-0.06     c0.31-0.04,0.57-0.14,0.79-0.28c0.17-0.11,0.3-0.25,0.4-0.41c0.1-0.16,0.15-0.4,0.15-0.7c0-0.3-0.07-0.53-0.21-0.69     c-0.14-0.16-0.32-0.28-0.54-0.34c-0.22-0.07-0.49-0.11-0.81-0.12c-0.31-0.01-0.61-0.01-0.88-0.01h-0.56v-1.82h0.58     c0.36,0,0.67-0.01,0.94-0.03s0.51-0.08,0.7-0.16c0.2-0.09,0.35-0.2,0.45-0.35c0.1-0.14,0.15-0.35,0.15-0.63     c0-0.21-0.05-0.37-0.16-0.5c-0.1-0.13-0.24-0.22-0.4-0.3c-0.18-0.08-0.39-0.14-0.63-0.16s-0.45-0.04-0.62-0.04     c-0.43,0-0.89,0.07-1.39,0.22c-0.5,0.15-0.99,0.36-1.46,0.64h-0.25v-2.21c0.37-0.15,0.88-0.29,1.52-0.43     c0.64-0.14,1.29-0.21,1.96-0.21c0.64,0,1.21,0.06,1.69,0.17c0.48,0.11,0.88,0.26,1.2,0.45c0.37,0.22,0.65,0.49,0.83,0.81     s0.27,0.69,0.27,1.12c0,0.57-0.18,1.07-0.53,1.51c-0.35,0.44-0.81,0.73-1.39,0.85v0.1c0.23,0.03,0.48,0.1,0.74,0.19     S108.44,227.96,108.66,228.14z"
                      />
                    </g>
                    <g>
                      <path
                        class="st2"
                        d="M63.97,315.02c0,0.52-0.1,1.02-0.29,1.48s-0.46,0.85-0.81,1.16c-0.37,0.34-0.8,0.59-1.28,0.77     s-1.05,0.27-1.71,0.27c-0.61,0-1.17-0.08-1.67-0.25c-0.5-0.17-0.94-0.42-1.3-0.76c-0.41-0.39-0.73-0.9-0.95-1.52     s-0.33-1.36-0.33-2.22c0-0.89,0.1-1.69,0.31-2.38s0.54-1.31,1-1.84c0.45-0.51,1.03-0.91,1.74-1.19s1.56-0.42,2.55-0.42     c0.33,0,0.7,0.02,1.09,0.07s0.65,0.08,0.77,0.1v2h-0.26c-0.12-0.06-0.33-0.13-0.63-0.2c-0.29-0.07-0.63-0.11-1-0.11     c-0.88,0-1.56,0.21-2.04,0.64s-0.78,1.03-0.88,1.79c0.35-0.21,0.72-0.38,1.11-0.51c0.39-0.13,0.81-0.2,1.26-0.2     c0.4,0,0.76,0.05,1.1,0.14s0.66,0.24,0.95,0.44c0.38,0.26,0.68,0.62,0.91,1.07S63.97,314.35,63.97,315.02z M60.86,316.56     c0.14-0.16,0.25-0.34,0.34-0.55c0.08-0.21,0.13-0.5,0.13-0.86c0-0.33-0.05-0.6-0.14-0.82c-0.1-0.21-0.23-0.38-0.4-0.51     c-0.16-0.13-0.36-0.22-0.58-0.26c-0.22-0.05-0.45-0.07-0.69-0.07c-0.2,0-0.41,0.02-0.63,0.07s-0.42,0.1-0.6,0.17     c0,0.05,0,0.12-0.01,0.23s-0.01,0.24-0.01,0.4c0,0.56,0.06,1.02,0.17,1.38c0.11,0.36,0.26,0.64,0.45,0.83     c0.13,0.14,0.28,0.25,0.45,0.31c0.17,0.07,0.36,0.1,0.56,0.1c0.15,0,0.32-0.03,0.5-0.1S60.73,316.7,60.86,316.56z"
                      />
                    </g>
                    <g>
                      <path
                        class="st2"
                        d="M159.99,310.33l-4.61,8.16h-2.93l4.78-8.23h-5.23v-1.95h7.98V310.33z"
                      />
                    </g>
                    <g>
                      <path
                        class="st2"
                        d="M141.62,390.54h-7.42v-1.78h2.34v-5.38h-2.34v-1.66c0.36,0,0.7-0.02,1.03-0.06c0.33-0.04,0.6-0.11,0.82-0.2     c0.26-0.11,0.45-0.26,0.58-0.44c0.13-0.18,0.2-0.41,0.22-0.68h2.47v8.43h2.29V390.54z"
                      />
                      <path
                        class="st2"
                        d="M153.11,385.44c0,0.86-0.08,1.62-0.25,2.3c-0.17,0.68-0.43,1.23-0.79,1.67c-0.37,0.44-0.84,0.78-1.42,1     c-0.58,0.23-1.29,0.34-2.13,0.34c-0.83,0-1.54-0.11-2.13-0.34c-0.59-0.23-1.06-0.57-1.42-1.01c-0.37-0.45-0.63-1-0.8-1.67     c-0.16-0.66-0.25-1.42-0.25-2.28c0-0.88,0.08-1.65,0.25-2.3c0.17-0.65,0.44-1.2,0.81-1.66c0.37-0.45,0.85-0.78,1.43-1.01     s1.29-0.33,2.1-0.33c0.85,0,1.56,0.12,2.14,0.35s1.05,0.57,1.42,1.03c0.36,0.45,0.63,1,0.79,1.65     C153.03,383.83,153.11,384.58,153.11,385.44z M150.22,385.44c0-1.23-0.13-2.11-0.4-2.63c-0.26-0.52-0.7-0.78-1.31-0.78     s-1.05,0.26-1.31,0.78c-0.26,0.52-0.4,1.4-0.4,2.65c0,1.21,0.13,2.08,0.4,2.61c0.27,0.53,0.7,0.8,1.31,0.8     c0.6,0,1.04-0.27,1.31-0.8C150.09,387.53,150.22,386.66,150.22,385.44z"
                      />
                    </g>
                    <g>
                      <path
                        class="st2"
                        d="M203.7,401.39h-6.79v-1.78h2.15v-5.38h-2.15v-1.66c0.33,0,0.64-0.02,0.94-0.06c0.3-0.04,0.55-0.11,0.75-0.2     c0.24-0.11,0.41-0.26,0.53-0.44c0.12-0.18,0.19-0.41,0.21-0.68h2.26v8.43h2.1V401.39z"
                      />
                      <path
                        class="st2"
                        d="M213.09,401.39h-6.79v-1.78h2.15v-5.38h-2.15v-1.66c0.33,0,0.64-0.02,0.94-0.06c0.3-0.04,0.55-0.11,0.75-0.2     c0.24-0.11,0.42-0.26,0.53-0.44c0.12-0.18,0.19-0.41,0.21-0.68h2.26v8.43h2.1V401.39z"
                      />
                    </g>
                    <g>
                      <path
                        class="st2"
                        d="M259.55,389.87h-6.79v-1.78h2.15v-5.38h-2.15v-1.66c0.33,0,0.64-0.02,0.94-0.06c0.3-0.04,0.55-0.11,0.75-0.2     c0.24-0.11,0.41-0.26,0.53-0.44c0.12-0.18,0.19-0.41,0.21-0.68h2.26v8.43h2.1V389.87z"
                      />
                      <path
                        class="st2"
                        d="M270.15,389.87h-7.99v-1.68c0.61-0.44,1.22-0.91,1.83-1.41c0.61-0.5,1.1-0.92,1.47-1.29     c0.55-0.53,0.94-1,1.18-1.39s0.35-0.79,0.35-1.18c0-0.46-0.15-0.82-0.45-1.08c-0.3-0.25-0.73-0.38-1.29-0.38     c-0.42,0-0.86,0.09-1.32,0.26c-0.46,0.17-0.89,0.39-1.29,0.66h-0.22v-2.26c0.32-0.14,0.8-0.28,1.43-0.42     c0.63-0.14,1.26-0.21,1.9-0.21c1.28,0,2.25,0.27,2.92,0.8c0.67,0.54,1,1.29,1,2.27c0,0.64-0.16,1.25-0.48,1.83     s-0.81,1.18-1.47,1.79c-0.42,0.38-0.83,0.73-1.25,1.06c-0.42,0.32-0.72,0.54-0.9,0.67h4.57V389.87z"
                      />
                    </g>
                    <g>
                      <path
                        class="st2"
                        d="M256.44,315.65c0,0.9-0.38,1.64-1.15,2.21c-0.77,0.57-1.82,0.86-3.16,0.86c-0.75,0-1.4-0.08-1.93-0.23     c-0.54-0.16-0.98-0.37-1.33-0.64c-0.35-0.27-0.6-0.58-0.77-0.94c-0.17-0.36-0.25-0.75-0.25-1.16c0-0.51,0.15-0.96,0.45-1.36     c0.3-0.39,0.81-0.74,1.55-1.04v-0.04c-0.59-0.27-1.03-0.62-1.31-1.03c-0.28-0.42-0.42-0.9-0.42-1.44c0-0.81,0.37-1.47,1.12-1.98     c0.75-0.51,1.72-0.77,2.92-0.77c1.26,0,2.24,0.23,2.96,0.7c0.71,0.47,1.07,1.09,1.07,1.87c0,0.48-0.15,0.91-0.45,1.29     s-0.76,0.7-1.38,0.96v0.04c0.71,0.27,1.24,0.63,1.58,1.09S256.44,315.03,256.44,315.65z M253.82,315.76     c0-0.33-0.08-0.58-0.25-0.75s-0.5-0.36-1.01-0.58c-0.15-0.07-0.37-0.15-0.66-0.25s-0.53-0.19-0.72-0.26     c-0.2,0.18-0.37,0.39-0.53,0.65c-0.16,0.25-0.24,0.54-0.24,0.85c0,0.47,0.17,0.85,0.51,1.13c0.34,0.28,0.78,0.42,1.33,0.42     c0.15,0,0.32-0.02,0.51-0.06c0.2-0.04,0.37-0.11,0.51-0.2c0.16-0.1,0.3-0.23,0.4-0.37C253.77,316.19,253.82,316,253.82,315.76z      M253.61,310.94c0-0.35-0.13-0.62-0.4-0.83s-0.62-0.31-1.06-0.31c-0.16,0-0.33,0.02-0.5,0.06s-0.33,0.1-0.47,0.18     c-0.13,0.08-0.24,0.18-0.33,0.3c-0.09,0.13-0.13,0.27-0.13,0.43c0,0.27,0.08,0.48,0.23,0.63c0.15,0.15,0.4,0.3,0.75,0.45     c0.13,0.05,0.3,0.12,0.52,0.2c0.22,0.08,0.49,0.17,0.8,0.27c0.21-0.25,0.36-0.47,0.45-0.66     C253.57,311.47,253.61,311.23,253.61,310.94z"
                      />
                    </g>
                    <g>
                      <path
                        class="st2"
                        d="M352.41,312.83c0,0.85-0.11,1.65-0.33,2.38s-0.55,1.35-1,1.85c-0.48,0.54-1.08,0.95-1.8,1.22     c-0.71,0.27-1.55,0.41-2.5,0.41c-0.34,0-0.7-0.02-1.09-0.06c-0.39-0.04-0.65-0.08-0.77-0.11v-2h0.27     c0.14,0.06,0.33,0.13,0.58,0.21s0.6,0.11,1.04,0.11c0.36,0,0.7-0.04,1.05-0.13c0.34-0.09,0.64-0.23,0.88-0.42     c0.27-0.21,0.49-0.46,0.66-0.77c0.17-0.31,0.29-0.68,0.35-1.11c-0.4,0.23-0.76,0.4-1.1,0.52c-0.34,0.12-0.76,0.18-1.27,0.18     c-0.38,0-0.75-0.05-1.1-0.14c-0.35-0.09-0.67-0.24-0.95-0.43c-0.38-0.27-0.68-0.62-0.91-1.06s-0.34-1-0.34-1.67     c0-1.09,0.38-1.98,1.14-2.67s1.75-1.03,2.98-1.03c0.63,0,1.2,0.08,1.69,0.25c0.49,0.17,0.91,0.42,1.27,0.76     c0.42,0.39,0.73,0.89,0.94,1.5C352.31,311.22,352.41,311.95,352.41,312.83z M349.79,312.44c0-0.56-0.05-1.01-0.16-1.37     c-0.11-0.36-0.25-0.64-0.44-0.83c-0.13-0.15-0.28-0.25-0.46-0.32c-0.17-0.06-0.36-0.1-0.55-0.1c-0.18,0-0.35,0.03-0.51,0.09     c-0.16,0.06-0.32,0.17-0.47,0.32c-0.14,0.15-0.26,0.34-0.34,0.58s-0.13,0.52-0.13,0.85c0,0.32,0.05,0.59,0.14,0.8     s0.23,0.39,0.4,0.52c0.16,0.12,0.35,0.21,0.56,0.26c0.22,0.05,0.45,0.08,0.71,0.08c0.21,0,0.43-0.03,0.67-0.08     c0.24-0.05,0.43-0.11,0.56-0.16c0-0.05,0-0.12,0.01-0.22C349.79,312.77,349.79,312.63,349.79,312.44z"
                      />
                    </g>
                    <g>
                      <path
                        class="st2"
                        d="M208.67,230.57h-1.39v2.37h-2.51v-2.37h-4.93v-1.93l4.76-5.91h2.67v5.97h1.39V230.57z M204.78,228.71v-3.68     l-2.96,3.68H204.78z"
                      />
                    </g>
                    <g>
                      <path
                        class="st2"
                        d="M303.64,229.47c0,0.53-0.1,1.02-0.3,1.47c-0.2,0.45-0.49,0.84-0.87,1.17c-0.42,0.35-0.9,0.6-1.45,0.77     c-0.54,0.17-1.17,0.25-1.86,0.25c-0.82,0-1.5-0.07-2.07-0.19c-0.56-0.13-1.02-0.27-1.38-0.42v-2.25h0.29     c0.42,0.25,0.86,0.45,1.34,0.62s0.96,0.25,1.43,0.25c0.29,0,0.6-0.03,0.93-0.1c0.33-0.07,0.6-0.19,0.8-0.36     c0.16-0.14,0.27-0.29,0.35-0.43c0.08-0.15,0.12-0.37,0.12-0.68c0-0.24-0.05-0.44-0.16-0.61c-0.11-0.17-0.25-0.31-0.41-0.41     c-0.25-0.15-0.54-0.25-0.89-0.3c-0.35-0.05-0.66-0.07-0.94-0.07c-0.41,0-0.8,0.04-1.18,0.11c-0.38,0.07-0.71,0.14-0.99,0.21h-0.3     v-5.74h7.24v1.95h-4.77v1.66c0.14-0.01,0.32-0.02,0.54-0.02c0.22-0.01,0.41-0.01,0.57-0.01c0.56,0,1.06,0.05,1.5,0.16     c0.44,0.11,0.82,0.26,1.14,0.45c0.42,0.25,0.74,0.58,0.97,1C303.52,228.33,303.64,228.85,303.64,229.47z"
                      />
                    </g>
                    <g>
                      <rect
                        x="203.13"
                        y="111.03"
                        class="st2"
                        width="1.28"
                        height="15.02"
                      />
                      <g>
                        <polygon
                          class="st2"
                          points="198.55,115.26 199.49,116.13 203.77,111.52 208.05,116.13 208.99,115.26 203.77,109.64     "
                        />
                      </g>
                    </g>
                  </g>
                  <path
                    class="st3"
                    d="M396.37,213.02c-8.42-7.48-19.68,0.3-25.88-5.9c-6.21-6.21-11.58-56.84-17.13-74.56   c-5.55-17.72-16.33-11.93-25.59-13.62c-6.08-1.11-11.29-5.19-14.1-24.76c-3.53-24.59-9.52-36.2-9.52-36.2s-7.89-4.6-25.02-12.64   c-35.08-16.46-74.96-15.21-74.96-15.21h-0.15v-0.02c-0.11,0-0.19,0-0.26,0.01c-0.06,0-0.14,0-0.26-0.01v0.01h-0.15   c0,0-39.88-1.25-74.96,15.21c-17.14,8.04-25.02,12.64-25.02,12.64s-5.99,11.61-9.52,36.2c-2.81,19.57-8.02,23.65-14.1,24.76   c-9.26,1.69-20.05-4.11-25.59,13.62c-5.55,17.72-10.92,68.35-17.13,74.56c-6.21,6.21-17.47-1.57-25.88,5.9   c-6.31,5.61-24.31,21.1,5.6,95.19c12.14,30.07,30.78,55.5,47.64,74.16c25.75,28.49,43.22,37.05,62.66,23.34   c11.33-7.99,28.9-9.35,36.52-3.25c6.56,5.25,5.94,13.11-1.74,13.92c-7.68,0.82-12.52,5.55-9.89,11.46   c2.66,5.99,19.68,7.44,29.6,7.23c10.04-0.21,14.3-3.16,14.3-3.16s0.15-15.18,2-21.78c1.16-4.16,5.16-4.22,5.16-4.22h0.54h0.51h0.54   c0,0,4,0.06,5.16,4.22c1.85,6.6,2,21.78,2,21.78s4.26,2.95,14.3,3.16c9.91,0.2,26.94-1.25,29.6-7.23   c2.63-5.91-2.22-10.64-9.89-11.46c-7.68-0.82-8.3-8.67-1.74-13.92c7.61-6.1,25.19-4.74,36.52,3.25   c19.43,13.71,36.91,5.15,62.66-23.34c16.86-18.66,35.5-44.09,47.64-74.16C420.68,234.11,402.68,218.63,396.37,213.02z"
                  />
                </g>
                <g>
                  <g
                    v-for="n in 12"
                    :key="n"
                    class="cursor-pointer wellstrokeactive"
                  >
                    <circle
                      class="wellstrokecircle"
                      :cx="wellCoordinates[n - 1].x + 41.5"
                      :cy="wellCoordinates[n - 1].y + 42"
                      r="42.5"
                    />
                    <circle
                      class="strokecircle2"
                      :cx="wellCoordinates[n - 1].x + 41.5"
                      :cy="wellCoordinates[n - 1].y + 42"
                      r="42.5"
                    />
                    <clipped
                      v-if="
                        puck['well' + n] &&
                        puck['well' + n].boxType &&
                        puck['well' + n].boxType == 'Clipped'
                      "
                      :x="wellCoordinates[n - 1].x + 'px'"
                      :y="wellCoordinates[n - 1].y + 'px'"
                      width="83"
                      height="84"
                      :show-grid-clips="gridClips(index, n)"
                      :data-well-number1="n"
                      :well-number="n"
                      class="well-clipped"
                      :class="`well-${puck['well' + n]?.color?.replace(
                        /\s+/g,
                        '-'
                      )}-clipped`"
                    ></clipped>
                    <unclipped
                      v-else-if="
                        puck['well' + n] &&
                        puck['well' + n].boxType &&
                        puck['well' + n].boxType == 'Unclipped'
                      "
                      :show-grid-clips="gridClips(index, n)"
                      :x="wellCoordinates[n - 1].x + 'px'"
                      :y="wellCoordinates[n - 1].y + 'px'"
                      width="83"
                      height="84"
                      :well-number="n"
                      :data-well-number1="n"
                      class="well-unclipped"
                      :class="`well-${puck['well' + n].color?.replace(
                        /\s+/g,
                        '-'
                      )}-unclipped`"
                    ></unclipped>
                    <opencup
                      v-else-if="
                        puck['well' + n] &&
                        puck['well' + n].boxType &&
                        puck['well' + n].boxType == 'opencup'
                      "
                      :show-grid-clips="gridClips(index, n)"
                      :x="wellCoordinates[n - 1].x + 'px'"
                      :y="wellCoordinates[n - 1].y + 'px'"
                      width="83"
                      height="84"
                      :well-number="n"
                      :data-well-number1="n"
                      class="well-unclipped"
                      :class="`well-${puck['well' + n].color?.replace(
                        /\s+/g,
                        '-'
                      )}-unclipped`"
                    ></opencup>
                    <g
                      v-else-if="
                        (puck['well' + n] && puck['well' + n] == 'hide') ||
                        puck.readOnly
                      "
                      :x="wellCoordinates[n - 1].x + 'px'"
                      :y="wellCoordinates[n - 1].y + 'px'"
                      width="83"
                      height="84"
                      :data-well-number1="n"
                    ></g>
                    <image
                      v-else
                      :x="wellCoordinates[n - 1].x + 'px'"
                      :y="wellCoordinates[n - 1].y + 'px'"
                      width="83"
                      height="84"
                      :data-well-number1="n"
                      class="boxonlysvg isanemptysvg"
                      href="assets/images/emptybox.svg"
                    />
                  </g>
                </g>
              </svg>
              <svg
                v-else
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                version="1.1"
                :id="'pucksvg-' + index"
                x="0px"
                y="0px"
                viewBox="0 0 407.54 435.08"
                style="enable-background: new 0 0 407.54 435.08"
                xml:space="preserve"
                class="iscartridge"
              >
                <svg:style>
                                                #pucksvg-{{index}} .st0 {

                                                  fill: {{  this.$store.state.transformPuckColor(puck.color)}};
                                                }
                                                #pucksvg-{{index}} .st1 {
                                                             fill: {{this.$store.state.transformPuckColor(puck.color,true)}};

                                                }
                                                #pucksvg-{{index}} .st2 {
                                                  fill: #ffffff;
                                                }
                                                #pucksvg-{{index}} .st3 {
                                                  fill: none;
                                                }

                                                .wellstrokecircleactive {
                    stroke: #00ffff  !important;

                  }
                                                       .wellstrokecircle {

                                         stroke:rgb(176, 176, 176); stroke-width:3; fill:none;
                                     }
                                    .wellstrokeactive .strokecircle2 {
                                      stroke:  rgb(247, 215, 86) !important; fill:none; stroke-width:0;
                                     }
                </svg:style>
                <path
                  d="M396.39,213.01C387.97,205.53 376.71,213.31 370.51,207.11C364.3,200.9 358.93,150.27 353.38,132.55C347.83,114.83 337.05,120.62 327.79,118.93C321.71,117.82 316.5,113.74 313.69,94.17C310.16,69.58 304.17,57.97 304.17,57.97C304.17,57.97 296.28,53.37 279.15,45.33C244.07,28.87 204.19,30.12 204.19,30.12L204.04,30.12L204.04,30.1C203.93,30.1 203.85,30.1 203.78,30.11C203.72,30.11 203.64,30.11 203.52,30.1L203.52,30.11L203.37,30.11C203.37,30.11 163.49,28.86 128.41,45.32C111.27,53.36 103.39,57.96 103.39,57.96C103.39,57.96 97.4,69.57 93.87,94.16C91.06,113.73 85.85,117.81 79.77,118.92C70.51,120.61 59.72,114.81 54.18,132.54C48.63,150.26 43.26,200.89 37.05,207.1C30.84,213.31 19.58,205.53 11.17,213C4.86,218.61 -13.14,234.1 16.77,308.19C28.91,338.26 47.55,363.69 64.41,382.35C90.16,410.84 107.63,419.4 127.07,405.69C138.4,397.7 155.97,396.34 163.59,402.44C170.15,407.69 169.53,415.55 161.85,416.36C154.17,417.18 149.33,421.91 151.96,427.82C154.62,433.81 171.64,435.26 181.56,435.05C191.6,434.84 195.86,431.89 195.86,431.89C195.86,431.89 196.01,416.71 197.86,410.11C199.02,405.95 203.02,405.89 203.02,405.89L204.61,405.89C204.61,405.89 208.61,405.95 209.77,410.11C211.62,416.71 211.77,431.89 211.77,431.89C211.77,431.89 216.03,434.84 226.07,435.05C235.98,435.25 253.01,433.8 255.67,427.82C258.3,421.91 253.45,417.18 245.78,416.36C238.1,415.54 237.48,407.69 244.04,402.44C251.65,396.34 269.23,397.7 280.56,405.69C299.99,419.4 317.47,410.84 343.22,382.35C360.08,363.69 378.72,338.26 390.86,308.19C420.7,234.1 402.7,218.62 396.39,213.01Z"
                  style="fill-rule: nonzero"
                />
                <g class="st0">
                  <path
                    d="M153.31,131.42C170.96,131.42 186.04,120.46 192.14,104.98L215.38,104.98C221.48,120.46 236.56,131.42 254.21,131.42C277.26,131.42 295.95,112.73 295.95,89.68C295.95,66.63 277.26,47.94 254.21,47.94C236.56,47.94 221.48,58.9 215.38,74.38L192.14,74.38C186.04,58.9 170.96,47.94 153.31,47.94C130.26,47.94 111.57,66.63 111.57,89.68C111.57,112.73 130.26,131.42 153.31,131.42Z"
                    style="fill-rule: nonzero"
                  />
                </g>
                <g class="st0">
                  <path
                    d="M163.94,102.3L163.94,109.55L142.36,109.55L142.36,102.3L148.18,102.3L148.18,74.45L141.97,74.45L141.97,67.2L158.49,67.2L158.49,102.3L163.94,102.3Z"
                    style="fill-rule: nonzero"
                  />
                </g>
                <g class="st0">
                  <path
                    d="M270.58,78.36C270.58,80.51 270.1,82.39 269.13,83.98C268.17,85.57 266.87,87.02 265.24,88.32C263.61,89.62 261.24,91.21 258.13,93.08C253.07,96.19 250.27,99.26 249.73,102.3L262.52,102.3L262.52,96.82L271.09,96.82L270.58,109.55L239.72,109.55C239.72,105.78 240.17,102.68 241.07,100.25C241.97,97.81 243.39,95.58 245.33,93.55C247.27,91.52 250.12,89.11 253.89,86.31C255.88,84.85 257.39,83.43 258.43,82.07C259.47,80.7 259.99,79.3 259.99,77.85C259.99,76.43 259.58,75.35 258.75,74.62C257.93,73.89 256.75,73.52 255.21,73.52C253.22,73.52 251.75,74.21 250.78,75.59C249.81,76.97 249.24,79.21 249.05,82.32L240.25,81.98C240.36,76.75 241.7,72.86 244.27,70.29C246.84,67.72 250.68,66.44 255.79,66.44C260.38,66.44 263.99,67.49 266.62,69.6C269.26,71.71 270.58,74.63 270.58,78.36Z"
                    style="fill-rule: nonzero"
                  />
                </g>
                <g class="st0">
                  <g>
                    <g>
                      <path
                        d="M157.34,147.56L150.55,147.56L150.55,145.78L152.7,145.78L152.7,140.4L150.55,140.4L150.55,138.74C150.88,138.74 151.19,138.72 151.49,138.68C151.79,138.64 152.04,138.57 152.24,138.48C152.48,138.37 152.65,138.22 152.77,138.04C152.89,137.86 152.96,137.63 152.98,137.36L155.24,137.36L155.24,145.79L157.34,145.79L157.34,147.56Z"
                        style="fill-rule: nonzero"
                      />
                    </g>
                    <g>
                      <path
                        d="M256.86,147.56L248.87,147.56L248.87,145.88C249.48,145.44 250.09,144.97 250.71,144.47C251.32,143.97 251.81,143.55 252.18,143.18C252.73,142.65 253.12,142.18 253.36,141.79C253.6,141.4 253.71,141 253.71,140.61C253.71,140.15 253.56,139.79 253.26,139.53C252.96,139.28 252.53,139.15 251.97,139.15C251.55,139.15 251.11,139.24 250.65,139.41C250.19,139.58 249.76,139.8 249.36,140.07L249.14,140.07L249.14,137.81C249.46,137.67 249.94,137.53 250.57,137.39C251.2,137.25 251.83,137.18 252.47,137.18C253.75,137.18 254.72,137.45 255.39,137.98C256.06,138.52 256.39,139.27 256.39,140.25C256.39,140.89 256.23,141.5 255.91,142.08C255.59,142.66 255.1,143.26 254.44,143.87C254.03,144.25 253.61,144.6 253.19,144.93C252.77,145.25 252.47,145.47 252.29,145.6L256.86,145.6L256.86,147.56Z"
                        style="fill-rule: nonzero"
                      />
                    </g>
                    <g>
                      <path
                        d="M108.66,228.14C108.88,228.33 109.06,228.56 109.19,228.81C109.32,229.06 109.39,229.4 109.39,229.83C109.39,230.31 109.3,230.75 109.11,231.17C108.92,231.59 108.63,231.94 108.24,232.24C107.86,232.53 107.41,232.75 106.89,232.9C106.37,233.05 105.74,233.13 105.01,233.13C104.17,233.13 103.44,233.06 102.84,232.93C102.24,232.8 101.75,232.65 101.37,232.49L101.37,230.25L101.64,230.25C102.03,230.49 102.5,230.69 103.04,230.87C103.58,231.05 104.08,231.13 104.53,231.13C104.79,231.13 105.08,231.11 105.39,231.07C105.7,231.03 105.96,230.93 106.18,230.79C106.35,230.68 106.48,230.54 106.58,230.38C106.68,230.22 106.73,229.98 106.73,229.68C106.73,229.38 106.66,229.15 106.52,228.99C106.38,228.83 106.2,228.71 105.98,228.65C105.76,228.58 105.49,228.54 105.17,228.53C104.86,228.52 104.56,228.52 104.29,228.52L103.73,228.52L103.73,226.7L104.31,226.7C104.67,226.7 104.98,226.69 105.25,226.67C105.52,226.65 105.76,226.59 105.95,226.51C106.15,226.42 106.3,226.31 106.4,226.16C106.5,226.02 106.55,225.81 106.55,225.53C106.55,225.32 106.5,225.16 106.39,225.03C106.29,224.9 106.15,224.81 105.99,224.73C105.81,224.65 105.6,224.59 105.36,224.57C105.12,224.55 104.91,224.53 104.74,224.53C104.31,224.53 103.85,224.6 103.35,224.75C102.85,224.9 102.36,225.11 101.89,225.39L101.64,225.39L101.64,223.18C102.01,223.03 102.52,222.89 103.16,222.75C103.8,222.61 104.45,222.54 105.12,222.54C105.76,222.54 106.33,222.6 106.81,222.71C107.29,222.82 107.69,222.97 108.01,223.16C108.38,223.38 108.66,223.65 108.84,223.97C109.02,224.29 109.11,224.66 109.11,225.09C109.11,225.66 108.93,226.16 108.58,226.6C108.23,227.04 107.77,227.33 107.19,227.45L107.19,227.55C107.42,227.58 107.67,227.65 107.93,227.74C108.19,227.83 108.44,227.96 108.66,228.14Z"
                        style="fill-rule: nonzero"
                      />
                    </g>
                    <g>
                      <path
                        d="M63.97,315.02C63.97,315.54 63.87,316.04 63.68,316.5C63.49,316.96 63.22,317.35 62.87,317.66C62.5,318 62.07,318.25 61.59,318.43C61.11,318.61 60.54,318.7 59.88,318.7C59.27,318.7 58.71,318.62 58.21,318.45C57.71,318.28 57.27,318.03 56.91,317.69C56.5,317.3 56.18,316.79 55.96,316.17C55.74,315.55 55.63,314.81 55.63,313.95C55.63,313.06 55.73,312.26 55.94,311.57C56.15,310.88 56.48,310.26 56.94,309.73C57.39,309.22 57.97,308.82 58.68,308.54C59.39,308.26 60.24,308.12 61.23,308.12C61.56,308.12 61.93,308.14 62.32,308.19C62.71,308.24 62.97,308.27 63.09,308.29L63.09,310.29L62.83,310.29C62.71,310.23 62.5,310.16 62.2,310.09C61.91,310.02 61.57,309.98 61.2,309.98C60.32,309.98 59.64,310.19 59.16,310.62C58.68,311.05 58.38,311.65 58.28,312.41C58.63,312.2 59,312.03 59.39,311.9C59.78,311.77 60.2,311.7 60.65,311.7C61.05,311.7 61.41,311.75 61.75,311.84C62.09,311.93 62.41,312.08 62.7,312.28C63.08,312.54 63.38,312.9 63.61,313.35C63.84,313.8 63.97,314.35 63.97,315.02ZM60.86,316.56C61,316.4 61.11,316.22 61.2,316.01C61.28,315.8 61.33,315.51 61.33,315.15C61.33,314.82 61.28,314.55 61.19,314.33C61.09,314.12 60.96,313.95 60.79,313.82C60.63,313.69 60.43,313.6 60.21,313.56C59.99,313.51 59.76,313.49 59.52,313.49C59.32,313.49 59.11,313.51 58.89,313.56C58.67,313.61 58.47,313.66 58.29,313.73C58.29,313.78 58.29,313.85 58.28,313.96C58.27,314.07 58.27,314.2 58.27,314.36C58.27,314.92 58.33,315.38 58.44,315.74C58.55,316.1 58.7,316.38 58.89,316.57C59.02,316.71 59.17,316.82 59.34,316.88C59.51,316.95 59.7,316.98 59.9,316.98C60.05,316.98 60.22,316.95 60.4,316.88C60.58,316.81 60.73,316.7 60.86,316.56Z"
                        style="fill-rule: nonzero"
                      />
                    </g>
                    <g>
                      <path
                        d="M159.99,310.33L155.38,318.49L152.45,318.49L157.23,310.26L152,310.26L152,308.31L159.98,308.31L159.98,310.33L159.99,310.33Z"
                        style="fill-rule: nonzero"
                      />
                    </g>
                    <g>
                      <path
                        d="M141.62,390.54L134.2,390.54L134.2,388.76L136.54,388.76L136.54,383.38L134.2,383.38L134.2,381.72C134.56,381.72 134.9,381.7 135.23,381.66C135.56,381.62 135.83,381.55 136.05,381.46C136.31,381.35 136.5,381.2 136.63,381.02C136.76,380.84 136.83,380.61 136.85,380.34L139.32,380.34L139.32,388.77L141.61,388.77L141.61,390.54L141.62,390.54Z"
                        style="fill-rule: nonzero"
                      />
                      <path
                        d="M153.11,385.44C153.11,386.3 153.03,387.06 152.86,387.74C152.69,388.42 152.43,388.97 152.07,389.41C151.7,389.85 151.23,390.19 150.65,390.41C150.07,390.64 149.36,390.75 148.52,390.75C147.69,390.75 146.98,390.64 146.39,390.41C145.8,390.18 145.33,389.84 144.97,389.4C144.6,388.95 144.34,388.4 144.17,387.73C144.01,387.07 143.92,386.31 143.92,385.45C143.92,384.57 144,383.8 144.17,383.15C144.34,382.5 144.61,381.95 144.98,381.49C145.35,381.04 145.83,380.71 146.41,380.48C146.99,380.25 147.7,380.15 148.51,380.15C149.36,380.15 150.07,380.27 150.65,380.5C151.23,380.73 151.7,381.07 152.07,381.53C152.43,381.98 152.7,382.53 152.86,383.18C153.03,383.83 153.11,384.58 153.11,385.44ZM150.22,385.44C150.22,384.21 150.09,383.33 149.82,382.81C149.56,382.29 149.12,382.03 148.51,382.03C147.9,382.03 147.46,382.29 147.2,382.81C146.94,383.33 146.8,384.21 146.8,385.46C146.8,386.67 146.93,387.54 147.2,388.07C147.47,388.6 147.9,388.87 148.51,388.87C149.11,388.87 149.55,388.6 149.82,388.07C150.09,387.53 150.22,386.66 150.22,385.44Z"
                        style="fill-rule: nonzero"
                      />
                    </g>
                    <g>
                      <path
                        d="M203.7,401.39L196.91,401.39L196.91,399.61L199.06,399.61L199.06,394.23L196.91,394.23L196.91,392.57C197.24,392.57 197.55,392.55 197.85,392.51C198.15,392.47 198.4,392.4 198.6,392.31C198.84,392.2 199.01,392.05 199.13,391.87C199.25,391.69 199.32,391.46 199.34,391.19L201.6,391.19L201.6,399.62L203.7,399.62L203.7,401.39Z"
                        style="fill-rule: nonzero"
                      />
                      <path
                        d="M213.09,401.39L206.3,401.39L206.3,399.61L208.45,399.61L208.45,394.23L206.3,394.23L206.3,392.57C206.63,392.57 206.94,392.55 207.24,392.51C207.54,392.47 207.79,392.4 207.99,392.31C208.23,392.2 208.41,392.05 208.52,391.87C208.64,391.69 208.71,391.46 208.73,391.19L210.99,391.19L210.99,399.62L213.09,399.62L213.09,401.39Z"
                        style="fill-rule: nonzero"
                      />
                    </g>
                    <g>
                      <path
                        d="M259.55,389.87L252.76,389.87L252.76,388.09L254.91,388.09L254.91,382.71L252.76,382.71L252.76,381.05C253.09,381.05 253.4,381.03 253.7,380.99C254,380.95 254.25,380.88 254.45,380.79C254.69,380.68 254.86,380.53 254.98,380.35C255.1,380.17 255.17,379.94 255.19,379.67L257.45,379.67L257.45,388.1L259.55,388.1L259.55,389.87Z"
                        style="fill-rule: nonzero"
                      />
                      <path
                        d="M270.15,389.87L262.16,389.87L262.16,388.19C262.77,387.75 263.38,387.28 263.99,386.78C264.6,386.28 265.09,385.86 265.46,385.49C266.01,384.96 266.4,384.49 266.64,384.1C266.88,383.71 266.99,383.31 266.99,382.92C266.99,382.46 266.84,382.1 266.54,381.84C266.24,381.59 265.81,381.46 265.25,381.46C264.83,381.46 264.39,381.55 263.93,381.72C263.47,381.89 263.04,382.11 262.64,382.38L262.42,382.38L262.42,380.12C262.74,379.98 263.22,379.84 263.85,379.7C264.48,379.56 265.11,379.49 265.75,379.49C267.03,379.49 268,379.76 268.67,380.29C269.34,380.83 269.67,381.58 269.67,382.56C269.67,383.2 269.51,383.81 269.19,384.39C268.87,384.97 268.38,385.57 267.72,386.18C267.3,386.56 266.89,386.91 266.47,387.24C266.05,387.56 265.75,387.78 265.57,387.91L270.14,387.91L270.14,389.87L270.15,389.87Z"
                        style="fill-rule: nonzero"
                      />
                    </g>
                    <g>
                      <path
                        d="M256.44,315.65C256.44,316.55 256.06,317.29 255.29,317.86C254.52,318.43 253.47,318.72 252.13,318.72C251.38,318.72 250.73,318.64 250.2,318.49C249.66,318.33 249.22,318.12 248.87,317.85C248.52,317.58 248.27,317.27 248.1,316.91C247.93,316.55 247.85,316.16 247.85,315.75C247.85,315.24 248,314.79 248.3,314.39C248.6,314 249.11,313.65 249.85,313.35L249.85,313.31C249.26,313.04 248.82,312.69 248.54,312.28C248.26,311.86 248.12,311.38 248.12,310.84C248.12,310.03 248.49,309.37 249.24,308.86C249.99,308.35 250.96,308.09 252.16,308.09C253.42,308.09 254.4,308.32 255.12,308.79C255.83,309.26 256.19,309.88 256.19,310.66C256.19,311.14 256.04,311.57 255.74,311.95C255.44,312.33 254.98,312.65 254.36,312.91L254.36,312.95C255.07,313.22 255.6,313.58 255.94,314.04C256.28,314.5 256.44,315.03 256.44,315.65ZM253.82,315.76C253.82,315.43 253.74,315.18 253.57,315.01C253.4,314.84 253.07,314.65 252.56,314.43C252.41,314.36 252.19,314.28 251.9,314.18C251.61,314.08 251.37,313.99 251.18,313.92C250.98,314.1 250.81,314.31 250.65,314.57C250.49,314.82 250.41,315.11 250.41,315.42C250.41,315.89 250.58,316.27 250.92,316.55C251.26,316.83 251.7,316.97 252.25,316.97C252.4,316.97 252.57,316.95 252.76,316.91C252.96,316.87 253.13,316.8 253.27,316.71C253.43,316.61 253.57,316.48 253.67,316.34C253.77,316.19 253.82,316 253.82,315.76ZM253.61,310.94C253.61,310.59 253.48,310.32 253.21,310.11C252.94,309.9 252.59,309.8 252.15,309.8C251.99,309.8 251.82,309.82 251.65,309.86C251.48,309.9 251.32,309.96 251.18,310.04C251.05,310.12 250.94,310.22 250.85,310.34C250.76,310.47 250.72,310.61 250.72,310.77C250.72,311.04 250.8,311.25 250.95,311.4C251.1,311.55 251.35,311.7 251.7,311.85C251.83,311.9 252,311.97 252.22,312.05C252.44,312.13 252.71,312.22 253.02,312.32C253.23,312.07 253.38,311.85 253.47,311.66C253.57,311.47 253.61,311.23 253.61,310.94Z"
                        style="fill-rule: nonzero"
                      />
                    </g>
                    <g>
                      <path
                        d="M352.41,312.83C352.41,313.68 352.3,314.48 352.08,315.21C351.86,315.94 351.53,316.56 351.08,317.06C350.6,317.6 350,318.01 349.28,318.28C348.57,318.55 347.73,318.69 346.78,318.69C346.44,318.69 346.08,318.67 345.69,318.63C345.3,318.59 345.04,318.55 344.92,318.52L344.92,316.52L345.19,316.52C345.33,316.58 345.52,316.65 345.77,316.73C346.02,316.81 346.37,316.84 346.81,316.84C347.17,316.84 347.51,316.8 347.86,316.71C348.2,316.62 348.5,316.48 348.74,316.29C349.01,316.08 349.23,315.83 349.4,315.52C349.57,315.21 349.69,314.84 349.75,314.41C349.35,314.64 348.99,314.81 348.65,314.93C348.31,315.05 347.89,315.11 347.38,315.11C347,315.11 346.63,315.06 346.28,314.97C345.93,314.88 345.61,314.73 345.33,314.54C344.95,314.27 344.65,313.92 344.42,313.48C344.19,313.04 344.08,312.48 344.08,311.81C344.08,310.72 344.46,309.83 345.22,309.14C345.98,308.45 346.97,308.11 348.2,308.11C348.83,308.11 349.4,308.19 349.89,308.36C350.38,308.53 350.8,308.78 351.16,309.12C351.58,309.51 351.89,310.01 352.1,310.62C352.31,311.22 352.41,311.95 352.41,312.83ZM349.79,312.44C349.79,311.88 349.74,311.43 349.63,311.07C349.52,310.71 349.38,310.43 349.19,310.24C349.06,310.09 348.91,309.99 348.73,309.92C348.56,309.86 348.37,309.82 348.18,309.82C348,309.82 347.83,309.85 347.67,309.91C347.51,309.97 347.35,310.08 347.2,310.23C347.06,310.38 346.94,310.57 346.86,310.81C346.78,311.05 346.73,311.33 346.73,311.66C346.73,311.98 346.78,312.25 346.87,312.46C346.96,312.67 347.1,312.85 347.27,312.98C347.43,313.1 347.62,313.19 347.83,313.24C348.05,313.29 348.28,313.32 348.54,313.32C348.75,313.32 348.97,313.29 349.21,313.24C349.45,313.19 349.64,313.13 349.77,313.08C349.77,313.03 349.77,312.96 349.78,312.86C349.79,312.77 349.79,312.63 349.79,312.44Z"
                        style="fill-rule: nonzero"
                      />
                    </g>
                    <g>
                      <path
                        d="M208.67,230.57L207.28,230.57L207.28,232.94L204.77,232.94L204.77,230.57L199.84,230.57L199.84,228.64L204.6,222.73L207.27,222.73L207.27,228.7L208.66,228.7L208.66,230.57L208.67,230.57ZM204.78,228.71L204.78,225.03L201.82,228.71L204.78,228.71Z"
                        style="fill-rule: nonzero"
                      />
                    </g>
                    <g>
                      <path
                        d="M303.64,229.47C303.64,230 303.54,230.49 303.34,230.94C303.14,231.39 302.85,231.78 302.47,232.11C302.05,232.46 301.57,232.71 301.02,232.88C300.48,233.05 299.85,233.13 299.16,233.13C298.34,233.13 297.66,233.06 297.09,232.94C296.53,232.81 296.07,232.67 295.71,232.52L295.71,230.27L296,230.27C296.42,230.52 296.86,230.72 297.34,230.89C297.82,231.06 298.3,231.14 298.77,231.14C299.06,231.14 299.37,231.11 299.7,231.04C300.03,230.97 300.3,230.85 300.5,230.68C300.66,230.54 300.77,230.39 300.85,230.25C300.93,230.1 300.97,229.88 300.97,229.57C300.97,229.33 300.92,229.13 300.81,228.96C300.7,228.79 300.56,228.65 300.4,228.55C300.15,228.4 299.86,228.3 299.51,228.25C299.16,228.2 298.85,228.18 298.57,228.18C298.16,228.18 297.77,228.22 297.39,228.29C297.01,228.36 296.68,228.43 296.4,228.5L296.1,228.5L296.1,222.76L303.34,222.76L303.34,224.71L298.57,224.71L298.57,226.37C298.71,226.36 298.89,226.35 299.11,226.35C299.33,226.34 299.52,226.34 299.68,226.34C300.24,226.34 300.74,226.39 301.18,226.5C301.62,226.61 302,226.76 302.32,226.95C302.74,227.2 303.06,227.53 303.29,227.95C303.52,228.33 303.64,228.85 303.64,229.47Z"
                        style="fill-rule: nonzero"
                      />
                    </g>
                    <g>
                      <rect x="203.13" y="111.03" width="1.28" height="15.02" />
                      <g>
                        <path
                          d="M198.55,115.26L199.49,116.13L203.77,111.52L208.05,116.13L208.99,115.26L203.77,109.64L198.55,115.26Z"
                          style="fill-rule: nonzero"
                        />
                      </g>
                    </g>
                  </g>
                  <path
                    d="M396.37,213.02C387.95,205.54 376.69,213.32 370.49,207.12C364.28,200.91 358.91,150.28 353.36,132.56C347.81,114.84 337.03,120.63 327.77,118.94C321.69,117.83 316.48,113.75 313.67,94.18C310.14,69.59 304.15,57.98 304.15,57.98C304.15,57.98 296.26,53.38 279.13,45.34C244.05,28.88 204.17,30.13 204.17,30.13L204.02,30.13L204.02,30.11C203.91,30.11 203.83,30.11 203.76,30.12C203.7,30.12 203.62,30.12 203.5,30.11L203.5,30.12L203.35,30.12C203.35,30.12 163.47,28.87 128.39,45.33C111.25,53.37 103.37,57.97 103.37,57.97C103.37,57.97 97.38,69.58 93.85,94.17C91.04,113.74 85.83,117.82 79.75,118.93C70.49,120.62 59.7,114.82 54.16,132.55C48.61,150.27 43.24,200.9 37.03,207.11C30.82,213.32 19.56,205.54 11.15,213.01C4.84,218.62 -13.16,234.11 16.75,308.2C28.89,338.27 47.53,363.7 64.39,382.36C90.14,410.85 107.61,419.41 127.05,405.7C138.38,397.71 155.95,396.35 163.57,402.45C170.13,407.7 169.51,415.56 161.83,416.37C154.15,417.19 149.31,421.92 151.94,427.83C154.6,433.82 171.62,435.27 181.54,435.06C191.58,434.85 195.84,431.9 195.84,431.9C195.84,431.9 195.99,416.72 197.84,410.12C199,405.96 203,405.9 203,405.9L204.59,405.9C204.59,405.9 208.59,405.96 209.75,410.12C211.6,416.72 211.75,431.9 211.75,431.9C211.75,431.9 216.01,434.85 226.05,435.06C235.96,435.26 252.99,433.81 255.65,427.83C258.28,421.92 253.43,417.19 245.76,416.37C238.08,415.55 237.46,407.7 244.02,402.45C251.63,396.35 269.21,397.71 280.54,405.7C299.97,419.41 317.45,410.85 343.2,382.36C360.06,363.7 378.7,338.27 390.84,308.2C420.68,234.11 402.68,218.63 396.37,213.02Z"
                    style="fill-rule: nonzero"
                  />
                </g>
                <g class="st1">
                  <g>
                    <circle cx="152.5" cy="89" r="42.5" />
                    <circle cx="152.5" cy="89" r="42.5" />
                  </g>
                  <g>
                    <circle cx="254.5" cy="89" r="42.5" />
                    <circle cx="254.5" cy="89" r="42.5" />
                  </g>
                </g>

                <g>
                  <g
                    v-for="n in 2"
                    :key="n"
                    class="cursor-pointer wellstrokeactive"
                  >
                    <circle
                      class="wellstrokecircle"
                      :cx="wellCoordinates[n - 1].x + 41.5"
                      :cy="wellCoordinates[n - 1].y + 42"
                      r="42.5"
                    />
                    <circle
                      class="strokecircle2"
                      :cx="wellCoordinates[n - 1].x + 41.5"
                      :cy="wellCoordinates[n - 1].y + 42"
                      r="42.5"
                    />
                    <clipped
                      v-if="
                        puck['well' + n] &&
                        puck['well' + n].boxType &&
                        puck['well' + n].boxType == 'Clipped'
                      "
                      :x="wellCoordinates[n - 1].x + 'px'"
                      :y="wellCoordinates[n - 1].y + 'px'"
                      width="83"
                      height="84"
                      :show-grid-clips="gridClips(index, n)"
                      :data-well-number1="n"
                      :well-number="n"
                      class="well-clipped"
                      :class="`well-${puck['well' + n]?.color?.replace(
                        /\s+/g,
                        '-'
                      )}-clipped`"
                    ></clipped>
                    <unclipped
                      v-else-if="
                        puck['well' + n] &&
                        puck['well' + n].boxType &&
                        puck['well' + n].boxType == 'Unclipped'
                      "
                      :show-grid-clips="gridClips(index, n)"
                      :x="wellCoordinates[n - 1].x + 'px'"
                      :y="wellCoordinates[n - 1].y + 'px'"
                      width="83"
                      height="84"
                      :well-number="n"
                      :data-well-number1="n"
                      class="well-unclipped"
                      :class="`well-${puck['well' + n].color?.replace(
                        /\s+/g,
                        '-'
                      )}-unclipped`"
                    ></unclipped>
                    <opencup
                      v-else-if="
                        puck['well' + n] &&
                        puck['well' + n].boxType &&
                        puck['well' + n].boxType == 'opencup'
                      "
                      :show-grid-clips="gridClips(index, n)"
                      :x="wellCoordinates[n - 1].x + 'px'"
                      :y="wellCoordinates[n - 1].y + 'px'"
                      width="83"
                      height="84"
                      :well-number="n"
                      :data-well-number1="n"
                      class="well-unclipped"
                      :class="`well-${puck['well' + n].color?.replace(
                        /\s+/g,
                        '-'
                      )}-unclipped`"
                    ></opencup>
                    <g
                      v-else-if="
                        (puck['well' + n] && puck['well' + n] == 'hide') ||
                        puck.readOnly
                      "
                      :x="wellCoordinates[n - 1].x + 'px'"
                      :y="wellCoordinates[n - 1].y + 'px'"
                      width="83"
                      height="84"
                      :data-well-number1="n"
                    ></g>
                    <image
                      v-else
                      :x="wellCoordinates[n - 1].x + 'px'"
                      :y="wellCoordinates[n - 1].y + 'px'"
                      width="83"
                      height="84"
                      :data-well-number1="n"
                      class="boxonlysvg isanemptysvg"
                      href="assets/images/emptybox.svg"
                    />
                  </g>
                  <g>
                    <g></g>
                    <g class="expandborderofbelow"></g>
                    <g class="isanemptysvg boxonlysvg">
                      <g class="cursor-pointer wellstrokeactive">
                        <rect
                          x="70.936"
                          y="171.845"
                          class="strokerect"
                          width="28.497"
                          height="175.9"
                          fill="black"
                          rx="5"
                          ry="5"
                        />
                        <rect
                          x="71.936"
                          y="172.845"
                          width="26.497"
                          height="173.9"
                          class="st1"
                          rx="4"
                          ry="4"
                        />
                        <circle
                          cx="85.185"
                          cy="260.295"
                          r="10"
                          class="st1"
                          stroke="white"
                          data-well-number1="3"
                          stroke-width="1"
                        />
                        <line
                          x1="85.185"
                          y1="257.295"
                          x2="85.185"
                          y2="263.295"
                          stroke="white"
                          stroke-width="1"
                        />
                        <line
                          x1="82.185"
                          y1="260.295"
                          x2="88.185"
                          y2="260.295"
                          stroke="white"
                          stroke-width="1"
                        />
                        <rect
                          v-if="puck['well3'] && puck['well3'].boxType"
                          x="71.936"
                          y="172.845"
                          width="26.497"
                          height="173.9"
                          fill="#e2e9ee"
                          rx="4"
                          ry="4"
                        />
                      </g>
                    </g>
                  </g>

                  <!-- Second Rectangle Group -->

                  <g>
                    <g></g>
                    <g class="expandborderofbelow"></g>
                    <g class="isanemptysvg boxonlysvg">
                      <g class="cursor-pointer wellstrokeactive">
                        <rect
                          x="105.433"
                          y="171.845"
                          width="28.497"
                          height="175.9"
                          class="strokerect"
                          fill="black"
                          rx="5"
                          ry="5"
                        />
                        <rect
                          x="106.433"
                          y="172.845"
                          width="26.497"
                          height="173.9"
                          class="st1"
                          rx="4"
                          ry="4"
                        />
                        <circle
                          cx="119.682"
                          cy="260.295"
                          data-well-number1="4"
                          r="10"
                          class="st1"
                          stroke="white"
                          stroke-width="1"
                        />
                        <line
                          x1="119.682"
                          y1="257.295"
                          x2="119.682"
                          y2="263.295"
                          stroke="white"
                          stroke-width="1"
                        />
                        <line
                          x1="116.682"
                          y1="260.295"
                          x2="122.682"
                          y2="260.295"
                          stroke="white"
                          stroke-width="1"
                        />
                        <rect
                          v-if="puck['well4'] && puck['well4'].boxType"
                          x="106.433"
                          y="172.845"
                          width="26.497"
                          height="173.9"
                          fill="#e2e9ee"
                          rx="4"
                          ry="4"
                        />
                      </g>
                    </g>
                  </g>

                  <!-- Third Rectangle Group -->
                  <g>
                    <g></g>
                    <g class="expandborderofbelow"></g>
                    <g class="isanemptysvg boxonlysvg">
                      <g class="cursor-pointer wellstrokeactive">
                        <rect
                          x="139.93"
                          class="strokerect"
                          y="171.845"
                          width="28.497"
                          height="175.9"
                          fill="black"
                          rx="5"
                          ry="5"
                        />
                        <rect
                          x="140.93"
                          y="172.845"
                          width="26.497"
                          height="173.9"
                          class="st1"
                          rx="4"
                          ry="4"
                        />
                        <circle
                          cx="154.179"
                          cy="260.295"
                          data-well-number1="5"
                          r="10"
                          class="st1"
                          stroke="white"
                          stroke-width="1"
                        />
                        <line
                          x1="154.179"
                          y1="257.295"
                          x2="154.179"
                          y2="263.295"
                          stroke="white"
                          stroke-width="1"
                        />
                        <line
                          x1="151.179"
                          y1="260.295"
                          x2="157.179"
                          y2="260.295"
                          stroke="white"
                          stroke-width="1"
                        />
                        <rect
                          v-if="puck['well5'] && puck['well5'].boxType"
                          x="140.93"
                          y="172.845"
                          width="26.497"
                          height="173.9"
                          fill="#e2e9ee"
                          rx="4"
                          ry="4"
                        />
                      </g>
                    </g>
                  </g>

                  <!-- Fourth Rectangle Group -->
                  <g>
                    <g></g>
                    <g class="expandborderofbelow"></g>
                    <g class="isanemptysvg boxonlysvg">
                      <g class="cursor-pointer wellstrokeactive">
                        <rect
                          x="174.427"
                          class="strokerect"
                          y="171.845"
                          width="28.497"
                          height="175.9"
                          fill="black"
                          rx="5"
                          ry="5"
                        />
                        <rect
                          x="175.427"
                          y="172.845"
                          width="26.497"
                          height="173.9"
                          class="st1"
                          rx="4"
                          ry="4"
                        />
                        <circle
                          cx="188.676"
                          cy="260.295"
                          r="10"
                          class="st1"
                          stroke="white"
                          data-well-number1="6"
                          stroke-width="1"
                        />
                        <line
                          x1="188.676"
                          y1="257.295"
                          x2="188.676"
                          y2="263.295"
                          stroke="white"
                          stroke-width="1"
                        />
                        <line
                          x1="185.676"
                          y1="260.295"
                          x2="191.676"
                          y2="260.295"
                          stroke="white"
                          stroke-width="1"
                        />
                        <rect
                          v-if="puck['well6'] && puck['well6'].boxType"
                          x="175.427"
                          y="172.845"
                          width="26.497"
                          height="173.9"
                          fill="#e2e9ee"
                          rx="4"
                          ry="4"
                        />
                      </g>
                    </g>
                  </g>

                  <!-- Fifth Rectangle (without inner elements) -->
                  <g>
                    <g></g>
                    <g class="expandborderofbelow"></g>

                    <g
                      transform="translate(-5, 0)"
                      class="isanemptysvg boxonlysvg"
                    >
                      <g class="cursor-pointer wellstrokeactive">
                        <rect
                          x="229.131"
                          y="171.845"
                          width="70.541"
                          height="175.9"
                          fill="black"
                          class="strokerect"
                          rx="5"
                          ry="5"
                        />
                        <rect
                          x="230.131"
                          y="172.845"
                          width="68.541"
                          height="173.9"
                          class="st1"
                          rx="4"
                          ry="4"
                        />
                        <circle
                          data-well-number1="7"
                          cx="264.901"
                          cy="260.295"
                          r="10"
                          class="st1"
                          stroke="white"
                          stroke-width="1"
                        />
                        <line
                          x1="264.901"
                          y1="257.295"
                          x2="264.901"
                          y2="263.295"
                          stroke="white"
                          stroke-width="1"
                        />
                        <line
                          x1="261.901"
                          y1="260.295"
                          x2="267.901"
                          y2="260.295"
                          stroke="white"
                          stroke-width="1"
                        />
                        <rect
                          v-if="puck['well7'] && puck['well7'].boxType"
                          x="230.131"
                          y="172.845"
                          width="68.541"
                          height="173.9"
                          fill="#e2e9ee"
                          rx="4"
                          ry="4"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
          </div>
        </div>
      </div>

      <!--end of inner rackS padding-->
    </div>
  </div>
  <div v-if="showPuck" id="pucksectionformain">
    <header-bar
      >Puck Details
      <span class="pl-6 text-sm"
        >Puck ID: {{ showPuck.serialNo }}</span
      ></header-bar
    >
    <div class="relative">
      <puck-section
        @resetautonavigate="(autoclickpuck = false), $emit('setrackfalse')"
        :autoclickpuck="autoclickpuck"
        :active-puck="compActivePuckRef()"
        :active-puck-index="activePuckIndex"
        :puck-data="showPuck"
        :clean-date="cleanDate"
        :transform-box-color="transformBoxColor"
        :box-colors="boxColors"
        :puck-data-change="puckDataChange"
        :reset-puck-selected="resetPuckSection"
        :data-chain-for-move="{
          dewar: activeDewarId,
          lab: activeDewarLabIndex,
          rack: activeRackElemNum,
          showgrids: storeOpenGrids,
        }"
        @new-puck-selected="resetPuckSection = false"
        @reset-puck-change="puckDataChange = false"
        @choose-new-puck-on-serialno-change="(id) => findActivePuck(id)"
      ></puck-section>
    </div>
  </div>
  <!-- <div class="fixed top-0 left-0 w-screen h-screen z-50 bg-black bg-opacity-50">
    <div class="bg-white"></div>
  </div> -->
</template>

<script>
import Unclipped from "./unclipped.vue";
import Opencup from "./opencup.vue";
import Clipped from "./clipped.vue";
import PuckSection from "./PuckSection.vue";
import HeaderBar from "./HeaderBar.vue";
import CustomUserAccess from "./CustomUserAccess.vue";
export default {
  components: {
    Unclipped,
    Clipped,
    HeaderBar,
    PuckSection,
    Opencup,
    CustomUserAccess,
  },
  inject: ["resetUnsavedData", "showRackExpandMessage", "saveData"],
  data() {
    return {
      wellCoordinates: [
        { x: 111, y: 47 },
        { x: 213, y: 47 },

        { x: 63, y: 132 },
        { x: 162, y: 132 },
        { x: 261, y: 132 },

        { x: 18, y: 218 },
        { x: 114, y: 218 },
        { x: 210, y: 218 },
        { x: 306, y: 218 },

        { x: 63, y: 303 },
        { x: 162, y: 303 },
        { x: 261, y: 303 },
      ],

      lastMouseX: null,
      lastMouseY: null,
      mouseCoordinateTimeout: null,
      storeOpenGrids: false,
      activeRack: null,
      activeRackElemNum: null,
      activePuckIndex: null,
      //activePuckData means the rack's puckS data aka .pucks
      activePuckData: null,
      showPuck: null,
      activePuckRef: "",
      rackEditBox: [],
      rackSelectBox: [],
      editRackBox: [],
      addPuckBox: [],
      resetPuckSection: false,
      mylabemail: [],

      mylab: [],
      autoclickpuck: false,
      puckDataChange: false,
      removePuckBox: [],
      boxColors: this.$store.state.boxColors,
      transformBoxColor: this.$store.state.transformBoxColor,
    };
  },
  props: [
    "resetRackFromDewar",

    "activeDewarId",
    "colorizeActiveContainer",
    "activeDewarLabIndex",
    "autoclickrack",
    "jump",
  ],
  watch: {
    resetRackFromDewar: function (val) {
      if (val) {
        console.log("changed", val);
        this.activeRackElemNum = null;
        this.activeRack = null;
        this.showPuck = null;
        this.$emit("resettotrue");
      }
    },
    autoclickrack(oldval, newval) {
      console.log(
        "autoclickrack changed",
        this.autoclickrack,
        oldval,
        newval,
        this.$refs[this.autoclickrack.rack]
      );
      if (this.autoclickrack) {
        this.storeOpenGrids = this.autoclickrack.opengrids;
        const tryClickRack = setInterval(() => {
          clearInterval(tryClickRack);
          this.$refs[this.autoclickrack.rack][0].click();
        }, 500);
        console.log("this ran");

        console.log(
          "the puck it is supposed to be going to is:",
          this.$refs[this.autoclickrack.puck]
        );

        const tryClick = setInterval(() => {
          console.log("try puck click", this.autoclickpuck);
          if (this.$refs[this.autoclickrack.puck][0]) {
            clearInterval(tryClick);

            this.$refs[this.autoclickrack.puck][0].click();

            console.log("this never ran");
            this.autoclickpuck = this.autoclickrack.puck;
          }
        }, 500);

        console.log("made it here");
      }
    },
    jump() {
      console.log(this.jump, "jump changed");
      console.log("SCROLL ME DADDIo");
      if (this.jump)
        window.scrollIntoView(this.$refs.titlebar, { block: "start" });
    },
    watcherForDewar() {
      console.log("dewar data changed!!!");
      this.updateActivePuckData();
      //this.puckDataChange = true;
    },
  },

  mounted() {
    if (this.jump) {
      console.log("SCROLL ME DADDIo");
      window.scrollIntoView(this.$refs.titlebar, { block: "start" });
    }

    this.rackSelectBox = [...this.typeInput];

    //adding puck box css data

    if (this.autoclickrack) {
      this.$refs[this.autoclickrack.rack][0].click();

      const tryClick = setInterval(() => {
        console.log(
          "in mounted autoclickrack",
          this.$refs[this.autoclickrack.puck][0]
        );
        if (this.$refs[this.autoclickrack.puck][0]) {
          clearInterval(tryClick);
          this.$refs[this.autoclickrack.puck][0].click();
          this.autoclickpuck = this.autoclickrack.puck;
          console.log(this.autoclickpuck);
        }
      }, 500);

      console.log("made it here");
    }
  },
  computed: {
    watcherForDewar() {
      return this.$store.state.dewarData;
    },
    showRackSelectData() {
      if (this.rackSelectBox.length == 0) return this.typeInput;
      else return this.rackSelectBox;
    },
    dewar() {
      console.log(this.activeDewarLabIndex, +this.activeDewarId);
      console.log(
        this.$store.state.dewarData[this.activeDewarLabIndex].data[
          +this.activeDewarId
        ].racks
      );
      if (this.activeDewarId)
        return this.$store.state.dewarData[this.activeDewarLabIndex].data[
          +this.activeDewarId
        ].racks;
      else return "";
    },
    dewarLab() {
      if (parseInt(this.activeDewarId) >= 0)
        return this.$store.state.dewarData[this.activeDewarLabIndex].data[
          +this.activeDewarId
        ].labId;
      // if (this.activeDewarId)
      //   return this.$store.state.dewarData[this.activeDewarLabIndex].labId;
      else return "";
    },
    descriptionsInput() {
      return this.dewar.map((rack) => rack?.description);
    },
    typeInput() {
      return this.dewar.map((rack) => rack?.rackType ?? "Storage");
    },
    titlesInput() {
      return this.dewar.map((rack) => rack?.title);
    },
    idsInput() {
      return this.dewar.map((rack) => rack?.customId);
    },
  },
  methods: {
    loadEditRackOrderModal() {},
    mylabuncheck(index, mylabchecked) {
      if (mylabchecked) this.mylab[index] = false;
      else this.mylab[index] = true;
    },
    sortPucks(pucks) {
      return pucks.sort((a, b) => {
        const keyA = a.serialNo,
          keyB = b.serialNo;
        // Compare the 2 dates
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
      });
    },
    closeUnassignedPucks(index) {
      this.editRackBox[index] = null;
      this.addPuckBox = [];
    },
    compActivePuckRef() {
      // console.log(
      //   "this is the puck",
      //   this.$refs[
      //     "puck-" +
      //       this.$store.state.dewarData[this.activeDewarLabIndex].data[
      //         this.activeDewarId
      //       ].racks[this.activeRackElemNum].pucks[this.activePuckIndex]._id
      //   ]
      // );
      // if (this.activeRackElemNum === 0 || this.activeRackElemNum)
      return this.$refs[
        "puck-" +
          this.$store.state.dewarData[this.activeDewarLabIndex].data[
            this.activeDewarId
          ].racks[this.activeRackElemNum].pucks[this.activePuckIndex]._id
      ];
      // else return;
    },
    gridClips(puckIndex, wellNum) {
      return [
        this.activePuckData[puckIndex]["well" + wellNum]?.gridTopLeft?.name,
        this.activePuckData[puckIndex]["well" + wellNum]?.gridTopRight?.name,
        this.activePuckData[puckIndex]["well" + wellNum]?.gridBottomLeft?.name,
        this.activePuckData[puckIndex]["well" + wellNum]?.gridBottomRight?.name,
      ];
    },

    updateActivePuckData() {
      if (
        (this.activeDewarId || this.activeDewarId === 0) &&
        (this.activeRackElemNum || this.activeRackElemNum === 0)
      ) {
        this.activePuckData = this.sortPucks(
          this.$store.state.dewarData[this.activeDewarLabIndex].data[
            this.activeDewarId
          ].racks[this.activeRackElemNum]?.pucks
        );

        //update the  active puck data
        this.showPuck = this.sortPucks(
          this.$store.state.dewarData[this.activeDewarLabIndex].data[
            this.activeDewarId
          ].racks[this.activeRackElemNum].pucks
        )[this.activePuckIndex];

        //this is needed because updateactivepuckdata runs on any dewardata change which will not activate the puckdata reset loop for puckdatachange if showpuck has not been initilaized yet
        if (this.showPuck) this.puckDataChange = true;
      }
    },

    findActivePuck(puckid) {
      console.log(puckid);
      this.$refs["puck-" + puckid][0].click();
    },

    async setActivePuck(puck, puckref, puckindex) {
      console.log(puckindex);
      if (
        this.$store.state.unsavedChangesMPBox ||
        this.$store.state.unsavedChangesMPGrid
      ) {
        this.$store.state.promptUnsaveModal = true;

        let answer;
        try {
          answer = await this.$store.state.promptAnswerPromise();
        } catch {
          //rejected

          answer = null;
        }

        this.$store.state.promptUnsaveModal = false;
        if (!answer) return;
        else {
          this.resetUnsavedData();
        }
      }

      this.resetPuckSection = true;
      console.log("reset puck sexction changed", this.resetPuckSection);
      this.activePuckIndex = puckindex;
      this.showPuck = puck;
      this.autoclickpuck = true;
      this.activePuckRef = this.$refs[puckref];
    },
    async updateRack(id, index) {
      if (
        this.$store.state.unsavedChangesMPBox ||
        this.$store.state.unsavedChangesMPGrid
      ) {
        this.$store.state.promptUnsaveModal = true;

        let answer;
        try {
          answer = await this.$store.state.promptAnswerPromise();
        } catch {
          //rejected

          answer = null;
        }

        this.$store.state.promptUnsaveModal = false;
        if (!answer) {
          return;
        } else {
          this.resetUnsavedData();
        }
      }

      this.$store.dispatch("updateItem", {
        item: "racks",
        objid: id,
        body: {
          title: this.titlesInput[index],
          rackType: this.typeInput[index],
          description: this.descriptionsInput[index],
          customId: this.idsInput[index],
        },
      });
    },

    async recordHighlightedRack(ev, puckData, rackIndex) {
      if (
        (this.$store.state.unsavedChangesMPBox ||
          this.$store.state.unsavedChangesMPGrid) &&
        rackIndex != this.activeRackElemNum
      ) {
        this.$store.state.promptUnsaveModal = true;

        let answer;
        try {
          answer = await this.$store.state.promptAnswerPromise();
        } catch {
          //rejected

          answer = null;
        }

        this.$store.state.promptUnsaveModal = false;
        if (!answer) return;
        else {
          this.resetUnsavedData();
        }
      }

      //allow clicking if this rack is NOT already active
      //careful in case the user has clicked a different dewar
      if (this.activeRackElemNum != rackIndex) {
        const parentContainer = ev.target.closest(".papaBgChange2");

        //lastDewarDivClicked
        this.activeRackElemNum = rackIndex;

        this.showPuck = false;
        const parentContainerRe = this.colorizeActiveContainer(
          parentContainer,
          this.activeRack
        );
        this.activeRack = parentContainerRe;
        this.activePuckData = puckData;
        setTimeout(() => {
          console.log("SCROLL ME DADDIo");
          window.scrollIntoView(this.$refs.activerackpucksauto);
        }, 500);
      }
    },
    // transformPuckColor(color, isSvgCup) {
    //   let hex = "#";
    //   switch (color) {
    //     case "red":
    //       hex += "FF0000";
    //       break;
    //     case "blue":
    //       hex += "0000FF";
    //       break;
    //     case "green":
    //       hex += "008000";
    //       break;
    //     case "orange":
    //       hex += "FFA500";
    //       break;
    //     case "violet":
    //       hex += "EE82EE";
    //       break;
    //     case "chrome":
    //       hex += "dbe4eb";
    //       break;
    //     case "gold":
    //       hex += "FFD700";
    //       break;
    //     case "sky blue":
    //       hex += "87ceeb";
    //       break;
    //     case "pink":
    //       hex += "FFC0CB";
    //       break;
    //     case "honey":
    //       hex += "EBA937";
    //       break;

    //     default:
    //       hex += "000000";
    //   }
    //   if (isSvgCup) {
    //     return this.hexToDarkerRgb(hex);
    //   } else return hex;
    // },

    // hexToDarkerRgb(hex) {
    //   var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    //   if (hex == "#000000") {
    //     return result
    //       ? `rgb(
    //         ${parseInt(result[1], 16) + 80},
    //         ${parseInt(result[2], 16) + 80},
    //         ${parseInt(result[3], 16) + 80}
    //       )`
    //       : null;
    //   } else {
    //     return result
    //       ? `rgb(
    //         ${parseInt(result[1], 16) - 80},
    //         ${parseInt(result[2], 16) - 80},
    //         ${parseInt(result[3], 16) - 80}
    //       )`
    //       : null;
    //   }
    // },
    async addAPuckToRack(event, puckid, rackid, lab, index) {
      if (
        this.$store.state.unsavedChangesMPBox ||
        this.$store.state.unsavedChangesMPGrid
      ) {
        this.$store.state.promptUnsaveModal = true;

        let answer;
        try {
          answer = await this.$store.state.promptAnswerPromise();
        } catch {
          //rejected

          answer = null;
        }

        this.$store.state.promptUnsaveModal = false;
        if (!answer) {
          if (!rackid) event.target.checked = true;
          else event.target.checked = false;

          return;
        } else {
          this.resetUnsavedData();
        }
      }
      event.stopPropagation();
      if (!rackid) {
        const unassignPuck = confirm(
          "Are you sure you want to unassign this puck?"
        );
        if (!unassignPuck) {
          event.target.checked = true;
          return;
        }
        console.log("catch this", this.showPuck, puckid);
        if (puckid.toString() == this.showPuck?._id) this.showPuck = null;
      }

      this.$store
        .dispatch("pushPuckToRack", { puckid, rackid, lab })
        .then(() => {
          if (rackid) this.addPuckBox[index] = null;
        })
        .catch((err) => console.log(err));
    },
    addAPuck(index) {
      //might need to change this to rackEditBox[index]
      if (!Object.values(this.rackEditBox).includes(index)) return;
      console.log("thisfar", this.dewarLab);
      this.editRackBox[index] = true;

      this.$store.dispatch("getUnassignedPucks", { lab: this.dewarLab });
    },
    addSelectToArray(ev, selectIndex) {
      this.rackSelectBox[selectIndex] = ev.target.value;
      console.log(this.rackSelectBox);
    },
    saveRackEdit() {
      console.log(this.rackSelectBox);
    },
    cleanDate(date, shortmonth, notime) {
      let month = "long";
      if (shortmonth) month = "short";
      var options = {
        year: "numeric",
        month: month,
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      };

      if (notime) {
        delete options.minute;
        delete options.hour;
      }
      var today = new Date(date);

      return today.toLocaleString("en-US", options); // Saturday, September 17, 2016
    },
    checkUndefined() {
      return true;
    },
  },
};
</script>

<style scoped>
.wellstrokecircleactive {
  stroke: #00ffff !important;
}
.wellstrokecircle {
  stroke: rgb(176, 176, 176);
  stroke-width: 3;
  fill: none;
}
.wellstrokeactive .strokecircle2 {
  stroke: rgb(247, 215, 86) !important;
  fill: none;
  stroke-width: 0;
}
.wellstrokeactive .strokerect {
  stroke: rgb(247, 215, 86) !important;
  stroke-width: 0;
}

/* .custom-x-position {
  top: -0.75rem;
  right: -0.25rem;
  position: relative;
} */

.skyblue {
  background-color: rgb(191, 216, 235);
}

input,
select {
  padding: 0;
  color: black;
  padding-left: 0.25rem;

  background: white;
}

textarea:disabled,
input:disabled,
select:disabled {
  border-color: rgb(118, 155, 186);
}
textarea {
  min-height: 100px;
  padding-left: 0.25rem;
  background: white;
}

textarea,
input,
select {
  border-color: rgb(0, 125, 29);
}
</style>
