<template>
  <div
    class="bg-white border-t-4 border-blue-800 inline-block mx-auto text-xl pb-4 cursor-default"
  >
    <div class="bg-yellow-400 py-2 px-10 font-semibold">
      Unsaved Changes Detected!
    </div>
    <div class="text-lg pt-4">
      You have unsaved
      {{ $store.state.unsavedChangesMPBox ? "box" : "" }}
      {{
        $store.state.unsavedChangesMPGrid && $store.state.unsavedChangesMPBox
          ? "and"
          : ""
      }}
      {{ $store.state.unsavedChangesMPGrid ? "grid" : "" }} changes.
    </div>
    <div class="px-6 py-3 flex flex-col items-center relative">
      <button
        @click="$store.state.promptAnswerReject()"
        class="btn-blue bg-green-500 text-black mb-4 hover:text-white"
      >
        Go Back
      </button>
      <button
        @click="$store.state.promptAnswerResolve('something')"
        class="btn-blue bg-yellow-400 text-black hover:bg-red-500"
      >
        Proceed
      </button>
      <div class="text-xs">Changes will be lost if you proceed!</div>
    </div>
  </div>
</template>
