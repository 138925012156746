<template>
  <div
    class="flex justify-between text-sky-900 text-2xl font-bold leading-none mt-1"
  >
    <div>
      {{ $store.state.activeUser.labId.institute.name }} >
      {{ $store.state.activeUser.labId.name }}
    </div>
    <div>
      Lab code:
      <span class="bg-yellow-500 px-2">{{
        $store.state.activeUser.labId.code
      }}</span>
    </div>
  </div>

  <header-bar>Manage Members From Other Labs</header-bar>

  <div class="flex mx-4 mt-4">
    <div class="flex-col">
      <div
        v-for="(user, index) in externalUsers"
        :key="user.userId._id"
        class="bg-sky-900 p-2 border-blue-800 border mb-2"
      >
        <div
          @click="loadUser(user.userId._id, index)"
          :ref="'userContainer' + index"
          class="bg-white p-2 border-blue-800 border grid grid-cols-3 cursor-pointer"
        >
          <div>
            <div class="relative mr-2 border border-black">
              <div
                style="width: 100%; padding-top: 100%; background-size: cover"
                :style="{
                  backgroundImage: `url(
                    ${
                      user.userId.photo
                        ? $store.state.hostname +
                          '/img/users/' +
                          user.userId.photo
                        : 'https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2Fs3.amazonaws.com%2Fappforest_uf%2Ff1583584638543x692525241529171000%2Fperson-placeholder-male-5.jpg?w=192&h=202&auto=compress&dpr=1.25&fit=max'
                    }
                  )`,
                }"
              ></div>
            </div>
          </div>
          <div
            class="flex flex-col text-sm font-bold justify-between max-w-xs"
            style="width: max-content; min-width: 7rem"
          >
            <div class="text-xs font-normal">Online status</div>
            <div class="capitalize">
              {{ user.userId.name }}<br />
              {{ user.userId.labId.name }}
            </div>
          </div>
          <div class="flex justify-end">
            <div>
              <i
                class="fas fa-envelope text-xl text-gray-500 cursor-pointer hover:text-green-800"
                data-v-4f22f8d6=""
                aria-hidden="true"
              ></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="ml-4 w-full">
      <div class="bggray border-black border relative">
        <div
          v-show="!selectedUser"
          class="text-center font-bold text-3xl text-red-800 w-full h-full"
        >
          <span v-if="externalUsers.length > 0"
            >Please select a user from the left panel.</span
          >
          <span v-else>No external lab members</span>
        </div>
        <!-- <div :class="{ invisible: !selectedUser }"> -->
        <div v-show="selectedUser">
          <div class="grid grid-cols-2 p-2">
            <div class="flex-col flex justify-between">
              <div class="flex px-2">
                <div class="relative mr-2 border border-black">
                  <div
                    style="
                      width: 6rem;
                      padding-top: 100%;
                      background-size: cover;
                    "
                    :style="{
                      backgroundImage: `url(
                    ${
                      selectedUser?.userId?.photo
                        ? $store.state.hostname +
                          '/img/users/' +
                          selectedUser?.userId?.photo
                        : 'https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2Fs3.amazonaws.com%2Fappforest_uf%2Ff1583584638543x692525241529171000%2Fperson-placeholder-male-5.jpg?w=192&h=202&auto=compress&dpr=1.25&fit=max'
                    }
                  )`,
                    }"
                  ></div>
                </div>
                <div class="text-3xl flex flex-col">
                  <span class="capitalize">{{ selectedUser?.userId.name }}</span
                  ><span class="text-sm">{{ selectedUser?.userId.email }}</span>
                </div>
              </div>
              <div class="flex justify-between mt-4 items-end">
                <div class="flex">
                  <input
                    type="checkbox"
                    v-model="editUser"
                    id="editUser"
                    class="w-4 h-4 cursor-pointer mb-0 mr-1"
                  />
                  <label for="editUser" class="leading-tight cursor-pointer"
                    >Edit User</label
                  >
                </div>
                <button
                  v-if="editUser"
                  @click="saveUser(selectedUser?._id)"
                  class="mr-4 rounded bg-green-700 px-4 text-white"
                  ref="saveUserChanges"
                >
                  Save
                </button>
              </div>
            </div>

            <div class="flex flex-col">
              <div class="flex items-end">
                <input
                  :disabled="
                    !editUser ||
                    (selectedUser?.userId.role == 'lab-admin' &&
                      $store.state.activeUser.labId == selectedUser.labId)
                  "
                  type="checkbox"
                  ref="userIsActive"
                  id="userIsActive"
                  @change="notifyNotSaved"
                  class="w-5 h-5 mr-1 cursor-pointer"
                /><label
                  for="userIsActive"
                  class="cursor-pointer mb-4 leading-none"
                  >Remove all access</label
                >
              </div>
              <textarea
                :disabled="!editUser"
                name=""
                ref="adminNotes"
                placeholder="Admin Notes"
                id=""
                cols="30"
                rows="5"
                @input="updateAdminNotesFn"
                v-model="selectedUserNotes"
                class="border-2 px-2 py-1"
              ></textarea>
            </div>
          </div>
          <div class="p-2 mt-4">
            <div class="flex">
              <div
                @click="activeAccessPanel = 'custom'"
                class="text-white font-semibold px-2 pt-2 pb-1 rounded-t-lg leading-none cursor-pointer"
                :class="[
                  activeAccessPanel == 'custom' ? 'bg-gray-700' : 'bg-gray-500',
                ]"
              >
                Custom Product Access
              </div>
            </div>
            <div class="relative bg-gray-700 px-2 pb-2 pt-2">
              <div class="relative">
                <div
                  v-if="
                    !editUser ||
                    (selectedUser?.userId.role == 'lab-admin' &&
                      $store.state.activeUser.labId ==
                        selectedUser?.userId.labId)
                  "
                  class="absolute bg-gray-700 opacity-50 w-full h-full px-0 py-0 z-10"
                ></div>

                <div>
                  <div class="grid grid-cols-4 gap-x-1 text-sm font-semibold">
                    <div class="bg-white border px-2 py-1">
                      <div>Dewars Access</div>

                      <div
                        class="p-2 rounded bg-gray-300 leading-none mb-2"
                        v-for="(
                          dewar, index
                        ) in selectedUser?.customDewarAccess"
                        :key="dewar.dewar?._id"
                      >
                        <div
                          class="mb-1 closestToLabel"
                          :data-itemid="dewar.dewar?._id"
                          :data-userid="selectedUser?.userId._id"
                          data-itemtype="dewar"
                        >
                          {{
                            dewar.dewar?.customId ??
                            "Dewar" + $store.state.twoDigitsPlease(index + 1)
                          }}
                          <br />
                          {{
                            dewar.dewar?.title ? `(${dewar.dewar?.title})` : ""
                          }}
                        </div>
                        <custom-item
                          v-model:custom-access="dewarAccessCustom[index]"
                          :selected="dewar.access"
                          item-type="dewar"
                          @val-change="updateCustomChangeContainer"
                          :index="index + 1"
                        ></custom-item>
                      </div>
                    </div>
                    <div class="bg-white border px-2 py-1">
                      <div>Racks Access</div>

                      <div
                        class="p-2 rounded bg-gray-300 leading-none mb-2"
                        v-for="(rack, index) in selectedUser?.customRackAccess"
                        :key="rack.rack?._id"
                      >
                        <div
                          class="mb-1 closestToLabel"
                          :data-itemid="rack.rack?._id"
                          :data-userid="selectedUser?.userId._id"
                          data-itemtype="rack"
                        >
                          {{ rack.rack?.serialNo }}<br />
                          {{
                            rack.rack?.title
                              ? `(${rack.rack?.title})`
                              : rack.rack?.customId
                              ? `(${rack.rack?.customId})`
                              : ""
                          }}
                        </div>
                        <custom-item
                          v-model:custom-access="rackAccessCustom[index]"
                          :selected="rack.access"
                          item-type="rack"
                          @val-change="updateCustomChangeContainer"
                          :index="index + 1"
                        ></custom-item>
                      </div>
                    </div>

                    <div class="bg-white border px-2 py-1">
                      <div>Pucks Access</div>

                      <div
                        class="p-2 rounded bg-gray-300 leading-none mb-2"
                        v-for="(puck, index) in selectedUser?.customPuckAccess"
                        :key="puck.puck?._id"
                      >
                        <div
                          class="mb-1 closestToLabel"
                          :data-itemid="puck.puck?._id"
                          :data-userid="selectedUser?.userId._id"
                          data-itemtype="puck"
                        >
                          {{ puck.puck?.serialNo }}<br />
                          {{
                            puck.puck?.title
                              ? `(${puck.puck?.title})`
                              : puck.puck?.customId
                              ? `(${puck.puck?.customId})`
                              : ""
                          }}
                        </div>
                        <custom-item
                          v-model:custom-access="puckAccessCustom[index]"
                          :selected="puck.access"
                          item-type="puck"
                          @val-change="updateCustomChangeContainer($event)"
                          :index="index + 1"
                        ></custom-item>
                      </div>
                    </div>

                    <div class="bg-white border px-2 py-1">
                      <div>Boxes Access</div>

                      <div
                        class="p-2 rounded bg-gray-300 leading-none mb-2"
                        v-for="(box, index) in selectedUser?.customBoxAccess"
                        :key="box.box?._id"
                      >
                        <div
                          class="mb-1 closestToLabel"
                          :data-itemid="box.box?._id"
                          :data-userid="selectedUser?.userId._id"
                          data-itemtype="box"
                        >
                          {{
                            box.box?.puckId.serialNo +
                            "-" +
                            $store.state.twoDigitsPlease(box.box?.wellNo)
                          }}
                          <br />
                          {{
                            box.box?.title
                              ? `(${box.box?.title})`
                              : box.box?.customId
                              ? `(${box.box?.customId})`
                              : ""
                          }}
                        </div>
                        <custom-item
                          v-model:custom-access="boxAccessCustom[index]"
                          :selected="box.access"
                          @val-change="updateCustomChangeContainer"
                          item-type="box"
                          :index="index + 1"
                        ></custom-item>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import HeaderBar from "./manage-products-sections/HeaderBar.vue";

import CustomItem from "./group-admin-page/CustomItem.vue";
export default {
  components: { HeaderBar, CustomItem },
  data() {
    return {
      lastClickedUser: null,
      dewarAccess: null,
      rackAccess: null,
      puckAccess: null,
      boxAccess: null,
      dewarAccessTemp: [],
      rackAccessTemp: [],
      puckAccessTemp: [],
      boxAccessTemp: [],
      dewarAccessCustom: [],
      rackAccessCustom: [],
      puckAccessCustom: [],
      boxAccessCustom: [],
      updateAdminNotes: false,
      selectedUserId: null,
      editUser: null,
      selectedUserNotes: "",
      activeAccessPanel: "custom",
      changedCustomItems: [],
    };
  },
  // watch: {
  //   externalUsers() {
  //     for (const [index, user] of this.externalUsers.entries()) {
  //       this.dewarAccessTemp[index] = user.globalDewarAccess;
  //       this.rackAccessTemp[index] = user.globalRackAccess;
  //       this.puckAccessTemp[index] = user.globalPuckAccess;
  //       this.boxAccessTemp[index] = user.globalBoxAccess;
  //     }
  //   },
  // },
  computed: {
    selectedUser() {
      const user = this.$store.state.externalusers.filter((user) => {
        return user.userId._id == this.selectedUserId;
      })[0];

      return user;
    },
    externalUsers() {
      return this.$store.state.externalusers;
    },
  },

  methods: {
    updateAdminNotesFn() {
      this.updateAdminNotes = true;
      this.notifyNotSaved();
    },
    updateCustomChangeContainer(ev) {
      const itemid =
        ev.target.parentElement.parentElement.previousElementSibling.dataset
          .itemid;
      const userid =
        ev.target.parentElement.parentElement.previousElementSibling.dataset
          .userid;
      const itemtype =
        ev.target.parentElement.parentElement.previousElementSibling.dataset
          .itemtype;

      var found = false;
      for (var i = 0; i < this.changedCustomItems.length; i++) {
        if (this.changedCustomItems[i].itemid == itemid) {
          found = true;
          this.changedCustomItems[i].customaccess = ev.target.value;
          break;
        }
      }

      if (!found)
        this.changedCustomItems.push({
          itemid,
          userid,
          itemtype,
          customaccess: ev.target.value,
        });

      console.log(this.changedCustomItems);
      this.notifyNotSaved();
    },
    notifyNotSaved() {
      this.$refs.saveUserChanges.style.outline = "2px red dashed";
    },
    loadUser(userId, containerIndex) {
      console.log(containerIndex);
      this.selectedUserId = userId;
      if (this.lastClickedUser)
        this.lastClickedUser.style.backgroundColor = "white";

      this.lastClickedUser = this.$refs["userContainer" + containerIndex];
      this.lastClickedUser.style.backgroundColor = "rgb(228, 240, 228)";

      this.selectedUserNotes = this.selectedUser?.adminNotes;
      console.log(this.selectedUser);
    },
    saveUser(idforremoval) {
      // const body = {
      //   adminNotes: this.$refs.adminNotes.value,
      // };

      if (this.$refs.userIsActive.checked) {
        //rem,ove user
        // body.role = "temp";
        // this.selectedUser = null;
        // this.$refs.userIsActive.checked = "true";
        console.log(idforremoval);
        const remove = confirm(
          "Are you sure you want to remove all access for this user?"
        );
        if (!remove) {
          this.$refs.userIsActive.checked = false;
          return;
        }

        //fetch request

        const arrayOfUsersCustomDewarIdsToRemove = [];
        this.selectedUser?.customDewarAccess.forEach((item) => {
          arrayOfUsersCustomDewarIdsToRemove.push(item.dewar._id);
        });
        const arrayOfUsersCustomRackIdsToRemove = [];
        this.selectedUser?.customRackAccess.forEach((item) => {
          arrayOfUsersCustomRackIdsToRemove.push(item.rack._id);
        });
        const arrayOfUsersCustomPuckIdsToRemove = [];
        this.selectedUser?.customPuckAccess.forEach((item) => {
          arrayOfUsersCustomPuckIdsToRemove.push(item.puck._id);
        });

        const arrayOfUsersCustomBoxIdsToRemove = [];
        this.selectedUser?.customBoxAccess.forEach((item) => {
          arrayOfUsersCustomBoxIdsToRemove.push(item.box._id);
        });

        const body = {
          userId: this.selectedUser?.userId._id,
          customDewarsToRemove: arrayOfUsersCustomDewarIdsToRemove,
          customRacksToRemove: arrayOfUsersCustomRackIdsToRemove,
          customPucksToRemove: arrayOfUsersCustomPuckIdsToRemove,
          customBoxesToRemove: arrayOfUsersCustomBoxIdsToRemove,
        };
        let url = `${this.$store.state.hostname}/api/v1/externaluser/removeall/${idforremoval}`;
        const request = new Request(url, {
          credentials: "include",
          method: "PATCH",
          body: JSON.stringify(body),

          headers: { "content-type": "application/json" },
        });

        fetch(request)
          .then((resp) => resp.json())
          .then((data) => {
            if (data.status != "success") throw new Error(data.message);
            else {
              console.log("dgaaggads");
              this.$refs.saveUserChanges.style.outline = "";
              this.getUsersForLab();
            }
          })
          .catch((err) => {
            console.log(err);
          });

        return;
      }

      let adminnotesbody = { adminNotes: this.selectedUserNotes };
      if (this.updateAdminNotes) {
        this.$store.dispatch("updateItem", {
          body: adminnotesbody,
          item: "externaluser",
          objid: idforremoval,
        });
      }

      this.changedCustomItems.forEach((obj, idx, array) => {
        const { itemid, itemtype, customaccess, userid } = obj;

        if (idx !== array.length - 1) {
          this.$store.state.manageUsersCustomAccessFnc(
            itemtype,
            itemid,
            customaccess,
            userid,
            true
          );
        } else {
          this.$store.state
            .manageUsersCustomAccessFnc(
              itemtype,
              itemid,
              customaccess,
              userid,
              true
            )

            .then(() => {
              console.log("here");
              this.getUsersForLab();
            })

            .catch((err) => console.log(err));
        }
      });

      this.$refs.saveUserChanges.style.outline = "";
      this.updateAdminNotes = false;
    },
    approveUser(userId, userLab, index) {
      let proceed = true;

      if (
        this.dewarAccessTemp[index] == null ||
        this.dewarAccessTemp[index] == "2ndnull"
      ) {
        this.dewarAccessTemp[index] = "2ndnull";
        proceed = false;
      }
      if (
        this.rackAccessTemp[index] == null ||
        this.rackAccessTemp[index] == "2ndnull"
      ) {
        this.rackAccessTemp[index] = "2ndnull";
        proceed = false;
      }
      if (
        this.puckAccessTemp[index] == null ||
        this.puckAccessTemp[index] == "2ndnull"
      ) {
        this.puckAccessTemp[index] = "2ndnull";
        proceed = false;
      }
      if (
        this.boxAccessTemp[index] == null ||
        this.boxAccessTemp[index] == "2ndnull"
      ) {
        this.boxAccessTemp[index] = "2ndnull";
        proceed = false;
      }

      if (proceed == false) return;

      const body = {
        globalDewarAccess: this.dewarAccessTemp[index],
        globalRackAccess: this.rackAccessTemp[index],
        globalPuckAccess: this.puckAccessTemp[index],
        globalBoxAccess: this.boxAccessTemp[index],
        role: "user",
      };

      //need to make approval reactive. run get users for lab after dispatch complete
      //need to do delete
      console.log(userLab);
      this.$store.dispatch("updateItem", {
        body,
        labid: userLab,
        item: "users",
        objid: userId,
      });
    },

    deleteUser(userId) {
      const deleteUser = confirm("Are you sure you want to delete this user?");
      if (!deleteUser) {
        return;
      }
      this.$store.dispatch("deleteItem", {
        item: "users",
        objid: userId,
      });
    },

    getUsersForLab() {
      //when a user selects a lab from update lab

      const labid = this.$store.state.activeUser.labId._id;

      this.$store.dispatch("getAdminStuff", {
        url: "externalusers",
        labid,
      });
    },
  },
  mounted() {
    this.getUsersForLab();
  },
};
</script>

<style scoped>
textarea {
  border-color: rgb(118, 155, 186);

  resize: none;
}
.bggray {
  background-color: rgb(237, 237, 237);
}
</style>
