<template>
  <div class="relative" @mousedown="trackClick">
    <div v-if="$store.state.activeUser.length <= 0"></div>
    <div
      class="w-full relative"
      v-else-if="$store.state.activeUser.role != 'temp'"
    >
      <div
        ref="changelog"
        class="w-full expandable bg-yellow-400 bg-opacity-50 text-sky-900 underline z-50 text-center text-sm"
      >
        <router-link to="changelog"
          >What's new in {{ "v" + $version }}</router-link
        >
      </div>
      <div class="w-full flex relative">
        <nav @click="closeNav()" ref="nav" class="nav w-1/4 hidden xl:block">
          <div id=""></div>
          <!--- nav for desktop-->

          <ul class="cursor-pointer flex-1 w-full sticky top-0">
            <router-link to="/home"><li>Home</li></router-link>
            <router-link to="my-profile"><li>My Profile</li></router-link>
            <router-link to="manage-products"
              ><li>Manage Products</li></router-link
            >
            <router-link
              v-if="$store.state.activeUser?.role == 'lab-admin'"
              to="shared-products"
              ><li>Shared Products</li></router-link
            >
            <router-link
              v-if="$store.state.activeUser?.role == 'lab-admin'"
              to="transport-racks"
              ><li>Transport Racks</li></router-link
            >
            <router-link
              v-if="$store.state.activeUser?.role == 'lab-admin'"
              to="group-admin-page"
              ><li>Group Admin Page</li></router-link
            >
            <router-link
              v-if="$store.state.activeUser?.role == 'lab-admin'"
              to="project-manager"
              ><li>Project Manager</li></router-link
            >
            <router-link to="team-members"><li>Team Members</li></router-link>

            <router-link to="archives"><li>Grid Explorer</li></router-link>
            <router-link
              v-if="$store.state.activeUser?.role == 'lab-admin'"
              to="data-settings"
              ><li>Data Settings</li></router-link
            >
            <!-- <a><li
            class="text-yellow-500"
            v-if="$store.state.isLabSuper"
            @click="setPage('billing')"
          >
            Premium
          </li></a> -->
            <router-link v-if="$store.state.isMark" to="admin">
              <li class="text-orange-500">Administrative</li></router-link
            >
          </ul>
        </nav>
        <div
          @click="closeNav()"
          style="z-index: 9000; padding-top: 10vh"
          ref="taptoclose"
          class="absolute bg-opacity-75 w-full top-0 text-3xl right-0 hidden bg-black h-screen text-white cursor-pointer"
        >
          <div class="absolute right-0 flex justify-center">Tap to Close</div>
        </div>
        <main>
          <div
            ref="toprowid"
            id="toprow"
            class="pb-4 pt-1 flex items-center sticky bg-white"
            style="top: 0; z-index: 49"
          >
            <div class="flex-1 ml-1 flex">
              <div class="mr-1 xl:hidden mx-0 my-auto flex-none">
                <span
                  class="material-symbols-outlined bg-gray-300 rounded px-2 py-1 text-3xl text-sky-900 mr-2 ml-2 cursor-pointer hover:text-green-800"
                  @click="showNav"
                  >menu
                </span>
                <!-- <i
                  @click="showNav"
                  class="bg-gray-300 rounded px-2 py-1 fas fa-bars text-3xl text-sky-900 mr-2 ml-2 cursor-pointer hover:text-green-800"
                ></i> -->
              </div>

              <div class="flex-initial">
                <img
                  alt="Subangstrom"
                  src="https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2Fs3.amazonaws.com%2Fappforest_uf%2Ff1577048235134x219865190081106400%2Fsubangstrom-medium.png?w=192&h=77&auto=compress&fit=crop&dpr=1.25"
                />
              </div>
            </div>

            <div class="mr-2">
              <div
                class="text-green-800 text-xs font-bold text-right my-1 block"
              >
                Hi {{ $store.state.activeUser?.name?.split(" ")[0] }}
              </div>
              <div class="flex justify-between items-end relative">
                <i
                  @click="setPage('messages')"
                  class="fas fa-envelope text-2xl text-sky-900 mr-3 cursor-pointer hover:text-green-800"
                ></i>
                <!-- <i
                  @click="setPage('my-profile')"
                  class="fas fa-user-circle text-2xl text-sky-900 mr-3 cursor-pointer hover:text-green-800"
                ></i> -->
                <span
                  v-if="
                    $store.state.activeUser.superLabIds &&
                    $store.state.activeUser.superLabIds.length > 0
                  "
                  ><select
                    style="margin: 0"
                    @change="switchLab($event)"
                    class="w-[max-content]"
                  >
                    <option
                      :key="labId._id"
                      :selected="
                        $store.state.activeUser.activeLabId?._id === labId._id
                      "
                      :value="labId._id"
                      v-for="labId in $store.state.activeUser.superLabIds"
                    >
                      {{ labId.name }}
                    </option>
                  </select></span
                >
                <span
                  @click="setPage('my-profile')"
                  class="material-symbols-outlined text-2xl text-sky-900 leading-none mx-3 cursor-pointer hover:text-green-800 relative"
                >
                  account_circle
                </span>

                <button
                  @click="$store.dispatch('logout')"
                  class="logoutbtn text-xs px-2"
                  style="margin: 0"
                >
                  Logout
                </button>
              </div>
            </div>
          </div>
          <div class="flex-1 justify-center w-full">
            <router-view
              :change-page="setPage"
              @openfeedback="showFeedback = true"
            ></router-view>
            <!-- <component :change-page="changeSelectedPage" :is="selectedPage" /> -->
          </div>
          <div
            class="bg-sky-900 invisible z-10 text-white text-center text-sm py-3 relative bottom-0 w-full mt-8"
          >
            inventory.subangstrom.com - All Rights Reserved
            <!--space holder-->
          </div>
          <div
            class="bg-sky-900 z-20 text-white text-center text-sm py-3 absolute bottom-0 left-0 w-full mt-8"
          >
            inventory.subangstrom.com - All Rights Reserved
            <div class="absolute bottom-0 right-0 text-xs text-gray-400 mr-2">
              {{ "v" + $version }}
            </div>
          </div>
        </main>
      </div>
    </div>
    <div class="w-screen h-screen flex items-center justify-center" v-else>
      <div class="inline-block m-auto">
        Your account is awaiting approval from your lab's administrator. You
        will receive an email notification once approved.
      </div>
    </div>

    <display-modal v-if="showFeedback" @ridfeedback="ridFeedback">
      <feedback-inner></feedback-inner>
    </display-modal>
  </div>
</template>

<script>
import FeedbackInner from "./modal-inners/feedback-inner.vue";
// import ManageProducts from './router-pages/loggedin/ManageProducts.vue';
// import MyProfile from './router-pages/loggedin/MyProfile.vue';
// import Messages from './router-pages/loggedin/Messages.vue';
// import SharedProducts from './router-pages/loggedin/SharedProducts.vue';
// import TeamMembers from './router-pages/loggedin/TeamMembers.vue';
// import ProjectManager from './router-pages/loggedin/ProjectManager.vue';
// import TransportRacks from './router-pages/loggedin/TransportRacks.vue';
// import GroupAdminPage from './router-pages/loggedin/GroupAdminPage.vue';
import { ref, provide, onMounted, watch, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default {
  components: { FeedbackInner },
  props: [],

  methods: {
    switchLab(event) {
      const selectedLabId = event.target.value;
      const url =
        this.$store.state.hostname + "/api/v1/users/switchlab/" + selectedLabId;
      const request = new Request(url, {
        credentials: "include",
        method: "GET",
      });
      fetch(request)
        .then((resp) => resp.json())
        .then((data) => {
          if (data.status == "fail") throw new Error(data.message);
          else {
            window.location.reload();
            // console.log(data.data.data);
            //context.commit("updateUserData", { value: data.data.data });
            //arrayOfDewars.value = data.data.data;
          }
        })
        .catch((err) => console.log(err));
    },
    trackClick(ev) {
      console.log("mouse down on", ev.target, this.$route);
      if (
        (this.$route.name == "home" || this.$route.name == "manage-products") &&
        !this.$store.state.importModalMounted
      ) {
        if (
          ev.target instanceof HTMLInputElement ||
          ev.target instanceof HTMLTextAreaElement ||
          ev.target instanceof HTMLSelectElement
        )
          console.log("input clicked");
        else
          window.setTimeout(() => {
            if (document.getElementById("searchbarforloggedin"))
              document.getElementById("searchbarforloggedin").focus();
          }, 100);
      }
    },
  },

  setup() {
    const store = useStore();
    const boxColors = store.state.boxColors;
    const transformBoxColor = store.state.transformBoxColor;
    const selectedPage = ref("home");
    const nav = ref(null);
    const toprowid = ref(null);
    const changelog = ref(null);
    const taptoclose = ref(null);
    const router = useRouter();
    const showFeedback = ref(false);
    const feedbackElem = ref(null);
    const userEmail = computed(() => {
      return store.state.activeUser?.email;
    });

    watch(userEmail, (newVal) => {
      if (newVal) {
        // Assuming you might need a delay, use setTimeout here
        setTimeout(() => {
          searchheightfix();
        }, 500); // Adjust the delay as needed
      }
    });
    const topRowHeight = ref("0px");
    provide("topRowHeight", topRowHeight);

    function searchheightfix() {
      const stupidinterval = setTimeout(() => {
        if (changelog.value) changelog.value.classList.add("giveheight");

        function outerHeight(el) {
          var height = el.offsetHeight;
          var style = getComputedStyle(el);

          height += parseInt(style.marginTop) + parseInt(style.marginBottom);
          return height;
        }

        if (toprowid.value) {
          console.log(outerHeight(toprowid.value) + "px");
          topRowHeight.value = outerHeight(toprowid.value) + "px";
          // console.log((toprowheight.value = this.topRowHeight.value));
          clearTimeout(stupidinterval);
        }
      }, 500);
    }

    // function changeSelectedPage(pageName) {
    //   selectedPage.value = pageName;
    // }

    function setPage(pagename) {
      router.push({ name: pagename });
      // if (window.innerWidth < 1280) {
      //   nav.value.style.transform = "translateX(-100%)";
      // }
    }

    function ridFeedback() {
      showFeedback.value = false;
    }

    function closeNav() {
      console.log(window.screen.width, window.innerWidth);
      if (window.innerWidth >= 1280) {
        taptoclose.value.style.display = "none";
        return;
      }

      if (window.innerWidth < 1280) {
        nav.value.style.transform = "translateX(-100%)";
      }

      taptoclose.value.style.display = "none";
      nav.value.style.display = "none";
    }

    onMounted(() => {
      searchheightfix();
      window.addEventListener("resize", searchheightfix);
      addCssForBoxColors();
    });

    function addCssForBoxColors() {
      if (!document.getElementById("singletonBoxColors")) {
        const styleElem = document.createElement("style");
        document.head.append(styleElem);
        let css = "";
        styleElem.id = "singletonBoxColors";
        styleElem.type = "text/css";

        boxColors.forEach((color, index) => {
          if (color == "white") {
            css += `.graycircle-${index} {fill: ${transformBoxColor(
              "white",
              true
            )};}`;

            css += `.boxChoice-color-${index}.clip .st0 {
                                       stroke:${transformBoxColor(
                                         color,
                                         true
                                       )};}`;

            css += `.boxChoice-color-${index} .st2 {stroke:${transformBoxColor(
              color,
              true
            )};}`;
          } else {
            css += `.graycircle-${index} {

            fill: ${transformBoxColor(color)};}`;
          }

          css += `.boxChoice-color-${index}.clip .st3 {
                                  fill: none;
                                }`;

          css += `.boxChoice-color-${index}.clip .st0 {

                                 fill: ${transformBoxColor(color)} !important;
                                 }`;
          css += `.boxChoice-color-${index}.unclip .st0 {

                                                    fill: ${transformBoxColor(
                                                      color,
                                                      true
                                                    )} !important;

                         }`;
          css += `.boxChoice-color-${index}.unclip .st1 {

                                                    fill: ${transformBoxColor(
                                                      color
                                                    )} !important;

                         }`;

          css += `.boxChoice-color-${index}.clip .st1 {fill: ${transformBoxColor(
            color,
            true
          )} !important;}`;

          css += `.boxChoice-color-${index} .st2 {

                         fill: #ffffff !important;
                         }`;

          css += `.boxChoice-color-${index} .stblack {

                         fill: #000000 !important;
                         }`;
          css += `.boxChoice-color-${index}.unclip .st3 {
   fill: url(#SVGID_PLUSSIGN) !important;
                             stroke: #ffffff;
                             stroke-width: 0.25;
                             stroke-miterlimit: 10;
                         }`;
        });

        boxColors.forEach((color) => {
          if (color == "white") {
            css += `.well-white-clipped .st2 {
           stroke:${transformBoxColor(color, true)} !important;}`;
          }

          css += `.well-${color.replace(/\s+/g, "-")}-clipped .st0 {
        fill:${transformBoxColor(color)} !important;
        }
        .well-${color.replace(/\s+/g, "-")}-clipped .st1 {
        fill:${transformBoxColor(color, true)} !important;
        }

        `;
        });

        boxColors.forEach((color) => {
          if (color == "white") {
            css += `.well-white-unclipped .st2 {
           stroke:${transformBoxColor(color, true)} !important;}`;
          }
          css += `.well-${color.replace(/\s+/g, "-")}-unclipped .st0 {
        fill:${transformBoxColor(color, true)} !important;
        }
        .well-${color.replace(/\s+/g, "-")}-unclipped .st1 {
        fill:${transformBoxColor(color)} !important;
        }

        `;
        });

        if (styleElem.styleSheet) {
          // This is required for IE8 and below.
          styleElem.styleSheet.cssText = css;
        } else {
          styleElem.appendChild(document.createTextNode(css));
        }
      }
    }

    function showNav() {
      console.log(nav.value.style.display);
      if (nav.value.style.transform != "translateX(0%)") {
        nav.value.style.display = "block";
        console.log(nav.value.offsetWidth);
        console.log(taptoclose.value.firstElementChild);
        taptoclose.value.firstElementChild.style.width =
          window.innerWidth - nav.value.offsetWidth + "px";
        taptoclose.value.style.display = "block";
        nav.value.style.position = "fixed";
        nav.value.style.zIndex = "9999";
        nav.value.style.transform = "translateX(0%)";
        nav.value.style.transition = "transform .5s ease-out";
      } else nav.value.style.transform = "translateX(-100%)";
      /*if (nav.value.style.transform != "none") {
        nav.value.style.width = "300px";
        nav.value.style.transition = "width .5s ease-out";
      } else nav.value.style.width = "0"; */
    }
    return {
      selectedPage,
      taptoclose,
      showNav,
      nav,
      showFeedback,
      topRowHeight,
      toprowid,
      addCssForBoxColors,
      ridFeedback,
      setPage,
      changelog,
      feedbackElem,
      closeNav,
    };
  },
};
</script>
<style scoped>
.expandable {
  overflow: hidden;

  line-height: 0;
  transition: all 0.5s ease-in-out;
}

.giveheight {
  line-height: 1.5;
}

.logoutbtn {
  padding: 0.1rem 0.4rem;
}

a:visited :not(.expandable) {
  color: white;
}

button {
  margin: auto;
  cursor: pointer;
  background: none rgba(51, 92, 139);
  border: 2px solid rgba(47, 83, 125);

  text-align: center;
  margin-bottom: 1rem;
  min-height: min-content;
  max-height: 39px;
  height: 6vh;
  max-width: 162px;
  width: 100%;
  color: rgb(255, 255, 255);

  font-family: Montserrat;
  border-radius: 26px;
}

#toprow {
  min-height: min-content;
  width: 100%;
}
#toprow img {
  max-height: 60px;
  width: auto;
}

nav {
  max-width: 300px;
  color: white;
  background: rgb(5, 46, 77);
  min-height: 100vh;
}

@media (max-width: 1279px) {
  nav {
    transform: translateX(-100%);
  }
}
li:hover {
  background: #097021;
}
li {
  padding: 1rem 0rem 1rem 1rem;
  border: solid 1px rgba(255, 255, 255, 0.1);
  border-width: 1px 0 0 0;
}

button {
  border-radius: 0;
  min-height: 0;
  height: auto;
}

main {
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  align-items: flex-start;
  position: relative;
  top: 0;
  left: 0;
}
</style>
