<template>
  <div class="flex flex-col w-full m-auto relative">
    <search-bar @pasted="goToManageProducts"></search-bar>
    <div class="mt-4 thisblue text-center font-bold text-4xl">SUBANGSTROM</div>
    <div class="mt-0 mb-4 text-center text-2xl">
      Inventory Management System
    </div>
    <main
      class="grid grid-cols-2 md:grid-cols-3 auto-cols-auto justify-items-center gap-8"
    >
      <home-item @click="changePage('manage-products')" image="manage-products"
        >Manage Products</home-item
      >
      <home-item
        v-if="$store.state.activeUser?.role == 'lab-admin'"
        @click="changePage('transport-racks')"
        image="transport-racks"
        >Transport Racks</home-item
      >
      <home-item
        v-if="$store.state.activeUser?.role == 'lab-admin'"
        @click="changePage('project-manager')"
        image="project-manager"
        >Project Manager
      </home-item>
      <home-item v-else @click="changePage('archives')" image="project-manager"
        >Grid Explorer
      </home-item>
      <home-item
        v-if="$store.state.activeUser?.role == 'lab-admin'"
        @click="changePage('shared-information')"
        image="shared-information"
        >Shared Information</home-item
      >
      <home-item
        v-if="$store.state.activeUser?.role == 'lab-admin'"
        @click="changePage('my-group')"
        image="my-group"
        >My Group</home-item
      >
      <home-item @click="changePage('team-members')" image="team-members"
        >Team Members</home-item
      >
    </main>
    <div
      class="text-center mt-16 underline cursor-pointer font-semibold"
      @click="$emit('openfeedback')"
    >
      Let us know how we're doing
    </div>
  </div>
</template>

<script>
import HomeItem from "../../HomeItem.vue";
import SearchBar from "../../ui/SearchBar.vue";

export default {
  props: ["changePage"],
  components: {
    HomeItem,
    SearchBar,
  },
  data() {
    return {
      showFeedback: false,
    };
  },
  async mounted() {
    await this.$store.dispatch("updateDewarData");
  },
  methods: {
    goToManageProducts(ev1, ev2) {
      //wont work for home page keyword search

      const specialNavigation = { puck: ev1, well: ev2, ev3: ev2 };
      this.$router.push({
        path: "/manage-products",
        query: { navigation: JSON.stringify(specialNavigation) },
      });
    },
  },
};
</script>

<style scoped>
input {
  width: calc(100% - 4rem);
}

.narrowme {
  max-width: 50%;
}

.thisblue {
  color: rgb(5, 46, 77);
}
</style>
