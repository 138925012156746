<template>
  <!-- <div
    class="mb-4 cursor-pointer underline bg-green-700 text-white font-bold p-2 inline-block rounded mt-4 absolute right-0"
    @click="showCalc = true"
  >
    Show Calculator Users
  </div>
    <div v-show="showCalc"></div> -->

  <div class="flex mb-2">
    <div
      class="cursor-pointer rounded border-blue-900 border-2 px-4 py-2 mr-4"
      @click="showLabOverview = false"
      :class="[!showLabOverview ? ['bg-sky-900', 'text-white'] : 'bg-white']"
    >
      Manage Labs
    </div>
    <div
      class="cursor-pointer border-blue-900 border-2 rounded px-4 py-2"
      @click="getLogData"
      :class="[showLabOverview ? ['bg-sky-900', 'text-white'] : 'bg-white']"
    >
      Lab Activity Overview
    </div>
  </div>
  <div v-show="showLabOverview">
    Total Labs: {{ labs.length }}
    <div class="bg-sky-900 border border-black">
      <div class="grid grid-cols-5 gap-px bg-black laboverviewgrid">
        <div>Institute</div>
        <div>Lab</div>
        <div>Total Members</div>
        <div>Last login</div>
        <div># of actions last 30 days</div>
        <template v-for="lab in labs" :key="lab._id">
          <div>{{ lab.institute.name }}</div>
          <div>{{ lab.name }}</div>
          <div>{{ getUserCountForLab(lab._id) }}</div>
          <div>{{ getLastLogin(lab._id) || ">30 days" }}</div>
          <div>{{ getLabActionsCount(lab._id) }}</div>
        </template>
      </div>
    </div>
  </div>
  <div v-show="!showLabOverview">
    <div
      class="mb-4 cursor-pointer underline bg-green-700 text-white font-bold p-2 inline-block rounded mt-4"
      @click="showInstitute = true"
    >
      Add an Institute
    </div>

    <div v-show="showInstitute">Institute name:</div>
    <div
      class="border-dashed justify-start items-stretch border-t-0 border-b-2 border-r-0 border-l-0 flex border-red-400 pb-4"
      v-show="showInstitute"
    >
      <input v-model="instituteName" type="text" class="w-auto" />
      <button
        class="relative lengthenbutton"
        @click="$store.state.buttonFXinit($event), addInstitute($event)"
      >
        <button-inner small="true" class="h-full">Add Institute</button-inner>
      </button>
      <div class="pl-4 fadeoutbutton">Added Institute :)</div>
    </div>
    <div></div>
    <div
      class="mb-4 cursor-pointer underline bg-green-700 text-white font-bold p-2 inline-block rounded mt-4"
      @click="showLab = true"
    >
      Add a Lab
    </div>
    <div></div>
    <div
      class="border-dashed justify-start border-t-0 border-b-2 border-r-0 border-l-0 border-red-400 pb-4"
      v-show="showLab"
    >
      <div>
        <input
          list="institute-list"
          type="text"
          ref="instituteForLab"
          placeholder="Select an institute(double click)"
        />
        <datalist id="institute-list">
          <option
            :data-institute-id="institute._id"
            v-for="institute in institutes"
            :key="institute._id"
            :value="institute.name"
          ></option>
        </datalist>
      </div>
      <!-- <div>
      <input
        list="admin-list"
        type="text"
        ref="userForLab"
        placeholder="Select a Lab Admin"
      />
      <datalist id="admin-list">
        <option
          :data-user-id="user._id"
          v-for="user in users"
          :key="user._id"
          :value="user.email"
        >
        </option>
      </datalist>
    </div> -->
      <div>Lab Name:</div>
      <div class="flex flex-col">
        <input type="text" class="w-auto" ref="labName" />
        <div>Lab Code:</div>
        <input type="text" class="w-auto" ref="labCode" />
        <button
          class="relative lengthenbutton"
          @click="$store.state.buttonFXinit($event), addLab($event)"
        >
          <button-inner small="true" class="h-full">Add Lab</button-inner>
        </button>
        <div class="pl-4 fadeoutbutton">Added Institute :)</div>
      </div>
    </div>
    <div></div>
    <div
      style="background-color: rgb(21 128 61)"
      class="mb-4 cursor-pointer underline bg-green-700 text-white font-bold p-2 inline-block rounded mt-4"
      @click="() => (showManageLabs = true)"
    >
      Manage labs
    </div>

    <div></div>
    <!--thelablist ref so institute input names dont conflict with lab anames-->
    <div ref="thelablist" v-show="showManageLabs">
      <input
        ref="labForUpdate"
        list="lab-list"
        type="text"
        @change="getUsersForLab($event)"
        placeholder="Select a Lab(double click)"
      />
      <datalist id="lab-list">
        <option
          :data-lab-id="lab._id"
          v-for="lab in labs"
          :key="lab._id"
          :value="lab.name"
        ></option>
      </datalist>

      <div>
        <input
          list="admin-list"
          type="text"
          ref="userForLab"
          @change="labAdminChanged = true"
          placeholder="Select a Lab Admin(double click)"
        />
        <datalist id="admin-list">
          <option
            :data-user-id="user._id"
            v-for="user in users"
            :key="user._id"
            :value="user.email"
          ></option>
        </datalist>
      </div>

      <div class="flex items-end">
        <div class="mb-4 pr-4">Lab Name:</div>
        <input type="text" class="w-auto" v-model="labName" />
      </div>
      <div class="flex items-end">
        <div class="mb-4 pr-4">Lab Code:</div>
        <input type="text" class="w-auto" v-model="labCode" />
      </div>
      <div>Has Premium:</div>

      <select v-model="hasPremium">
        <option value="no">No</option>
        <option value="yes">Yes</option>
      </select>
      <div class="flex mb-4" v-show="hasPremium != 'no'">
        <div class="mr-4">
          <div>Premium start date:</div>

          <DatePicker v-if="flightDatesExist" v-model="startDate" />
          <DatePicker v-else v-model="startDate" />
        </div>
        <div>
          <div>Premium end date:</div>
          <DatePicker v-if="flightDatesExist" v-model="endDate" />
          <DatePicker v-else v-model="endDate" />
        </div>
      </div>

      <div class="flex">
        <button
          class="relative lengthenbutton"
          @click="$store.state.buttonFXinit($event), updateLab($event)"
        >
          <button-inner small="true" class="h-full">Update Lab</button-inner>
        </button>
        <div class="pl-4 fadeoutbutton">Updated Lab :)</div>
      </div>
    </div>

    <div
      class="mb-4 cursor-pointer underline bg-green-700 text-white font-bold p-2 inline-block rounded mt-4"
      @click="showCreate = true"
    >
      Create rack and pucks for lab
    </div>
    <div>
      <div class="bg-gray-300 rounded p-4 flex">
        <div class="bg-gray-300 rounded p-2 mr-8">
          <input
            ref="labForCreate"
            list="lab-list"
            type="text"
            placeholder="Select a Lab(double click)"
            @focus="removeRedBorder()"
          />
          <datalist id="lab-list">
            <option
              :data-lab-id="lab._id"
              v-for="lab in labs"
              :key="lab._id"
              :value="lab.name"
            ></option>
          </datalist>
          <div>Rack id:<input v-model="rackCustomId" type="text" /></div>
          <div class="flex items-center mb-2">
            <input
              type="checkbox"
              class="w-8 h-8 m-0"
              id="istransportrack"
              ref="istransportrack"
              v-model="istransportchecked"
            />
            <label for="istransportrack"> is Transport Rack</label>
          </div>

          <div class="flex" v-if="!istransportchecked">
            <div class="mr-6">
              Puck prefix:<input v-model="puckPrefix" type="text" />
            </div>
            <div>Start # <input type="number" v-model="puckStartNo" /></div>
            <div>2 digit<input type="checkbox" v-model="twoDigit" /></div>
          </div>
          <div v-if="!istransportchecked">
            <div v-for="n in 10" :key="n" class="flex">
              <input
                type="text"
                class="puckserialno"
                :value="
                  puckPrefix +
                  parseInt((puckStartNo * n) / n + n - 1).toLocaleString(
                    'en-US',
                    {
                      minimumIntegerDigits: twoDigit ? 2 : 1,
                      useGrouping: false,
                    }
                  )
                "
              />
              <select
                class="font-bold ml-4 text-black puckcolor"
                :class="{
                  'text-white':
                    $store.state.puckColors[n > 10 ? (n - 1) % 10 : n - 1] ==
                    'black'
                      ? true
                      : false,
                }"
                :style="{
                  'background-color': $store.state.transformPuckColor(
                    $store.state.puckColors[n > 10 ? (n - 1) % 10 : n - 1]
                  ),
                }"
              >
                <option
                  v-for="(color, index) in $store.state.puckColors"
                  :selected="n - 1 == index"
                  :value="color"
                  :key="color"
                >
                  {{ color }}
                </option>
              </select>
            </div>
          </div>
          <div></div>
          <div class="flex justify-center items-center">
            <button
              class="relative lengthenbutton text-xs"
              @click="createTheStuff($event)"
            >
              <button-inner small="true" class="h-full"
                >Create rack & pucks</button-inner
              >
            </button>
            <div class="pl-4 fadeoutbutton">Created the stuff :)</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- <div @click="showLab">Add a Lab</div> -->
</template>
<script>
import { DatePicker } from "v-calendar";
export default {
  components: {
    DatePicker,
  },
  data() {
    return {
      logdata: [],
      usersdataforoverview: [],
      firstLabOverviewLoad: true,
      showLabOverview: false,
      twoDigit: true,
      hasEventListener: false,
      puckPrefix: "",
      labAdminChanged: false,
      puckStartNo: 1,
      showManageLabs: false,
      showInstitute: false,
      showLab: false,
      hasPremium: "no",
      flightDatesExist: false,
      startDate: new Date(2023, 0, 1),
      endDate: new Date(2030, 0, 1),
      showCreate: false,
      instituteName: "",
      istransportchecked: false,
      labName: "",
      labCode: "",
      rackCustomId: "",
      subscribeToCreated: this.$store.subscribe((mutation) => {
        if (mutation.type === "updateAdminStuff" && mutation.payload.signal) {
          //allt his code just to default selet the user email for lab update
          console.log("fired");
          const labValue = this.$refs.labForUpdate.value;
          const labid = document.querySelector(
            'option[value="' + labValue + '"]'
          ).dataset.labId;
          const labmatch = this.$store.state.labs.filter(
            (lab) => lab._id == labid
          )[0];

          const usermatch = this.$store.state.users.filter((user) => {
            console.log(user, labmatch.labAdmin);
            return user._id == labmatch.labAdmin;
          });

          this.$refs.userForLab.value = usermatch[0]?.email ?? "";
        }
        // if (mutation.type === "adminCreatedStuff") {
        //   // if (!this.hasEventListener) {
        //   //   this.$refs.successdiv.addEventListener(
        //   //     "transitionend",
        //   //     successEffect("successdiv", "out")
        //   //   );

        //   //   this.hasEventListener = true;
        //   // }

        //   this.successEffect("successdiv", "in", mutation.payload.value);
        // }
      }),
    };
  },
  computed: {
    institutes() {
      return this.$store.state.institutes;
    },
    labs() {
      return this.$store.state.labs ?? [];
    },
    users() {
      return this.$store.state.users ?? [];
    },
  },
  methods: {
    async getLogData() {
      this.showLabOverview = true;

      if (this.firstLabOverviewLoad) this.firstLabOverviewLoad = false;
      else return;

      const url = `${this.$store.state.hostname}/api/v1/logs/laboverview`;

      const request = new Request(url, {
        credentials: "include",
        method: "GET",
        headers: { "content-type": "application/json" },
      });
      const data = await fetch(request);
      const jsondata = await data.json();

      this.logdata = jsondata.data.data;

      const usersurl = `${this.$store.state.hostname}/api/v1/users`;

      const usersrequest = new Request(usersurl, {
        credentials: "include",
        method: "GET",
        headers: { "content-type": "application/json" },
      });
      const usersdata = await fetch(usersrequest);
      const usersjsondata = await usersdata.json();

      this.usersdataforoverview = usersjsondata.data.data;
    },
    getLastLogin(labId) {
      const logs = this.logdata.filter((log) => {
        console.log(log.labId, labId);
        return log.labId == labId;
      });

      return this.$store.state.cleanDate(
        logs.sort(
          (a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt)
        )[0]?.createdAt,
        true
      );
    },
    getLabActionsCount(labId) {
      console.log("gotlabactiosn");
      const logs = this.logdata.filter((log) => {
        console.log(log.labId, labId);
        return log.labId == labId;
      });
      return logs.length;
    },
    getUserCountForLab(labId) {
      console.log(this.usersdataforoverview);
      const users = this.usersdataforoverview.filter((user) => {
        return user.labId?._id == labId;
      });

      return users.length;
    },
    // successEffect(refName, inOrOut, value) {
    //   if (inOrOut == "out") {
    //     this.$refs[refName].classList.remove("fadein");
    //     this.$refs[refName].classList.add("fadeout");
    //   } else {
    //     this.$refs[refName].addEventListener("transitionend", () => {
    //       this.successEffect(refName, "out");
    //     });
    //     this.$refs[refName].textContent = value;

    //     this.$refs[refName].classList.remove("fadeout");
    //     this.$refs[refName].classList.add("fadein");
    //   }
    // },
    removeRedBorder() {
      if (this.$refs.labForCreate.style.borderColor == "red") {
        this.$refs.labForCreate.style.borderColor = "";
      }
    },
    createTheStuff(buttonev) {
      const labName = this.$refs.labForCreate.value;
      console.log(labName);
      if (!document.querySelector('option[value="' + labName + '"]')) {
        this.$refs.labForCreate.style.borderColor = "red";
        return;
      }

      this.$store.state.buttonFXinit(buttonev);

      const labId = document.querySelector('option[value="' + labName + '"]')
        .dataset.labId;

      const inputs = [...document.querySelectorAll(".puckserialno")];
      console.log(inputs);
      const colors = document.querySelectorAll(".puckcolor");
      const puckstocreate = inputs.map((input) => {
        return { serialNo: input.value, labId };
      });

      colors.forEach((option, index) => {
        puckstocreate[index].color = option.value;
      });

      const body = {
        serialNo: this.rackCustomId,
        labId,
        pucks: puckstocreate,
        rackType: this.istransportchecked ? "Transport" : "Storage",
      };

      this.$store
        .dispatch("adminCreateRackAndPucks", {
          body,
        })
        .then(() => {
          return this.$store.state.buttonFX(
            buttonev.target.closest("button"),
            "Created the stuff :)"
          );
        })
        .catch((err) => {
          return this.$store.state.buttonFX(
            buttonev.target.closest("button"),
            "Error: " + err
          );
        });
    },

    addInstitute(buttonev) {
      this.$store
        .dispatch("createItem", {
          item: "institutes",
          body: { name: this.instituteName.trim() },
        })
        .then(() => {
          return this.$store.state.buttonFX(
            buttonev.target.closest("button"),
            "Added Institute :)"
          );
        })
        .catch((err) => {
          return this.$store.state.buttonFX(
            buttonev.target.closest("button"),
            "Error: " + err
          );
        });
    },

    addLab(buttonev) {
      const optionValue = this.$refs.instituteForLab.value;
      const optionInstituteData = document.querySelector(
        'option[value="' + optionValue + '"]'
      ).dataset.instituteId;

      this.$store
        .dispatch("createItem", {
          item: "labs",
          body: {
            name: this.$refs.labName.value.trim(),
            code: this.$refs.labCode.value.trim().toUpperCase(),
            institute: optionInstituteData.trim(),
          },
        })
        .then(() => {
          return this.$store.state.buttonFX(
            buttonev.target.closest("button"),
            "Added Lab :)"
          );
        })
        .catch((err) => {
          return this.$store.state.buttonFX(
            buttonev.target.closest("button"),
            "Error: " + err
          );
        });
    },

    updateLab(buttonev) {
      const userValue = this.$refs.userForLab.value;
      let userData;
      if (userValue) {
        userData = document.querySelector('option[value="' + userValue + '"]')
          .dataset.userId;
      }
      const labValue = this.$refs.labForUpdate.value;
      const labId = document.querySelector('option[value="' + labValue + '"]')
        .dataset.labId;

      const updateLabBody = {
        name: this.labName.trim(),
        code: this.labCode.trim().toUpperCase(),

        labAdmin: userData,
      };

      if (this.hasPremium != "no" && (!this.startDate || !this.endDate))
        return this.$store.state.buttonFX(
          buttonev.target.closest("button"),
          "Error: You must set the dates"
        );

      if (this.hasPremium != "no" && this.startDate && this.endDate) {
        updateLabBody.premium = true;
        updateLabBody.premiumStart = this.startDate;
        updateLabBody.premiumEnd = this.endDate;
      } else {
        updateLabBody.premium = false;
        updateLabBody.premiumStart = null;
        updateLabBody.premiumEnd = null;
      }

      this.$store
        .dispatch("updateItem", {
          objid: labId,
          item: "labs",
          setAdmin: this.labAdminChanged,
          body: updateLabBody,
        })
        .then(() => {
          return this.$store.state.buttonFX(
            buttonev.target.closest("button"),
            "Updated Lab :)"
          );
        })
        .catch((err) => {
          return this.$store.state.buttonFX(
            buttonev.target.closest("button"),
            "Error: " + err
          );
        });

      this.labAdminChanged = false;
    },

    // transformPuckColor(color) {
    //   let hex = "#";
    //   switch (color) {
    //     case "red":
    //       hex += "FF0000";
    //       break;
    //     case "blue":
    //       hex += "0000FF";
    //       break;
    //     case "green":
    //       hex += "008000";
    //       break;
    //     case "orange":
    //       hex += "FFA500";
    //       break;
    //     case "violet":
    //       hex += "EE82EE";
    //       break;
    //     case "chrome":
    //       hex += "dbe4eb";
    //       break;
    //     case "gold":
    //       hex += "FFD700";
    //       break;
    //     case "sky blue":
    //       hex += "87ceeb";
    //       break;
    //     case "pink":
    //       hex += "FFC0CB";
    //       break;

    //     default:
    //       hex += "000000";
    //   }
    //   return hex;
    // },
    getInstitutesAndLabs() {
      this.$store.dispatch("getAdminStuff", { url: "institutes" });
      this.$store.dispatch("getAdminStuff", { url: "labs" });
    },
    getUsersForLab(event) {
      //when a user selects a lab from update lab
      const labValue = event.target.value;

      const labid = this.$refs.thelablist.querySelector(
        'option[value="' + labValue + '"]'
      ).dataset.labId;

      console.log(labValue, labid);

      this.$store.dispatch("getAdminStuff", {
        url: "users",
        labid,
        signal: true,
      });

      const labmatch = this.$store.state.labs.filter(
        (lab) => lab._id == labid
      )[0];
      this.hasPremium = labmatch.premium ? "yes" : "no";
      this.startDate = labmatch.premiumStart ?? new Date(2023, 0, 1);
      this.endDate = labmatch.premiumEnd ?? new Date(2030, 0, 1);
      console.log(labmatch.premiumStart, labmatch.premiumEnd);
      if (this.startDate && this.endDate) this.flightDatesExist = true;
      this.labCode = labmatch.code;
      this.labName = labmatch.name;
      // console.log(labmatch);
    },
  },
  mounted() {
    this.getInstitutesAndLabs();

    document.querySelectorAll("input[list]").forEach((input) => {
      console.log(input);
      input.addEventListener("focus", function (ev) {
        ev.target.value = "";
      });
    });
  },
};
</script>

<style scoped>
input,
select {
  padding: 0.25rem 0.25rem;
  max-width: 20rem;
}

input[type="number"] {
  width: 5rem;
}

button {
  margin-left: 1rem;
  cursor: pointer;
  background: none rgba(51, 92, 139);
  border: 2px solid rgba(47, 83, 125);

  text-align: center;
  margin-bottom: 1rem;
  min-height: min-content;
  max-height: 39px;
  padding: 0 1rem;
  height: 6vh;
  max-width: 162px;
  color: rgb(255, 255, 255);

  font-family: Montserrat;
  border-radius: 26px;
}

.lengthenbutton {
  height: auto;
  min-height: 39px;
}

.laboverviewgrid div:nth-child(-n + 5) {
  background: rgb(30 58 138) !important;
  color: white;
}
.laboverviewgrid div {
  padding-left: 1rem;
  background: rgb(229, 231, 235);
}
.laboverviewgrid div {
  padding-left: 1rem;
}
.laboverviewgrid div:nth-child(10n + 6),
div:nth-child(10n + 7),
div:nth-child(10n + 8),
div:nth-child(10n + 9),
div:nth-child(10n + 10) {
  background: white;
}
</style>
