<template>
  <div
    @mouseover="changeimagesource"
    @mouseleave="revertimagesource"
    class="topone bg-sky-900 cursor-pointer w-full relative"
  >
    <div
      class="absolute z-10 theimage"
      ref="image2"
      :style="theimagesrc2"
    ></div>
    <div class="theimage z-20 relative" :style="theimagesrc1"></div>
    <div
      class="block w-full py-1 px-0 m-0 border-0 bg-sky-900 text-center text-white font-bold"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      theimagesrc1: {
        backgroundImage: "url('assets/images/" + this.image + ".png')",
      },
      theimagesrc2: {
        backgroundImage: "url('assets/images/" + this.image + "-active.png')",
      },
    };
  },
  methods: {
    changeimagesource() {
      // this.theimagesrc = {
      //   backgroundImage: "url('/assets/images/" + this.image + "-active.png')",
      // };
      this.$refs.image2.classList.add("z-30");
    },
    revertimagesource() {
      this.$refs.image2.classList.remove("z-30");
      // this.theimagesrc = {

      //   backgroundImage: "url('/assets/images/" + this.image + ".png')",
      // };
    },
  },
  props: ["image"],
};
</script>

<style scoped>
.theimage {
  width: 100%;
  padding-top: 96.4%;

  background-size: cover;
  background-position: center;
}

.topone {
  max-width: 250px;
  border: solid 0.25rem #2c5282;
}
</style>
