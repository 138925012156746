<template>
  <div class="text-sky-900 font-bold">
    <div class="flex justify-between mb-2">
      <div>Move {{ isGrid ? "Grid" : "Box" }} to:</div>
      <div
        class="flex items-center text-black text-sm font-medium leading-none"
      >
        <input
          checked="true"
          id="followmove"
          ref="followmove"
          type="checkbox"
          class="w-3 h-3 mb-0 cursor-pointer"
        /><label
          class="cursor-pointer pr-2"
          for="followmove"
          style="padding-left: 0.2rem"
          >Follow</label
        >

        <button
          :disabled="
            !moveRacksList ||
            !movePucksList ||
            !moveWellsList ||
            (isGrid ? !hasGridName : false)
          "
          class="btn-blue inline-block text-sm"
          :class="{
            'bg-gray-500':
              !moveRacksList ||
              !movePucksList ||
              !moveWellsList ||
              (isGrid ? !hasGridName : false),
            'hover:bg-gray-500':
              !moveRacksList ||
              !movePucksList ||
              !moveWellsList ||
              (isGrid ? !hasGridName : false),
          }"
          @click="isGrid ? moveBox(true) : moveBox()"
        >
          Move
        </button>
      </div>
    </div>
    <div class="grid gap-x-2" :class="[isGrid ? 'grid-cols-5' : 'grid-cols-4']">
      <select @change="changeMoveDewar($event)" class="w-auto px-0 m-0">
        <option
          v-for="(dewarid, index) in $store.state.dewarData[
            +dataChainForMove.lab
          ].data"
          :key="dewarid._id"
          :selected="index == dataChainForMove.dewar ? true : false"
          :value="index"
        >
          {{
            index == dataChainForMove.dewar
              ? `Current Dewar(${dewarid.customId})`
              : dewarid.customId
          }}
        </option>
      </select>
      <select @change="changeMoveRack($event)" class="w-auto px-0 m-0">
        <option
          v-for="(dewarid, index) in moveRacksList"
          :key="dewarid._id"
          :selected="index == dataChainForMove.rack ? true : false"
          :value="index"
        >
          {{
            index == dataChainForMove.rack &&
            ((!moveDewars && moveDewars !== 0) ||
              moveDewars == dataChainForMove.dewar)
              ? `Current Rack(${dewarid.customId ?? dewarid.serialNo})`
              : dewarid.customId ?? dewarid.serialNo
          }}
        </option>
      </select>

      <select
        :style="{
          'background-color': compMovePucksColor,
        }"
        @change="changeMovePuck($event)"
        ref="movepuckselect"
        class="w-auto px-0 m-0"
      >
        <option
          :style="{ background: dewarid.color }"
          v-for="(dewarid, index) in movePucksList"
          :key="dewarid._id"
          :selected="index == activePuckIndex ? true : false"
          :value="index"
          :data-puckid="dewarid._id"
          :data-serial="dewarid.serialNo"
          :data-color="dewarid.color"
        >
          {{
            index == activePuckIndex &&
            ((!moveDewars && moveDewars !== 0) ||
              moveDewars == dataChainForMove.dewar) &&
            (moveRacks == dataChainForMove.rack ||
              (!moveRacks && moveRacks !== 0))
              ? `Current Puck(${dewarid.serialNo})`
              : `${dewarid.serialNo}(${dewarid.color})`
          }}
        </option>
      </select>
      <select ref="movewellselect" class="w-auto px-0 m-0">
        <option
          v-for="unoccupiedWellNo in moveWellsList"
          :key="unoccupiedWellNo"
          :value="unoccupiedWellNo"
        >
          Well {{ unoccupiedWellNo }}
        </option>
      </select>
      <select v-if="isGrid" ref="movegridselect" class="w-auto px-0 m-0">
        <option
          v-for="gridPos in moveGridsList"
          :key="gridPos"
          :value="gridPos.label"
        >
          Grid {{ gridPos.label }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    console.log(this.dataChainForMove, "datachain");
  },
  inject: ["endPuckNavigate", "startAutoNavigate"],
  props: [
    "isGrid",
    "activePuckIndex",
    "dataChainForMove",
    "puckData",
    "activeWellNo",
    "hasGridName",
  ],
  data() {
    return {
      moveDewars: null,
      moveRacks: null,
      movePucks: null,
      movePucksColor: null,
      moveWells: null,
    };
  },

  computed: {
    unoccupiedWells() {
      const unoccupiedWellArray = [];
      for (let n = 1; n < 13; n++) {
        if (!this.puckData["well" + n]) unoccupiedWellArray.push(n);
      }
      return unoccupiedWellArray;
    },
    occupiedWells() {
      const occupiedWellArray = [];
      for (let n = 1; n < 13; n++) {
        if (this.puckData["well" + n]) occupiedWellArray.push(n);
      }
      return occupiedWellArray;
    },
    compMovePucksColor() {
      if (this.movePucksColor)
        return this.$store.state.transformBoxColor(this.movePucksColor);
      else return this.$store.state.transformBoxColor(this.puckData.color);
    },
    moveRacksList() {
      console.log(this.moveDewars);
      if (this.moveDewars || this.moveDewars === 0) {
        return this.$store.state.dewarData[+this.dataChainForMove.lab].data[
          +this.moveDewars
        ].racks;
      } else {
        return this.$store.state.dewarData[+this.dataChainForMove.lab].data[
          +this.dataChainForMove.dewar
        ].racks;
      }
    },
    movePucksList() {
      if (
        this.moveDewars ||
        this.moveDewars === 0 ||
        this.moveRacks ||
        this.moveRacks === 0
      ) {
        return this.$store.state.dewarData[+this.dataChainForMove.lab].data[
          +this.moveDewars
        ].racks[+this.moveRacks]?.pucks;
      } else {
        return this.$store.state.dewarData[+this.dataChainForMove.lab].data[
          +this.dataChainForMove.dewar
        ].racks[+this.dataChainForMove.rack].pucks;
      }
    },

    moveGridsList() {
      return [
        { label: "Top Left" },
        { label: "Top Right" },
        { label: "Bottom Left" },
        { label: "Bottom Right" },
      ];
    },
    moveWellsList() {
      if (
        this.moveDewars ||
        this.moveDewars === 0 ||
        this.movePucks ||
        this.movePucks === 0
      ) {
        console.log(
          "wells should be updated",
          this.moveDewars,
          this.moveRacks,
          this.movePucks
        );
        if (!this.movePucksList || this.movePucksList.length < 1) {
          return [];
        }
        const unoccupiedWellArray = [];
        for (let n = 1; n < 13; n++) {
          if (this.isGrid) {
            if (
              this.$store.state.dewarData[+this.dataChainForMove.lab].data[
                this.moveDewars || this.moveDewars === 0
                  ? +this.moveDewars
                  : +this.dataChainForMove.dewar
              ].racks[
                this.moveRacks || this.moveRacks === 0
                  ? +this.moveRacks
                  : +this.dataChainForMove.rack
              ]?.pucks[
                this.movePucks || this.movePucks === 0
                  ? +this.movePucks
                  : +this.dataChainForMove.puck
              ]["well" + n]
            )
              unoccupiedWellArray.push(n);
          } else {
            if (
              !this.$store.state.dewarData[+this.dataChainForMove.lab].data[
                this.moveDewars || this.moveDewars === 0
                  ? +this.moveDewars
                  : +this.dataChainForMove.dewar
              ].racks[
                this.moveRacks || this.moveRacks === 0
                  ? +this.moveRacks
                  : +this.dataChainForMove.rack
              ]?.pucks[
                this.movePucks || this.movePucks === 0
                  ? +this.movePucks
                  : +this.dataChainForMove.puck
              ]?.["well" + n]
            )
              unoccupiedWellArray.push(n);
          }
        }
        console.log(unoccupiedWellArray);
        return unoccupiedWellArray;
      } else {
        if (this.isGrid) return this.occupiedWells;
        else return this.unoccupiedWells;
      }
    },
  },
  methods: {
    changeMoveDewar(ev) {
      this.moveDewars = +ev.target.value;
      this.movePucksColor = "white";
    },
    changeMoveRack(ev) {
      this.moveRacks = +ev.target.value;
      this.movePucksColor = "white";
    },
    changeMovePuck(ev) {
      this.movePucksColor =
        this.$refs.movepuckselect.options[
          this.$refs.movepuckselect.selectedIndex
        ].dataset.color;
      console.log(this.movePucksColor);
      console.log(this.movePucks);
      this.movePucks = +ev.target.value;
    },

    async moveGrid() {
      console.log(this.isGrid);
      console.log("ran move grid");
    },
    async moveBox(isGrid) {
      if (
        this.$store.state.unsavedChangesMPBox ||
        this.$store.state.unsavedChangesMPGrid
      ) {
        this.$store.state.promptUnsaveModal = true;

        let answer;
        try {
          answer = await this.$store.state.promptAnswerPromise();
        } catch {
          //rejected

          answer = null;
        }

        this.$store.state.promptUnsaveModal = false;
        if (!answer) return;
        else {
          this.resetUnsavedData();
        }
      }

      const puckId =
        this.$refs.movepuckselect.options[
          this.$refs.movepuckselect.selectedIndex
        ].dataset.puckid;
      const wellNo = this.$refs.movewellselect.value;

      console.log(wellNo, puckId);
      let samepuck = false;
      if (this.puckData._id == puckId) samepuck = true;

      let objtoupdate = {
        item: "boxes",
        samepuck: samepuck,
        objid: this.puckData["well" + this.activeWellNo]._id,
        body: {
          wellNo: this.activeWellNo,
          puckId: this.puckData._id,

          moveTo: { toWell: wellNo, toPuck: puckId },
        },
      };

      if (isGrid) objtoupdate.body.isGrid = true;

      //to delete teh grid from the current well. still need to move it
      if (isGrid) {
        objtoupdate.body[this.hasGridName] = {
          gridType: [0, 0, 0],
          name: null,
          description: null,
        };
        console.log(objtoupdate, this.hasGridName);

        const nospace = this.$refs.movegridselect.value.replace(/ /g, "");
        const gridNameToMoveTo = "grid" + nospace;
        objtoupdate.body.moveTo.toGrid = gridNameToMoveTo;
        objtoupdate.body.moveTo.withGridData = isGrid;
        console.log("YOOO", objtoupdate.body.moveTo, gridNameToMoveTo, nospace);
      }

      this.$emit("moveboxstart");
      this.$store.dispatch("updateItem", objtoupdate).then(
        () => {
          if (this.puckData._id != puckId && this.$refs.followmove.checked) {
            const serialNo =
              this.$refs.movepuckselect.options[
                this.$refs.movepuckselect.selectedIndex
              ].dataset.serial;
            this.startAutoNavigate(serialNo, wellNo);
          } else if (
            this.puckData._id != puckId &&
            !this.$refs.followmove.checked
          ) {
            this.loadPuck();
          }
        },
        (reason) => {
          alert(reason);
          this.$emit("cancelmoveboxstart");
          // Error!
        }
      );
    },
  },
};
</script>
