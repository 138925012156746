<template>
  <div
    class="bg-white border-t-4 border-blue-800 inline-block mx-auto text-xl pt-4 cursor-default"
  >
    Your Comments and Suggestions
    <div class="px-6 py-3 flex flex-col items-center relative">
      <textarea
        class="border-2 border-blue-600 rounded px-1"
        name=""
        id=""
        ref="feedbackElem"
        style="width:50vw;max-width:500px;height:20vh"
      ></textarea>
      <button
        style="width: max-content;max-width:100%;max-height:100%;border-radius:26px; margin-top:1rem; margin-bottom:0rem; text-align:center; vertical-align:middle;"
        class="relative px-4 py-2"
        @click="$store.state.buttonFXinit($event), submitFeedback($event)"
      >
        <button-inner>Submit Feedback</button-inner>
      </button>
      <div class="fadeoutbutton">Submitted!</div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    submitFeedback(ev) {
      if (!this.$refs.feedbackElem.value) {
        this.$refs.feedbackElem.focus();
        return;
      }

      const fromemail = this.$store.state.activeUser.email;

      const body = {
        fromemail,
        feedback: this.$refs.feedbackElem.value,
      };

      const url = `${process.env.VUE_APP_HOST}/api/v1/dosecalc/sendusfeedback`;

      const request = new Request(url, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(body),
        headers: { "content-type": "application/json" },
      });
      fetch(request)
        .then((resp) => {
          console.log(resp);
          if (!resp.ok) throw new Error("Err");
          else return resp.json();
        })
        .then((data) => {
          console.log(data);
          if (data.status == "fail" || !data) {
            console.log("surely nopt here");
            throw new Error(data.message);
          } else {
            this.$store.state.buttonFX(
              ev.target.closest("button"),
              "Submitted!"
            );
          }
        })
        .catch((err) => {
          console.log("here too", err);
          this.$store.state.buttonFX(
            ev.target.closest("button"),
            "Sending Failed :("
          );
        });
    },
  },
};
</script>

<style scoped>
button {
  margin: auto;
  cursor: pointer;
  background: none rgba(51, 92, 139);
  border: 2px solid rgba(47, 83, 125);

  text-align: center;
  margin-bottom: 1rem;
  min-height: min-content;
  max-height: 39px;
  height: 6vh;
  max-width: 162px;
  width: 100%;
  color: rgb(255, 255, 255);

  font-family: Montserrat;
  border-radius: 26px;
}
</style>
