<template>
  <div class="text-xl text-center m-4">
    Reset Password
  </div>
  <div class="text-sm w-9/12 mx-auto mb-6">
    Enter the email address associated with your account, and we'll email you a
    link to reset your password.
  </div>
  <form @submit.prevent="" class="text-sm">
    <input
      class="rounded"
      type="email"
      placeholder="Email"
      ref="resetInputElem"
    />

    <div
      class="w-full flex flex-col justify-center items-center text-md mt-6 mb-4"
    >
      <button
        type="submit"
        class="w-full max-w-full relative"
        @click="sendReset($event)"
      >
        <button-inner>Send Reset Link</button-inner>
      </button>
      <div class="fadeoutbutton">Sent!</div>
    </div>
  </form>
</template>
<script>
import { inject, ref } from "vue";
import { useStore } from "vuex";
export default {
  props: ["showForgot"],
  setup() {
    const store = useStore();
    const resetInputElem = ref(null);
    const swapShowForm = inject("swapShowForm");

    function sendReset(ev) {
      const feedback = resetInputElem.value.value;
      if (!feedback) {
        resetInputElem.value.focus();
        return;
      }

      const button = ev.target.closest("button");

      button.disabled = "true";

      button.children[1].style.display = "none";
      button.children[0].style.display = "flex";

      const body = {
        email: feedback,
      };

      const url = `${process.env.VUE_APP_HOST}/api/v1/users/forgotPassword`;

      const request = new Request(url, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(body),
        headers: { "content-type": "application/json" },
      });
      fetch(request)
        .then((resp) => {
          console.log(resp);
          if (!resp.ok) throw new Error("Err");
          else return resp.json();
        })
        .then(function(data) {
          if (data.status == "fail" || !data) {
            throw new Error(data.message);
          } else {
            store.state.buttonFX(button, "Sent!");
          }
        })
        .catch(() => {
          store.state.buttonFX(button, "Error. Sending failed");
        });
    }

    return { swapShowForm, sendReset, resetInputElem };
  },
};
</script>

<style scoped>
button {
  margin: auto;
  cursor: pointer;
  background: none rgba(51, 92, 139);
  border: 2px solid rgba(47, 83, 125);

  text-align: center;
  margin-bottom: 1rem;
  min-height: min-content;
  max-height: 39px;
  height: 6vh;
  max-width: 162px;
  width: 100%;
  color: rgb(255, 255, 255);

  font-family: Montserrat;
  border-radius: 26px;
}
</style>
