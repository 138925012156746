<template>
  <display-modal v-if="showImport" @ridfeedback="showImport = false">
    <grid-import @import-grid="feedIntoGrid"></grid-import>
  </display-modal>
  <div
    class="w-full mx-auto sm:w-[98%] py-4 grid grid-cols-1 sm:grid-cols-2 gap-4"
  >
    <div v-for="n in 4" :key="n + wellData._id">
      <div
        v-show="
          n < 2 ||
          this.puckData.puckType != 'Cartridge' ||
          (this.puckData.puckType == 'Cartridge' && this.wellNo < 3)
        "
        class="text-sm p-4 rounded-lg"
        style="background-color: rgb(217, 217, 217)"
      >
        <div class="flex items-stretch">
          <div class="flex-shrink basis-[84%] sm:basis-[88%]">
            <span>Grid Name</span>
            <div class="flex">
              <input
                @input="
                  saveData(
                    $event,
                    wellData._id,
                    'boxes',
                    'gridProperty',
                    false,
                    {
                      gridProperty: {
                        [gridNameFromNumber(n)]: {
                          name: gridName[n - 1],
                        },
                        gridupdate: [gridNameFromNumber(n), 'name'],
                        puckId: puckId,
                        wellNo: wellNo,
                        rackIndex: this.dataChainForMove.rack,
                        puckIndex: this.puckIndex,
                      },
                    }
                  )
                "
                @blur="
                  saveData(
                    $event,
                    wellData._id,
                    'boxes',
                    'gridProperty',
                    true,
                    {
                      gridProperty: {
                        [this.gridNameFromNumber(n)]: {
                          name: gridName[n - 1],
                        },
                        gridupdate: [gridNameFromNumber(n), 'name'],
                        puckId: puckId,
                        wellNo: wellNo,
                        rackIndex: this.dataChainForMove.rack,
                        puckIndex: this.puckIndex,
                      },
                    }
                  )
                "
                :ref="'gridName' + n"
                v-model="gridName[n - 1]"
                class="flex-1"
                type="text"
                placeholder="Type here..."
              />

              <div
                :class="{ invisible: boxType == 'opencup' }"
                class="aspect-square mx-4 flex flex-shrink-0 flex-grow max-w-[2rem] max-h-[2rem] items-center justify-center bg-sky-900 rounded-full text-white text-base font-bold text-center"
              >
                {{ boxOrderArray[n - 1] }}
              </div>
            </div>
          </div>

          <div
            class="flex-grow flex-shrink basis-[16%] sm:basis-[12%]"
            :ref="'bigCircle-' + n"
            v-show="
              this.puckData.puckType != 'Cartridge' ||
              (this.puckData.puckType == 'Cartridge' && this.wellNo < 3)
            "
          ></div>
        </div>
        <div>
          Description
          <div>
            <textarea
              placeholder="Type here..."
              name=""
              id=""
              class="w-full"
              rows="5"
              @input="
                saveData($event, wellData._id, 'boxes', 'gridProperty', false, {
                  gridProperty: {
                    [gridNameFromNumber(n)]: {
                      // gridType: this.gridType[number - 1],
                      // name: gridName[n - 1],

                      description: this.gridDescription[n - 1],
                    },
                    gridupdate: [gridNameFromNumber(n), 'description'],
                    puckId: puckId,
                    wellNo: wellNo,
                    rackIndex: dataChainForMove.rack,
                    puckIndex: puckIndex,
                  },
                })
              "
              @blur="
                saveData($event, wellData._id, 'boxes', 'gridProperty', true, {
                  gridProperty: {
                    [this.gridNameFromNumber(n)]: {
                      // gridType: this.gridType[n - 1],
                      // name: gridName[n - 1],
                      // trueDateCreated: showDatePicker[n - 1],
                      description: this.gridDescription[n - 1],
                    },
                    gridupdate: [gridNameFromNumber(n), 'description'],
                    puckId: puckId,
                    wellNo: wellNo,
                    rackIndex: this.dataChainForMove.rack,
                    puckIndex: this.puckIndex,
                  },
                })
              "
              v-model="gridDescription[n - 1]"
            ></textarea>
          </div>
          <div>
            <div class="hidden"></div>
            <button
              v-if="!gridImage[n - 1]"
              class="bg-gray-200 rounded text-black border-black border px-2 py-1 text-xs mb-3 hover:text-gray-200"
              @click="
                (ev) => {
                  ev.currentTarget.nextSibling.value = '';
                  ev.currentTarget.nextSibling.click();
                  //need to make sure that this has a file
                  this.getUploadUrl(ev.currentTarget);
                }
              "
            >
              Add an image
            </button>
            <div class="flex items-end" v-else>
              <a
                target="_blank"
                class="mr-2 underline mb-2"
                :href="gridImage[n - 1]"
                >View Image</a
              >
              <button
                class="bg-gray-200 rounded text-black border-black border px-2 py-1 text-xs mb-3 hover:text-gray-200"
                @click="
                  (ev) => {
                    ev.currentTarget.parentElement.nextSibling.value = '';
                    ev.currentTarget.parentElement.nextSibling.click();
                    //need to make sure that this has a file
                    this.getUploadUrl(ev.currentTarget.parentElement);
                  }
                "
              >
                Edit image
              </button>
            </div>
            <input
              @input="(ev) => uploadGridImage(ev.currentTarget, n)"
              type="file"
              style="display: none"
            />
            <div class="mb-3 text-xs text-black hidden">Uploading image</div>
          </div>

          <div class="flex mb-2" :ref="'gridType' + n">
            <div
              style="
                background-color: rgb(191, 216, 235);
                border-color: rgb(118, 155, 186);
              "
              class="flex rounded p-2 border flex-wrap"
            >
              <div class="flex items-end mr-6">
                <input
                  class="cursor-pointer w-4 h-4"
                  style="margin-bottom: 0"
                  type="checkbox"
                  :checked="!!gridType[n - 1][0]"
                  :id="'screened-' + n"
                  :name="'screentype-' + n"
                  v-model="gridType[n - 1][0]"
                  @change="
                    saveData(
                      $event,
                      wellData._id,
                      'boxes',
                      'gridProperty',
                      true,
                      {
                        gridProperty: {
                          [this.gridNameFromNumber(n)]: {
                            gridType: this.gridType[n - 1],
                          },
                          gridupdate: [gridNameFromNumber(n), 'gridType'],
                          puckId: puckId,
                          wellNo: wellNo,
                          rackIndex: this.dataChainForMove.rack,
                          puckIndex: this.puckIndex,
                        },
                      }
                    )
                  "
                />
                <label
                  class="pl-1 cursor-pointer leading-none"
                  :name="'screentype-' + n"
                  :for="'screened-' + n"
                  >Screened</label
                >
              </div>
              <div class="flex items-end mr-6">
                <input
                  class="cursor-pointer w-4 h-4"
                  style="margin-bottom: 0"
                  type="checkbox"
                  :checked="!!gridType[n - 1][1]"
                  :name="'screentype-' + n"
                  :id="'imaged-' + n"
                  v-model="gridType[n - 1][1]"
                  @change="
                    saveData(
                      $event,
                      wellData._id,
                      'boxes',
                      'gridProperty',
                      true,
                      {
                        gridProperty: {
                          [this.gridNameFromNumber(n)]: {
                            gridType: this.gridType[n - 1],
                          },
                          gridupdate: [gridNameFromNumber(n), 'gridType'],
                          puckId: puckId,
                          wellNo: wellNo,
                          rackIndex: this.dataChainForMove.rack,
                          puckIndex: this.puckIndex,
                        },
                      }
                    )
                  "
                />
                <label
                  class="pl-1 cursor-pointer leading-none"
                  :name="'screentype-' + n"
                  :for="'imaged-' + n"
                  >Imaged</label
                >
              </div>
              <div class="flex items-end">
                <input
                  class="cursor-pointer w-4 h-4"
                  style="margin-bottom: 0"
                  type="checkbox"
                  :checked="!!gridType[n - 1][2]"
                  :name="'screentype-' + n"
                  :id="'tossed-' + n"
                  v-model="gridType[n - 1][2]"
                  @change="
                    saveData(
                      $event,
                      wellData._id,
                      'boxes',
                      'gridProperty',
                      true,
                      {
                        gridProperty: {
                          [this.gridNameFromNumber(n)]: {
                            gridType: this.gridType[n - 1],
                          },
                          gridupdate: [gridNameFromNumber(n), 'gridType'],
                          puckId: puckId,
                          wellNo: wellNo,
                          rackIndex: this.dataChainForMove.rack,
                          puckIndex: this.puckIndex,
                        },
                      }
                    )
                  "
                />
                <label
                  class="pl-1 cursor-pointer leading-none"
                  :name="'screentype-' + n"
                  :for="'tossed-' + n"
                  >Tossed</label
                >
              </div>
            </div>
          </div>
          <div class="flex justify-between items-end flex-wrap">
            <div class="text-xs flex-shrink flex-grow-0">
              <div class="inline-block text-xs">Date Created:</div>

              <DatePicker timezone="utc" v-model="showDatePicker[n - 1]">
                <template v-slot="{ inputValue, inputEvents }">
                  <div class="flex basis-0">
                    <input
                      class="bg-white border px-2 py-1 rounded flex-1 w-0"
                      style="margin: 0"
                      :value="inputValue"
                      v-on="!isReadOnly ? inputEvents : null"
                    />
                  </div>
                </template>
              </DatePicker>

              <!-- <div
                  v-if="!showDatePicker[n]"
                  @click="showDatePicker[n] = true"
                  class="cursor-pointer bg-white font-semibold rounded p-2 border border-gray-400 rounded-lg "
                >
                  {{ cleanDate(Date.now(), true, true) }}
                </div>
                <div v-else class="relative">
                  <div
                    class="invisible cursor-pointer bg-white font-semibold rounded p-2 border border-gray-400 rounded-lg "
                  >
                    {{ cleanDate(Date.now(), true, true) }}
                  </div>
                  <div class="absolute top-0 left-0 z-50  ">
                    <DatePicker v-if="flightDatesExist" v-model="startDate" />
                    <DatePicker v-else v-model="startDate" />
                  </div>
                </div> -->
            </div>
            <div class="text-xs px-4">
              Last modified:<br />{{
                cleanDate(wellData[gridNameFromNumber(n)].dateModified, true)
              }}
            </div>

            <div
              v-if="!isReadOnly && gridName[n - 1] && !clearInProgress[n]"
              class="flex items-end flex-1 justify-end"
            >
              <div class="mr-4 flex-col flex items-center">
                <div class="fadeoutbutton">Copied</div>
                <button
                  class="btn-blue bg-green-800"
                  @click="copyGrid($event, n)"
                >
                  Copy Grid
                </button>
              </div>

              <div>
                <div class="flex justify-center relative">
                  <div
                    class="flex relative justify-center items-center border rounded bg-red-200 border-red-800 px-1"
                  >
                    <input
                      class="flex-0 mr-1"
                      type="checkbox"
                      :ref="'archive' + n"
                      :id="'archive' + n"
                      style="margin-bottom: 0; width: 1rem; height: 1rem"
                    /><label
                      class="cursor-pointer block top-px relative overflow-hidden"
                      :for="'archive' + n"
                      >Archive</label
                    >
                  </div>
                </div>
                <button class="btn-blue bg-red-800" @click="removeGrid(n)">
                  Empty Grid
                </button>
              </div>
            </div>

            <div
              v-else-if="!isReadOnly && !clearInProgress[n]"
              class="flex flex-1 justify-end"
            >
              <div
                :class="[getCopiedGrid.value?.name ? 'visible' : 'invisible']"
                class="flex items-end"
              >
                <button class="btn-blue bg-orange-700" @click="pasteGrid(n)">
                  Paste Grid
                </button>
              </div>
              <div class="ml-4">
                <div>
                  <!---just for space--->
                  <div class="flex justify-center invisible">
                    <input
                      class="flex-0 mr-1"
                      type="checkbox"
                      checked="true"
                      style="margin-bottom: 0; width: 1rem; height: 1rem"
                    /><label class="cursor-pointer">Archive</label>
                  </div>
                  <button class="btn-blue bg-sky-900" @click="importGrid(n)">
                    Import Grid
                  </button>
                </div>
              </div>
            </div>
            <div
              v-show="clearInProgress[n]"
              :ref="'fadearchive' + n"
              class="fadeoutbutton"
            ></div>
          </div>
        </div>
      </div>
      <div
        style="background-color: rgb(217, 217, 217)"
        class="rounded-md mt-2 p-4 hidden"
        v-if="!isReadOnly"
      >
        <move-box-or-grid
          :data-chain-for-move="dataChainForMove"
          :puck-data="puckData"
          :is-grid="{
            name: gridName[n - 1],
            description: gridDescription[n - 1],
            gridType: gridType[n - 1],
          }"
          :has-grid-name="gridNameFromNumber(n)"
          :active-well-no="wellNo"
        />
      </div>
    </div>
  </div>
  <div
    v-if="!isReadOnly"
    class="w-full flex flex-col items-center justify-center"
  >
    <!-- <button
      id="saveUserChanges"
      ref="saveUserChanges"
      class="btn-blue relative py-2 px-4"
      @click="$store.state.buttonFXinit($event), saveAllGrids($event)"
    >
      <button-inner>Save All Grids</button-inner>
    </button> -->
    <div class="fadeoutbutton text-xs">Added</div>
  </div>
</template>
<script>
import { DatePicker } from "v-calendar";
import GridImport from "../../../modal-inners/import-inner.vue";
import MoveBoxOrGrid from "./MoveBoxOrGrid.vue";
export default {
  components: { GridImport, MoveBoxOrGrid, DatePicker },
  props: [
    "boxImage",
    "boxSetting",
    "wellData",
    "cleanDate",
    "wellNo",
    "boxType",
    "puckId",
    "puckData",
    "puckIndex",
    "dataChainForMove",
    "checkReadOnly",
  ],
  inject: ["getCopiedGrid", "setCopiedGrid", "saveData"],

  computed: {
    gridChangedUpdatePuckVisual() {
      return this.$store.state.gridChangedPuckVisualTrigger;
    },
    datePickerClone() {
      return JSON.stringify(this.showDatePicker);
    },

    gridTypeComp() {
      console.log("fired comp!");
      let tempArrayGrid1 =
        this.wellData?.[this.gridNameFromNumber(1)]?.gridType;

      let tempArrayGrid2 =
        this.wellData?.[this.gridNameFromNumber(2)]?.gridType;
      let tempArrayGrid3 =
        this.wellData?.[this.gridNameFromNumber(3)]?.gridType;
      let tempArrayGrid4 =
        this.wellData?.[this.gridNameFromNumber(4)]?.gridType;

      console.log(
        tempArrayGrid1,
        tempArrayGrid2,
        tempArrayGrid3,
        tempArrayGrid4
      );

      //legacy code check
      if (this.wellData?.[this.gridNameFromNumber(1)]?.gridType == "Screened")
        tempArrayGrid1 = [1, 0, 0];
      if (
        this.wellData?.[this.gridNameFromNumber(1)]?.gridType ==
          "Not Screened" ||
        !this.wellData?.[this.gridNameFromNumber(1)]?.gridType
      )
        tempArrayGrid1 = [0, 0, 0];
      if (this.wellData?.[this.gridNameFromNumber(2)]?.gridType == "Screened")
        tempArrayGrid2 = [1, 0, 0];
      if (
        this.wellData?.[this.gridNameFromNumber(2)]?.gridType ==
          "Not Screened" ||
        !this.wellData?.[this.gridNameFromNumber(2)]?.gridType
      )
        tempArrayGrid2 = [0, 0, 0];
      if (this.wellData?.[this.gridNameFromNumber(3)]?.gridType == "Screened")
        tempArrayGrid3 = [1, 0, 0];
      if (
        this.wellData?.[this.gridNameFromNumber(3)]?.gridType ==
          "Not Screened" ||
        !this.wellData?.[this.gridNameFromNumber(3)]?.gridType
      )
        tempArrayGrid3 = [0, 0, 0];
      if (
        this.wellData?.[this.gridNameFromNumber(4)]?.gridType ==
          "Not Screened" ||
        !this.wellData?.[this.gridNameFromNumber(4)]?.gridType
      )
        tempArrayGrid4 = [0, 0, 0];
      if (this.wellData?.[this.gridNameFromNumber(4)]?.gridType == "Screened")
        tempArrayGrid4 = [1, 0, 0];

      return JSON.parse(
        JSON.stringify([
          tempArrayGrid1,
          tempArrayGrid2,
          tempArrayGrid3,
          tempArrayGrid4,
        ])
      );
    },
    boxOrderArray() {
      if (this.boxSetting == 2) return [1, 2, 4, 3];
      else if (this.boxSetting == 3) return [4, 1, 3, 2];
      else if (this.boxSetting == 4) return [4, 3, 1, 2];
      else if (this.boxSetting == 5) return [1, 4, 2, 3];
      else if (this.boxSetting == 6) return [1, 3, 2, 4];
      else return [1, 2, 3, 4];
    },
    isReadOnly() {
      return this.wellData?.readOnly;
    },
  },

  data() {
    return {
      activeImportGridNumber: null,
      clearInProgress: [],
      showDatePicker: [
        this.wellData?.[this.gridNameFromNumber(1)]?.trueDateCreated ?? "",
        this.wellData?.[this.gridNameFromNumber(2)]?.trueDateCreated ?? "",
        this.wellData?.[this.gridNameFromNumber(3)]?.trueDateCreated ?? "",
        this.wellData?.[this.gridNameFromNumber(4)]?.trueDateCreated ?? "",
      ],
      showImport: false,
      //gridtype moved to compute du eto addressing screen logic

      gridType: [
        [0, 0, 0],
        [0, 0, 0],
        [0, 0, 0],
        [0, 0, 0],
      ],
      gridImage: [
        this.wellData?.[this.gridNameFromNumber(1)]?.imageurl ?? "",
        this.wellData?.[this.gridNameFromNumber(2)]?.imageurl ?? "",
        this.wellData?.[this.gridNameFromNumber(3)]?.imageurl ?? "",
        this.wellData?.[this.gridNameFromNumber(4)]?.imageurl ?? "",
      ],
      gridName: [
        this.wellData?.[this.gridNameFromNumber(1)]?.name ?? "",
        this.wellData?.[this.gridNameFromNumber(2)]?.name ?? "",
        this.wellData?.[this.gridNameFromNumber(3)]?.name ?? "",
        this.wellData?.[this.gridNameFromNumber(4)]?.name ?? "",
      ],
      gridDescription: [
        this.wellData?.[this.gridNameFromNumber(1)]?.description ?? "",
        this.wellData?.[this.gridNameFromNumber(2)]?.description ?? "",
        this.wellData?.[this.gridNameFromNumber(3)]?.description ?? "",
        this.wellData?.[this.gridNameFromNumber(4)]?.description ?? "",
      ],
    };
  },

  watch: {
    gridChangedUpdatePuckVisual(newValue) {
      //this is for the weird bug where after updating in store it uses the same memory address as the gridtype
      if (newValue) {
        setTimeout(() => {
          this.gridType = JSON.parse(JSON.stringify(this.gridTypeComp));
        }, 100);
      }
    },
    datePickerClone(newValue, oldValue) {
      //using a clone of computed because deep(object type) doesnt track newvalue oldvalue changes
      console.log(newValue, oldValue);
      // Note: `newValue` will be equal to `oldValue` here
      // on nested mutations as long as the object itself
      // hasn't been replaced.
      // const a = new Date(oldValue).toLocaleDateString();
      // const b = new Date(newValue).toLocaleDateString();
      if (!newValue) return;
      let indexThatChanged;
      newValue = JSON.parse(newValue);
      oldValue = JSON.parse(oldValue);
      for (let i = 0; i < newValue.length; i++) {
        if (newValue[i] != oldValue[i]) {
          console.log(i, newValue[i], oldValue[i]);
          indexThatChanged = i;
          break;
        }
      }
      const n = indexThatChanged + 1;

      let date1 = this.showDatePicker[n - 1]; // Your first date object
      let date2 = this.wellData?.[this.gridNameFromNumber(n)]?.trueDateCreated; // Another date object you're comparing against

      // Function to format a date as 'YYYY-MM-DD'
      function formatDate(date) {
        let d = new Date(date),
          month = "" + (d.getMonth() + 1),
          day = "" + d.getDate(),
          year = d.getFullYear();

        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;

        return [year, month, day].join("-");
      }

      let formattedDate1 = formatDate(date1);
      let formattedDate2 = formatDate(date2);
      console.log(formattedDate1, formattedDate2);

      if (formattedDate1 === formattedDate2) {
        return;
      }

      console.log(n);
      this.saveData(null, this.wellData._id, "boxes", "gridProperty", true, {
        gridProperty: {
          //this key has to be first due to use of store's updateitem object keys 0 check
          [this.gridNameFromNumber(n)]: {
            trueDateCreated: this.showDatePicker[n - 1],
          },

          gridupdate: [this.gridNameFromNumber(n), "trueDateCreated"],
          puckId: this.puckId,
          wellNo: this.wellNo,
          rackIndex: this.dataChainForMove.rack,
          puckIndex: this.puckIndex,
        },
      });
    },
    wellData() {
      setTimeout(() => {
        this.reInitAll();
      }, 100);

      this.gridName = [
        this.wellData?.[this.gridNameFromNumber(1)]?.name ?? "",
        this.wellData?.[this.gridNameFromNumber(2)]?.name ?? "",
        this.wellData?.[this.gridNameFromNumber(3)]?.name ?? "",
        this.wellData?.[this.gridNameFromNumber(4)]?.name ?? "",
      ];

      this.showDatePicker = [
        this.wellData?.[this.gridNameFromNumber(1)]?.trueDateCreated ?? "",
        this.wellData?.[this.gridNameFromNumber(2)]?.trueDateCreated ?? "",
        this.wellData?.[this.gridNameFromNumber(3)]?.trueDateCreated ?? "",
        this.wellData?.[this.gridNameFromNumber(4)]?.trueDateCreated ?? "",
      ];

      this.gridDescription = [
        this.wellData?.[this.gridNameFromNumber(1)]?.description ?? "",
        this.wellData?.[this.gridNameFromNumber(2)]?.description ?? "",
        this.wellData?.[this.gridNameFromNumber(3)]?.description ?? "",
        this.wellData?.[this.gridNameFromNumber(4)]?.description ?? "",
      ];

      console.log("well data changed", this.gridImage);
      this.gridImage = [
        this.wellData?.[this.gridNameFromNumber(1)]?.imageurl ?? "",
        this.wellData?.[this.gridNameFromNumber(2)]?.imageurl ?? "",
        this.wellData?.[this.gridNameFromNumber(3)]?.imageurl ?? "",
        this.wellData?.[this.gridNameFromNumber(4)]?.imageurl ?? "",
      ];

      console.log("well data changed", this.gridImage);
    },
  },
  methods: {
    async getUploadUrl(input) {
      const url = `${process.env.VUE_APP_HOST}/api/v1/users/cloudimageurl`;

      const request = new Request(url, {
        credentials: "include",
        method: "GET",

        headers: { "content-type": "application/json" },
      });

      try {
        const response = await fetch(url, request);
        const data = await response.json();
        console.log(data);
        input.previousSibling.innerHTML = data.result.uploadURL;
      } catch (error) {
        console.error("Error fetching upload URL:", error);
      }
    },
    async uploadGridImage(input, n) {
      const file = input.files[0];
      if (!file) return;
      const formData = new FormData();
      formData.append("file", file);
      let dotCount = 0;
      const animatedText = input.nextSibling;
      function animateDots() {
        let text = "Uploading image";
        for (let i = 0; i < dotCount; i++) {
          text += ".";
        }
        animatedText.textContent = text;

        if (dotCount < 3) {
          dotCount++;
        } else {
          dotCount = 0;
        }
      }

      // Replace with your actual upload URL received from Cloudflare
      const uploadURL = input.previousSibling.previousSibling.innerText;

      console.log(uploadURL, input.previousSibling.previousSibling);

      if (uploadURL) {
        input.nextSibling.classList.remove("hidden");
        input.previousSibling.classList.add("hidden");
        setInterval(animateDots, 500);

        try {
          const response = await fetch(uploadURL, {
            method: "POST",
            body: formData,
          });

          if (!response.ok) {
            throw new Error(
              response.status == 415 ? "Unsupported Media Type" : "Error"
            );
          }

          const data = await response.json();

          console.log("Success:", data);

          if (!data.result.uploaded) throw data.errors;
          await this.saveData(
            null,
            this.wellData._id,
            "boxes",
            "gridProperty",
            true,
            {
              gridProperty: {
                [this.gridNameFromNumber(n)]: {
                  imageurl: data.result.variants[0],
                },
                gridupdate: [this.gridNameFromNumber(n), "imageurl"],
                imageurltodelete: this.gridImage[n - 1],
                puckId: this.puckId,
                wellNo: this.wellNo,
                rackIndex: this.dataChainForMove.rack,
                puckIndex: this.puckIndex,
              },
            }
          );

          // This will now execute after saveData resolves
          console.log("then unhide");
          this.gridImage[n - 1] = data.result.variants[0];
          input.nextSibling.classList.add("hidden");
          input.previousSibling.classList.remove("hidden");
        } catch (error) {
          // Handle errors
          alert("Adding image failed. " + error);
          input.nextSibling.classList.add("hidden");
          input.previousSibling.classList.remove("hidden");
        }
      }
    },
    pasteGrid(n) {
      this.gridName[n - 1] = this.getCopiedGrid.value.name;
      this.gridType[n - 1] = this.getCopiedGrid.value.type;
      this.gridImage[n - 1] = this.getCopiedGrid.value.imageurl;
      this.showDatePicker[n - 1] = this.getCopiedGrid.value.trueDateCreated;
      this.gridDescription[n - 1] = this.getCopiedGrid.value.description;
      this.saveData(null, this.wellData._id, "boxes", "gridProperty", true, {
        gridProperty: {
          [this.gridNameFromNumber(n)]: {
            gridType: this.gridType[n - 1],
            name: this.gridName[n - 1],
            trueDateCreated: this.showDatePicker[n - 1],
            imageurl: this.gridImage[n - 1],
            description: this.gridDescription[n - 1],
          },

          puckId: this.puckId,
          wellNo: this.wellNo,
          rackIndex: this.dataChainForMove.rack,
          puckIndex: this.puckIndex,
        },
      });
      // this.notifyNotSaved();
    },
    copyGrid(ev, n) {
      this.setCopiedGrid({
        name: this.gridName[n - 1],
        type: this.gridType[n - 1],
        imageurl: this.gridImage[n - 1],
        trueDateCreated: this.showDatePicker[n - 1],
        description: this.gridDescription[n - 1],
      });
      ev.target.previousSibling.addEventListener("transitionend", () => {
        ev.target.previousSibling.classList.remove("fadeinbutton");
        ev.target.previousSibling.classList.add("fadeoutbutton");
      });

      ev.target.previousSibling.classList.remove("fadeoutbutton");
      ev.target.previousSibling.classList.add("fadeinbutton");
    },
    async removeGrid(n) {
      const deleted = confirm("Are you sure you want to delete this grid?");
      if (!deleted) return;
      let saverefofimage = JSON.stringify(this.gridImage[n - 1]);
      console.log("checktest", this.$refs["archive" + n][0].checked);
      if (this.$refs["archive" + n][0].checked) {
        console.log("wtf i checked you!");
        this.clearInProgress[n] = true;
        await this.createGridArchive(
          this.gridName[n - 1],
          this.gridType[n - 1],
          this.gridImage[n - 1],
          this.gridDescription[n - 1],
          this.showDatePicker[n - 1]
        )
          .then((data) => {
            this.$refs["fadearchive" + n][0].textContent = data;
            this.$refs["fadearchive" + n][0].addEventListener(
              "transitionend",
              () => {
                this.$refs["fadearchive" + n][0].classList.remove(
                  "fadeinbutton"
                );
                this.$refs["fadearchive" + n][0].classList.add("fadeoutbutton");
                this.gridName[n - 1] = null;
                this.gridType[n - 1] = [0, 0, 0];
                this.showDatePicker[n - 1] = null;
                this.gridImage[n - 1] = null;
                this.gridDescription[n - 1] = null;
                this.clearInProgress[n] = false;
              }
            );

            this.$refs["fadearchive" + n][0].classList.remove("fadeoutbutton");
            this.$refs["fadearchive" + n][0].classList.add("fadeinbutton");
          })
          .catch((err) => {
            this.$refs["fadearchive" + n][0].textContent = err;
            this.$refs["fadearchive" + n][0].addEventListener(
              "transitionend",
              () => {
                this.$refs["fadearchive" + n][0].classList.remove(
                  "fadeinbutton"
                );
                this.$refs["fadearchive" + n][0].classList.add("fadeoutbutton");
                this.gridName[n - 1] = null;
                this.gridType[n - 1] = [false, false, false];
                this.gridImage[n - 1] = null;
                this.gridDescription[n - 1] = null;
                this.clearInProgress[n] = false;
              }
            );

            this.$refs["fadearchive" + n][0].classList.remove("fadeoutbutton");
            this.$refs["fadearchive" + n][0].classList.add("fadeinbutton");
          });
      } else {
        this.gridName[n - 1] = null;
        this.gridType[n - 1] = [false, false, false];
        this.gridImage[n - 1] = null;
        this.showDatePicker[n - 1] = null;
        this.gridDescription[n - 1] = null;
      }

      this.saveData(null, this.wellData._id, "boxes", "gridProperty", true, {
        gridProperty: {
          [this.gridNameFromNumber(n)]: {
            gridType: [false, false, false],
            name: null,
            trueDateCreated: null,
            imageurl: null,
            description: null,
          },
          puckId: this.puckId,
          wellNo: this.wellNo,
          rackIndex: this.dataChainForMove.rack,
          puckIndex: this.puckIndex,
          keepArchive: this.$refs["archive" + n]?.[0]?.checked,
          imageurltodelete: JSON.parse(saverefofimage),
        },
      });
    },
    importGrid(number) {
      this.showImport = true;
      this.activeImportGridNumber = number;
    },
    createGridArchive(name, type, image, description, datecreated) {
      console.log("increatearchive");
      return new Promise((res, rej) => {
        const body = {
          name,
          imageurl: image,
          gridType: type,
          description,
          trueDateCreated: datecreated,
        };
        this.$store
          .dispatch("createItem", {
            item: "gridarchives",
            body,
          })
          .then((data) => {
            console.log(data);
            res("Archive created.");
          })
          .catch((err) => {
            console.log(err);
            rej("Failed to create archive!");
          });
      });
    },
    checkBorderColor(ev, isRadioButton) {
      let bordercontainer = ev.target;
      let valueToCheck = ev.target.value;
      console.log(isRadioButton);
      if (isRadioButton) {
        bordercontainer = ev.target.parentElement.parentElement;
        valueToCheck = false;
        console.log('input[name="' + ev.target.name + '"]');
        bordercontainer
          .querySelectorAll('input[name="' + ev.target.name + '"]')
          .forEach((input) => {
            console.log(input);
            if (input.checked == true) valueToCheck = true;
          });
        console.log(valueToCheck);
        if (valueToCheck) {
          if (bordercontainer.classList.contains("outline"))
            bordercontainer.classList.remove("outline");
        } else {
          if (!bordercontainer.classList.contains("outline"))
            bordercontainer.classList.add("outline");
        }
      } else {
        if (valueToCheck) {
          if (bordercontainer.classList.contains("redborder"))
            bordercontainer.classList.remove("redborder");
        } else {
          if (!bordercontainer.classList.contains("redborder"))
            bordercontainer.classList.add("redborder");
        }
      }
    },
    async saveAllGrids(buttonev) {
      //dont think this is used at all anymore
      let body = { puckId: this.puckId, wellNo: this.wellNo };
      for (let i = 0; i < 4; i++) {
        if (
          this.gridName[i] ||
          this.gridType[i][0] ||
          this.gridType[i][1] ||
          this.gridType[i][2] ||
          this.gridImage[i] ||
          this.gridDescription[i] ||
          this.showDatePicker[i]
        ) {
          console.log(this.showDatePicker[i]);
          if (!this.gridName[i] && !this.showDatePicker[i]) {
            this.$refs["gridName" + (i + 1)].classList.add("redborder");
            return this.$store.state.buttonFX(
              buttonev.target.closest("button")
            );
          } // else if (!this.gridType[i]) {
          //   this.$refs["gridType" + (i + 1)].classList.add("outline");
          //   return this.$store.state.buttonFX(
          //     buttonev.target.closest("button")
          //   );
          // }
          console.log("yoooo");
          body[this.gridNameFromNumber(i + 1)] = {
            gridType: this.gridType[i],
            name: this.gridName[i],
            imageurl: this.gridImage[i],
            description: this.gridDescription[i],
            trueDateCreated: this.showDatePicker[i],
          };
        } else {
          body[this.gridNameFromNumber(i + 1)] = {
            gridType: [0, 0, 0],
            name: null,
            description: null,
          };
        }
      }

      if (this.$store.state.unsavedChangesMPBox) {
        this.$store.state.promptUnsaveModal = true;

        let answer;
        try {
          answer = await this.$store.state.promptAnswerPromise();
        } catch {
          //rejected

          answer = null;
        }

        this.$store.state.promptUnsaveModal = false;
        if (!answer) {
          this.$store.state.buttonFX(
            buttonev.target.closest("button"),
            "Cancelled"
          );
          return;
        } else {
          this.$store.state.unsavedChangesMPGrid = false;
          this.$store.state.unsavedChangesMPBox = false;
          if (document.getElementById("saveUserChanges")) {
            document.getElementById("saveUserChanges").style.outline = "none";
          }
        }
      }

      this.$store
        .dispatch("updateItem", {
          item: "boxes",
          objid: this.wellData._id,
          body,
        })
        .then(() => {
          this.notifySaved();
          this.$store.state.buttonFX(
            buttonev.target.closest("button"),
            "Grids Saved"
          );
        })
        .catch(() => {
          this.$store.state.buttonFX(
            buttonev.target.closest("button"),
            "ERROR: Saving Failed!"
          );
        });
    },
    notifySaved() {
      this.$refs.saveUserChanges.style.outline = "none";
      this.$store.state.unsavedChangesMPGrid = false;
    },
    notifyNotSaved() {
      this.$refs.saveUserChanges.style.outline = "2px red dashed";
      this.$store.state.unsavedChangesMPGrid = true;
    },
    saveGrid(number) {
      if (!this.gridName[number - 1]) {
        this.$refs["gridName" + number].classList.add("redborder");
        return;
      } else if (!this.gridType[number - 1]) {
        this.$refs["gridType" + number].classList.add("outline");
        return;
      }
      console.log("grid typ eis", this.gridType[number - 1]);
      this.$store.dispatch("updateItem", {
        item: "boxes",
        objid: this.wellData._id,
        body: {
          [this.gridNameFromNumber(number)]: {
            gridType: this.gridType[number - 1],
            name: this.gridName[number - 1],
            description: this.gridDescription[number - 1],
          },
          puckId: this.puckId,
          wellNo: this.wellNo,
        },
      });
    },
    gridNameFromNumber(number) {
      let name;
      switch (number) {
        case 1:
          name = "gridTopLeft";
          break;
        case 2:
          name = "gridTopRight";
          break;
        case 3:
          name = "gridBottomLeft";
          break;
        case 4:
          name = "gridBottomRight";
          break;
      }
      return name;
    },
    cloneSvg() {
      console.log(this.boxImage.children);
      for (let i = 0; i < 4; i++) {
        if (this.boxImage.children.length) {
          const clone = this.boxImage.cloneNode(true);
          const container = this.$refs["bigCircle-" + (i + 1)];
          const pieces = clone.querySelectorAll(".gridpieces ellipse");
          // if (this.boxType == "opencup") {
          //   pieces.forEach((piece) => {
          //     piece.remove();
          //   });
          // } else {
          //   pieces.forEach((piece, index) => {
          //     if (index != i) piece.remove();
          //     piece.style.display = "block";
          //   });
          // }
          pieces.forEach((piece, index) => {
            if (index != i) piece.remove();
            piece.style.display = "block";
          });
          container[0].innerHTML = "";
          console.log(container, "clone is", clone);
          // clone.children[0].removeAttribute("viewBox");
          // clone.children[0].style.aspectRatio = "1";
          clone.classList.add("aspect-square", "h-full");
          // clone.classList.add("h-full", "aspect-square", "w-4", "bg-black");
          clone.children[0].removeAttribute("width");
          clone.children[0].removeAttribute("height");
          clone.children[0].style.width = "100%";
          clone.children[0].style.height = "100%";
          // clone.children[0].classList.add("aspect-square");
          //         const atemplate = document.createElement("template");
          //         atemplate.innerHTML = `<div class="h-full bg-black aspect-square"><img class="h-full w-full" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"></div>
          //  `;
          // clone.appendChild(atemplate.content.firstChild);
          // clone.children[0].style.flexGrow = 1;
          // const imagetemplate = document.createElement("template");
          // imagetemplate.innerHTML = `<img class="h-full" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7">`;
          // clone.children[1].appendChild(clone.children[0]);
          // clone.children[0].appendChild(imagetemplate.content.firstChild);
          container[0].appendChild(clone);

          // container[0].appendChild(atemplate.content.firstChild);
          // container[0].style.height = "100%";
        }
      }
    },
    reInitAll() {
      this.cloneSvg();
      this.checkReadOnly(true);
      //round circle number

      this.gridType = this.gridTypeComp;

      document.querySelectorAll(".circleresize").forEach((elem) => {
        elem.style.width = elem.offsetHeight + "px";
      });
    },
    feedIntoGrid(dataObj) {
      this.showImport = false;
      const { gridDescription, gridName, gridType, gridImage } = dataObj;
      const n = this.activeImportGridNumber;
      if (!n) return;
      this.gridName[n - 1] = gridName;
      this.gridDescription[n - 1] = gridDescription;

      this.gridType[n - 1] = gridType;
      this.gridImage[n - 1] = gridImage;
      // this.notifyNotSaved();

      this.saveData(null, this.wellData._id, "boxes", "gridProperty", true, {
        gridProperty: {
          [this.gridNameFromNumber(n)]: {
            gridType: this.gridType[n - 1],
            name: this.gridName[n - 1],
            imageurl: this.gridImage[n - 1],
            trueDateCreated: this.showDatePicker[n - 1],
            description: this.gridDescription[n - 1],
          },
          puckId: this.puckId,
          wellNo: this.wellNo,
          rackIndex: this.dataChainForMove.rack,
          puckIndex: this.puckIndex,
        },
      });
      this.activeImportGridNumber = null;
    },
  },

  mounted() {
    console.log("well data" + JSON.stringify(this.wellData));
    this.reInitAll();
  },
};
</script>

<style scoped>
input,
textarea {
  border: 2px solid rgb(118, 155, 186);
  border-radius: 6px;
  padding: 0.3rem;
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
}

.redborder {
  border-color: rgb(200, 0, 0);
}

input[type="radio"] {
  margin-bottom: 0;
}

input:focus,
textarea:focus {
  border-color: rgb(43, 94, 141);
}

.outline {
  outline: rgb(200, 0, 0) solid 2px;
}
</style>
