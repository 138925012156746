<template>
  <div class="flex justify-center mb-4">
    <button
      @click="showActive = true"
      class="rounded-full py-2 px-4 border-2 font-semibold mr-4 "
      :class="[showActive ? 'activeButton' : 'inactiveButton']"
    >
      Active Grids
    </button>
    <button
      @click="showActive = false"
      class="rounded-full py-2 px-4 border-2 font-semibold"
      :class="[showActive ? 'inactiveButton' : 'activeButton']"
    >
      Archived Grids
    </button>
  </div>
  <archives :noimport="true" v-if="!showActive" />
  <active-grids v-else />
</template>

<script>
import Archives from "../../modal-inners/import-inner.vue";
import ActiveGrids from "./active-grids.vue";
export default {
  data() {
    return {
      showActive: true,
    };
  },
  components: { Archives, ActiveGrids },
};
</script>

<style scoped>
.inactiveButton {
  color: #2c5282;
  background-color: white;
  border-color: #2c5282;
}
.activeButton,
.inactiveButton:hover,
.activeButton:hover {
  color: white;
  background-color: #2c5282;
  border-color: #2c5282;
}

.inactiveButton:hover {
  background-color: #2b6cb0;
}
</style>
