import { createStore } from "vuex";

import { reactive } from "vue";

// import productsModule from './modules/products.js';
// import cartModule from './modules/cart.js';

let vuehost = process.env.VUE_APP_HOST;

if (window.location.hostname == "devtest.subangstrom.com")
  vuehost = "https://nodedev.subangstrom.com";

const store = createStore({
  //   modules: {
  //     prods: productsModule,
  //     cart: cartModule,
  //   },
  state() {
    return {
      importModalMounted: false,
      gridChangedPuckVisualTrigger: false,
      gridSaveInProgress: false,
      gridSaveTimeout: null,
      gridSaveLastWellData: null,
      unsavedChangesMPGrid: false,
      unsavedChangesMPBox: false,
      noAdminUsers: [],
      usersOnline: new Set(),
      sensors: [],
      promptAnswerResolve: null,
      promptAnswerReject: null,
      promptAnswerPromise: () => {
        return new Promise((res, rej) => {
          store.state.promptAnswerResolve = res;
          store.state.promptAnswerReject = rej;
        });
      },

      promptUnsaveModal: null,
      userDataResolve: null,
      userDataReject: null,
      checkFor: null,
      moveTo: null,
      moveToForFollow: null,
      serverdate: null,
      userDataAlreadyLoaded: false,
      getUsersForLab: () => {
        //when a user selects a lab from update lab

        const labid = store.state.activeUser.labId?._id;

        store.dispatch("getAdminStuff", {
          url: "users",
          labid,
        });
      },

      transformPuckColor(color, isSvgCup) {
        let hex = "#";
        switch (color) {
          case "red":
            hex += "FF0000";
            break;
          case "blue":
            hex += "0000FF";
            break;
          case "green":
            hex += "008000";
            break;
          case "orange":
            hex += "FA6800";
            break;
          case "violet":
            hex += "EE82EE";
            break;
          case "chrome":
            hex += "dbe4eb";
            break;
          case "gold":
            hex += "FFD700";
            break;
          case "honey":
            hex += "EBA937";
            break;
          case "sky blue":
            hex += "87ceeb";
            break;
          case "pink":
            hex += "FFC0CB";
            break;

          default:
            hex += "000000";
        }
        if (isSvgCup) {
          return store.state.hexToDarkerRgb(hex);
        } else return hex;
      },

      twoDigitsPlease(digit) {
        return parseInt(digit).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        });
      },
      transformBoxColor(color, isSvgCup) {
        let hex = "#";
        switch (color) {
          case "red":
            hex += "FF0000";
            break;
          case "blue":
            hex += "0000FF";
            break;
          case "green":
            hex += "008000";
            break;
          case "orange":
            hex += "FFA500";
            break;
          case "violet":
            hex += "EE82EE";
            break;
          case "chrome":
            hex += "dbe4eb";
            break;
          case "gold":
            hex += "FFD700";
            break;
          case "sky blue":
            hex += "87ceeb";
            break;
          case "pink":
            hex += "FFC0CB";
            break;
          case "black":
            hex += "000000";
            break;
          case "white":
            hex += "FFFFFF";
            break;
          case "brown":
            hex += "8F3F25";
            break;

          default:
            hex += "000000";
        }
        if (isSvgCup) {
          return store.state.hexToDarkerRgb(hex);
        } else return hex;
      },
      buttonFXinit(buttonev) {
        const button = buttonev.target.closest("button");
        button.children[0].style.display = "flex";
        button.children[1].style.visibility = "hidden";
        button.disabled = true;
      },
      buttonFX(button, message, redirect) {
        console.log(button);
        button.children[0].style.display = "none";
        // button.children[1].style.visibility = "visible";
        button.children[1].style.display = "flex";
        button.children[1].style.justifyContent = "center";

        //needed for superadmin
        button.children[1].style.visibility = "visible";

        if (message) {
          button.nextSibling.textContent = message;
          button.nextSibling.addEventListener("transitionend", () => {
            button.nextSibling.classList.remove("fadeinbutton");
            button.nextSibling.classList.add("fadeoutbutton");
            if (redirect) window.location.replace("/");
          });

          button.nextSibling.classList.remove("fadeoutbutton");
          button.nextSibling.classList.add("fadeinbutton");
        }
        button.disabled = false;
      },
      hexToDarkerRgb(hex) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        if (hex == "#000000") {
          return result
            ? `rgb(
              ${parseInt(result[1], 16) + 80},
              ${parseInt(result[2], 16) + 80},
              ${parseInt(result[3], 16) + 80}
            )`
            : null;
        } else {
          return result
            ? `rgb(
              ${parseInt(result[1], 16) - 80},
              ${parseInt(result[2], 16) - 80},
              ${parseInt(result[3], 16) - 80}
            )`
            : null;
        }
      },
      cleanDate(date, shortmonth, notime) {
        let month = "long";
        if (shortmonth) month = "short";
        var options = {
          year: "numeric",
          month: month,
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        };
        if (notime) {
          delete options.hour;
          delete options.minute;
        }

        var today = new Date(date);

        return today.toLocaleString("en-US", options) == "Invalid Date"
          ? ""
          : today.toLocaleString("en-US", options); // Saturday, September 17, 2016
      },

      manageUsersCustomAccessFnc(
        itemtype,
        itemid,
        customaccess,
        user,
        isexternal
      ) {
        return new Promise((res, rej) => {
          const body = {
            customaccess,
            objtype: itemtype.charAt(0).toUpperCase() + itemtype.slice(1),
            [itemtype + "id"]: itemid,
          };
          if (isexternal) {
            body.findbyemail = true;
            body.sendingId = true;
          }
          console.log(body);

          store
            .dispatch("updateItem", {
              objid: user,
              item: "users",
              body,
            })
            .then(() => res())
            .catch(() => rej());
        });
      },
      isLoggedIn: false,
      dewarData: [],
      isMark: false,
      isLabSuper: false,
      hostname: vuehost,
      unassignedPucks: [],
      unassignedRacks: [],
      institutes: [],
      disableBoxUpdate: false,
      users: [],
      logs: [],
      externalusers: [],
      labs: [],
      transports: [],
      projects: [],
      refreshtransport: false,
      transportUpdate: false,
      activeUser: [],

      puckColors: [
        "red",
        "orange",
        "green",
        "blue",
        "violet",
        "black",
        "chrome",
        "pink",
        "sky blue",
        "gold",
        "honey",
      ],
      boxColors: [
        "gold",
        "white",
        "sky blue",
        "red",
        "violet",
        "pink",
        "orange",
        "green",
        "chrome",
        "brown",
        "blue",
        "black",
      ],
    };
  },

  mutations: {
    ADD_SENSOR(state, sensor) {
      // Re-create the Set and add the new sensor
      const existingIndex = state.sensors.findIndex(
        (s) => s._id === sensor._id
      );

      if (existingIndex !== -1) {
        // Replace the existing sensor if found
        state.sensors[existingIndex] = sensor;
      } else {
        // Add the new sensor if not found
        state.sensors.push(sensor);
      }
    },
    login(state) {
      state.isLoggedIn = true;
    },
    logout(state) {
      state.isLoggedIn = false;
    },
    updateDewarData(state, payload) {
      state.dewarData = payload.value;
      console.log("dewarcyhanged", state.dewarData, payload.value);
    },
    updateUnassignedPucks(state, payload) {
      console.log(payload.value);
      if (!payload.isRack) state.unassignedPucks = payload.value;
      else state.unassignedRacks = payload.value;

      console.log(state.unassignedRacks);
    },
    updateAdminStuff(state, payload) {
      if (payload.stateName == "logs" && payload.logpagemore) {
        state[payload.stateName] = [
          ...state[payload.stateName],
          ...payload.value,
        ];
      } else {
        state[payload.stateName] = payload.value;
        console.log(" the state users object was updated", payload.stateName);
      }
    },
    // adminCreatedStuff() {
    //   console.log("ran once");
    // },
    updateUserData(state, payload) {
      state.activeUser = payload.value;
      state.getUsersForLab();
      if (payload.value.isMark) {
        state.isMark = true;
      }

      if (payload.value.isLabSuper) {
        state.isLabSuper = true;
      }

      state.userDataAlreadyLoaded = true;
      if (state.checkFor == "mark") {
        if (state.isMark) {
          state.userDataResolve();
        } else state.userDataReject();
      } else if (state.checkFor == "lab-admin") {
        if (state.activeUser.role == "lab-admin") {
          state.userDataResolve();
        } else state.userDataReject();
      }
    },
  },
  actions: {
    addSensor({ commit }, sensor) {
      commit("ADD_SENSOR", sensor);
    },
    userDataGot(context, payload) {
      // context.state.userDataResolve = null;
      // context.state.userDataReject = null;
      return new Promise(function (resolve, reject) {
        if (payload?.labadmincheck) context.state.checkFor = "lab-admin";
        else context.state.checkFor = "mark";
        context.state.userDataResolve = resolve;
        context.state.userDataReject = reject;
      });
    },

    adminCreateRackAndPucks(context, payload) {
      return new Promise(function (resolve, reject) {
        const body = payload.body;
        let url = `${context.state.hostname}/api/v1/racks/createloadedrack`;
        console.log(body);
        const request = new Request(url, {
          credentials: "include",
          method: "POST",
          body: JSON.stringify(body),
          headers: { "content-type": "application/json" },
        });
        fetch(request)
          .then((resp) => resp.json())
          .then(function (data) {
            console.log(data);
            if (data.status == "fail" || data.status == "error")
              throw new Error(data.message);
            else {
              //do sometihng on completion
              //arrayOfDewars.value = data.data.data;
              resolve();
            }

            // context.commit("adminCreatedStuff", { value: data.status });
          })
          .catch((err) => {
            console.log(err);
            reject(err);
            // context.commit("adminCreatedStuff", { value: "Failed" });
          });
      });
    },

    pushPuckToRack(context, payload) {
      return new Promise((res, rej) => {
        const url =
          context.state.hostname + "/api/v1/pucks/move/" + payload.puckid;
        const request = new Request(url, {
          credentials: "include",
          method: "PATCH",
          body: JSON.stringify({ rackId: payload.rackid }),

          headers: { "content-type": "application/json" },
        });
        fetch(request)
          .then((resp) => resp.json())
          .then(function (data) {
            if (data.status == "fail") throw new Error(data.message);
            else {
              // console.log(data.data.data);
              if (payload.fromtransport) {
                context.state.transportUpdate = true;
                console.log("ran");
              }

              context.dispatch("updateDewarData");
              if (!payload.fromtransport)
                context.dispatch("getUnassignedPucks", { lab: payload.lab });

              res();

              //arrayOfDewars.value = data.data.data;
            }
          })
          .catch((err) => rej(err));
      });
    },

    pushRackToDewar(context, payload) {
      let movePath = "move";
      const bodyObj = { dewarId: payload.dewarid };
      if (payload.removeRack) {
        bodyObj.removeRack = true;
        movePath = "remove";
      }
      const url =
        context.state.hostname +
        "/api/v1/racks/" +
        movePath +
        "/" +
        payload.rackid;

      const request = new Request(url, {
        credentials: "include",
        method: "PATCH",
        body: JSON.stringify(bodyObj),

        headers: { "content-type": "application/json" },
      });
      fetch(request)
        .then((resp) => resp.json())
        .then(function (data) {
          if (data.status == "fail") throw new Error(data.message);
          else {
            // console.log(data.data.data);
            context.dispatch("updateDewarData");

            //arrayOfDewars.value = data.data.data;
          }
        })
        .catch((err) => console.log(err));
    },

    getAdminStuff(context, payload) {
      return new Promise((res, rej) => {
        let url = context.state.hostname + "/api/v1/" + payload.url;
        if (payload.url == "users" || payload.url == "logs")
          url =
            context.state.hostname +
            "/api/v1/" +
            payload.url +
            "?labid=" +
            payload.labid;

        if (payload.url == "externalusers") {
          context.state.externalusers = [];
          url =
            context.state.hostname +
            "/api/v1/users/" +
            payload.url +
            "?labid=" +
            payload.labid;
        }

        if (payload.page) url += "&page=" + payload.page;

        if (payload.projusers)
          url =
            context.state.hostname +
            "/api/v1/projects/" +
            payload.projusers +
            "/linkuser";

        const request = new Request(url, {
          credentials: "include",
          method: "GET",

          headers: { "content-type": "application/json" },
        });
        fetch(request)
          .then((resp) => resp.json())
          .then(function (data) {
            if (data.status == "fail") throw new Error(data.message);
            else {
              res(data.data.data);

              context.commit("updateAdminStuff", {
                value: data.data.data,
                stateName: payload.url,
                signal: payload.signal,
                logpagemore: payload.page,
              });

              //arrayOfDewars.value = data.data.data;
            }
          })
          .catch((err) => {
            console.log(err);
            rej(err);
          });
      });
    },
    getUnassignedPucks(context, payload) {
      let modelPath = "pucks";
      if (payload.isRack) modelPath = "racks";

      const url =
        context.state.hostname +
        "/api/v1/" +
        modelPath +
        "/labs/" +
        payload.lab;
      const request = new Request(url, {
        credentials: "include",
        method: "GET",

        headers: { "content-type": "application/json" },
      });
      fetch(request)
        .then((resp) => resp.json())
        .then(function (data) {
          if (data.status == "fail") throw new Error(data.message);
          else {
            const payloadObj = {
              value: data.data.data,
            };
            if (payload.isRack) payloadObj.isRack = true;

            context.commit("updateUnassignedPucks", payloadObj);

            //arrayOfDewars.value = data.data.data;
          }
        })
        .catch((err) => console.log(err));
    },
    getArchives(context, payload) {
      console.log("ok");
      return new Promise((res, rej) => {
        const url =
          context.state.hostname +
          "/api/v1/gridarchives?type=" +
          payload.importType;
        const request = new Request(url, {
          credentials: "include",
          method: "GET",

          headers: { "content-type": "application/json" },
        });
        fetch(request)
          .then((resp) => resp.json())
          .then(function (data) {
            console.log(data, "ASDGggd");
            if (data.status == "fail") throw new Error(data.message);
            else {
              console.log(data.data.data);
              return res(data.data.data);
            }
          })
          .catch((err) => {
            console.log(err);
            return rej("error fetching archives");
          });
      });
    },

    updateItem(context, payload) {
      return new Promise((res, rej) => {
        const { item, objid, body, setAdmin, labcode, forpayload, linkuser } =
          payload;

        if (forpayload) {
          if (item == "dewars") {
            if (
              context.state.dewarData[forpayload.labIndex].data[
                forpayload.dewarIndex
              ][forpayload.propertytoupdate] ==
              body[forpayload.propertytoupdate]
            )
              return res();
          } else if (item == "racks") {
            if (
              context.state.dewarData[forpayload.labIndex].data[
                forpayload.dewarIndex
              ].racks[forpayload.rackIndex][forpayload.propertytoupdate] ==
              body[forpayload.propertytoupdate]
            )
              return res();
          } else if (item == "pucks") {
            if (
              context.state.dewarData[forpayload.labIndex].data[
                forpayload.dewarIndex
              ].racks[forpayload.rackIndex].pucks[forpayload.puckIndex][
                forpayload.propertytoupdate
              ] == body[forpayload.propertytoupdate]
            )
              return res();
          } else if (
            item == "boxes" &&
            forpayload.propertytoupdate != "gridProperty"
          ) {
            if (
              context.state.dewarData[forpayload.labIndex].data[
                forpayload.dewarIndex
              ].racks[forpayload.rackIndex].pucks[forpayload.puckIndex][
                "well" + forpayload.wellNo
              ][forpayload.propertytoupdate] ==
              body[forpayload.propertytoupdate]
            )
              return res();
          }
        }

        let url = `${context.state.hostname}/api/v1/${item}/${objid}`;
        console.log("URL is", url);
        if (setAdmin)
          url = `${context.state.hostname}/api/v1/${item}/${objid}?setAdmin=true`;
        if (linkuser)
          url = `${context.state.hostname}/api/v1/${item}/${objid}/linkuser`;
        if (labcode)
          url =
            `${context.state.hostname}/api/v1/${item}/${objid}?addLabCode=` +
            labcode;

        //froget what needs this. annoying cause it causes issues with project id update at the very least
        if (item != "projects" && forpayload?.propertytoupdate != "project")
          body.dateModified = Date.now();

        console.log(JSON.stringify(body));

        // url = "https://dgasgdalgad1111.com";
        const request = new Request(url, {
          credentials: "include",
          method: "PATCH",
          body: JSON.stringify(body),

          headers: { "content-type": "application/json" },
        });
        console.log(request);
        fetch(request)
          .then((resp) => resp.json())
          .then(function (data) {
            if (data.status == "fail" || data.status == "error")
              throw new Error(data.message);
            else {
              // console.log(data.data.data);
              if (item == "labs" && labcode)
                console.log("maybne do something else");
              else if (item == "labs")
                context.dispatch("getAdminStuff", { url: "labs" });
              else if (item == "users")
                context.dispatch("getAdminStuff", {
                  url: "users",
                  labid: payload.labid ?? context.state.activeUser.labId?._id,
                });
              else if (!payload.body.customaccess) {
                console.log(
                  data.data.data,
                  context.state.dewarData,
                  forpayload
                );

                // const existingdewarcontainer = context.state.dewarData.filter(
                //   (lab) => lab.labId == data.data.data.labId
                // );

                // existingdewarcontainer.filter();

                if (forpayload) {
                  if (item == "dewars")
                    context.state.dewarData[forpayload.labIndex].data[
                      forpayload.dewarIndex
                    ][forpayload.propertytoupdate] =
                      data.data.data[forpayload.propertytoupdate];
                  else if (item == "racks") {
                    context.state.dewarData[forpayload.labIndex].data[
                      forpayload.dewarIndex
                    ].racks[forpayload.rackIndex][forpayload.propertytoupdate] =
                      data.data.data[forpayload.propertytoupdate];
                  } else if (item == "pucks") {
                    const indexof = data.data.data.pucks.findIndex(
                      (puck) => puck._id == objid
                    );
                    console.log(indexof, data.data.data.pucks, objid);
                    if (indexof >= 0)
                      context.state.dewarData[forpayload.labIndex].data[
                        forpayload.dewarIndex
                      ].racks[forpayload.rackIndex].pucks[forpayload.puckIndex][
                        forpayload.propertytoupdate
                      ] =
                        data.data.data.pucks[indexof][
                          forpayload.propertytoupdate
                        ];
                  } else if (
                    item == "boxes" &&
                    forpayload.propertytoupdate != "gridProperty"
                  ) {
                    //the property being updated
                    context.state.dewarData[forpayload.labIndex].data[
                      forpayload.dewarIndex
                    ].racks[forpayload.rackIndex].pucks[forpayload.puckIndex][
                      "well" + forpayload.wellNo
                    ][forpayload.propertytoupdate] =
                      data.data.data["well" + forpayload.wellNo][
                        forpayload.propertytoupdate
                      ];

                    //the date of the item being updated
                    context.state.dewarData[forpayload.labIndex].data[
                      forpayload.dewarIndex
                    ].racks[forpayload.rackIndex].pucks[forpayload.puckIndex][
                      "well" + forpayload.wellNo
                    ].dateModified =
                      data.data.data["well" + forpayload.wellNo].dateModified;
                  } else if (
                    item == "boxes" &&
                    forpayload.propertytoupdate == "gridProperty"
                  ) {
                    clearTimeout(context.state.gridSaveTimeout);
                    context.state.gridSaveTimeout = setTimeout(() => {
                      console.log(
                        "UPDATED GRID FOR REALZ",
                        forpayload.gridupdate,
                        data.data.data["well" + forpayload.gridProperty.wellNo]
                      );
                      if (forpayload.gridProperty.gridupdate) {
                        context.state.dewarData[forpayload.labIndex].data[
                          forpayload.dewarIndex
                        ].racks[forpayload.gridProperty.rackIndex].pucks[
                          forpayload.gridProperty.puckIndex
                        ]["well" + forpayload.gridProperty.wellNo][
                          forpayload.gridProperty.gridupdate[0]
                        ][forpayload.gridProperty.gridupdate[1]] =
                          data.data.data[
                            "well" + forpayload.gridProperty.wellNo
                          ][forpayload.gridProperty.gridupdate[0]][
                            forpayload.gridProperty.gridupdate[1]
                          ];
                        context.state.dewarData[forpayload.labIndex].data[
                          forpayload.dewarIndex
                        ].racks[forpayload.gridProperty.rackIndex].pucks[
                          forpayload.gridProperty.puckIndex
                        ]["well" + forpayload.gridProperty.wellNo][
                          forpayload.gridProperty.gridupdate[0]
                        ].dateModified =
                          data.data.data[
                            "well" + forpayload.gridProperty.wellNo
                          ][forpayload.gridProperty.gridupdate[0]].dateModified;
                        console.log(
                          data.data.data[
                            "well" + forpayload.gridProperty.wellNo
                          ][forpayload.gridProperty.gridupdate[0]][
                            forpayload.gridProperty.gridupdate[1]
                          ]
                        );
                      } else {
                        //idk what this does
                        //what condition would
                        // item == "boxes" &&
                        // forpayload.propertytoupdate == "gridProperty" be true
                        // forpayload.gridProperty.gridupdate but would be false

                        context.state.dewarData[forpayload.labIndex].data[
                          forpayload.dewarIndex
                        ].racks[forpayload.gridProperty.rackIndex].pucks[
                          forpayload.gridProperty.puckIndex
                        ]["well" + forpayload.gridProperty.wellNo] =
                          data.data.data[
                            "well" + forpayload.gridProperty.wellNo
                          ];
                      }

                      console.log("visual changed go");
                      context.state.gridChangedPuckVisualTrigger = true;
                      res("ok");
                    }, 101);
                  }
                } else if (payload.linkuser && payload.body.delete) {
                  res("deleted");
                } else {
                  console.log("UPDATE DEWAR DATA RAN");
                  context.dispatch("updateDewarData");
                }

                // console.log(item, forpayload.propertytoupdate);
                // context.state.dewarData.
                // context.dispatch("updateDewarData");
                // console.log("wooga");
                // console.log(context.state.dewarData);
                context.state.refreshtransport = true;
              }

              if (body.moveTo && payload.samepuck)
                context.state.moveTo = body.moveTo.toWell;

              if (!context.state.gridSaveTimeout) {
                console.log("this res ran");
                res("ok");
              }
              console.log("update item aka ressed");
              //arrayOfDewars.value = data.data.data;
            }
          })
          .catch((err) => {
            // context.dispatch("updateDewarData");
            console.log("why rej", err);
            rej(err);
          });
      });
    },
    deleteItem(context, payload) {
      return new Promise((res, rej) => {
        const { item, objid, body } = payload;

        const url = `${context.state.hostname}/api/v1/${item}/${objid}`;
        const request = new Request(url, {
          credentials: "include",
          method: "DELETE",
          body: JSON.stringify(body),
          headers: { "content-type": "application/json" },
        });
        console.log(url);
        console.log(body);
        fetch(request)
          .then((resp) => {
            if (resp.status == 204 || resp.status == 200) {
              if (item == "users")
                context.dispatch("getAdminStuff", {
                  url: "users",
                  labid: payload.labid ?? context.state.activeUser.labId?._id,
                });
              else if (item != "gridarchives")
                context.dispatch("updateDewarData");

              if (item == "gridarchives") console.log("archive deleted");

              res();
            }
          })

          .catch((err) => {
            console.log(err);
            rej();
          });
      });
    },
    login(context) {
      context.commit("login");
      context.dispatch("getUserData", { url: "users/me" });
    },
    logout(context) {
      const url = context.state.hostname + "/api/v1/users/logout";
      const request = new Request(url, {
        credentials: "include",
        method: "GET",

        headers: { "content-type": "application/json" },
      });
      fetch(request)
        .then((resp) => resp.json())
        .then(function (data) {
          if (data.status == "fail") throw new Error(data.message);
          else {
            // console.log(data.data.data);
            context.commit("logout");

            //arrayOfDewars.value = data.data.data;
          }
        })
        .catch((err) => console.log(err));
    },
    updateDewarData(context) {
      return new Promise((res, rej) => {
        const url = context.state.hostname + "/api/v1/dewars";
        const request = new Request(url, {
          credentials: "include",
          method: "GET",

          headers: { "content-type": "application/json" },
        });
        console.log(url);
        fetch(request)
          .then((resp) => resp.json())
          .then(function (data) {
            if (data.status == "fail") throw new Error(data.message);
            else {
              console.log("dewardatahere", data.data.data);
              context.commit("updateDewarData", { value: data.data.data });
              return res();
              //arrayOfDewars.value = data.data.data;
            }
          })
          .catch((err) => {
            console.log(err);
            rej();
          });
      });
    },

    getUserData(context, payload) {
      const url = context.state.hostname + "/api/v1/" + payload.url;
      const request = new Request(url, {
        credentials: "include",
        method: "GET",

        headers: { "content-type": "application/json" },
      });
      console.log(url);
      fetch(request)
        .then((resp) => resp.json())
        .then(function (data) {
          if (data.status == "fail") throw new Error(data.message);
          else {
            // console.log(data.data.data);
            context.commit("updateUserData", { value: data.data.data });

            //arrayOfDewars.value = data.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    createItem(context, payload) {
      return new Promise((res, rej) => {
        // console.log(payload.value);
        const { item, body, dewarId } = payload;
        let url;
        if (dewarId)
          url = `${context.state.hostname}/api/v1/dewars/${dewarId}/${item}`;
        else url = `${context.state.hostname}/api/v1/${item}`;
        console.log(body);
        const request = new Request(url, {
          credentials: "include",
          method: "POST",
          body: JSON.stringify(body),
          headers: { "content-type": "application/json" },
        });
        fetch(request)
          .then((resp) => resp.json())
          .then(function (data) {
            if (data.status == "fail") throw new Error(data.message);
            else {
              //context.commit('updateDewarData', { value: data.data.data });
              res(item + " added");

              //labs and users is probably unnecessary as its now lab specific called on lab change
              if (item == "institutes")
                context.dispatch("getAdminStuff", { url: "institutes" });
              else if (item == "labs")
                context.dispatch("getAdminStuff", { url: "labs" });
              else if (item == "users")
                context.dispatch("getAdminStuff", { url: "users" });
              else if (item == "projects")
                context.dispatch("getAdminStuff", { url: "projects" });
              else if (item == "gridarchives") {
                //here jsut to avoid the below else refreshing the data
              } else {
                //for rack section
                context.dispatch("updateDewarData");
                //for transport
                console.log("woogacreated");
                context.state.refreshtransport = true;
              }
              if (item == "transports")
                context.dispatch("getAdminStuff", { url: "transports" });

              console.log("created");
              //arrayOfDewars.value = data.data.data;
            }
          })
          .catch((err) => {
            console.log(err);
            rej("error :(");
          });
      });
    },
  },
  getters: {
    isAuthenticated(state) {
      return state.isLoggedIn;
    },

    nonTempUsers(state) {
      return state.users.filter((user) => user.role != "temp");
    },
    nonTempUsersNoAdmin(state) {
      return state.users.filter(
        (user) => user.role != "temp" && user.role != "lab-admin"
      );
    },
    validPremium(state) {
      if (
        state.activeUser.labId.premiumEnd &&
        state.activeUser.labId.premiumStart &&
        state.activeUser.labId.premium
      ) {
        let compareDate = new Date(state.serverdate).setHours(0, 0, 0, 0);
        let startDate = new Date(state.activeUser.labId.premiumStart).setHours(
          0,
          0,
          0,
          0
        );
        let endDate = new Date(state.activeUser.labId.premiumEnd).setHours(
          0,
          0,
          0,
          0
        );

        console.log("comparing from index", compareDate, startDate, endDate);
        if (compareDate <= endDate && compareDate >= startDate) return true;
      }
      return false;
    },
    //uh is this even used?
    // unassignedRacksClean(state) {
    //   return Object.values(state.unassignedRacks);
    // },
  },
});

export default store;
