<template>
  <header-bar>Transport/Manage Racks</header-bar>
  <div class="grid grid-cols-3 px-4 gap-x-4 mx-auto gridtemplate mt-4">
    <div>
      <input
        list="transport-list"
        type="text"
        ref="transportRack"
        @focus="clearValue($event)"
        @change="loadLeftRackPucks"
        placeholder="Select a transport rack"
      />
      <datalist id="transport-list">
        <option
          v-for="rack in transportRacks"
          :data-rack-id="rack._id"
          :key="rack._id"
          :value="rack.customId ?? rack.serialNo"
        >
        </option>
      </datalist>
      <div class="transport-pucks">
        <transport-item
          :side="'left'"
          @show-puck="showPuck"
          :array-of-pucks="pucksForLeft"
        ></transport-item>
      </div>
    </div>
    <div
      class="self-center flex flex-col font-bold text-lg parentarrows text-green-800"
    >
      <div class="mb-4">
        <img class="w-full" src="/assets/images/greenarrow.png" />
      </div>
      <div class="text-center">ADD PUCKS</div>

      <div class="mt-4">
        <img class="w-full" src="/assets/images/greenarrow.png" />
      </div>
    </div>
    <div>
      <input
        list="allracks-list"
        type="text"
        ref="allRack"
        @focus="clearValue($event)"
        @change="loadRightRackPucks"
        placeholder="Select a rack"
      />
      <datalist id="allracks-list">
        <option
          v-for="rack in allRacks"
          :data-rack-id="rack._id"
          :key="rack._id"
          :value="rack.customId ?? rack.serialNo"
        >
        </option>
      </datalist>
      <div class="all-rack-pucks">
        <transport-item
          @show-puck="showPuck"
          :disabled="pucksForLeft.length >= 10"
          :side="'right'"
          :torackid="activeTransportRackId ?? true"
          :array-of-pucks="pucksForRight"
        ></transport-item>
      </div>
    </div>
  </div>
  <div>
    <!-- :active-puck="compActivePuckRef()"-->

    <puck-section
      v-if="showPuckSection"
      :active-puck="activePuckRef"
      :puck-data-change="puckDataChange"
      :reset-puck-selected="resetPuckSection"
      @new-puck-selected="resetPuckSection = false"
      @reset-puck-change="puckDataChange = false"
      :puck-data="activePuckData"
    ></puck-section>
  </div>
</template>
<script>
import TransportItem from "./TransportItem.vue";
import PuckSection from "./manage-products-sections/PuckSection.vue";
import HeaderBar from "./manage-products-sections/HeaderBar.vue";
export default {
  components: { TransportItem, PuckSection, HeaderBar },
  data() {
    return {
      allDewars: [],
      activePuckRef: null,
      puckDataChange: false,
      resetPuckSection: false,
      activePuckData: [],
      activePuckId: null,
      activeRackId: null,
      transportRacks: [],
      activeTransportRackId: null,
      pucksForLeft: [],
      pucksForRight: [],
      showPuckSection: false,
    };
  },
  watch: {
    allRacks() {
      if (this.pucksForRight.length > 0 || this.pucksForLeft.length > 0) {
        console.log("it changed");
        this.loadRightRackPucks();

        this.getTransportRacksOnly(true);
        this.updateActivePuckData();
      }
    },
    transportUpdate() {
      console.log("hjmm");
      if (this.$store.state.transportUpdate) {
        console.log("in here");

        this.getTransportRacksOnly(true);

        this.$store.state.transportUpdate = false;
      }
    },
  },
  computed: {
    transportUpdate() {
      return this.$store.state.transportUpdate;
    },

    allRacks() {
      let allRacks = [];
      this.allDewars.forEach((dewar) => {
        console.log("racks", dewar.racks);
        dewar.racks.forEach((rack) => allRacks.push(rack));
      });
      console.log("allracks", allRacks);

      return allRacks;
    },
  },
  methods: {
    clearValue(ev) {
      ev.target.value = "";
    },
    showPuck(puckData, puckRef) {
      //function called when a transport item text button puck is clicked
      console.log(puckRef);
      this.activeRackId = puckData.rackId;
      this.activePuckId = puckData._id;
      this.activePuckRef = puckRef;
      //sets the data from the transport item
      this.activePuckData = puckData;

      this.resetPuckSection = true;
      console.log(this.activePuckData);
      this.showPuckSection = true;
    },

    updateActivePuckData() {
      console.log("here but");
      //update all the pucks called from dewarData store watcher
      console.log(this.activeDewarId, this.activeRackElemNum);
      if (this.activeRackId && this.activePuckId) {
        //need the rack id that was clicked
        //loop through the dewars find the dewar that matches the rack id and return
        let findrack;
        let thepuck;
        console.log(this.activeRackId, this.activePuckId, "ADsgadgs");
        for (const dewar of this.allDewars) {
          findrack = dewar.racks?.filter((rack) => {
            return rack._id.toString() == this.activeRackId.toString();
          });
          console.log(findrack);
          if (findrack) break;
        }

        thepuck = findrack[0]?.pucks?.filter((puck) => {
          console.log(puck._id, this.activePuckId);
          return puck._id == this.activePuckId;
        });

        // this.activePuckData = findrack[0]?.pucks;
        //update the  active puck data
        //ad the puckid
        //
        this.activePuckData = thepuck[0];
        //console.log(this.activePuckData, this.showPuck);
        console.log(this.puck);

        this.puckDataChange = true;
      }
    },
    getDewarsOldWay() {
      const url =
        this.$store.state.hostname +
        "/api/v1/dewars/fortransport?fortransportracks=true";
      const request = new Request(url, {
        credentials: "include",
        method: "GET",

        headers: { "content-type": "application/json" },
      });
      console.log(url);
      fetch(request)
        .then((resp) => resp.json())
        .then((data) => {
          if (data.status == "fail") throw new Error(data.message);
          else {
            this.allDewars = data.data.data;
          }
        })
        .catch((err) => console.log(err));
    },
    getTransportRacksOnly(autoLoadLeft) {
      const url =
        this.$store.state.hostname + "/api/v1/racks?transportRacksOnly=true";
      const request = new Request(url, {
        credentials: "include",
        method: "GET",

        headers: { "content-type": "application/json" },
      });
      console.log(url);
      fetch(request)
        .then((resp) => resp.json())
        .then((data) => {
          if (data.status == "fail") throw new Error(data.message);
          else {
            // console.log(data.data.data);
            //context.commit("updateUserData", { value: data.data.data });
            //arrayOfDewars.value = data.data.data;
            this.transportRacks = data.data.data;
            if (autoLoadLeft) {
              //notsure if this is right

              this.loadLeftRackPucks();
              this.updateActivePuckData();
            }
            console.log(data.data.data);
            console.log(this.transportRacks);
          }
        })
        .catch((err) => console.log(err));
    },
    loadRightRackPucks() {
      const optionValue = this.$refs.allRack.value;
      //is this an issue if the values are the same how does it know which one to
      const optionRackId = document.querySelector(
        'option[value="' + optionValue + '"]'
      )?.dataset?.rackId;
      if (!optionRackId) return;

      const rackmatch = this.allRacks.filter(
        (rack) => rack._id == optionRackId
      );

      this.pucksForRight = rackmatch[0].pucks;
      console.log(this.pucksForRight);
    },
    loadLeftRackPucks() {
      const optionValue = this.$refs.transportRack.value;
      const optionRackId = document.querySelector(
        'option[value="' + optionValue + '"]'
      )?.dataset?.rackId;
      if (!optionRackId) return;
      this.activeTransportRackId = optionRackId;
      console.log(this.transportRacks);
      const rackmatch = this.transportRacks.filter(
        (rack) => (rack._id = optionRackId)
      );

      this.pucksForLeft = rackmatch[0].pucks;
    },
  },

  mounted() {
    this.getTransportRacksOnly();
    this.getDewarsOldWay();
  },
};
</script>
<style scoped>
.gridtemplate {
  grid-template-columns: 1fr min-content 1fr;
}
</style>
