<template>
  <div
    v-if="
      $store.state.activeUser.role == 'lab-admin' &&
      (container == 'puck'
        ? containerobj.labId._id
        : container == 'box'
        ? boxPuckLabId
        : containerobj.labId == $store.state.activeUser.labId._id)
    "
    class="pl-2 py-2 bg-gray-300 rounded-b-xl text-left"
  >
    <div v-if="!showCustom">
      <button
        @click="showCustom = true"
        class="bg-sky-900 rounded border border-white text-white font-semibold px-2 py-1"
      >
        Add Custom Access Users to
        <span class="capitalize">{{ container }}</span>
      </button>
    </div>
    <div class="inline-block" v-show="showCustom">
      <div class="text-left bg-sky-900 text-white px-1 font-semibold">
        <span class="capitalize">{{ container }}&nbsp;</span>
        <span
          >{{
            compBoxId ||
            containerobj?.title ||
            containerobj?.customId ||
            containerobj?.serialNo ||
            $store.state.twoDigitsPlease(index + 1)
          }}
        </span>
      </div>
      <div class="bg-white border-sky-900 border px-2 p-1 mb-1 rounded-b">
        <div class="flex items-start leading-none mb-1 mt-2">
          <input
            type="radio"
            :name="container + 'labChoice' + index"
            :id="container + 'mylab' + index"
            @change="mylabuncheck(index, true)"
            checked="checked"
            class="cursor-pointer"
          />
          <label :for="container + 'mylab' + index" class="mr-2 cursor-pointer"
            >My Lab</label
          >
          <input
            type="radio"
            @change="mylabuncheck(index)"
            :name="container + 'labChoice' + index"
            :id="container + 'externalLab' + index"
            class="cursor-pointer"
          />
          <label :for="container + 'externalLab' + index" class="cursor-pointer"
            >External Lab</label
          >
        </div>

        <div class="flex w-full mb-3">
          <div :class="[mylab[index] ? 'w-0' : 'flex-1']" class="flex">
            <select
              name=""
              ref="selectmylabuseremail"
              class="flex-1 w-0 mb-0 leading-none"
            >
              <option value="" disabled selected>Select User Email</option>
              <option
                :value="user._id"
                v-for="user in $store.getters.nonTempUsersNoAdmin"
                :key="user._id"
              >
                {{ user.email }}
              </option>
            </select>
          </div>
          <div
            :class="[!mylab[index] ? 'w-0' : 'flex-1']"
            class="flex flex-col basis-0 overflow-hidden"
          >
            <input
              class="mb-0 leading-none h-full"
              type="email"
              v-model="mylabemail[index]"
              placeholder="Enter User Email"
            />
          </div>
        </div>
        <div>
          <div class="flex">
            <input
              class="m-0 cursor-pointer"
              type="radio"
              checked="checked"
              value="readwrite"
              v-model="customAccessValue"
              :id="container + 'dewarReadWrite' + index"
            /><label
              :for="container + 'dewarReadWrite' + index"
              class="text-sm leading-none mr-2 cursor-pointer"
              >Read and Write</label
            >
            <input
              type="radio"
              :id="container + 'dewarReadOnly' + index"
              v-model="customAccessValue"
              value="readonly"
              class="cursor-pointer"
            /><label
              :for="container + 'dewarReadOnly' + index"
              class="text-sm leading-none cursor-pointer"
              >Read Only</label
            >
          </div>
          <div class="flex justify-end mt-4 mb-1">
            <button
              class="text-sky-900 my-0 mr-4 h-full bg-white border border-sky-900 font-semibold rounded px-2 relative hover:bg-sky-100"
              @click="showCustom = false"
            >
              Cancel
            </button>
            <button
              class="my-0 h-full bg-green-700 border-green-900 border rounded px-2 relative"
              @click="
                ($event) => {
                  $store.state.buttonFXinit($event);

                  if (!mylab[index])
                    customAccessFnc(container, index, containerobj._id, $event);
                  else if (mylab[index])
                    customAccessFnc(
                      container,
                      index,
                      containerobj._id,
                      $event,
                      mylabemail[index]
                    );
                }
              "
            >
              <button-inner small="true">Add User</button-inner>
            </button>
          </div>
          <div class="text-xs leading-none flex justify-end fadeoutbutton">
            Placeholder height
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["container", "containerobj", "index", "compBoxId", "boxPuckLabId"],
  data() {
    return {
      mylab: [],
      mylabemail: [],
      customAccessValue: "readwrite",
      showCustom: this.container == "project" ? true : false,
    };
  },
  methods: {
    buttonFX(button, message, redirect) {
      console.log(button);
      button.children[0].style.display = "none";
      // button.children[1].style.visibility = "visible";
      button.children[1].style.display = "flex";
      button.children[1].style.justifyContent = "center";

      //needed for superadmin
      button.children[1].style.visibility = "visible";

      if (message) {
        button.parentElement.nextSibling.textContent = message;
        button.parentElement.nextSibling.addEventListener(
          "transitionend",
          () => {
            button.parentElement.nextSibling.classList.remove("fadeinbutton");
            button.parentElement.nextSibling.classList.add("fadeoutbutton");
            if (redirect) window.location.replace("/");
          }
        );

        button.parentElement.nextSibling.classList.remove("fadeoutbutton");
        button.parentElement.nextSibling.classList.add("fadeinbutton");
      }
      button.disabled = false;
    },
    mylabuncheck(index, mylabchecked) {
      if (mylabchecked) this.mylab[index] = false;
      else this.mylab[index] = true;
    },

    customAccessFnc(itemtype, index, itemid, buttonev, isexternal) {
      const select = this.$refs.selectmylabuseremail;

      let user = select?.value;

      if (isexternal) user = isexternal;

      const buttonElem = buttonev.currentTarget;

      if (!user) return this.buttonFX(buttonElem, "Select a user!");

      //   const customaccess = document.querySelector(
      //     `input[name="${itemtype}Custom${index}"]:checked`
      //   ).value;
      const customaccess = this.customAccessValue;

      const body = {
        customaccess,
        objtype: itemtype.charAt(0).toUpperCase() + itemtype.slice(1),
        [itemtype + "id"]: itemid,
      };

      console.log("the body is", body);
      if (isexternal) body.findbyemail = true;

      let objtodispatch = {
        objid: user,
        item: "users",
        body,
      };

      if (this.container == "project") {
        //do i need to change the body too?
        body.userid = user;
        objtodispatch = {
          objid: this.containerobj._id,
          item: "projects",
          body,
          linkuser: true,
        };
      }
      this.$store
        .dispatch("updateItem", objtodispatch)
        .then(() => {
          if (buttonev) {
            this.buttonFX(buttonev.target.closest("button"), "User Added");
            this.$emit("useradded");
          }
        })
        .catch((err) => {
          if (buttonev) {
            if (err == "Error: no user for email")
              this.buttonFX(
                buttonev.target.closest("button"),
                "User Not Found!"
              );
            else this.buttonFX(buttonev.target.closest("button"), "Error!");
          }
        });
    },
  },
};
</script>
