<!-- 
//notes
//using index because of dewareditbox and carry over of mapped array -->

<template>
  <div class="shadow-md rounded-b-lg cursor-auto">
    <div
      :class="{
        useraccessbottomtab: $store.state.activeUser.role == 'lab-admin',
      }"
    >
      <div
        class="flex flex-col custom-gray-color rounded-xl border-2 px-2 py-4 border-gray-600 papaBgChange2"
        :ref="'papaBgChange2-' + index"
      >
        <div class="flex relative">
          <div
            class="text-xs relative py-1 px-2 bg-white bg-opacity-50 rounded"
            :class="{ invisible: !dewarEditBox[index] }"
          >
            <div class="flex">
              <div class="mr-2 w-16">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  version="1.1"
                  id="Layer_1"
                  x="0px"
                  y="0px"
                  viewBox="0 0 49.48 49.48"
                  style="enable-background: new 0 0 49.48 49.48"
                  xml:space="preserve"
                >
                  <svg:style type="text/css">
                    .stblue {
                      fill: rgb(5, 46, 77);
                    }
                    .stdewar1 {
                      fill: url(#SVGID_1_);
                    }
                    .stdewar2 {
                      fill: #ffffff;
                    }
                    .stdewar3 {
                      fill: #b1b3b6;
                    }
                  </svg:style>
                  <g>
                    <path
                      class="stblue"
                      d="M24.74,47.67c-12.64,0-22.93-10.29-22.93-22.93S12.1,1.81,24.74,1.81S47.67,12.1,47.67,24.74   S37.38,47.67,24.74,47.67z"
                    />
                    <g>
                      <linearGradient
                        id="SVGID_1_"
                        gradientUnits="userSpaceOnUse"
                        x1="12.4702"
                        y1="26.3658"
                        x2="37.098"
                        y2="26.3658"
                        gradientTransform="matrix(1 0 0 -1 0 51.4774)"
                      >
                        <stop offset="0.4058" style="stop-color: #ffffff" />
                        <stop offset="1" style="stop-color: #b1b3b6" />
                      </linearGradient>
                      <path
                        class="stdewar1"
                        d="M32.74,40.61h-15.9c-2.36,0-4.36-1.91-4.36-4.18V23.34c0-5.82,2.64-11.09,6.91-13.63l0.09-0.09h10.72l0,0    c4.18,2.54,6.82,7.82,6.91,13.63v13.18C37.1,38.79,35.1,40.61,32.74,40.61z"
                      />
                      <g>
                        <path
                          class="stdewar2"
                          d="M19.65,10.34c-3.91,2.45-6.36,7.36-6.36,12.9v13.18c0,1.82,1.54,3.36,3.54,3.36h15.9     c1.91,0,3.54-1.54,3.54-3.36V23.34c0-5.54-2.45-10.45-6.36-12.9H19.65V10.34z"
                        />
                        <polygon
                          class="stdewar2"
                          points="28.74,6.53 20.83,6.53 19.2,8.44 19.2,9.25 30.37,9.25 30.37,8.44    "
                        />
                        <path
                          class="stdewar2"
                          d="M12.02,10.62c-0.27,0-0.45,0.36-0.45,0.64c0.09,0.36,0.36,0.45,0.64,0.45c1.54,0.27,2.73,0.82,3.18,1.18     c0.18,0.09,0.36,0.18,0.55,0.09c0.09,0,0.18-0.09,0.27-0.18c0.18-0.27,0.82-0.55,0.27-1C16.11,11.43,13.83,10.25,12.02,10.62z"
                        />
                        <path
                          class="stdewar2"
                          d="M37.46,10.62c-1.82-0.27-4.09,0.82-4.45,1.27c-0.55,0.55,0.09,0.82,0.27,1c0.09,0.09,0.18,0.18,0.27,0.18     c0.18,0.09,0.36,0,0.55-0.09c0.55-0.36,1.73-1,3.18-1.18c0.27-0.09,0.64-0.18,0.64-0.45C37.92,10.98,37.73,10.62,37.46,10.62z"
                        />
                        <path
                          class="stdewar3"
                          d="M35.19,27.43H14.11c-0.18,0-0.36-0.18-0.36-0.36c0-0.18,0.18-0.36,0.36-0.36h21.08     c0.18,0,0.36,0.18,0.36,0.36C35.55,27.25,35.46,27.43,35.19,27.43z"
                        />
                        <path
                          class="stdewar3"
                          d="M35.19,29.16H14.11c-0.18,0-0.36-0.18-0.36-0.36c0-0.18,0.18-0.36,0.36-0.36h21.08     c0.18,0,0.36,0.18,0.36,0.36C35.55,28.97,35.46,29.16,35.19,29.16z"
                        />
                        <path
                          class="stdewar3"
                          d="M35.19,30.88H14.11c-0.18,0-0.36-0.18-0.36-0.36c0-0.18,0.18-0.36,0.36-0.36h21.08     c0.18,0,0.36,0.18,0.36,0.36C35.55,30.7,35.46,30.88,35.19,30.88z"
                        />
                        <path
                          class="stdewar3"
                          d="M35.19,32.61H14.11c-0.18,0-0.36-0.18-0.36-0.36s0.18-0.36,0.36-0.36h21.08c0.18,0,0.36,0.18,0.36,0.36     S35.46,32.61,35.19,32.61z"
                        />
                        <path
                          class="stdewar3"
                          d="M35.19,34.24H14.11c-0.18,0-0.36-0.18-0.36-0.36s0.18-0.36,0.36-0.36h21.08c0.18,0,0.36,0.18,0.36,0.36     S35.46,34.24,35.19,34.24z"
                        />
                        <path
                          class="stdewar3"
                          d="M35.19,35.97H14.11c-0.18,0-0.36-0.18-0.36-0.36s0.18-0.36,0.36-0.36h21.08c0.18,0,0.36,0.18,0.36,0.36     C35.64,35.79,35.46,35.97,35.19,35.97z"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
              <div class="flex flex-col items-center">
                <div class="text-xs">Capacity</div>
                <input
                  v-model="capacity"
                  @input="
                    saveData($event, dewar._id, 'dewars', 'capacity', false, {
                      labIndex: activeDewarLabIndex,
                      dewarIndex: index,
                    })
                  "
                  @blur="
                    saveData($event, dewar._id, 'dewars', 'capacity', true, {
                      labIndex: activeDewarLabIndex,
                      dewarIndex: index,
                    })
                  "
                  class="w-8 m-0 p-0 text-center"
                  type="number"
                />
                <div class="text-xs">Racks</div>
              </div>
            </div>
            <div>Dewar ID</div>
            <input
              style="padding-left: 0.25rem"
              @input="
                saveData($event, dewar._id, 'dewars', 'customId', false, {
                  labIndex: activeDewarLabIndex,
                  dewarIndex: index,
                })
              "
              @blur="
                saveData($event, dewar._id, 'dewars', 'customId', true, {
                  labIndex: activeDewarLabIndex,
                  dewarIndex: index,
                })
              "
              v-model="customId"
              type="text"
            />
            <div>Dewar Title</div>
            <input
              @input="
                saveData($event, dewar._id, 'dewars', 'title', false, {
                  labIndex: activeDewarLabIndex,
                  dewarIndex: index,
                })
              "
              @blur="
                saveData($event, dewar._id, 'dewars', 'title', true, {
                  labIndex: activeDewarLabIndex,
                  dewarIndex: index,
                })
              "
              style="padding-left: 0.25rem"
              v-model="title"
              type="text"
            />
            <div>Dewar Details</div>
            <div>
              <textarea
                v-model="description"
                @input="
                  saveData($event, dewar._id, 'dewars', 'details', false, {
                    labIndex: activeDewarLabIndex,
                    dewarIndex: index,
                  })
                "
                @blur="
                  saveData($event, dewar._id, 'dewars', 'details', true, {
                    labIndex: activeDewarLabIndex,
                    dewarIndex: index,
                  })
                "
                class="p-1 border-2 textarea-dewaredit"
              ></textarea>
            </div>
            <!-- <div>
              <button
                :class="{
                  buttondisabled: !dewarEditBox[index],
                }"
                :disabled="!dewarEditBox[index]"
                type="submit"
                @click="updateDewar($event, dewar._id)"
                class="my-0 h-full bg-green-700 border-green-900 border-0 rounded text-white text-lg px-4"
              >
                Save
              </button>
            </div> -->
          </div>

          <div class="ml-2 flex-1 flex flex-col">
            <div class="text-xs font-bold">Inside Racks</div>
            <div
              class="border-white text-white bg-sky-900 border-2 font-bold text-sm px-2 pt-2 pb-4 w-full"
            >
              <span v-if="dewar.racks.length == 0">No racks added</span>
              <span v-for="(rack, index) in dewar.racks" :key="index"
                >{{ rack.customId ?? rack.serialNo
                }}<span v-if="index + 1 < dewar.racks.length">, </span>
              </span>
            </div>

            <div
              class="text-xs flex flex-col justify-end flex-1 mt-4"
              v-if="addRackActive[index]"
            >
              <div class="flex">
                <div>Unassigned Racks</div>
                <div
                  class="flex-1 flex justify-end cursor-pointer custom-x-position"
                  @click="closeUnassignedRacks(index)"
                >
                  <span
                    class="bg-red-700 text-center mb-1 w-4 h-4 text-white font-semibold flex items-center justify-center"
                    style="border-radius: 50%"
                    ><span class="relative -top-px">x</span></span
                  >
                </div>
              </div>
              <div
                class="flex-1 relative overflow-y-scroll overflow-x-hidden bg-white rounded-lg px-2 py-1"
              >
                <div class="absolute w-full pr-4">
                  <ul class="flex flex-col items-stretch w-full">
                    <li
                      v-for="(rack, indexRack) in unassignedRacks"
                      :key="rack._id + 'unassigned'"
                      class="mt-1 mb-1 rounded text-white p-2 text-sm font-bold py-1 bg-gray-400"
                    >
                      <div class="flex items-stretch">
                        <div>
                          <input
                            class="cursor-pointer w-4 h-full block mb-0 pb-0"
                            :value="rack._id"
                            v-model="addUnassignedRackCheckBoxes[indexRack]"
                            type="checkbox"
                            @click="
                              addARackToDewar(
                                $event,
                                rack._id,
                                dewar._id,
                                indexRack
                              )
                            "
                          />
                        </div>

                        <span
                          >&nbsp;{{
                            rack.customId ?? rack.serialNo ?? ""
                          }}</span
                        >
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div v-else>
              <div
                class="text-white text-center mt-1 p-1 border-white bg-pink-800 border-2 font-bold text-xs"
                v-if="dewar.racks.length >= dewar.capacity"
              >
                You reached the max # of racks
              </div>
              <div
                @click="createRack(index)"
                :class="{
                  buttondisabled: !dewarEditBox[index],
                }"
                class="text-white text-center cursor-pointer mt-2 p-1 border-white bg-green-800 border-2 font-bold text-xs"
                v-else
              >
                Add a Rack
              </div>

              <div
                v-if="dewar.racks.length > 0"
                class="text-white text-center mt-2 p-1 border-white bg-sky-900 border-2 font-bold text-xs"
              >
                Remove Racks
              </div>

              <div
                @click="deleteDewar(dewar._id, index)"
                :class="{
                  buttondisabled: !dewarEditBox[index],
                }"
                class="text-white text-center cursor-pointer mt-2 p-1 border-white bg-red-800 border-2 font-bold text-xs"
                v-else
              >
                Delete Dewar
              </div>
              <div
                v-if="dewar.racks.length > 0"
                class="grid grid-cols-2 p-2 bg-white rounded-b gap-2"
              >
                <div class="col-span-2 text-xs text-center w-full leading-none">
                  (Drag & Drop to Reorder)
                </div>
                <div
                  v-for="(rack, indexformoverack) in dewar.racks"
                  :key="'formoving' + rack._id"
                >
                  <div
                    :id="indexformoverack"
                    :key="'placeholder' + rack._id"
                    @dragover="
                      (ev) => {
                        ev.preventDefault();
                        ev.dataTransfer.dropEffect = 'move';
                      }
                    "
                    @dragleave="
                      (ev) => {
                        con.log('l;eaving', ev.currentTarget);

                        if (!ev.currentTarget.classList.contains('invisible'))
                          ev.currentTarget.classList.add('invisible', 'h-0');
                      }
                    "
                    @drop="
                      (ev) => {
                        // ev.currentTarget.removeEventListener(
                        //   'dragover',
                        //   () => {}
                        // );
                        ev.preventDefault();
                        con.log('indrop', indexformoverack);
                        con.log(
                          'dggads',
                          ev.dataTransfer.getData('application/my-app')
                        );

                        changeDewarsRackOrder(
                          dewar._id,

                          ev.dataTransfer.getData('application/my-app'),
                          indexformoverack,

                          dewar.racks
                        );
                        ev.currentTarget.classList.add('invisible', 'h-0');
                        startdragindex = null;
                      }
                    "
                    class="invisible h-0 rounded bg-white border-dashed border text-center border-emerald-600 py-1 px-1 text-xs text-green-800"
                  >
                    Move Here
                  </div>
                  <div
                    draggable="true"
                    @dragstart="
                      (ev) => {
                        startdragindex = indexformoverack;
                        ev.dataTransfer.effectAllowed = 'move';

                        if (
                          ev.dataTransfer.getData('application/my-app') ===
                          indexformoverack
                        )
                          return;

                        con.log('hmm');
                        ev.dataTransfer.setData(
                          'application/my-app',
                          indexformoverack
                        );
                        con.log(ev.dataTransfer.getData('application/my-app'));
                      }
                    "
                    @dragenter="
                      (ev) => {
                        con.log(
                          startdragindex,

                          indexformoverack
                        );
                        if (
                          startdragindex &&
                          startdragindex != indexformoverack
                        ) {
                          ev.currentTarget.previousSibling.classList.remove(
                            'invisible',
                            'h-0'
                          );
                          lastmovehere = indexformoverack;
                        }
                      }
                    "
                    @dragover="
                      (ev) => {
                        ev.preventDefault();
                        ev.dataTransfer.dropEffect = 'move';
                      }
                    "
                    @drop="
                      (ev) => {
                        // ev.currentTarget.removeEventListener(
                        //   'dragover',
                        //   () => {}
                        // );
                        ev.preventDefault();
                        con.log('huh');

                        ev.currentTarget.previousSibling.classList.add(
                          'invisible',
                          'h-0'
                        );
                        startdragindex = null;
                      }
                    "
                    class="bg-green-800 text-xs py-1 text-white font-bold px-1 rounded flex cursor-pointer"
                  >
                    <Checkbox
                      :is-disabled="!dewarEditBox[index]"
                      :is-checked="true"
                      @click="deleteRack($event, rack._id, dewar._id)"
                    />
                    <span>&nbsp;{{ rack.customId ?? rack.serialNo }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <custom-user-access
      container="dewar"
      :containerobj="dewar"
      :index="index"
    ></custom-user-access>
  </div>
</template>

<script>
import CustomUserAccess from "./CustomUserAccess.vue";

export default {
  components: { CustomUserAccess },
  inject: ["removeBadBorder", "resetUnsavedData", "saveData"],
  props: [
    "dewar",
    "activeDewarLabIndex",
    "dewarLab",
    "notSavedAnswer",
    "index",
  ],
  mounted() {
    this.dewarEditBox[this.index] = true;
  },

  data() {
    return {
      addRackActive: [],
      dewarEditBox: [],
      addUnassignedRackCheckBoxes: [],
      capacity: this.dewar.capacity,
      title: this.dewar.title,
      customId: this.dewar.customId,
      description: this.dewar.details,
    };
  },
  //   watch: {
  //     dewar(val) {
  //       if (val) {
  //         if (this.compBoxProject && this.compBoxProject != "none")
  //           this.setProjectFilter(this.compBoxProject, true);
  //       }
  //     },
  // },
  computed: {
    unassignedRacks() {
      return this.$store.state.unassignedRacks;
    },
    // descriptionsInput() {
    //   return this.$store.state.dewarData[this.activeDewarLabIndex].data.map(
    //     (dewar) => dewar.details
    //   );
    // },

    // titlesInput() {
    //   return this.$store.state.dewarData[this.activeDewarLabIndex].data.map(
    //     (dewar) => dewar.title
    //   );
    // },
    // idsInput() {
    //   return this.$store.state.dewarData[this.activeDewarLabIndex].data[
    //     this.index
    //   ].customId;
    // },
    // capacitiesInput() {
    //   return this.$store.state.dewarData[this.activeDewarLabIndex].data[
    //     this.index
    //   ].capacity;
    // },
  },
  methods: {
    async addARackToDewar(event, rackid, dewarid) {
      const answer = this.notSavedAnswer();
      if (!answer) {
        if (!dewarid) event.target.checked = true;
        else event.target.checked = false;

        return false;
      } else {
        this.resetUnsavedData();
      }

      event.stopPropagation();

      if (!dewarid) {
        const unassignRack = confirm(
          "Are you sure you want to unassign this rack?"
        );
        if (!unassignRack) {
          event.target.checked = true;
          return;
        }
      }

      this.$store.dispatch("pushRackToDewar", { rackid, dewarid });

      //for when u first add a rack to a dewar
      //the dewar still needs to be highlighted which it wont through edit dewar
      this.$emit("showRacksEvent");
    },
    closeUnassignedRacks(index) {
      this.addRackActive[index] = null;
      this.addUnassignedRackCheckBoxes = [];
    },
    createRack(index) {
      if (!this.dewarEditBox[index]) return;

      this.addRackActive[index] = true;

      // const target_copy = Object.assign({}, this.dewarLab);
      // console.log(this.dewarLab);
      this.$store.dispatch("getUnassignedPucks", {
        lab: this.dewarLab,
        isRack: true,
      });
      //this.$store.dispatch("createItem", { dewarId, item: "racks", body: {} });
    },

    async changeDewarsRackOrder(dewarid, rackindex, indextomoveto, racks) {
      console.log(dewarid, rackindex, indextomoveto, racks);

      const rackids = racks.map((rack) => rack._id);
      // const racktomove = rackids[rackindex];

      rackids.splice(indextomoveto, 0, rackids.splice(rackindex, 1)[0]);

      console.log(rackids);
      this.$store.dispatch("updateItem", {
        item: "dewars",
        objid: dewarid,
        body: {
          moveRack: rackids,
          toIndex: indextomoveto,
        },
      });
    },

    async deleteDewar(dewarId, index) {
      if (!this.dewarEditBox[index]) return;
      const confirmDelete = confirm(
        "Are you sure you want to delete this dewar?"
      );
      if (confirmDelete) {
        await this.$store.dispatch("deleteItem", {
          item: "dewars",
          objid: dewarId,
        });
        this.$emit("ridfeedback", index);
      }
    },

    async deleteRack(ev, rackid, dewarid) {
      if (
        this.$store.state.unsavedChangesMPBox ||
        this.$store.state.unsavedChangesMPGrid
      ) {
        this.$store.state.promptUnsaveModal = true;

        let answer;
        try {
          answer = await this.$store.state.promptAnswerPromise();
        } catch {
          //rejected

          answer = null;
        }

        this.$store.state.promptUnsaveModal = false;
        if (!answer) {
          ev.target.checked = true;

          return;
        } else {
          this.resetUnsavedData();
        }
      }

      const unassignRack = confirm(
        "Are you sure you want to unassign this Rack?"
      );
      if (!unassignRack) {
        ev.target.checked = true;
        return;
      }

      this.$store.dispatch("pushRackToDewar", {
        rackid,
        dewarid,
        removeRack: true,
      });
    },
  },
};
</script>
<style scoped>
.custom-blue-border {
  border-color: rgb(118, 155, 186);
}
.custom-blue-bg {
  background-color: rgb(118, 155, 186);
}
input {
  padding: 0;
  margin-bottom: 0.25rem;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="radio"] {
  margin: 0;
}
.edit-dewar {
  bottom: -0.5rem;

  position: relative;
}

.maxwfitcontent {
  max-width: fit-content;
}

.dewar-container-bg {
  background: rgb(208, 232, 214);
}

/* .dewar-container > div {
  width: calc(25% - 1rem);
} */

.textarea-dewaredit {
  min-height: 100px;
}

@media (max-width: 1300px) {
  .dewar-container > div {
    width: 100%;
  }
}

.gridinner {
  grid-template-columns: max-content max-content;
}

.gridfit {
  width: calc(100% - 1rem);
  grid-template-columns: repeat(4, 1fr);
}

.gridouter {
  grid-template-columns: 1fr 1fr;
  grid-template-rows: max-content max-content;
}

.gridinner input {
  margin: 0;
  padding: 0.25rem 0.5rem;
}

.dewarImg {
  width: 80%;
}

.inputmatchselect {
  padding: 0.5rem 0.5rem;
  width: 50%;
}
</style>
