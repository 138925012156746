<template>
  <header-bar>My Transports</header-bar>
  <div>
    Note: Rack transports must be accepted before the rack appears in your
    available racks.
    <div class="flex">
      <div
        class="rounded-t-md px-2 font-semibold text-white pt-1 cursor-pointer"
        :class="[
          activeTransportPanel == 'pending' ? 'bg-sky-900' : 'bg-gray-500',
        ]"
        @click="activeTransportPanel = 'pending'"
      >
        Pending Transports
      </div>
      <div
        class="rounded-t-md px-2 font-semibold text-white pt-1 cursor-pointer"
        :class="[
          activeTransportPanel == 'completed' ? 'bg-sky-900' : 'bg-gray-500',
        ]"
        @click="activeTransportPanel = 'completed'"
      >
        Completed Transports
      </div>
    </div>
    <div
      class="grid bg-sky-900 reportgrid p-2 items-stretch"
      :class="[
        activeTransportPanel == 'completed' ? 'grid-cols-4' : 'grid-cols-5',
        activeTransportPanel == 'completed' ? 'only4grids' : '',
      ]"
    >
      <div>Rack Name</div>
      <div>Direction</div>
      <div>Status</div>
      <div>Other Info</div>
      <div v-if="activeTransportPanel == 'pending'">Action</div>
      <template v-if="activeTransportPanel == 'pending'">
        <template v-for="log in transportlogs" :key="log._id">
          <div>{{ log.rackId?.customId ?? log.rackId?.serialNo }}</div>
          <div>
            <span
              class="inline-block px-2 py-1 rounded mr-2"
              :class="{
                'bg-red-500': log.sending,
                'bg-green-500': log.receiving,
              }"
              >{{
                log.receiving ? "Incoming" : log.sending ? "Outgoing" : ""
              }}</span
            >
            {{ log.receiving ? "from" : log.sending ? "to" : "" }}
            {{
              log.receiving
                ? log.senderLab.name
                : log.sending
                ? log.receiverLab.name
                : ""
            }}
          </div>
          <div>
            Pending Acceptance
            <div
              class="inline-block rounded-full w-3 h-3 ml-2 bg-orange-400"
            ></div>
          </div>
          <div class="flex justify-evenly">
            <div
              class="relative"
              @mouseover="showBox(log._id, 'comment')"
              @mouseleave="showBox(log._id, 'comment', true)"
            >
              <button
                class="bg-blue-400 rounded p-1 border border-blue-800"
                v-if="log.comments"
              >
                Comments
              </button>
              <div
                :data-comments="log._id"
                class="absolute inset-0 hidden z-20 hiddencomments border border-blue-800 rounded bg-white px-1"
                style="
                  width: max-content;
                  height: max-content;
                  max-width: 300px;
                  min-width: 300px;
                "
              >
                <div
                  class="overflow-y-scroll text-sm"
                  style="max-height: 50vh; min-height: 100px"
                >
                  {{ log.comments }}
                </div>
              </div>
            </div>
            <div
              class="relative"
              @mouseover="showBox(log._id, 'ship')"
              @mouseleave="showBox(log._id, 'ship', true)"
            >
              <button
                class="bg-blue-400 rounded p-1 border border-blue-800"
                v-if="log.shipping"
              >
                Shipping
              </button>
              <div
                :data-shipping="log._id"
                class="absolute inset-0 hidden z-20 hiddenship border border-blue-800 rounded bg-white px-1"
                style="
                  width: max-content;
                  height: max-content;
                  max-width: 300px;
                  min-width: 300px;
                "
              >
                <div
                  class="overflow-y-scroll text-sm"
                  style="max-height: 50vh; min-height: 100px"
                >
                  Shipping Courier: {{ log.shipping?.courier }}<br />
                  Tracking Number: {{ log.shipping?.trackingid }}
                </div>
              </div>
            </div>
          </div>
          <div>
            <button
              :data-transport-id="log._id"
              :data-lab-id="log._id"
              :data-rack-id="log.rackId?._id"
              class="text-white border-white border rounded font-semibold p-1"
              :class="{
                'bg-red-700': log.sending,
                'bg-green-700': log.receiving,
              }"
              @click="acceptOrCancel"
            >
              {{
                log.receiving
                  ? "Accept Transport"
                  : log.sending
                  ? "Cancel Transport"
                  : ""
              }}
            </button>
          </div>
        </template>
      </template>
      <template v-if="activeTransportPanel == 'completed'">
        <template v-for="log in transportlogshistorical" :key="log._id">
          <div>{{ log.rackId?.customId ?? log.rackId?.serialNo }}</div>
          <div>
            <span
              class="inline-block px-2 py-1 rounded mr-2"
              :class="{
                'bg-red-500': log.sending,
                'bg-green-500': log.receiving,
              }"
              >{{
                log.receiving ? "Incoming" : log.sending ? "Outgoing" : ""
              }}</span
            >
            {{ log.receiving ? "from" : log.sending ? "to" : "" }}
            {{
              log.receiving
                ? log.senderLab.name
                : log.sending
                ? log.receiverLab.name
                : ""
            }}
          </div>
          <div>
            {{ log.status == "accepted" ? "Accepted" : "Cancelled" }}
            <div
              class="inline-block rounded-full w-3 h-3 ml-2"
              :class="log.status == 'accepted' ? 'bg-green-500' : 'bg-red-500'"
            ></div>
          </div>
          <div class="flex justify-evenly">
            <div
              class="relative"
              @mouseover="showBox(log._id, 'comment')"
              @mouseleave="showBox(log._id, 'comment', true)"
            >
              <button
                class="bg-blue-400 rounded p-1 border border-blue-800"
                v-if="log.comments"
              >
                Comments
              </button>
              <div
                :data-comments="log._id"
                class="absolute inset-0 hidden z-20 hiddencomments border border-blue-800 rounded bg-white px-1"
                style="
                  width: max-content;
                  height: max-content;
                  max-width: 300px;
                  min-width: 300px;
                "
              >
                <div
                  class="overflow-y-scroll text-sm"
                  style="max-height: 50vh; min-height: 100px"
                >
                  {{ log.comments }}
                </div>
              </div>
            </div>
            <div
              class="relative"
              @mouseover="showBox(log._id, 'ship')"
              @mouseleave="showBox(log._id, 'ship', true)"
            >
              <button
                class="bg-blue-400 rounded p-1 border border-blue-800"
                v-if="log.shipping"
              >
                Shipping
              </button>
              <div
                :data-shipping="log._id"
                class="absolute inset-0 hidden z-20 hiddenship border border-blue-800 rounded bg-white px-1"
                style="
                  width: max-content;
                  height: max-content;
                  max-width: 300px;
                  min-width: 300px;
                "
              >
                <div
                  class="overflow-y-scroll text-sm"
                  style="max-height: 50vh; min-height: 100px"
                >
                  Shipping Courier: {{ log.shipping?.courier }}<br />
                  Tracking Number: {{ log.shipping?.trackingid }}
                </div>
              </div>
            </div>
          </div>
        </template>
      </template>
    </div>
  </div>
  <!-- <header-bar>Unload a Transport Rack</header-bar>
  <div class="px-4 py-2"></div> -->
  <!-- Load & Send a Transport Rack -->
  <header-bar>Transport a Rack</header-bar>
  <div class="px-4 py-2">
    <show-button @click="showRack = !showRack">
      {{ !showRack ? "Load" : "Collapse" }} Rack</show-button
    >
    <div v-show="showRack" class="border-b-2 mb-4 pb-4">
      <!--
            @show-puck is an event triggered from transport item when a user clicks a puck from transport item

            -->
      <div class="grid grid-cols-3 px-4 gap-x-4 mx-auto gridtemplate mt-4">
        <div>
          <input
            list="transport-list"
            type="text"
            ref="transportRack"
            @focus="clearValue($event)"
            @change="loadLeftRackPucks"
            placeholder="Select a transport rack"
          />
          <datalist id="transport-list" ref="trydatalist">
            <option
              v-for="rack in transportRacks"
              :data-rack-id="rack._id"
              :key="rack._id"
              :value="rack.customId ?? rack.serialNo"
            ></option>
          </datalist>
          <div class="transport-pucks">
            <transport-item
              :side="'left'"
              @show-puck="showPuck"
              :left-has-selected-rack="leftHasSelectedRack"
              :array-of-pucks="pucksForLeft"
            ></transport-item>
          </div>
        </div>
        <div
          class="self-center flex flex-col font-bold text-lg parentarrows text-green-800"
        >
          <div class="mb-4">
            <img class="w-full" src="/assets/images/greenarrow.png" />
          </div>
          <div class="text-center">ADD PUCKS</div>

          <div class="mt-4">
            <img class="w-full" src="/assets/images/greenarrow.png" />
          </div>
        </div>
        <div>
          <input
            list="allracks-list"
            type="text"
            ref="allRack"
            @focus="clearValue($event)"
            @change="loadRightRackPucks"
            placeholder="Select a rack"
          />
          <datalist id="allracks-list">
            <option
              v-for="rack in allRacks"
              :data-rack-id="rack._id"
              :key="rack._id"
              :value="rack.customId ?? rack.serialNo"
            ></option>
          </datalist>
          <div class="all-rack-pucks">
            <transport-item
              @show-puck="showPuck"
              :disabled="pucksForLeft.length >= 10"
              :side="'right'"
              :torackid="activeTransportRackId ?? true"
              :right-has-selected-rack="rightHasSelectedRack"
              :array-of-pucks="pucksForRight"
            ></transport-item>
          </div>
        </div>
      </div>

      <puck-section
        v-if="showPuckSection"
        :active-puck="activePuckRef"
        :puck-data-change="puckDataChange"
        :reset-puck-selected="resetPuckSection"
        @new-puck-selected="resetPuckSection = false"
        @reset-puck-change="puckDataChange = false"
        :puck-data="activePuckData"
      ></puck-section>
      <div id="prepuck"></div>
    </div>

    <show-button @click="showComments = !showComments">
      {{ !showComments ? "Add" : "Collapse" }} Comments</show-button
    >

    <div class="border-b-2 mb-4" v-show="showComments">
      Comments:<br />
      <textarea
        class="mb-2 px-2 py-1"
        name=""
        id=""
        ref="comments"
        cols="10"
        rows="7"
        style="max-width: 500px"
      ></textarea>
    </div>

    <show-button @click="showShipping = !showShipping">
      {{ !showShipping ? "Add" : "Collapse" }} Shipping Info</show-button
    >
    <div class="border-b-2 mb-4" v-show="showShipping">
      <div class="flex flex-col" style="max-width: max-content">
        <span>Shipping Courier:</span>
        <select ref="courier" class="flex-1" name="" id="">
          <option value="" selected="true" disabled="disabled">
            Select Courier
          </option>
          <option value="dhl">DHL</option>
          <option value="fedex">FedEx</option>
          <option value="ups">UPS</option>
          <option value="usps">USPS</option>
        </select>
      </div>
      Tracking Number:
      <input ref="trackingNo" style="max-width: 500px" type="text" />
    </div>

    <div class="grid items-stretch justify-start labgrid mb-4 gap-x-8">
      <!-- <div>
        <span class="font-semibold">Select A Rack</span>
        <select>
          <option v-for="rack in allRacks" :value="rack._id" :key="rack._id">{{
            rack.customId ?? rack.serialNo
          }}</option></select
        >
      </div>
      <div class="px-2">Transport To</div> -->
      <div class="flex flex-col">
        <div class="font-semibold">Transporting Rack:</div>
        <div
          class="flex-1 flex items-center"
          :class="[
            { 'text-red-700': !$refs.transportRack?.value },
            { 'font-semibold': !$refs.transportRack?.value },
          ]"
        >
          {{
            $refs.transportRack?.value
              ? $refs.transportRack?.value
              : "No transport rack loaded"
          }}
        </div>
      </div>
      <div class="flex flex-col">
        <span class="font-semibold">Select Receiving Lab</span>

        <select
          name=""
          id=""
          style="width: max-content"
          class="mb-0 flex-1"
          ref="receivinglab"
        ></select>
      </div>
      <div>
        <span class="font-semibold block">Add a Lab: </span>
        <div class="flex items-end">
          <input
            id="labcode"
            type="text"
            ref="labcode"
            placeholder="Enter Lab Code"
            class="mb-0 inline-block"
            style="width: max-content"
          />
          <button
            @click="addLab"
            class="inline-block rounded bg-green-700 text-white p-1 ml-2 relative"
          >
            <button-inner>Add</button-inner>
          </button>
          <div class="fadeoutbutton">Lab added!</div>
        </div>
      </div>
    </div>
    <div class="px-2 mb-4 flex">
      <button
        @click="transportRackMethod"
        :class="[!transportlabval || !receivinglabval ? 'buttondisabled' : '']"
        class="h-full bg-emerald-600 rounded-3xl text-white text-xl font-semibold px-4 py-3 relative"
      >
        <button-inner>Transport Rack</button-inner>
      </button>
      <div class="fadeoutbutton flex items-center ml-2"></div>
    </div>
  </div>
</template>
<script>
import TransportItem from "./TransportItem.vue";
import PuckSection from "./manage-products-sections/PuckSection.vue";
import HeaderBar from "./manage-products-sections/HeaderBar.vue";
import ShowButton from "../../ui/TransportShowButton.vue";
export default {
  components: { HeaderBar, ShowButton, TransportItem, PuckSection },
  data() {
    return {
      rightHasSelectedRack: false,
      leftHasSelectedRack: false,
      transportlabval: false,
      receivinglabval: false,
      showComments: false,
      showShipping: false,
      showRack: false,
      allDewars: [],
      transportRacks: [],
      transportlogs: [],
      transportlogshistorical: [],
      activePuckRef: null,
      puckDataChange: false,
      resetPuckSection: false,
      activePuckData: [],
      activePuckId: null,
      activeRackId: null,
      activePuckSide: null,
      activeTransportRackId: null,
      pucksForLeft: [],
      pucksForRight: [],
      activeTransportPanel: "pending",
      showPuckSection: false,
    };
  },

  watch: {
    allRacks() {
      if (this.pucksForRight.length > 0 || this.pucksForLeft.length > 0) {
        console.log("it changed");
        this.loadRightRackPucks();

        this.getTransportRacksOnly(true);
        this.updateActivePuckData();
      }
    },
    transportUpdate() {
      //this is for when a puck moves to a  rack
      if (this.$store.state.transportUpdate) {
        this.getTransportRacksOnly(true);
        this.getDewarsOldWay(true);

        this.$store.state.transportUpdate = false;
      }
    },
    transportrefresh() {
      //for refreshing the puck after a svaae ;has been made
      if (this.$store.state.refreshtransport) {
        console.log("yes it changed to a value");
        this.getTransportRacksOnly(true);
        this.getDewarsOldWay(true);

        this.$store.state.refreshtransport = false;
      }
    },
  },

  computed: {
    transportUpdate() {
      return this.$store.state.transportUpdate;
    },
    transportrefresh() {
      return this.$store.state.refreshtransport;
    },
    allRacks() {
      let allRacks = [];
      this.allDewars?.forEach((dewar) => {
        dewar.racks.forEach((rack) => allRacks.push(rack));
      });

      return allRacks;
    },
  },
  methods: {
    showCompletedTransports() {},

    acceptOrCancel(buttonev) {
      const isCancel = buttonev.target.classList.contains("bg-red-700");

      let body = {
        rackId: buttonev.target.closest("button").dataset.rackId,

        status: "accepted",
      };
      if (isCancel) body.status = "cancelled";

      this.$store
        .dispatch("updateItem", {
          item: "transports",
          objid: buttonev.target.closest("button").dataset.transportId,
          body: body,
        })
        .then(() => {
          this.getTransportLogs();
          this.getTransportLogs(true);
        });
    },
    getTransportLogs(historical) {
      let url = "transports";
      if (historical) url = "transports?historical=true";
      this.$store
        .dispatch("getAdminStuff", { url })
        .then((data) => {
          if (historical) this.transportlogshistorical = data;
          else this.transportlogs = data;
        })
        .catch((err) => console.log(err));
    },

    transportRackMethod(buttonev) {
      if (
        buttonev.target.closest("button").classList.contains("buttondisabled")
      )
        return;

      const optionValue = this.$refs.transportRack.value;
      const optionRackId = document.querySelector(
        'option[value="' + optionValue + '"]'
      )?.dataset?.rackId;
      this.$store.state.buttonFXinit(buttonev);
      this.$store
        .dispatch("createItem", {
          item: "transports",
          body: {
            senderLab: this.$store.state.activeUser.labId._id,
            receiverLab: this.$refs.receivinglab.value,
            comments: this.$refs.comments.value,
            shipping: {
              courier: this.$refs.courier.value,
              trackingid: this.$refs.trackingNo.value,
            },
            rackId: optionRackId,
            status: "pending",
          },
        })
        .then(() => {
          this.getTransportLogs();
          return this.$store.state.buttonFX(
            buttonev.target.closest("button"),
            "Transport Started"
          );
        })
        .then(() => {
          setTimeout(() => {
            this.pucksForleft = [];
            this.pucksForRight = [];
            this.$refs.allRack.value = "";
            this.$refs.transportRack.value = "";
            this.leftHasSelectedRack = false;
            this.rightHasSelectedRack = false;
            window.scrollTo(0, 0);
          }, 500);
        })
        .catch((err) => {
          return this.$store.state.buttonFX(
            buttonev.target.closest("button"),
            "Error: " + err
          );
        });
    },
    addLab(buttonev) {
      console.log(this.$store.state.activeUser);
      if (!this.$refs.labcode.value) return;

      this.$store.state.buttonFXinit(buttonev);
      this.$store
        .dispatch("updateItem", {
          item: "labs",
          objid: this.$store.state.activeUser.labId._id,
          labcode: this.$refs.labcode.value.toUpperCase(),
          body: {},
        })

        .then(() => {
          this.getReceivingLabsList(this.$refs.labcode.value);
          return this.$store.state.buttonFX(
            buttonev.target.closest("button"),
            "Added lab!"
          );
        })
        .catch((err) => {
          console.log(err);
          return this.$store.state.buttonFX(
            buttonev.target.closest("button"),
            err
          );
        });
    },
    clearValue(ev) {
      ev.target.value = "";
    },
    showPuck(puckData, puckRef, puckSide) {
      //function called when a transport item text button puck is clicked

      console.log(puckRef);
      this.activePuckSide = puckSide;
      this.activeRackId = puckData.rackId;
      this.activePuckId = puckData._id;
      this.activePuckRef = puckRef;
      //sets the data from the transport item
      this.activePuckData = puckData;

      this.resetPuckSection = true;
      console.log("activepuckdata", this.activePuckData);
      this.showPuckSection = true;
      setTimeout(
        () => document.getElementById("prepuck").scrollIntoView(),
        500
      );
    },
    showBox(logId, boxType, mouseOut) {
      let selector = "comments";
      if (boxType == "ship") selector = "shipping";

      let elem = document.querySelector(`[data-${selector} = "${logId}"]`);
      console.log(elem, selector);

      if (mouseOut) elem.classList.add("hidden");
      else elem.classList.remove("hidden");
    },
    updateActivePuckData() {
      console.log("here but");
      //update all the pucks called from dewarData store watcher
      console.log(this.activeDewarId, this.activeRackElemNum);
      if (this.activeRackId && this.activePuckId) {
        //need the rack id that was clicked
        //loop through the dewars find the dewar that matches the rack id and return
        let findrack;
        let thepuck;
        console.log(this.activeRackId, this.activePuckId, "ADsgadgs");

        //this will only work for right side pucks
        console.log(this.activePuckSide, "activePuckSide");
        if (this.activePuckSide == "right") {
          for (const dewar of this.allDewars) {
            findrack = dewar.racks?.filter((rack) => {
              return rack._id.toString() == this.activeRackId.toString();
            });

            if (findrack.length > 0) break;
          }
        } else if (this.activePuckSide == "left") {
          findrack = this.transportRacks?.filter((rack) => {
            return rack._id.toString() == this.activeRackId.toString();
          });
        }

        thepuck = findrack[0]?.pucks?.filter((puck) => {
          console.log(puck._id, this.activePuckId);
          return puck._id == this.activePuckId;
        });

        // this.activePuckData = findrack[0]?.pucks;
        //update the  active puck data
        //ad the puckid
        //
        this.activePuckData = thepuck[0];
        //console.log(this.activePuckData, this.showPuck);
        console.log(this.puck);

        this.puckDataChange = true;
      }
    },
    getReceivingLabsList(labcode) {
      const url =
        this.$store.state.hostname + "/api/v1/labs/getTransportLabsList";
      const request = new Request(url, {
        credentials: "include",
        method: "GET",

        headers: { "content-type": "application/json" },
      });
      console.log(url);
      fetch(request)
        .then((resp) => resp.json())
        .then((data) => {
          if (data.status == "fail") throw new Error(data.message);
          else {
            console.log(data.data.data, "we here");
            this.$refs.receivinglab.innerHTML = "";
            if (data.data.data.length == 0) {
              const option = document.createElement("option");

              option.selected = true;
              option.disabled = true;
              option.value = "nolabs";
              option.text = "No labs added";
              this.$refs.receivinglab.appendChild(option);

              return;
            }

            data.data.data.forEach((lab) => {
              const option = document.createElement("option");
              if (labcode && labcode.toUpperCase() == lab.code)
                option.selected = true;
              option.value = lab._id;
              option.text = lab.name;
              this.$refs.receivinglab.appendChild(option);
            });
            console.log(this.$refs.receivinglab.value, "valueis");
            if (this.$refs.receivinglab.value) this.receivinglabval = true;
            else this.receivinglabval = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getDewarsOldWay(autoLoadRight) {
      return new Promise((res, rej) => {
        const url =
          this.$store.state.hostname +
          "/api/v1/dewars/fortransport?fortransportracks=true";
        const request = new Request(url, {
          credentials: "include",
          method: "GET",

          headers: { "content-type": "application/json" },
        });
        console.log(url);
        fetch(request)
          .then((resp) => {
            if (!resp.ok) throw new Error("Network response was not ok.");
            return resp.json();
          })
          .then((data) => {
            if (data.status == "fail") throw new Error(data.message);
            else {
              this.allDewars = data.data.data;
              console.log("this is allDewars, ", this.allDewars);
              if (autoLoadRight) {
                //notsure if this is right
                this.loadRightRackPucks();
                this.updateActivePuckData();
              }
              res();
            }
          })
          .catch((err) => {
            console.log(err);
            rej(err);
          });
      });
    },
    getTransportRacksOnly(autoLoadLeft) {
      return new Promise((res, rej) => {
        const url =
          this.$store.state.hostname + "/api/v1/racks?transportRacksOnly=true";
        const request = new Request(url, {
          credentials: "include",
          method: "GET",

          headers: { "content-type": "application/json" },
        });
        console.log(url);
        fetch(request)
          .then((resp) => resp.json())
          .then((data) => {
            if (data.status == "fail") throw new Error(data.message);
            else {
              // console.log(data.data.data);
              //context.commit("updateUserData", { value: data.data.data });
              //arrayOfDewars.value = data.data.data;
              this.transportRacks = data.data.data;
              if (autoLoadLeft) {
                //notsure if this is right

                this.loadLeftRackPucks();
                // this.loadRightRackPucks();
                this.updateActivePuckData();
              }

              res();
            }
          })
          .catch((err) => {
            console.log(err);
            rej();
          });
      });
    },

    loadRightRackPucks() {
      const optionValue = this.$refs.allRack.value;
      //is this an issue if the values are the same how does it know which one to
      const optionRackId = document.querySelector(
        'option[value="' + optionValue + '"]'
      )?.dataset?.rackId;
      if (!optionRackId) {
        this.pucksForRight = [];
        return;
      } else {
        this.rightHasSelectedRack = true;
      }

      const rackmatch = this.allRacks.filter(
        (rack) => rack._id == optionRackId
      );

      this.pucksForRight = rackmatch[0].pucks;
      //this.getDewarsOldWay(rackmatch[0].pucks);
    },

    loadLeftRackPucks() {
      if (this.$refs.transportRack.value) this.transportlabval = true;
      else this.transportlabval = false;
      console.log(this.transportlabval, this.receivinglabval);
      const optionValue = this.$refs.transportRack.value;
      const optionRackId = document.querySelector(
        'option[value="' + optionValue + '"]'
      )?.dataset?.rackId;
      if (!optionRackId) {
        this.pucksForLeft = [];
        return;
      } else {
        this.leftHasSelectedRack = true;
      }
      this.activeTransportRackId = optionRackId;
      console.log(
        "here are the transport racks",
        this.transportRacks,
        "here is the active transport rack",
        optionRackId
      );
      const rackmatch = this.transportRacks.filter(
        (rack) => rack._id == optionRackId
      );

      this.pucksForLeft = rackmatch[0].pucks;
    },
  },
  mounted() {
    this.$store.state.transportrefresh = false;
    var isFirefox = typeof InstallTrigger !== "undefined";

    if (isFirefox) {
      this.$refs.transportRack.placeholder += " (double click to load all)";
      this.$refs.transportRack.addEventListener(
        "select",
        this.loadLeftRackPucks
      );
      this.$refs.allRack.placeholder += " (double click to load all)";
      this.$refs.allRack.addEventListener("select", this.loadRightRackPucks);
    }
    this.getTransportLogs();
    this.getTransportLogs(true);
    this.getTransportRacksOnly();
    this.getReceivingLabsList();
    this.getDewarsOldWay();
  },
};
</script>
<style scoped>
textarea:focus {
  outline: none;
  border-color: rgb(43, 94, 141);
  color: rgb(43, 94, 141);
}
textarea {
  border-color: rgb(118, 155, 186);
  height: 100%;
  border-width: 2px;
  width: 100%;
  resize: none;
}

.reportgrid {
  gap: 1px;
}

.gridtemplate {
  grid-template-columns: 1fr min-content 1fr;
}

.reportgrid > div {
  background-color: lightgray;
  padding-left: 1rem;
  display: flex;
  align-items: center;
  padding: 0.25rem;
}
.reportgrid > div:nth-child(-n + 5) {
  background-color: lightblue;
  font-weight: 500;
}
.only4grids > div:nth-child(5) {
  background-color: lightgray;
  font-weight: 400;
}

.labgrid {
  grid-template-columns: max-content max-content max-content;
}
</style>
