<template>
  <div class="formwrapper">
    <form
      ref="theform"
      @submit.prevent=""
      class="relative grid gap-x-4 gap-y-2"
    >
      <div></div>
      <div class="flex items-end justify-between">
        <div class="capitalize font-bold text-lg">
          my profile
        </div>
      </div>
      <div></div>
      <div class="flex items-end justify-between">
        <div>
          <div class="mt-4">
            <input
              disabled
              id="file-upload"
              type="file"
              accept="image/*"
              name="photo"
              ref="photoinput"
              @change="changePhoto($event)"
            />
            <label for="file-upload">
              <img
                ref="photoelement"
                :class="{ photoenabled: editMode }"
                :src="
                  $store.state.activeUser.photo
                    ? $store.state.hostname +
                      '/img/users/' +
                      $store.state.activeUser.photo
                    : 'https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2Fs3.amazonaws.com%2Fappforest_uf%2Ff1583584638543x692525241529171000%2Fperson-placeholder-male-5.jpg?w=192&h=202&auto=compress&dpr=1.25&fit=max'
                "
            /></label>
          </div>
        </div>
        <div class="flex flex-auto justify-end items-end">
          <div class="pr-8 flex items-end">
            <input
              @change="onEdit($event)"
              type="checkbox"
              class=" w-4 h-4 inline-block  cursor-pointer"
              id="editinfo"
            /><label class="maxme text-sm cursor-pointer" for="editinfo"
              >Edit Info</label
            >
          </div>
          <div>
            <button
              type="submit"
              :disabled="!editMode"
              class="mb-0 btn-blue w-full"
              :class="[editMode ? 'buttonactive' : 'buttondisabled']"
              @click="saveProfile"
            >
              Save
            </button>
          </div>
        </div>
      </div>

      <div class="mt-4 flex items-center justify-end gridleftcol">
        First Name
      </div>

      <input
        disabled
        :value="firstName"
        class="mt-4"
        type="text"
        ref="firstnameinput"
        :class="{ inputenabled: editMode }"
      />
      <div class="flex items-center justify-end gridleftcol">Last Name</div>
      <input
        :value="lastName"
        disabled
        ref="lastnameinput"
        type="text"
        :class="{ inputenabled: editMode }"
      />
      <div class="mt-4 flex items-center justify-end gridleftcol">
        <span>Email</span>
      </div>
      <input
        :value="emailAddress"
        disabled
        readonly
        class="mt-4"
        type="email"
        :class="{ inputenabled: editMode }"
      />
      <div class="mt-4 flex items-center justify-end gridleftcol">
        <span>Phone</span>
      </div>
      <input
        disabled
        ref="phoneinput"
        :value="phoneNumber"
        class="mt-4"
        type="tel"
        :class="{ inputenabled: editMode }"
      />

      <div class="mt-4 flex items-center justify-end gridleftcol">Group</div>
      <input
        :value="labId"
        readonly
        disabled
        class="mt-4"
        type="text"
        :class="{ inputenabled: editMode }"
      />
      <div class="mt-4 flex items-center justify-end gridleftcol">Title</div>
      <input
        :value="userRole"
        readonly
        disabled
        class="mt-4 capitalize"
        type="text"
        :class="{ inputenabled: editMode }"
      />
      <div class="mt-4 flex items-center justify-end gridleftcol">
        Address 1
      </div>
      <input
        ref="address1input"
        :value="addressOne"
        placeholder="Address"
        disabled
        class="mt-4"
        type="text"
        :class="{ inputenabled: editMode }"
      />
      <div class="flex items-center justify-end gridleftcol">Address 2</div>
      <input
        ref="address2input"
        :value="addressTwo"
        placeholder="City - State - Zip Code"
        disabled
        type="text"
        :class="{ inputenabled: editMode }"
      />
      <div class="flex items-center justify-end gridleftcol">Country</div>
      <input
        ref="countryinput"
        :value="country"
        disabled
        type="text"
        :class="{ inputenabled: editMode }"
      />
    </form>
  </div>
</template>
<script>
import { ref } from "vue";
import { useStore } from "vuex";
export default {
  setup() {
    const theform = ref(null);
    const editMode = ref(false);
    const store = useStore();

    const address1input = ref(null);
    const address2input = ref(null);
    const phoneinput = ref(null);
    const countryinput = ref(null);
    const firstnameinput = ref(null);
    const lastnameinput = ref(null);
    const photoinput = ref(null);
    const photoelement = ref(null);

    function changePhoto(event) {
      photoelement.value.src = URL.createObjectURL(event.target.files[0]);
      photoelement.value.onload = function() {
        URL.revokeObjectURL(photoelement.value.src); // free memory
      };
    }

    function saveProfile() {
      console.log("clickedd");
      const url = store.state.hostname + "/api/v1/users/updateMe";
      const form = new FormData();
      form.append(
        "name",
        firstnameinput.value.value + " " + lastnameinput.value.value
      );
      form.append("address1", address1input.value.value);
      form.append("address2", address2input.value.value);
      form.append("phone", phoneinput.value.value);
      form.append("country", countryinput.value.value);
      if (photoinput.value.files[0])
        form.append("photo", photoinput.value.files[0]);
      // const bodytopass = {
      //   name: firstnameinput.value.value + " " + lastnameinput.value.value,
      //   address1: address1input.value.value,
      //   address2: address2input.value.value,
      //   phone: phoneinput.value.value,
      //   country: countryinput.value.value,
      //   photo: photoinput.value.files[0],
      // };
      console.log(photoinput.value.files[0]);
      const request = new Request(url, {
        credentials: "include",
        method: "PATCH",
        body: form,
      });
      fetch(request)
        .then((resp) => resp.json())
        .then((data) => {
          if (data.status == "fail") throw new Error(data.message);
          else {
            // console.log(data.data.data);
            //context.commit("updateUserData", { value: data.data.data });
            //arrayOfDewars.value = data.data.data;
            store.dispatch("login");
            onEdit();
          }
        })
        .catch((err) => console.log(err));
    }

    function onEdit(ev) {
      theform.value.querySelectorAll("input");
      if (ev?.target?.checked) {
        editMode.value = true;

        for (
          let i = 0;
          i < theform.value.querySelectorAll("input").length;
          i++
        ) {
          theform.value.querySelectorAll("input")[i].disabled = false;
        }
      } else {
        editMode.value = false;
        for (
          let i = 0;
          i < theform.value.querySelectorAll("input").length;
          i++
        ) {
          theform.value.querySelectorAll("input")[i].disabled = true;
        }
        theform.value.querySelector("#editinfo").disabled = false;
        theform.value.querySelector("#editinfo").checked = false;
      }
    }

    return {
      theform,
      onEdit,
      editMode,
      saveProfile,
      firstnameinput,
      lastnameinput,
      address1input,
      address2input,
      countryinput,
      phoneinput,
      photoinput,
      photoelement,
      changePhoto,
    };
  },
  computed: {
    firstName() {
      const nameArray = this.$store.state.activeUser?.name?.split(" ");
      console.log(nameArray);

      return nameArray?.[0];
    },
    lastName() {
      const nameArray = this.$store.state.activeUser?.name?.split(" ");
      return nameArray?.[1];
    },
    emailAddress() {
      return this.$store.state.activeUser?.email;
    },
    phoneNumber() {
      return this.$store.state.activeUser?.phone;
    },
    labId() {
      return this.$store.state.activeUser?.labId?.name;
    },
    country() {
      return this.$store.state.activeUser?.country;
    },
    addressOne() {
      return this.$store.state.activeUser?.address1;
    },
    addressTwo() {
      return this.$store.state.activeUser?.address2;
    },
    userRole() {
      if (this.$store.state.activeUser?.role == "user") return "Lab Member";

      return this.$store.state.activeUser?.role.split("-").join(" ");
    },
  },
};
</script>
<style scoped>
img {
  height: 134px;
  width: 134px;
  object-fit: cover;
  border: 2px solid white;
}
#file-upload {
  display: none;
}

button {
  padding: 0.25rem 2rem;
}

.buttonactive {
  cursor: pointer;
  color: white;
}
.buttonactive:hover {
  background: rgba(55, 101, 151);
  border-color: rgb(0, 156, 36);
  cursor: pointer;
  color: white;
}

.maxme {
  width: max-content;
}

input[type="checkbox"] {
  margin-right: 0.2rem;
  transform: translateY(-0.2rem);
}
input {
  margin-bottom: 0;
  border-radius: 4px;
  max-width: 510px;
  border: 2px solid rgb(237, 237, 237);
  background-color: rgb(237, 237, 237);
}

.inputenabled {
  background-color: white;
  border-color: rgb(135, 135, 135);
  color: black;
}
.photoenabled {
  border: dashed 2px rgb(0, 156, 36);
  cursor: pointer;
}

form {
  display: inline-grid;

  grid-template-columns: max-content minmax(min-content, 510px);
  width: auto;

  max-width: 100%;
  margin: 0 auto;
}

@media (min-width: 1024px) {
  form {
    grid-template-columns: 0 minmax(min-content, 510px);
  }
  .gridleftcol {
    width: max-content;
    transform: translateX(-100%);
  }
}

.formwrapper {
  width: 100%;
  text-align: center;
  padding: 0 1rem;
}
</style>
