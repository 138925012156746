<template>
  <div class="text-xl text-center m-4">
    <span v-if="failed">Sign Up Failed</span>
    <span v-else>Sign Up Successful</span>
  </div>
  <div class="text-sm w-9/12 mx-auto mb-6">
    <div v-if="failed">
      There was an error processing your sign up.
      <div
        class="bg-black p-2 border-green-400 border text-green-400 font-mono"
      >
        Error: {{ computefail }}
      </div>
      Please contact your lab's administrator or
      <a
        href="mailto:andrew@subangstrom.com,info@subangstrom.com"
        class="text-blue-700 underline"
        >email us</a
      >
      for additional assistance.
    </div>
    <span v-else>
      Thank you for signing up. You will receive an email notification once your
      lab's administrator has approved your account.</span
    >
  </div>
</template>

<script>
export default {
  props: ["failed"],
  computed: {
    computefail() {
      if (this.failed == "nointernet")
        return "Unable to connect to the server. Check your internet connection.";
      else if (this.failed.includes("E11000"))
        return "This email address is already signed up.";
      else return this.failed;
    },
  },
};
</script>
