<template>
  <div id="mainLoggedOut" :class="isFixed" class="flex justify-center w-full">
    <main
      class="mx-auto mt-0 lg:mt-4 portrait:mt-4 uppercase w-6/12 lg:w-full portrait:w-full"
    >
      <div>
        <img alt="Subangstrom" src="assets/images/logo.svg" />
      </div>
      <div class="font-sans text-3xl md:text-5xl text-center">
        grid storage system
      </div>
      <div class="flex justify-center mt-6 lg:mt-16 portrait:mt-16">
        <home-button color="blue">signup</home-button
        ><home-button color="green">login</home-button>
      </div>
    </main>
  </div>
</template>

<script>
import HomeButton from "./ui/HomeButton.vue";

export default {
  props: ["isFixed"],
  components: {
    HomeButton,
  },
};
</script>

<style scoped>
main {
  color: rgb(156, 156, 156);

  max-width: 772px;
  min-width: 320px;
  font-size: 2.5rem;
}

img {
  max-height: 330px;
}
</style>
