<template>
  <main
    @mousedown="fadeOut"
    class="flex absolute inset-0 bluebg z-10 w-full"
    ref="mainContainer"
  >
    <div
      id="inner"
      class="z-30 relative"
      @mousedown="stopChildClick"
      ref="innerContainer"
    >
      <component :show-forgot="showForgot" :is="showLogin"></component>
    </div>
  </main>
</template>

<script>
import { ref, onMounted, inject, watch } from "vue";

import LogInMain from "./LogInMain.vue";
import LogInForgot from "./LogInForgot.vue";

export default {
  components: {
    LogInMain,
    LogInForgot,
  },

  setup() {
    function stopChildClick(event) {
      event.stopPropagation();
    }

    const groupType = ref(null);
    const mainContainer = ref(null);
    const innerContainer = ref(null);
    const groupNameInput = ref(null);
    const selectMenu = ref(null);
    const doShowForm = inject("doShowForm");
    const showLogin = ref("log-in-main");

    watch(groupType, function () {
      if (groupType.value == "select") {
        groupNameInput.value.classList.remove("visible");
        groupNameInput.value.classList.add("invisible");
        selectMenu.value.classList.remove("invisible");

        selectMenu.value.classList.add("visible");
      } else {
        selectMenu.value.classList.remove("visible");
        selectMenu.value.classList.add("invisible");
        groupNameInput.value.classList.remove("invisible");
        groupNameInput.value.classList.add("visible");
      }
    });

    function reportWindowSize() {
      if (innerContainer.value) {
        if (innerContainer.value.offsetHeight < window.innerHeight * 0.8) {
          mainContainer.value.style.alignItems = "flex-start";
          innerContainer.value.style.marginTop = "10vh";
        } else {
          innerContainer.value.style.marginTop = "auto";
          mainContainer.value.style.alignItems = "center";
        }

        if (innerContainer.value.offsetHeight > window.innerHeight)
          mainContainer.value.style.height =
            innerContainer.value.offsetHeight + "px";
        else mainContainer.value.style.height = window.innerHeight + "px";
      }
    }
    window.addEventListener("resize", reportWindowSize);

    onMounted(reportWindowSize);

    function fadeOut() {
      mainContainer.value.classList.add("bluebgreverse");
      mainContainer.value.addEventListener("animationend", doShowForm);
    }

    function showForgot() {
      if (showLogin.value == "log-in-main") showLogin.value = "log-in-forgot";
      else showLogin.value = "log-in-main";
    }

    return {
      mainContainer,
      doShowForm,
      stopChildClick,
      innerContainer,
      fadeOut,
      groupType,
      groupNameInput,
      selectMenu,
      showLogin,
      showForgot,
    };
  },
};
</script>

<style>
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(69, 69, 69);
  opacity: 1; /* Firefox */
}

input,
select {
  border-style: solid;
  border-width: 2px;

  padding: 0.6rem 0.4rem;
  width: 100%;
  color: rgb(118, 155, 186);
  margin-bottom: 1rem;
  border-color: rgb(118, 155, 186);
  display: block;
}

input[type="radio"] {
  padding: 0;
  margin: 0;
  width: auto;
}

:focus {
  outline: none;
  border-color: rgb(43, 94, 141);
  color: rgb(43, 94, 141);
}

form {
  position: relative;
  width: 80%;
  margin: 0 auto;
}
main {
  align-items: center;
  font-family: Montserrat;
}
#inner {
  color: rgb(69, 69, 69);
  max-width: 374px;
  border-style: solid;
  border-width: 1vh 0 0 0;
  border-color: rgb(43, 94, 141);
  margin: auto;
  width: 100%;
  background: white;
}

button:hover {
  background: none rgba(55, 101, 151);
}
</style>

<style scoped>
button {
  margin: auto;
  cursor: pointer;
  background: none rgba(51, 92, 139);
  border: 2px solid rgba(47, 83, 125);

  text-align: center;
  margin-bottom: 1rem;
  min-height: min-content;
  max-height: 39px;
  height: 6vh;
  max-width: 162px;
  width: 100%;
  color: rgb(255, 255, 255);

  font-family: Montserrat;
  border-radius: 26px;
}
</style>
