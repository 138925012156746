<template>
  <div class="mt-4 w-full mx-auto relative">
    <div class="flex sm:hidden puck-buttons-mobile w-full mb-2 px-2 relative">
      <div @click="setActivePuckPanel($event, 1)">Puck Image</div>
      <div @click="setActivePuckPanel($event, 2)">Puck Information</div>
      <div @click="setActivePuckPanel($event, 3)">Puck Summary</div>
      <div
        v-show="activeWellElem"
        id="3rdbuttonmobile"
        @click="setActivePuckPanel($event, 4)"
      >
        Box Information
      </div>
      <div
        v-show="!activeWellElem"
        style="background: none rgba(52, 91, 140, 0.51)"
      >
        Please Select a Box
      </div>
    </div>

    <div class="flex justify-center mx-auto relative">
      <div
        v-if="$store.state.disableBoxUpdate"
        class="absolute top-0 font-semibold left-0 w-full h-full bg-gray-300 bg-opacity-75 z-10 flex items-center justify-center text-3xl"
      >
        Updating a grid for this box...
      </div>
      <div
        v-show="computePuckMobileShow"
        class="absolute top-0 left-0 pl-4 text-xl font-bold z-20"
        ref="leftpuckserial"
      >
        {{ leftPuckSerial }}
      </div>
      <div class="grid grid-cols-1 sm:grid-cols-2 gap-x-4 w-full mx-4">
        <div>
          <div
            :class="[computePuckMobileShow ? '' : 'hidden']"
            id="puckcontainer"
            class="relative"
          ></div>
          <div ref="leftpuckserialbottom"></div>
        </div>

        <!---startright-->
        <div class="flex-col flex">
          <div class="hidden sm:flex puck-buttons w-full mb-1">
            <div style="display: none" @click="setActivePuckPanel($event, 1)">
              Puck Image
            </div>
            <div @click="setActivePuckPanel($event, 2)">Puck Information</div>
            <div @click="setActivePuckPanel($event, 3)">Puck Summary</div>
            <div
              v-show="activeWellElem"
              id="3rdbutton"
              @click="setActivePuckPanel($event, 4)"
            >
              Box Information
            </div>
            <div
              v-show="!activeWellElem"
              style="background: none rgba(52, 91, 140, 0.51)"
            >
              Please Select a Box
            </div>
          </div>
          <div>
            <!--startrightpanels-->
            <!--PANEL 2 puck summary-->
            <div
              class="flex flex-col bg-white w-full parentmoveup"
              v-show="activePuckPanel == 3"
            >
              <div
                v-for="n in this.puckType == 'Cartridge' ? 7 : 12"
                :key="n + '-uniqueidentifier'"
                class="bg-gray-300 border-gray-600 border relative w-full mb-1 py-1"
              >
                <div class="innermoveup w-full bg-gray-300 flex items-center">
                  <div
                    class="mx-2 flex items-center justify-center bg-sky-900 rounded-full circlesize text-white text-xs text-center"
                  >
                    {{ n }}
                  </div>
                  <div class="boximage"></div>
                  <div
                    class="ml-2 text-sm font-bold"
                    style="min-width: max-content; width: 20%"
                  >
                    {{ leftPuckSerial }}-{{
                      n.toLocaleString("en-US", {
                        minimumIntegerDigits: 2,
                        useGrouping: false,
                      })
                    }}
                    <br />
                    {{
                      puckData?.["well" + n]?.title
                        ? `"${puckData?.["well" + n]?.title}"`
                        : ""
                    }}
                  </div>
                  <div class="flex justify-center flex-1">
                    <div class="grid grid-cols-2 gridtitles w-10/12 gap-1">
                      <input
                        type="text"
                        readonly
                        :value="puckData?.['well' + n]?.gridTopLeft?.name ?? ''"
                      />
                      <input
                        type="text"
                        readonly
                        :value="
                          puckData?.['well' + n]?.gridTopRight?.name ?? ''
                        "
                      />
                      <input
                        type="text"
                        readonly
                        :value="
                          puckData?.['well' + n]?.gridBottomLeft?.name ?? ''
                        "
                      />
                      <input
                        type="text"
                        readonly
                        :value="
                          puckData?.['well' + n]?.gridBottomRight?.name ?? ''
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--PANEL 3-->
            <div v-show="activePuckPanel == 4">
              <div class="useraccessbottomtab rounded" id="boxforreadonly">
                <div
                  style="background: rgb(228, 237, 230)"
                  class="border-gray-500 border rounded py-1 px-3 pb-2"
                >
                  <div
                    v-if="
                      this.activeWellNo &&
                      !puckData['well' + this.activeWellNo]?.readOnly
                    "
                    class="flex justify-between"
                  >
                    <div class="flex">
                      <div
                        class="flex pt-1 pb-3"
                        v-if="puckData['well' + activeWellNo]"
                      >
                        <div class="mr-4 flex-col flex items-center relative">
                          <button
                            class="btn-blue bg-green-800"
                            @click="copyBox($event)"
                          >
                            Copy Box
                          </button>
                          <div
                            class="fadeoutbutton absolute text-xs leading-tight"
                            style="
                              bottom: 0;
                              left: 50%;
                              transform: translate(-50%, 100%);
                            "
                          >
                            Copied
                          </div>
                        </div>
                      </div>

                      <div v-if="getCopiedBox?.value?.customId">
                        <div class="flex pt-1 pb-3">
                          <button
                            class="btn-blue bg-orange-700"
                            @click="pasteBox()"
                          >
                            Paste Box
                          </button>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div
                        v-if="puckData['well' + activeWellNo]"
                        class="flex pt-1 pb-3"
                      >
                        <button
                          class="btn-blue bg-red-800"
                          @click="deleteBox()"
                        >
                          Delete Box
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="flex">
                    <div class="flex-1 pr-3">
                      <div class="font-bold text-sm">
                        {{
                          puckType == "Cartridge" && this.activeWellNo > 2
                            ? "Cartridge"
                            : "Box"
                        }}
                        ID
                      </div>
                      <div>
                        <input
                          class="panel1grid blueborder rounded-lg text-red-600 font-bold"
                          v-model="compBoxId"
                          type="text"
                          @input="
                            saveData(
                              $event,
                              puckData['well' + activeWellNo]?._id,
                              'boxes',
                              'customId',
                              false,
                              {
                                rackIndex: dataChainForMove.rack,
                                puckIndex: activePuckIndex,

                                wellNo: activeWellNo,
                                puckId: puckData._id,
                              }
                            )
                          "
                          @blur="
                            saveData(
                              $event,
                              puckData['well' + activeWellNo]?._id,
                              'boxes',
                              'customId',
                              true,
                              {
                                rackIndex: dataChainForMove.rack,
                                puckIndex: activePuckIndex,

                                wellNo: activeWellNo,
                                puckId: puckData._id,
                              }
                            )
                          "
                        />
                      </div>
                    </div>
                    <div
                      v-show="
                        puckType != 'Cartridge' ||
                        (puckType == 'Cartridge' && this.activeWellNo < 3)
                      "
                      class="panel3box"
                      ref="panel3svgcontainer"
                    ></div>
                  </div>

                  <div v-if="puckType == 'Cartridge' && this.activeWellNo > 2">
                    <div class="font-bold text-sm">Cartridge Type</div>
                    <div>
                      <select
                        v-model="forCartridgeType"
                        class="rounded text-xs w-auto"
                        @change="
                          (ev) => {
                            this.boxType = ev.currentTarget.value;
                            saveData(
                              ev,
                              puckData['well' + activeWellNo]?._id,
                              'boxes',
                              'boxType',
                              true,
                              {
                                rackIndex: dataChainForMove.rack,
                                puckIndex: activePuckIndex,

                                wellNo: activeWellNo,
                                puckId: puckData._id,
                              }
                            );
                          }
                        "
                      >
                        <option value="Standard">Standard</option>
                        <option value="Autogrid">Autogrid</option>
                        <option value="Dual Axis">Dual Axis</option>
                        <option value="Lift Out">Lift Out</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                  </div>
                  <div
                    v-if="puckType !== 'Cartridge'"
                    class="font-bold text-sm"
                  >
                    Box Name
                  </div>
                  <div v-if="puckType !== 'Cartridge'">
                    <input
                      v-model="compBoxTitle"
                      class="panel1grid blueborder rounded-lg text-red-600 font-bold"
                      type="text"
                      @input="
                        () => {
                          saveData(
                            $event,
                            puckData['well' + activeWellNo]?._id,
                            'boxes',
                            'title',
                            false,
                            {
                              rackIndex: dataChainForMove.rack,
                              puckIndex: activePuckIndex,

                              wellNo: activeWellNo,
                              puckId: puckData._id,
                            }
                          );
                          doc.querySelector(
                            '.fowell' + activeWellNo
                          ).innerHTML = compBoxTitle;
                        }
                      "
                      @blur="
                        saveData(
                          $event,
                          puckData['well' + activeWellNo]?._id,
                          'boxes',
                          'title',
                          true,
                          {
                            rackIndex: dataChainForMove.rack,
                            puckIndex: activePuckIndex,

                            wellNo: activeWellNo,
                            puckId: puckData._id,
                          }
                        )
                      "
                    />
                  </div>
                  <div class="font-bold text-sm">
                    {{
                      puckType == "Cartridge" && this.activeWellNo > 2
                        ? "Cartridge"
                        : "Box"
                    }}
                    Description
                  </div>
                  <div>
                    <textarea
                      class="blueborder"
                      rows="4"
                      v-model="compBoxDescription"
                      @input="
                        saveData(
                          $event,
                          puckData['well' + activeWellNo]?._id,
                          'boxes',
                          'description',
                          false,
                          {
                            rackIndex: dataChainForMove.rack,
                            puckIndex: activePuckIndex,

                            wellNo: activeWellNo,
                            puckId: puckData._id,
                          }
                        )
                      "
                      @blur="
                        saveData(
                          $event,
                          puckData['well' + activeWellNo]?._id,
                          'boxes',
                          'description',
                          true,
                          {
                            rackIndex: dataChainForMove.rack,
                            puckIndex: activePuckIndex,

                            wellNo: activeWellNo,
                            puckId: puckData._id,
                          }
                        )
                      "
                    >
                    </textarea>
                  </div>

                  <div class="font-bold text-sm">Project ID</div>

                  <select
                    ref="projectselect"
                    :disabled="$store.state.activeUser.role != 'lab-admin'"
                    @change="
                      saveData(
                        $event,
                        puckData['well' + activeWellNo]?._id,
                        'boxes',
                        'project',
                        true,
                        {
                          rackIndex: dataChainForMove.rack,
                          puckIndex: activePuckIndex,

                          wellNo: activeWellNo,
                          puckId: puckData._id,
                        }
                      )
                    "
                    class="rounded text-xs w-auto"
                  >
                    <option
                      value=""
                      :selected="
                        activeWellNo &&
                        !puckData['well' + activeWellNo]?.project
                      "
                      disabled
                    >
                      Select a project
                    </option>

                    <option
                      :selected="
                        activeWellNo &&
                        puckData['well' + activeWellNo]?.project == project._id
                      "
                      v-for="project in $store.state.projects"
                      :key="'box' + project._id"
                      :value="project._id"
                    >
                      {{ project?.name }}
                    </option>
                  </select>
                  <!-- {{ puckData["well" + activeWellNo]?.project }} -->

                  <div
                    style="background: rgb(228, 237, 230)"
                    class="border-gray-500 mt-2 border rounded py-1 px-3 mb-2"
                    v-if="
                      (puckType != 'Cartridge' ||
                        (puckType == 'Cartridge' && this.activeWellNo < 3)) &&
                      activeWellNo &&
                      !puckData['well' + this.activeWellNo]?.readOnly
                    "
                  >
                    <div class="flex items-center relative">
                      <span class="text-sky-900 font-bold">Box Preferences</span
                      ><input
                        type="checkbox"
                        class="w-4 h-4 mb-0 ml-4 mr-0 cursor-pointer relative"
                        style="top: -1px"
                        ref="editbox"
                        id="editbox"
                        @click="checkIfChecked($event)"
                      /><label for="editbox" class="font-bold cursor-pointer"
                        >Edit</label
                      >
                    </div>
                    <div v-show="editBoxPref" class="relative">
                      <div
                        v-if="!editBoxPref"
                        class="absolute z-10 w-full h-full"
                        style="background: rgba(228, 237, 230, 0.7)"
                      ></div>
                      <div class="flex mt-4 text-sm">
                        <div class="border-r-2 border-gray-800 mr-4 pr-4">
                          <div class="font-bold">1 - Select Box Type:</div>
                          <div class="tailwindsucks mt-1 grid">
                            <div>
                              <input
                                v-model="boxType"
                                @change="notifyBoxNotSaved()"
                                @checked="setBoxType('Unclipped')"
                                value="Unclipped"
                                :checked="boxType == 'Unclipped' || !boxType"
                                type="radio"
                                name="cliptype"
                                id="unclippedbox"
                                class="inline-block"
                              />
                              <label for="unclippedbox"> Unclipped grid</label>
                            </div>
                            <div>
                              <input
                                v-model="boxType"
                                value="Clipped"
                                @change="notifyBoxNotSaved()"
                                @checked="setBoxType('Clipped')"
                                type="radio"
                                :checked="boxType == 'Clipped'"
                                id="clippedbox"
                                name="cliptype"
                                class="inline-block"
                              /><label for="clippedbox"> Clipped grid</label>
                            </div>

                            <div>
                              <input
                                v-model="boxType"
                                @checked="setBoxType('opencup')"
                                @change="notifyBoxNotSaved()"
                                value="opencup"
                                :checked="boxType == 'opencup'"
                                type="radio"
                                name="cliptype"
                                id="opencupbox"
                                class="inline-block"
                              />
                              <label for="opencupbox"> Open cup HPF</label>
                            </div>
                            <!-- <div @click="setBoxType('Empty')">
                          <input
                            v-model="boxType"
                            value="Empty"
                            @change="notifyBoxNotSaved()"
                            :checked="boxType == 'Empty'"
                            type="radio"
                            name="cliptype"
                            id="emptybox"
                            class="inline-block"
                          />
                          <label for="emptybox"> Empty</label>
                        </div> -->
                          </div>
                        </div>

                        <div v-show="boxType != 'Empty'">
                          <div class="text-red-600 font-bold">
                            2 - Select Box Color:
                          </div>
                          <div
                            class="grid grid-cols-4 border-white rounded-2xl mt-2 border bg-gray-300 gap-px overflow-hidden"
                          >
                            <div
                              class="bg-gray-300 flex justify-center px-2 py-1"
                              :class="{
                                'bg-blue-400': boxColorElemNum == n - 1,
                              }"
                              v-for="n in 12"
                              :key="n - 'boxcolor'"
                            >
                              <div
                                :id="'boxcoloricon' + n"
                                :ref="'boxcoloricon' + n"
                                @click="
                                  boxColorSelected($event, n - 1),
                                    notifyBoxNotSaved()
                                "
                                class="rounded-full bg-white flex justify-center items-center cursor-pointer"
                                style="width: 40px; height: 40px"
                              >
                                <svg
                                  v-if="boxType == 'Clipped'"
                                  width="40px"
                                  height="36px"
                                  :ref="'boxcoloriconsvg' + (n - 1)"
                                  :class="'boxChoice-color-' + (n - 1)"
                                  viewBox="0 0 41.1 41.1"
                                  class="clip"
                                >
                                  <g>
                                    <path
                                      class="st0"
                                      d="M25.8,0.7c0,0.3,0.1,0.6,0.1,0.9c0,2.9-2.4,5.3-5.3,5.3s-5.3-2.4-5.3-5.3c0-0.3,0-0.6,0.1-0.9   C6.5,3,0,11,0,20.5C0,31.8,9.2,41,20.5,41S41,31.8,41,20.5C41.1,11,34.6,3,25.8,0.7z"
                                    />
                                    <g>
                                      <circle
                                        class="st1"
                                        cx="12.9"
                                        cy="4.6"
                                        r="1.3"
                                      />
                                      <circle
                                        class="st1"
                                        cx="28.3"
                                        cy="4.6"
                                        r="1.3"
                                      />
                                      <circle
                                        class="st1"
                                        cx="36.7"
                                        cy="20.2"
                                        r="1.3"
                                      />
                                      <circle
                                        class="st1"
                                        cx="4.1"
                                        cy="20.2"
                                        r="1.3"
                                      />
                                      <circle
                                        class="st1"
                                        cx="20.6"
                                        cy="36.4"
                                        r="1.3"
                                      />
                                    </g>
                                    <path
                                      class="st2"
                                      d="M17.6,10.6h-0.7c-0.3,0-0.6,0.3-0.6,0.6v0.3h-2v-1.4c0-0.7-0.5-1.2-1.2-1.2h-3c-0.7,0-1.2,0.6-1.2,1.2v1.4h-2   v-0.3c0-0.3-0.3-0.6-0.6-0.6H5.6c-0.3,0-0.6,0.3-0.6,0.6v2.6c0,0.3,0.3,0.6,0.6,0.6h0.7c0.3,0,0.6-0.3,0.6-0.6v-0.2h2V15   c0,0.7,0.6,1.2,1.2,1.2h3c0.7,0,1.2-0.5,1.2-1.2v-1.4h2v0.2c0,0.3,0.3,0.6,0.6,0.6h0.7c0.3,0,0.6-0.3,0.6-0.6v-2.6   C18.2,10.9,17.9,10.6,17.6,10.6z"
                                    />
                                    <path
                                      class="st2"
                                      d="M35.2,10.6h-0.7c-0.3,0-0.6,0.3-0.6,0.6v0.3h-2v-1.4c0-0.7-0.5-1.2-1.2-1.2h-3c-0.7,0-1.2,0.6-1.2,1.2v1.4h-2   v-0.3c0-0.3-0.3-0.6-0.6-0.6h-0.7c-0.3,0-0.6,0.3-0.6,0.6v2.6c0,0.3,0.3,0.6,0.6,0.6h0.7c0.3,0,0.6-0.3,0.6-0.6v-0.2h2V15   c0,0.7,0.5,1.2,1.2,1.2h3c0.7,0,1.2-0.5,1.2-1.2v-1.4h2v0.2c0,0.3,0.3,0.6,0.6,0.6h0.7c0.3,0,0.6-0.3,0.6-0.6v-2.6   C35.8,10.9,35.5,10.6,35.2,10.6z"
                                    />
                                    <path
                                      class="st2"
                                      d="M17.6,27.6h-0.7c-0.3,0-0.6,0.3-0.6,0.6v0.3h-2v-1.4c0-0.7-0.5-1.2-1.2-1.2h-3c-0.7,0-1.2,0.5-1.2,1.2v1.4h-2   v-0.3c0-0.3-0.3-0.6-0.6-0.6H5.6c-0.3,0-0.6,0.3-0.6,0.6v2.6c0,0.3,0.3,0.6,0.6,0.6h0.7c0.3,0,0.6-0.3,0.6-0.6v-0.2h2V32   c0,0.7,0.6,1.2,1.2,1.2h3c0.7,0,1.2-0.5,1.2-1.2v-1.4h2v0.2c0,0.3,0.3,0.6,0.6,0.6h0.7c0.3,0,0.6-0.3,0.6-0.6v-2.6   C18.2,27.9,17.9,27.6,17.6,27.6z"
                                    />
                                    <path
                                      class="st2"
                                      d="M35.2,27.6h-0.7c-0.3,0-0.6,0.3-0.6,0.6v0.3h-2v-1.4c0-0.7-0.5-1.2-1.2-1.2h-3c-0.7,0-1.2,0.5-1.2,1.2v1.4h-2   v-0.3c0-0.3-0.3-0.6-0.6-0.6h-0.7c-0.3,0-0.6,0.3-0.6,0.6v2.6c0,0.3,0.3,0.6,0.6,0.6h0.7c0.3,0,0.6-0.3,0.6-0.6v-0.2h2V32   c0,0.7,0.5,1.2,1.2,1.2h3c0.7,0,1.2-0.5,1.2-1.2v-1.4h2v0.2c0,0.3,0.3,0.6,0.6,0.6h0.7c0.3,0,0.6-0.3,0.6-0.6v-2.6   C35.8,27.9,35.5,27.6,35.2,27.6z"
                                    />
                                    <g class="showOnBigWell hidden">
                                      <ellipse
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="stblack"
                                        cx="11.7"
                                        cy="12.5"
                                        rx="6.4"
                                        ry="1.1"
                                      />
                                      <ellipse
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="stblack"
                                        cx="29.2"
                                        cy="12.5"
                                        rx="6.4"
                                        ry="1.1"
                                      />
                                      <ellipse
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="stblack"
                                        cx="11.7"
                                        cy="29.7"
                                        rx="6.4"
                                        ry="1.1"
                                      />
                                      <ellipse
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="stblack"
                                        cx="29.2"
                                        cy="29.7"
                                        rx="6.4"
                                        ry="1.1"
                                      />
                                    </g>
                                    <circle
                                      class="st1"
                                      cx="20.5"
                                      cy="20.5"
                                      r="4.3"
                                    />
                                    <circle
                                      class="st3"
                                      cx="20.5"
                                      cy="20.5"
                                      r="19.5"
                                    />
                                  </g>
                                </svg>
                                <svg
                                  v-if="
                                    boxType == 'Unclipped' ||
                                    boxType == 'opencup'
                                  "
                                  width="40px"
                                  height="36px"
                                  :class="'boxChoice-color-' + (n - 1)"
                                  viewBox="0 0 41.1 41.1"
                                  class="unclip"
                                >
                                  <g>
                                    <path
                                      class="st0"
                                      d="M25.8,0.7c0,0.3,0.1,0.6,0.1,0.9c0,2.9-2.4,5.3-5.3,5.3s-5.3-2.4-5.3-5.3c0-0.3,0-0.6,0.1-0.9
		C6.5,3,0,11,0,20.5c0,11.3,9.2,20.5,20.5,20.5s20.5-9.2,20.5-20.5C41.1,11,34.6,3,25.8,0.7z"
                                    />

                                    <circle
                                      v-if="n == 2"
                                      :class="'graycircle-' + (n - 1)"
                                      cx="20.5"
                                      cy="20.5"
                                      r="20.5"
                                    />

                                    <path
                                      class="st1"
                                      d="M25.8,0.7c0,0.3,0.1,0.6,0.1,0.9c0,2.9-2.4,5.3-5.3,5.3s-5.3-2.4-5.3-5.3c0-0.3,0-0.6,0.1-0.9   C6.5,3,0,11,0,20.5C0,31.8,9.2,41,20.5,41S41,31.8,41,20.5C41.1,11,34.6,3,25.8,0.7z"
                                    ></path>
                                    <polygon
                                      class="st2"
                                      points="7.8,7.5 7.7,14.8 14.6,17.6 14.6,10.3 	"
                                    />
                                    <polygon
                                      class="st2"
                                      points="26.1,7.5 26.1,15 33,17.8 33,10.3 	"
                                    />
                                    <polygon
                                      class="st2"
                                      points="7.7,23.7 7.5,31.2 14.4,34 14.6,26.5 	"
                                    />
                                    <polygon
                                      class="st2"
                                      points="27,23.6 27,31.1 33.9,33.7 34.1,26.4 	"
                                    />

                                    <circle
                                      class="st2"
                                      cx="20.5"
                                      cy="20.5"
                                      r="3.9"
                                    />

                                    <path
                                      class="st3"
                                      d="M20.5,16.3c-2.4,0-4.2,1.9-4.2,4.2s1.9,4.2,4.2,4.2s4.2-1.9,4.2-4.2S22.9,16.3,20.5,16.3z M20.9,21V24h-0.8V21
		h-3v-0.8h3v-3.1h0.8v3.1h3V21H20.9z"
                                    />
                                    <g class="showOnBigWell hidden">
                                      <ellipse
                                        xmlns="http://www.w3.org/2000/svg"
                                        transform="matrix(0.8204 -0.5718 0.5718 0.8204 -5.1713 8.6935)"
                                        class="stblack"
                                        cx="11.3"
                                        cy="12.6"
                                        rx="1.4"
                                        ry="5.1"
                                      />
                                      <ellipse
                                        xmlns="http://www.w3.org/2000/svg"
                                        transform="matrix(0.8204 -0.5718 0.5718 0.8204 -1.88 19.1681)"
                                        class="stblack"
                                        cx="29.6"
                                        cy="12.6"
                                        rx="1.4"
                                        ry="5.1"
                                      />
                                      <ellipse
                                        xmlns="http://www.w3.org/2000/svg"
                                        transform="matrix(0.8287 -0.5597 0.5597 0.8287 -10.9052 22.0804)"
                                        class="stblack"
                                        cx="30.6"
                                        cy="28.9"
                                        rx="1.4"
                                        ry="5.1"
                                      />
                                      <ellipse
                                        xmlns="http://www.w3.org/2000/svg"
                                        transform="matrix(0.8287 -0.5597 0.5597 0.8287 -14.2433 11.1645)"
                                        class="stblack"
                                        cx="11.1"
                                        cy="28.9"
                                        rx="1.4"
                                        ry="5.1"
                                      />
                                    </g>
                                    <radialGradient
                                      id="SVGID_PLUSSIGN"
                                      cx="22.1032"
                                      cy="1511.963"
                                      r="4.8437"
                                      gradientTransform="matrix(1 0 0 1 0 -1492.9199)"
                                      gradientUnits="userSpaceOnUse"
                                    >
                                      <stop
                                        offset="0"
                                        style="stop-color: #ececec"
                                      />
                                      <stop
                                        offset="0.3528"
                                        style="stop-color: #bebebe"
                                      />
                                      <stop
                                        offset="1"
                                        style="stop-color: #626060"
                                      />
                                    </radialGradient>
                                  </g>
                                </svg>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="w-full border-gray-800 border-t-2 my-3"></div>

                      <div
                        :class="[
                          boxType == 'opencup' ? 'invisible' : 'visible',
                        ]"
                        class="flex pb-2 justify-between"
                      >
                        <div class="text-sm">
                          <div class="font-bold">3 - Grid Numbering:</div>
                          <div
                            class="tailwindsucks mt-1 grid grid-cols-2 gap-x-4"
                          >
                            <div>
                              <input
                                type="radio"
                                v-model="gridNumbering"
                                value="1"
                                :checked="gridNumbering == 1 || !gridNumbering"
                                id="setting1"
                                name="settingtype"
                                @change="notifyBoxNotSaved()"
                                class="inline-block"
                              /><label for="setting1"> Setting 1</label>
                            </div>

                            <div>
                              <input
                                v-model="gridNumbering"
                                value="4"
                                :checked="gridNumbering == 4"
                                type="radio"
                                name="settingtype"
                                id="setting4"
                                class="inline-block"
                                @change="notifyBoxNotSaved()"
                              />
                              <label for="setting4"> Setting 4</label>
                            </div>
                            <div>
                              <input
                                v-model="gridNumbering"
                                value="2"
                                :checked="gridNumbering == 2"
                                type="radio"
                                name="settingtype"
                                id="setting2"
                                class="inline-block"
                                @change="notifyBoxNotSaved()"
                              />
                              <label for="setting2"> Setting 2</label>
                            </div>
                            <div>
                              <input
                                v-model="gridNumbering"
                                value="5"
                                :checked="gridNumbering == 5"
                                type="radio"
                                name="settingtype"
                                id="setting5"
                                class="inline-block"
                                @change="notifyBoxNotSaved()"
                              />
                              <label for="setting5"> Setting 5</label>
                            </div>

                            <div>
                              <input
                                v-model="gridNumbering"
                                value="3"
                                :checked="gridNumbering == 3"
                                type="radio"
                                name="settingtype"
                                id="setting3"
                                class="inline-block"
                                @change="notifyBoxNotSaved()"
                              />
                              <label for="setting3"> Setting 3</label>
                            </div>
                            <div>
                              <input
                                v-model="gridNumbering"
                                value="6"
                                :checked="gridNumbering == 6"
                                type="radio"
                                name="settingtype"
                                id="setting6"
                                class="inline-block"
                                @change="notifyBoxNotSaved()"
                              />
                              <label for="setting6"> Setting 6</label>
                            </div>
                          </div>
                        </div>
                        <div class="flex items-stretch">
                          <div class="flex-col flex justify-between">
                            <div class="setting-number">{{ boxOrder[0] }}</div>
                            <div class="setting-number">{{ boxOrder[1] }}</div>
                          </div>
                          <div class="h-full w-full flex items-center">
                            <div
                              class="border-gray-500 border my-2 bg-white rounded-full relative"
                              style="height: 93px; width: 93px"
                            >
                              <div v-show="boxColorElemNum === null">
                                <div v-if="boxType == 'Clipped'">
                                  <svg
                                    viewBox="-2 -2 46 46"
                                    width="93"
                                    height="93"
                                  >
                                    <g>
                                      <ellipse
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="stblack"
                                        cx="11.7"
                                        cy="12.5"
                                        rx="6.4"
                                        ry="1.1"
                                      ></ellipse>
                                      <ellipse
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="stblack"
                                        cx="29.2"
                                        cy="12.5"
                                        rx="6.4"
                                        ry="1.1"
                                      ></ellipse>
                                      <ellipse
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="stblack"
                                        cx="11.7"
                                        cy="29.7"
                                        rx="6.4"
                                        ry="1.1"
                                      ></ellipse>
                                      <ellipse
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="stblack"
                                        cx="29.2"
                                        cy="29.7"
                                        rx="6.4"
                                        ry="1.1"
                                      ></ellipse>
                                    </g>
                                  </svg>
                                </div>
                                <div v-else-if="boxType == 'Unclipped'">
                                  <svg
                                    viewBox="-2 -2 46 46"
                                    width="93"
                                    height="93"
                                  >
                                    <g>
                                      <ellipse
                                        xmlns="http://www.w3.org/2000/svg"
                                        transform="matrix(0.8204 -0.5718 0.5718 0.8204 -5.1713 8.6935)"
                                        class="stblack"
                                        cx="11.3"
                                        cy="12.6"
                                        rx="1.4"
                                        ry="5.1"
                                      ></ellipse>
                                      <ellipse
                                        xmlns="http://www.w3.org/2000/svg"
                                        transform="matrix(0.8204 -0.5718 0.5718 0.8204 -1.88 19.1681)"
                                        class="stblack"
                                        cx="29.6"
                                        cy="12.6"
                                        rx="1.4"
                                        ry="5.1"
                                      ></ellipse>
                                      <ellipse
                                        xmlns="http://www.w3.org/2000/svg"
                                        transform="matrix(0.8287 -0.5597 0.5597 0.8287 -10.9052 22.0804)"
                                        class="stblack"
                                        cx="30.6"
                                        cy="28.9"
                                        rx="1.4"
                                        ry="5.1"
                                      ></ellipse>
                                      <ellipse
                                        xmlns="http://www.w3.org/2000/svg"
                                        transform="matrix(0.8287 -0.5597 0.5597 0.8287 -14.2433 11.1645)"
                                        class="stblack"
                                        cx="11.1"
                                        cy="28.9"
                                        rx="1.4"
                                        ry="5.1"
                                      ></ellipse>
                                    </g>
                                  </svg>
                                </div>
                              </div>
                              <div
                                v-show="boxColorElemNum !== null"
                                id="dynamicbox"
                              ></div>
                            </div>
                          </div>
                          <div class="flex-col flex justify-between">
                            <div class="setting-number">
                              {{ boxOrder[2] }}
                            </div>
                            <div class="setting-number">{{ boxOrder[3] }}</div>
                          </div>
                        </div>

                        <div
                          class="inline-grid grid-cols-1 justify-end items-end"
                          style="
                            grid-template-rows: 1fr max-content;
                            grid-template-columns: min-content;
                          "
                        >
                          <div
                            v-if="boxColor === null"
                            class="text-xs text-red-600 font-bold min-w-0 inline-block text-center p-1"
                          >
                            Please select a box color
                          </div>
                          <!-- <div class="text-center">
                        <button
                          @click="saveBoxSettings()"
                          class="text-sm inline-block"
                          :class="{
                            buttondisabled: !boxColor,
                            'grayscale-btn': !boxColor,
                            'btn-blue': boxColor,
                          }"
                          :disabled="!boxColor"
                          style="width: max-content"
                        >
                          Save Settings
                        </button>
                      </div> -->
                        </div>
                      </div>
                      <div
                        v-show="boxType == 'Empty'"
                        class="flex justify-end pb-2"
                      >
                        <button
                          @click="setBoxType('Empty')"
                          class="btn-blue text-sm"
                        >
                          Remove Box
                        </button>
                      </div>
                      <div
                        class="justify-end items-end flex-col flex flex-grow flex-shrink-0"
                        v-if="this.puckData?.['well' + this.activeWellNo]?._id"
                      >
                        <div class="fadeoutbutton text-xs">Box Saved</div>
                        <button
                          id="saveBoxChanges"
                          ref="saveBoxChanges"
                          class="btn-blue inline-block py-2 px-4 relative"
                          @click="
                            $store.state.buttonFXinit($event),
                              saveBoxData($event)
                          "
                        >
                          <button-inner>Save Box Preferences</button-inner>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div class="flex items-end">
                    <div class="flex items-end flex-shrink flex-grow-0">
                      <div class="text-xs mr-4" style="flex: 1 0 0">
                        <div class="text-xs">Date Created:</div>
                        <div class="inline-block">
                          <!-- wrap it because its a block element widthfull -->
                          <DatePicker
                            class="flex"
                            timezone="utc"
                            v-model="boxDatePicker"
                          >
                            <template v-slot="{ inputValue, inputEvents }">
                              <input
                                class="bg-white border px-2 py-1 rounded"
                                style="margin: 0"
                                :value="inputValue"
                                v-on="!puckData.readOnly ? inputEvents : null"
                              />
                            </template>
                          </DatePicker>
                        </div>
                      </div>
                      <div
                        v-if="this.puckData?.['well' + this.activeWellNo]?._id"
                        class="text-xs flex-grow flex-shrink-0"
                      >
                        Last modified:<br />
                        {{
                          activeWellNo && puckData["well" + activeWellNo]
                            ? cleanDate(
                                puckData["well" + activeWellNo].dateModified
                              )
                            : ""
                        }}
                      </div>
                    </div>

                    <div
                      class="justify-end items-end flex-col flex flex-grow flex-shrink-0"
                      v-if="
                        !puckData.readOnly &&
                        !this.puckData?.['well' + this.activeWellNo]?._id
                      "
                    >
                      <div class="fadeoutbutton text-xs">Box Saved</div>
                      <button
                        id="saveCreateBoxChanges"
                        ref="saveCreateBoxChanges"
                        class="btn-blue inline-block py-2 px-4 relative"
                        @click="
                          $store.state.buttonFXinit($event), saveBoxData($event)
                        "
                      >
                        <button-inner>Create Box</button-inner>
                      </button>
                    </div>
                  </div>
                </div>

                <custom-user-access
                  v-show="puckData['well' + activeWellNo]?._id"
                  container="box"
                  :containerobj="puckData['well' + activeWellNo]"
                  :index="index"
                  :compBoxId="compBoxId"
                  :boxPuckLabId="puckData.labId._id"
                ></custom-user-access>
              </div>

              <div
                style="background: rgb(228, 237, 230)"
                class="border-gray-500 mt-2 border rounded py-1 px-3"
                v-if="
                  !puckData.readOnly &&
                  puckData['well' + activeWellNo] &&
                  puckType != 'Cartridge'
                "
              >
                <move-box-or-grid
                  @moveboxstart="
                    () => {
                      boxmoveinprogress = true;
                    }
                  "
                  @cancelmoveboxstart="
                    () => {
                      boxmoveinprogress = false;
                    }
                  "
                  :data-chain-for-move="dataChainForMove"
                  :puck-data="puckData"
                  :active-puck-index="activePuckIndex"
                  :active-well-no="activeWellNo"
                />
              </div>
            </div>

            <!--PANEL 1-->
            <div
              v-show="
                activePuckPanel == 2 ||
                (activePuckPanel == 1 && computeWindowWidth)
              "
              :class="{
                useraccessbottomtab:
                  $store.state.activeUser.role == 'lab-admin',
                rounded: $store.state.activeUser.role == 'lab-admin',
              }"
            >
              <div
                class="flex-1 rounded p-2 text-xs font-bold"
                style="background-color: #e2e2e2"
              >
                <div
                  class="grid grid-cols-2 gap-x-4 panel1grid"
                  id="pucksectionforreadonly"
                >
                  <div>Title</div>
                  <div>Puck ID</div>

                  <input
                    :value="puckTitle"
                    @input="
                      saveData($event, puckData._id, 'pucks', 'title', false, {
                        rackIndex: dataChainForMove.rack,
                        puckIndex: activePuckIndex,
                        rackid: puckData.rackId,
                      })
                    "
                    @blur="
                      saveData($event, puckData._id, 'pucks', 'title', true, {
                        rackIndex: dataChainForMove.rack,
                        puckIndex: activePuckIndex,
                        rackid: puckData.rackId,
                      })
                    "
                    type="text"
                    ref="puckTitle"
                    class="mb-2 blueborder"
                    placeholder="Type here..."
                  />
                  <div
                    v-if="!showEditPuckSerial"
                    class="flex items-center w-full puckiddiv text-white rounded pl-2 text-base font-light mb-2"
                    @dblclick="this.showEditPuckSerial = true"
                  >
                    {{ puckData?.serialNo }}
                  </div>
                  <div v-else>
                    <input
                      @blur="
                        async ($event) => {
                          const serialno = await saveData(
                            $event,
                            puckData._id,
                            'pucks',
                            'serialNo',
                            true,
                            {
                              rackIndex: dataChainForMove.rack,
                              puckIndex: activePuckIndex,
                              rackid: puckData.rackId,
                            }
                          );
                          con.log('serialno executed', serialno);
                          this.showEditPuckSerial = false;
                          this.$emit(
                            'choose-new-puck-on-serialno-change',
                            serialno
                          );
                        }
                      "
                      :value="puckData?.serialNo"
                    />
                  </div>
                  <div>Description</div>

                  <div class="">Project ID</div>

                  <textarea
                    @input="
                      saveData(
                        $event,
                        puckData._id,
                        'pucks',
                        'description',
                        false,
                        {
                          rackIndex: dataChainForMove.rack,
                          puckIndex: activePuckIndex,
                          rackid: this.puckData.rackId,
                        }
                      )
                    "
                    @blur="
                      saveData(
                        $event,
                        puckData._id,
                        'pucks',
                        'description',
                        true,
                        {
                          rackIndex: dataChainForMove.rack,
                          puckIndex: activePuckIndex,
                          rackid: this.puckData.rackId,
                        }
                      )
                    "
                    ref="puckDescription"
                    :value="puckDescription"
                    rows="4"
                    class="blueborder"
                    placeholder="Type here..."
                  ></textarea>

                  <select
                    ref="projectselect"
                    v-model="selectedPuckProject"
                    :disabled="$store.state.activeUser.role != 'lab-admin'"
                    @change="
                      saveData($event, puckData._id, 'pucks', 'project', true, {
                        rackIndex: dataChainForMove.rack,
                        puckIndex: activePuckIndex,
                        rackid: puckData.rackId,
                      })
                    "
                    class="rounded w-auto h-[max-content]"
                  >
                    <option value="" disabled>Select a project</option>

                    <option
                      v-for="project in $store.state.projects"
                      :key="project._id"
                      :value="project._id"
                    >
                      {{ project?.name }}
                    </option>
                  </select>
                  <div class="mt-2 text-xs">
                    Puck Color
                    <div>
                      <div class="custom-select text-sm">
                        <div
                          class="selected-option relative text-sm"
                          @click="() => (showDropdown = !showDropdown)"
                          :style="{
                            backgroundColor:
                              $store.state.transformPuckColor(puckColor),
                            color: puckColor === 'black' ? 'white' : 'black',
                          }"
                        >
                          {{ puckColor }}
                          <div class="invisible block w-full h-0 -z-10 px-1">
                            sky blue
                          </div>
                        </div>
                        <div class="options" v-if="showDropdown">
                          <div
                            v-for="color in $store.state.puckColors"
                            :key="color"
                            class="dropdown-item p-1"
                            :style="{
                              backgroundColor:
                                $store.state.transformPuckColor(color),
                              color: color === 'black' ? 'white' : 'black',
                            }"
                            @click="
                              async () => {
                                const confirm = win.confirm(
                                  'Are you sure you want to change the puck\'s color?'
                                );
                                if (!confirm) return;
                                await saveData(
                                  { currentTarget: { value: color } },
                                  puckData._id,
                                  'pucks',
                                  'color',
                                  true,
                                  {
                                    rackIndex: dataChainForMove.rack,
                                    puckIndex: activePuckIndex,
                                    rackid: puckData.rackId,
                                  }
                                );
                                showDropdown = false;
                                win.setTimeout(() => {
                                  this.loadPuck();
                                  // this.highlightWellCircle(null, true);
                                  // this.$emit('reset-puck-change');
                                }, 1000);
                              }
                            "
                          >
                            {{ color }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div></div>
                  <div></div>
                  <div
                    class="flex justify-between mt-2 font-light text-base items-cente h-0"
                    ref="cryoButtonContainer"
                  >
                    <div class="invisible">
                      <div class="flex cursor-pointer leading-none">
                        <input
                          class="cursor-pointer"
                          type="radio"
                          :checked="puckType == 'HPF'"
                          name="cryotype"
                          value="HPF"
                          id="HPFbutton"
                        /><label class="cursor-pointer" for="HPFbutton">
                          HPF</label
                        >
                      </div>
                      <div class="flex cursor-pointer leading-none">
                        <input
                          type="radio"
                          :checked="puckType == 'Cryo-EM'"
                          name="cryotype"
                          id="Cryobutton"
                          value="Cryo-EM"
                          class="cursor-pointer"
                        /><label class="cursor-pointer" for="Cryobutton">
                          Cryo-EM</label
                        >
                      </div>
                    </div>
                    <!-- <button
                      @click="savePuckData()"
                      class="btn-blue"
                      v-if="!puckData.readOnly"
                    >
                      Save
                    </button>
                    <button class="btn-blue invisible" v-else>Save</button> -->
                  </div>
                  <div></div>
                  <div
                    class="font-light supersmall justify-end w-full flex items-end tracking-tight"
                  >
                    Date modified: {{ cleanDate(puckData?.dateModified, true) }}
                  </div>
                </div>
              </div>
              <custom-user-access
                container="puck"
                :containerobj="puckData"
                :index="index"
              ></custom-user-access>
              <!-- <div
              v-if="
                $store.state.activeUser.role == 'lab-admin' &&
                  puckData.labId._id == $store.state.activeUser.labId._id
              "
              class="pl-2 pt-1 pb-1 bg-gray-300 rounded-b-xl"
            >
              User Access to Puck
              <span class="font-semibold"
                >{{ puckData?.customId ?? puckData?.serialNo ?? "" }}
              </span>
              <div class="flex items-start leading-none mb-1">
                <input
                  type="radio"
                  :name="'labChoiceRack'"
                  :id="'mylabrack'"
                  @change="mylab = !mylab"
                  checked
                />
                <label :for="'mylabrack'" class="mr-2">My Lab</label>
                <input
                  type="radio"
                  @change="mylab = !mylab"
                  :name="'labChoiceRack'"
                  :id="'externalLabRack'"
                />
                <label :for="'externalLabRack'">External Lab</label>
              </div>
              <select
                v-if="!mylab"
                name=""
                :id="'selectcustomaccesspuck'"
                class="w-auto mb-1"
              >
                <option value="" disabled selected>Select User Email</option>
                <option
                  :value="user._id"
                  v-for="user in $store.getters.nonTempUsersNoAdmin"
                  :key="user._id"
                >
                  {{ user.email }}
                </option>
              </select>

              <input
                v-else
                class="w-auto mb-1 inputmatchselect"
                type="email"
                v-model="mylabemail"
                placeholder="Enter User Email"
              />
              <div class="flex items-center">
                <input
                  class="m-0"
                  type="radio"
                  :name="'puckCustom'"
                  :id="'puckReadWrite'"
                  checked
                  value="readwrite"
                /><label
                  :for="'puckReadWrite'"
                  class="text-sm leading-none mr-2"
                  >Read and Write</label
                >
                <input
                  type="radio"
                  :id="'puckReadOnly'"
                  :name="'puckCustom'"
                  value="readonly"
                /><label :for="'puckReadOnly'" class="text-sm leading-none"
                  >Read Only</label
                >
                <button
                  v-if="!mylab"
                  class="
                    my-0
                    ml-4
                    h-full
                    bg-green-700
                    border-green-900 border-0
                    rounded
                    px-2
                    relative
                  "
                  @click="
                    $store.state.buttonFXinit($event),
                      $store.state.customAccessFnc(
                        'puck',
                        '',
                        puckData._id,
                        $event
                      )
                  "
                >
                  <button-inner small="true">Add</button-inner>
                </button>
                <button
                  v-else
                  class="
                    my-0
                    ml-4
                    h-full
                    bg-green-700
                    border-green-900 border-0
                    rounded
                    px-2
                    relative
                  "
                  @click="
                    $store.state.buttonFXinit($event),
                      $store.state.customAccessFnc(
                        'puck',
                        '',
                        puckData._id,
                        $event,
                        mylabemail
                      )
                  "
                >
                  <button-inner small="true"> Add </button-inner>
                </button>
                <div class="pl-4 fadeoutbutton">Added!</div>
              </div>
            </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="relative"
      v-if="
        showGridPanel && puckData['well' + activeWellNo] && boxType != 'opencup'
      "
    >
      <header-bar-grid
        ><div class="w-1/2">Grid Data - Well {{ activeWellNo }}</div>
        <div>Box ID: {{ compBoxId }}</div></header-bar-grid
      >

      <div ref="coolgrids" id="gridforreadonly">
        <grid-section
          :data-chain-for-move="dataChainForMove"
          :puck-data="puckData"
          :puck-index="activePuckIndex"
          :check-read-only="checkReadOnly"
          :puck-id="puckData._id"
          :well-no="activeWellNo"
          :clean-date="cleanDate"
          :box-setting="gridNumbering"
          :box-type="boxType"
          :well-data="puckData['well' + activeWellNo]"
          :box-image="$refs.panel3svgcontainer"
          @grid-removed-or-added="highlightWellCircle(ev, true)"
        ></grid-section>
      </div>
    </div>
  </div>
  <div v-if="welloccupiedalert">
    <DisplayModal @ridfeedback="welloccupiedalert = false">
      <div class="bg-white rounded px-8 py-4">
        Well already occupied. Reverting move.
        <div class="block">
          <button
            class="mt-4 bg-sky-900 text-white px-4 py-2 rounded"
            @click="welloccupiedalert = false"
          >
            OK
          </button>
        </div>
      </div>
    </DisplayModal>
  </div>
  <div v-if="boxmoveinprogress">
    <DisplayModal>
      <div class="bg-white rounded px-8 py-4">
        Box move in progress. One moment.
      </div>
    </DisplayModal>
  </div>
</template>
<script>
import { DatePicker } from "v-calendar";
import GridSection from "./GridSection.vue";
import HeaderBarGrid from "./HeaderBarGrid.vue";
import MoveBoxOrGrid from "./MoveBoxOrGrid.vue";
import CustomUserAccess from "./CustomUserAccess.vue";
import DisplayModal from "../../../ui/DisplayModal.vue";

export default {
  components: {
    GridSection,
    HeaderBarGrid,
    MoveBoxOrGrid,
    DisplayModal,
    DatePicker,
    CustomUserAccess,
  },

  props: [
    "activePuckIndex",
    "activePuck",
    "puckData",
    "puckDataChange",
    "resetPuckSelected",
    "autoclickpuck",
    "dataChainForMove",
  ],
  inject: [
    "aboxmoveinprogressfrommp",
    "resetaboxmoveinprogressfrommp",
    "endPuckNavigate",
    "setCopiedBox",
    "getCopiedBox",
    "resetUnsavedData",
    "startAutoNavigate",
    "topRowHeight",
    "saveData",
    "activeDewarLabIndex",
  ],

  watch: {
    movetoforfollowcomputed(newValue) {
      if (newValue == null) {
        this.resetaboxmoveinprogressfrommp();
      }
    },

    aboxmoveinprogressfrommp: {
      handler(newValue) {
        if (newValue) {
          this.boxmoveinprogress = false;
        }
      },
      deep: true,
    },
    gridChangedUpdatePuckVisual(newValue) {
      if (newValue) {
        console.log("grid changed value is ", newValue);
        setTimeout(() => {
          this.loadPuck();
          this.highlightWellCircle(null, true, true);
          this.$store.state.gridChangedPuckVisualTrigger = false;
          this.$store.state.disableBoxUpdate = false;
        }, 100);
      }
    },
    boxDatePicker: {
      handler(newValue, oldValue) {
        // Note: `newValue` will be equal to `oldValue` here
        // on nested mutations as long as the object itself
        // hasn't been replaced.
        const a = new Date(oldValue).toLocaleDateString();
        const b = new Date(newValue).toLocaleDateString();
        if (
          oldValue != null &&
          newValue != null &&
          a.toString() != b.toString()
        ) {
          console.log(oldValue, newValue, a.toString(), b.toString());
          this.saveData(
            null,
            this.puckData["well" + this.activeWellNo]?._id,
            "boxes",
            "trueDateCreated",
            true,
            {
              rackIndex: this.dataChainForMove.rack,
              puckIndex: this.activePuckIndex,

              wellNo: this.activeWellNo,
              puckId: this.puckData._id,
            },
            newValue
          );
        }
      },
      deep: true,
    },
    autoclickpuck(oldval, newval) {
      console.log("autoclickpuck changed", this.autoclickpuck, oldval, newval);
      if (newval) {
        const sequence = async () => {
          await window.scrollIntoView(this.$refs.leftpuckserialbottom, {
            behavior: "smooth",
            scrollMode: "if-needed",
          });
          console.log("SCROLL ME DADDIo");
          console.log("GOT HERE OUTSIDe");

          if (this.$store.state.moveToForFollow) {
            console.log("should be this");
            this.highlightWellCircle(null, true);
            setTimeout(() => {
              if (this.dataChainForMove.showgrids) {
                console.log("showgrids real");
                if (this.$refs.coolgrids) {
                  console.log("so iscoolgridsreal");
                  window.scrollIntoView(this.$refs.coolgrids, {
                    behavior: "smooth",
                    scrollMode: "if-needed",
                  });
                  console.log("SCROLL ME DADDIo");
                  console.log("GOT HERE OUTSIDe");
                }
              }
            }, 500);

            this.$store.state.moveToForFollow = null;
          } else
            console.log(
              "didnt open the grids",
              this.dataChainForMove,
              this.$store.state.moveToForFollow
            );

          this.$emit("resetautonavigate");
          this.endPuckNavigate();
        };

        sequence();
      } else {
        this.$emit("resetautonavigate");
        this.endPuckNavigate();
      }
    },
    activePuck() {
      this.loadPuck();
    },
    resetPuckSelected() {
      console.log("yes it reset");
      console.log("cryo typ eis:", this.puckType);
      if (this.resetPuckSelected) {
        this.activePuckPanel = this.activePuckPanelDefaultByWindowSize();
        this.showGridPanel = false;
        this.activeWellElem = null;

        if (this.activePuckPanelDefaultByWindowSize() == 1) {
          document.querySelector(
            ".puck-buttons-mobile"
          ).childNodes[0].style.backgroundColor = "rgb(7, 130, 35)";
          document.querySelector(
            ".puck-buttons-mobile"
          ).childNodes[1].style.backgroundColor = "rgb(43, 94, 141)";
          document.querySelector(
            ".puck-buttons-mobile"
          ).childNodes[2].style.backgroundColor = "rgb(43, 94, 141)";
          document.querySelector(
            ".puck-buttons-mobile"
          ).childNodes[3].style.backgroundColor = "rgb(43, 94, 141)";
        } else {
          document.querySelector(
            ".puck-buttons"
          ).childNodes[1].style.backgroundColor = "rgb(7, 130, 35)";
          document.querySelector(
            ".puck-buttons"
          ).childNodes[2].style.backgroundColor = "rgb(43, 94, 141)";
          document.querySelector(
            ".puck-buttons"
          ).childNodes[3].style.backgroundColor = "rgb(43, 94, 141)";
        }

        this.$emit("new-puck-selected");
      }
    },
    puckDataChange() {
      //this runs when the data from the prop
      if (this.puckDataChange) {
        console.log("1010101puckdata changeed!!!!");
        this.loadPuck();
        this.editBoxPref = false;
        this.highlightWellCircle(null, true);
        this.$emit("reset-puck-change");
      }
    },
  },
  data() {
    return {
      showDropdown: false,
      showEditPuckSerial: false,
      timeoutboxnamechange: null,
      selectedPuckProject: "",
      boxSvgDragTarget: null,
      welloccupiedalert: false,
      boxmoveinprogress: false,
      boxSvgClickStartTime: null,
      boxSvgClickStartEnd: null,
      boxSvgTrueCoords: null,
      SVGRoot: null,
      boxSvgGrabPoint: null,
      transformBoxColor: this.$store.state.transformBoxColor,
      boxColors: this.$store.state.boxColors,
      cleanDate: this.$store.state.cleanDate,
      highlightedev: null,
      activeBoxReadOnly: true,
      boxColorElemNum: null,
      editBoxPref: false,
      boxDatePicker: null,
      boxType: "Unclipped",
      boxColor: null,
      mylab: false,
      mylabbox: false,
      mylabemail: "",
      mylabemailbox: "",
      leftPuckSerial: "",
      showGridPanel: false,
      activeWellElem: null,

      activePuckPanel: this.activePuckPanelDefaultByWindowSize(),
      panel3box: null,
      activeWellNo: null,
      compBoxTitle: null,
      compBoxId: null,
      compBoxDescription: null,
      compBoxProject: null,
      // moveDewars: null,
      // moveRacks: null,
      // movePucks: null,
      // movePucksColor: null,
      // moveWells: null,
      gridNumbering: null,
    };
  },
  computed: {
    forCartridgeType: {
      get() {
        return this.boxType && this.boxType != "Unclipped"
          ? this.boxType
          : "Standard";
      },
      set(value) {
        this.boxType = value;
      },
    },
    movetoforfollowcomputed() {
      return this.$store.state.moveToForFollow;
    },
    gridChangedUpdatePuckVisual() {
      return this.$store.state.gridChangedPuckVisualTrigger;
    },
    computeWindowWidth() {
      return window.innerWidth >= 640;
    },
    computePuckMobileShow() {
      console.log("adsgasdg");
      console.log(
        this.activePuckPanelDefaultByWindowSize(),
        this.activePuckPanel
      );
      return (
        (this.activePuckPanelDefaultByWindowSize() == 1 &&
          this.activePuckPanel == 1) ||
        this.activePuckPanelDefaultByWindowSize() == 2
      );
    },
    puckTitle() {
      return this.puckData?.title ?? "";
    },
    puckType() {
      return this.puckData?.puckType ?? "HPF";
    },
    currentPuckType() {
      return this.$refs.cryoButtonContainer.querySelector(
        "input[name=cryotype]:checked"
      ).value;
    },

    puckDescription() {
      return this.puckData?.description ?? "";
    },
    puckColor() {
      return this.puckData?.color ?? "black";
    },

    boxOrder() {
      if (this.gridNumbering == 2) return [1, 4, 2, 3];
      else if (this.gridNumbering == 3) return [4, 3, 1, 2];
      else if (this.gridNumbering == 4) return [4, 1, 3, 2];
      else if (this.gridNumbering == 5) return [1, 2, 4, 3];
      else if (this.gridNumbering == 6) return [1, 2, 3, 4];
      else return [1, 3, 2, 4];
    },

    computedWellNo() {
      return (
        this.leftPuckSerial +
        "-" +
        parseInt(this.activeWellNo).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false,
        })
      );
    },
  },
  methods: {
    updateStartDate() {
      console.log("dgasdg");
    },
    activePuckPanelDefaultByWindowSize() {
      if (window.innerWidth < 640) return 1;
      else return 2;
    },
    async deleteBox() {
      if (
        this.$store.state.unsavedChangesMPBox ||
        this.$store.state.unsavedChangesMPGrid
      ) {
        this.$store.state.promptUnsaveModal = true;

        let answer;
        try {
          answer = await this.$store.state.promptAnswerPromise();
        } catch {
          //rejected

          answer = null;
        }

        this.$store.state.promptUnsaveModal = false;
        if (!answer) return;
        else {
          this.resetUnsavedData();
        }
      }

      const deleteconfirm = confirm(
        "Are you sure you want to delete this box?"
      );
      if (!deleteconfirm) return;

      this.$store.dispatch("deleteItem", {
        item: "boxes",
        objid: this.puckData["well" + this.activeWellNo]._id,
        body: {
          wellNo: this.activeWellNo,
          puckId: this.puckData._id,
          deleteBox: true,
        },
      });
    },

    async saveBoxData(buttonev) {
      const button = buttonev ? buttonev.target.closest("button") : null;
      function forBoxSaveButton(textContent) {
        button.children[0].style.display = "none";
        // button.children[1].style.visibility = "visible";
        button.children[1].style.display = "flex";
        button.children[1].style.justifyContent = "center";

        //needed for superadmin
        button.children[1].style.visibility = "visible";

        button.previousSibling.textContent = textContent;
        button.previousSibling.addEventListener("transitionend", () => {
          button.previousSibling.classList.remove("fadeinbutton");
          button.previousSibling.classList.add("fadeoutbutton");
        });

        button.previousSibling.classList.remove("fadeoutbutton");
        button.previousSibling.classList.add("fadeinbutton");
        button.disabled = false;
      }

      if (this.$store.state.unsavedChangesMPGrid) {
        this.$store.state.promptUnsaveModal = true;

        let answer;
        try {
          answer = await this.$store.state.promptAnswerPromise();
        } catch {
          //rejected

          answer = null;
        }

        this.$store.state.promptUnsaveModal = false;
        if (!answer) {
          if (buttonev) forBoxSaveButton("Cancelled");
          return;
        } else {
          this.$store.state.unsavedChangesMPGrid = false;
          this.$store.state.unsavedChangesMPBox = false;
          if (document.getElementById("saveBoxChanges")) {
            document.getElementById("saveBoxChanges").style.outline = "none";
          }
          if (document.getElementById("saveCreateBoxChanges")) {
            document.getElementById("saveCreateBoxChanges").style.outline =
              "none";
          }
          if (document.getElementById("saveUserChanges")) {
            document.getElementById("saveUserChanges").style.outline = "none";
          }
        }
      }

      //must refresh puck data otherwise it will try to createitem instead of updating it
      if (this.puckData?.["well" + this.activeWellNo]?._id) {
        const theobj = {
          item: "boxes",
          objid: this.puckData["well" + this.activeWellNo]._id,
          body: {
            title: this.compBoxTitle,
            description: this.compBoxDescription,
            customId: this.compBoxId,
            project: this.$refs.projectselect.value,
            boxType: this.boxType,
            trueDateCreated: this.boxDatePicker,
            gridNumbering: this.gridNumbering,
            wellNo: this.activeWellNo,
            puckId: this.puckData._id,
          },
        };
        if (this.boxColor) theobj.body.color = this.boxColor;

        if (!this.$refs.projectselect.value) delete theobj.body.project;

        this.$store
          .dispatch("updateItem", theobj)
          .then(() => {
            console.log("ok");
            this.notifyBoxSaved();
            if (buttonev) forBoxSaveButton("");
          })
          .catch((err) => {
            console.log(err);
            if (buttonev) forBoxSaveButton("Error: Saving Failed!");
          });
      } else {
        //CREATE BOX
        console.log("ACTIVE WELL NO", this.activeWellNo);
        const theobj = {
          item: "boxes",
          body: {
            boxType: this.boxType,
            gridNumbering: this.gridNumbering,
            title: this.compBoxTitle,
            project: this.$refs.projectselect.value,
            description: this.compBoxDescription,
            trueDateCreated: this.boxDatePicker,
            customId: this.compBoxId,
            wellNo: this.activeWellNo,
            puckId: this.puckData._id,
          },
        };

        if (this.boxColor) theobj.body.color = this.boxColor;
        else if (this.puckType == "Cartridge") theobj.body.color = "blue";
        else return buttonev ? forBoxSaveButton("Box Color Required") : null;

        if (!this.$refs.projectselect.value) delete theobj.body.project;

        this.$store
          .dispatch("createItem", theobj)
          .then(() => {
            this.notifyBoxSaved();
            if (buttonev) forBoxSaveButton("Box Saved");
            setTimeout(
              () => {
                if (this.$refs.coolgrids) {
                  console.log("SCROLL ME DADDIo");
                  window.scrollIntoView(this.$refs.coolgrids, {
                    behavior: "smooth",
                    scrollMode: "if-needed",
                  });
                }
              },

              500
            );
          })
          .catch((err) => {
            console.log("why create fail", err);
            if (buttonev) forBoxSaveButton("Error: Saving Failed!");
          });
      }
    },
    // saveBoxSettings() {
    //   console.log("wellno,", this.activeWellNo);
    //   if (this.puckData?.["well" + this.activeWellNo]?._id) {
    //     const theobj = {
    //       item: "boxes",
    //       objid: this.puckData["well" + this.activeWellNo]._id,
    //       body: {
    //         boxType: this.boxType,
    //         gridNumbering: this.gridNumbering,
    //         wellNo: this.activeWellNo,
    //         puckId: this.puckData._id,
    //       },
    //     };
    //     if (this.boxColor) theobj.body.color = this.boxColor;
    //     this.$store.dispatch("updateItem", theobj);
    //   } else {
    //     console.log("huuuh");
    //     const theobj = {
    //       item: "boxes",
    //       body: {
    //         boxType: this.boxType,
    //         gridNumbering: this.gridNumbering,
    //         wellNo: this.activeWellNo,
    //         puckId: this.puckData._id,
    //       },
    //     };
    //     if (this.boxColor) theobj.body.color = this.boxColor;
    //     this.$store.dispatch("createItem", theobj).then(() => {
    //       setTimeout(
    //         () => {
    //           if (this.$refs.coolgrids) {
    //             window.scrollIntoView(this.$refs.coolgrids, {
    //               behavior: "smooth",
    //               scrollMode: "if-needed",
    //             });
    //           }
    //         },

    //         500
    //       );
    //     });
    //   }
    // },
    async savePuckData() {
      //PUCK

      if (
        this.$store.state.unsavedChangesMPBox ||
        this.$store.state.unsavedChangesMPGrid
      ) {
        this.$store.state.promptUnsaveModal = true;

        let answer;
        try {
          answer = await this.$store.state.promptAnswerPromise();
        } catch {
          //rejected

          answer = null;
        }

        this.$store.state.promptUnsaveModal = false;
        if (!answer) {
          return;
        } else {
          this.$store.state.unsavedChangesMPGrid = false;
          this.$store.state.unsavedChangesMPBox = false;
          if (document.getElementById("saveUserChanges")) {
            document.getElementById("saveUserChanges").style.outline = "none";
          }
        }
      }

      this.$store.dispatch("updateItem", {
        item: "pucks",
        objid: this.puckData._id,
        body: {
          title: this.$refs.puckTitle.value,
          description: this.$refs.puckDescription.value,
          puckType: this.currentPuckType,
          rackid: this.puckData.rackId,
        },
      });
    },
    getTrueCoords(evt) {
      // find the current zoom level and pan setting, and adjust the reported
      //    mouse position accordingly
      // var newScale = this.SVGRoot.currentScale;
      // var translation = this.SVGRoot.currentTranslate;
      // console.log(translation);
      this.boxSvgTrueCoords.x = evt.offsetX;
      this.boxSvgTrueCoords.y = evt.offsetY;
      // console.log(this.boxSvgTrueCoords);
      // this.boxSvgTrueCoords.x = (evt.clientX - translation.x) / newScale;
      // this.boxSvgTrueCoords.y = (evt.clientY - translation.y) / newScale;
    },
    forMoveGrabBox(ev) {
      console.log("GRABBED", ev.target);
      if (
        (!ev.target.querySelector("svg .boxonlysvg") &&
          !ev.target.closest("svg .boxonlysvg")) ||
        ev.target.querySelector(".isanemptysvg") ||
        ev.target.classList.contains("isanemptysvg") ||
        ev.target.closest(".isanemptysvg")
      )
        return console.log("notfound");

      const temp =
        ev.target.querySelector("svg .boxonlysvg") ||
        ev.target.closest("svg .boxonlysvg");
      this.boxSvgDragTarget = temp.parentElement;

      console.log("yooo", this.boxSvgDragTarget);

      // this.boxSvgStartingCoordinates = [
      //   temp.parentElement.firstElementChild.cx.baseVal.value,
      //   temp.parentElement.firstElementChild.cy.baseVal.value,
      // ];
      this.boxSvgClickStartTime = Date.now();
      console.log("whhy", this.boxSvgDragTarget);

      // move this element to the "top" of the display, so it is (almost)
      //    always over other elements (exception: in this case, elements that are
      //    "in the folder" (children of the folder group) with only maintain
      //    hierarchy within that group
      this.boxSvgDragTarget.parentNode.appendChild(this.boxSvgDragTarget);

      // turn off all pointer events to the dragged element, this does 2 things:
      //    1) allows us to drag text elements without selecting the text
      //    2) allows us to find out where the dragged element is dropped (see Drop)
      this.boxSvgDragTarget.setAttributeNS(null, "pointer-events", "none");

      // we need to find the current position and translation of the grabbed element,
      //    so that we only apply the differential between the current location
      //    and the new location
      // var transMatrix = this.boxSvgDragTarget.getCTM();
      this.boxSvgTrueCoords = this.SVGRoot.createSVGPoint();
      this.boxSvgGrabPoint = this.SVGRoot.createSVGPoint();

      console.log(this.boxSvgDragTarget.firstElementChild);
      var transMatrix = this.SVGRoot.getScreenCTM();
      this.boxSvgGrabPoint.x = ev.offsetX;
      this.boxSvgGrabPoint.y = ev.offsetY;
      // console.log(this.boxSvgGrabPoint.matrixTransform(transMatrix));
      console.log(
        "boxsvggrabpoints",
        this.boxSvgGrabPoint,
        "matrix transform is",
        transMatrix
      );
    },
    forMoveDragBox(ev) {
      if (this.boxSvgDragTarget) {
        console.log("moving");
        // account for zooming and panning
        this.getTrueCoords(ev);

        // account for the offset between the element's origin and the
        //    exact place we grabbed it... this way, the drag will look more natural
        var newX = this.boxSvgTrueCoords.x - this.boxSvgGrabPoint.x;
        var newY = this.boxSvgTrueCoords.y - this.boxSvgGrabPoint.y;

        // apply a new tranform translation to the dragged element, to display
        //    it in its new location
        this.boxSvgDragTarget.setAttributeNS(
          null,
          "transform",
          "translate(" + newX + "," + newY + ")"
        );

        const temp = ev.target.closest(".wellstrokeactive");

        document
          .querySelector(".wellstrokecircleactive")
          ?.classList.remove("wellstrokecircleactive");
        if (temp)
          temp.firstElementChild.classList.add("wellstrokecircleactive");

        // console.log(this.boxSvgDragTarget.firstElementChild.cx);
      }
    },
    getElementIndex(item) {
      for (var i = 0, l = item.classList.length; i < l; ++i) {
        if (/well-number-.*/.test(item.classList[i])) {
          console.log("testing", item.classList[i]);
          const value = item.classList[i].split("well-number-")[1];
          console.log(value);
          return +value;
        }
      }
    },
    forMoveDropBox(ev) {
      console.log("drop fired on ", ev.target);
      const temp = ev.target.closest(".wellstrokeactive");
      //if the movement was
      // console.log(temp.firstElementChild.cx.baseVal.value);

      const wellNoFrom = this.boxSvgDragTarget
        ? this.getElementIndex(this.boxSvgDragTarget)
        : null;
      const wellNoTo = temp ? this.getElementIndex(temp) : null;
      console.log(
        temp,
        this.boxSvgDragTarget,
        this.boxSvgGrabPoint,
        this.boxSvgTrueCoords
      );

      if (temp && this.boxSvgDragTarget && !this.puckData.readOnly) {
        if (
          this.$store.state.dewarData[this.activeDewarLabIndex].data[
            +this.dataChainForMove.dewar
          ].racks[this.dataChainForMove.rack].pucks[this.activePuckIndex]?.[
            "well" + wellNoTo
          ]
        ) {
          if (this.boxSvgDragTarget) {
            this.boxSvgDragTarget.setAttributeNS(
              null,
              "transform",
              "translate(" + 0 + "," + 0 + ")"
            );
            this.boxSvgDragTarget.setAttributeNS(
              null,
              "pointer-events",
              "auto"
            );
            this.boxSvgDragTarget = null;

            document
              .querySelector(".wellstrokecircleactive")
              ?.classList.remove("wellstrokecircleactive");
          }

          return (this.welloccupiedalert = true);
        }

        const xTransform =
          temp.firstElementChild.cx.baseVal.value -
          this.boxSvgDragTarget.firstElementChild.cx.baseVal.value;

        const yTransform =
          temp.firstElementChild.cy.baseVal.value -
          this.boxSvgDragTarget.firstElementChild.cy.baseVal.value;

        this.boxSvgDragTarget.setAttributeNS(
          null,
          "transform",
          "translate(" + xTransform + "," + yTransform + ")"
        );
        this.boxSvgClickEndTime = Date.now();

        //no longer using endtime-starttime because 500ms was too long to trigger on fast swaps
        if (document.querySelector(".wellstrokecircleactive")) {
          if (wellNoFrom && wellNoTo) this.moveBoxUX(wellNoFrom, wellNoTo);
          else {
            alert("Error try again");
            this.boxSvgDragTarget.setAttributeNS(
              null,
              "transform",
              "translate(" + 0 + "," + 0 + ")"
            );
            this.boxSvgDragTarget.setAttributeNS(
              null,
              "pointer-events",
              "auto"
            );
            this.boxSvgDragTarget = null;

            document
              .querySelector(".wellstrokecircleactive")
              ?.classList.remove("wellstrokecircleactive");
          }
        }
      } else {
        this.highlightWellCircle(
          ev,
          null,
          null,
          temp ? temp : this.boxSvgDragTarget
        );
        if (this.boxSvgDragTarget) {
          this.boxSvgDragTarget.setAttributeNS(
            null,
            "transform",
            "translate(" + 0 + "," + 0 + ")"
          );
        }
      }
      if (this.boxSvgDragTarget) {
        this.boxSvgDragTarget.setAttributeNS(null, "pointer-events", "auto");
        this.boxSvgDragTarget = null;
      }
    },

    checkIfChecked(ev) {
      if (ev.target.checked) this.editBoxPref = true;
      else this.editBoxPref = false;
    },
    setBoxType(type) {
      this.boxType = type;
      //this.boxColorElemNum = null;
      if (this.boxColorElemNum)
        setTimeout(() => {
          this.boxColorSelected(null, this.boxColorElemNum);
        }, 100);

      document.getElementById("dynamicbox").innerHTML = "";
    },
    copyBox(ev) {
      this.setCopiedBox({
        title: this.compBoxTitle,
        description: this.compBoxDescription,
        customId: this.compBoxId,

        boxType: this.boxType,
        gridNumbering: this.gridNumbering,
        color: this.boxColor,
      });
      ev.target.nextSibling.addEventListener("transitionend", () => {
        ev.target.nextSibling.classList.remove("fadeinbutton");
        ev.target.nextSibling.classList.add("fadeoutbutton");
      });

      ev.target.nextSibling.classList.remove("fadeoutbutton");
      ev.target.nextSibling.classList.add("fadeinbutton");
    },
    pasteBox() {
      if (this.puckData?.["well" + this.activeWellNo]?._id) {
        const overwrite = confirm(
          "A box already exists in this well. Are you sure you want to overwrite it?"
        );

        if (!overwrite) return;
      }

      this.compBoxTitle = this.getCopiedBox.value.title;
      this.compBoxDescription = this.getCopiedBox.value.description;
      this.compBoxId = this.getCopiedBox?.value?.customId;

      this.boxType = this.getCopiedBox.value.boxType;
      this.gridNumbering = this.getCopiedBox.value.gridNumbering;

      const boxcolorindex =
        this.$store.state.boxColors.indexOf(this.getCopiedBox.value.color) + 1;
      console.log(boxcolorindex, this.getCopiedBox.value.color);
      if (this.$refs["boxcoloricon" + boxcolorindex]?.[0])
        this.$refs["boxcoloricon" + boxcolorindex][0].click();

      this.saveBoxData();

      // this.notifyBoxNotSaved();
    },

    async moveBoxUX(wellNoFrom, wellNoTo) {
      if (this.$store.state.unsavedChangesMPBox) {
        this.$store.state.promptUnsaveModal = true;

        let answer;
        try {
          answer = await this.$store.state.promptAnswerPromise();
        } catch {
          //rejected

          answer = null;
        }

        this.$store.state.promptUnsaveModal = false;
        if (!answer) return;
        else {
          this.resetUnsavedData();
        }
      }

      //need this to get the box(well) id
      // const wellnofromid = this.$store.state.dewarData[
      //   +this.dataChainForMove.lab
      // ].data[+this.dataChainForMove.dewar].racks[+this.dataChainForMove.rack]
      //   ?.pucks[+this.dataChainForMove.puck]?.["well" + n]?._id;

      const wellnofromid = this.puckData["well" + wellNoFrom]._id;

      let samepuck = true;

      let objtoupdate = {
        item: "boxes",
        samepuck: samepuck,
        objid: wellnofromid,
        body: {
          //the well your moving from
          wellNo: wellNoFrom,
          puckId: this.puckData._id,

          moveTo: { toWell: wellNoTo, toPuck: this.puckData._id },
        },
      };
      console.log(objtoupdate);
      this.activeWellNo = wellNoFrom;
      // this.showGridPanel = true;
      console.log(
        this.showGridPanel,
        this.activeWellNo,
        this.puckData,
        this.puckData["well" + this.activeWellNo]
      );
      this.boxmoveinprogress = true;
      this.$store
        .dispatch("updateItem", objtoupdate)
        .then(() => {})
        .catch((err) => {
          console.log(err);
          this.boxmoveinprogress = false;
          alert(
            "Error moving box. Please check internet connection and try again, or contact support."
          );
        });
    },

    boxColorSelected(ev, boxnum) {
      this.boxColorElemNum = boxnum;
      this.boxColor = this.boxColors[boxnum];
      console.log(this.boxColorElemNum);
      console.log(".boxChoice-color-" + boxnum);
      const dynamicdiv = document.getElementById("dynamicbox");
      dynamicdiv.style.display = "block";
      // let thesvg;
      // if (this.boxType == "clipped")
      //   thesvg = document.getElementById("clippedsvg");
      // else if (this.boxType == "unclipped")
      // console.log(ev.target);

      const thesvg =
        ev?.target?.closest(".boxChoice-color-" + boxnum) ??
        document.querySelector(".boxChoice-color-" + boxnum);

      console.log(thesvg);

      const cln = thesvg.cloneNode(true);
      if (this.boxColorElemNum == 1) {
        if (cln.querySelector(".st0"))
          cln.querySelector(".st0").style.strokeWidth = 1;
      }
      cln.style.width = "91px";
      cln.style.height = "91px";

      cln.querySelector(".showOnBigWell").classList.remove("hidden");
      dynamicdiv.innerHTML = "";
      dynamicdiv.appendChild(cln);
    },

    setActivePuckPanel(ev, puckNumber) {
      if (ev && puckNumber == 4 && !this.activeWellElem) {
        return;
      }

      if (puckNumber != this.activePuckPanel) {
        Array.from(document.querySelector(".puck-buttons").childNodes).map(
          (node, index) => {
            if (index != puckNumber - 1) {
              if (
                index ==
                Array.from(document.querySelector(".puck-buttons").childNodes)
                  .length -
                  1
              )
                node.style.background = "none rgba(52, 91, 140, 0.51)";
              else node.style.backgroundColor = "rgb(43, 94, 141)";
            }
          }
        );

        Array.from(
          document.querySelector(".puck-buttons-mobile").childNodes
        ).map((node, index) => {
          if (index != puckNumber - 1) {
            if (
              index ==
              Array.from(
                document.querySelector(".puck-buttons-mobile").childNodes
              ).length -
                1
            )
              node.style.background = "none rgba(52, 91, 140, 0.51)";
            else node.style.backgroundColor = "rgb(43, 94, 141)";
          }
        });
      }
      console.log(puckNumber, this.activePuckPanel);

      if (puckNumber == 3) {
        let arrayOfBoxes = document
          .getElementById("puckcontainer")
          .querySelectorAll(".boxonlysvg");

        //added this because drag and drop code changes dom order of arrayboxes
        arrayOfBoxes = Array.from(arrayOfBoxes).sort(
          (a, b) => +a.dataset.wellNumber1 - +b.dataset.wellNumber1
        );
        console.log(arrayOfBoxes);
        const arrayOfBoxRows = document
          .querySelector(".parentmoveup")
          .querySelectorAll(".boximage");
        console.log(arrayOfBoxRows);
        for (let i = 0; i < (this.puckType == "Cartridge" ? 7 : 12); i++) {
          arrayOfBoxRows[i].innerHTML = "";
          if (arrayOfBoxes[i]) {
            const cln = arrayOfBoxes[i].cloneNode(true);
            if (!cln.classList.contains("isanemptysvg")) {
              cln.style.width = "30px";
              cln.style.height = "30px";

              arrayOfBoxRows[i].appendChild(cln);
            } else {
              const container = document.createElement("div");

              container.style.width = "30px";
              container.style.height = "30px";
              container.style.border = "1px solid black";
              container.style.backgroundColor = "white";
              container.style.borderRadius = "50%";
              container.style.opacity = "50%";

              arrayOfBoxRows[i].appendChild(container);
            }
          }
        }
      }

      console.log(ev);
      if (ev.target) {
        // for making green when it goes to box info for clicking box info

        document.querySelector(".puck-buttons").childNodes[
          puckNumber - 1
        ].style.backgroundColor = "rgb(7, 130, 35)";
        document.querySelector(".puck-buttons-mobile").childNodes[
          puckNumber - 1
        ].style.backgroundColor = "rgb(7, 130, 35)";
      } else {
        //for when ti goes to the box info from highlighting a well
        document.getElementById("3rdbutton").style.backgroundColor =
          "rgb(7, 130, 35)";
        document.getElementById("3rdbuttonmobile").style.backgroundColor =
          "rgb(7, 130, 35)";
      }

      this.activePuckPanel = puckNumber;
    },
    async highlightWellCircle(
      ev,
      autoRefreshedPuck,
      fromgridupdate,
      wellTargetElem
    ) {
      if (
        this.$store.state.unsavedChangesMPBox ||
        this.$store.state.unsavedChangesMPGrid
      ) {
        this.$store.state.promptUnsaveModal = true;

        let answer;
        try {
          answer = await this.$store.state.promptAnswerPromise();
        } catch {
          //rejected

          answer = null;
        }

        this.$store.state.promptUnsaveModal = false;
        if (!answer) return;
        else {
          this.resetUnsavedData();
        }
      }

      //reset puckData
      console.log("ran highlight", autoRefreshedPuck);

      //for distinguishing between a user well click and a save puck reload
      let evTarget = ev?.target;

      const tempMoveFollow = this.$store.state.moveToForFollow;

      let wellElem;
      if (autoRefreshedPuck) {
        if (this.$store.state.moveTo || this.$store.state.moveToForFollow) {
          evTarget = document.querySelector(
            ".well-number-" +
              (this.$store.state.moveTo ?? this.$store.state.moveToForFollow)
          );
          this.$store.state.moveTo = null;
          console.log(tempMoveFollow, "temp move follow");
        } else
          evTarget = document.querySelector(
            ".well-number-" + this.activeWellNo
          );

        wellElem = evTarget;
      } else {
        wellElem = this.boxSvgDragTarget || wellTargetElem;
      }

      // console.log(ev.target, this.activeWellNo, wellElem);

      this.highlightedev = ev;

      console.log("YAAA", wellTargetElem, wellElem);

      this.activeWellNo = wellElem.childNodes[2].dataset.wellNumber1;

      this.checkReadOnly(true, true);

      // !this.puckDataBotBox &&
      // this.puckData?.["well" + this.activeWellNo]?.boxType
      //   ? this.puckData["well" + this.activeWellNo].boxType
      //   : this.puckDataBotBox?.["well" + this.activeWellNo]?.boxType
      //   ? this.puckDataBotBox["well" + this.activeWellNo].boxType
      //   : "Clipped";

      this.boxType = this.puckData?.["well" + this.activeWellNo]?.boxType
        ? this.puckData["well" + this.activeWellNo].boxType
        : "Unclipped";

      this.boxDatePicker = null;

      setTimeout(() => {
        this.boxDatePicker = this.puckData?.["well" + this.activeWellNo]
          ?.trueDateCreated
          ? this.puckData["well" + this.activeWellNo].trueDateCreated
          : // : this.puckData?.["well" + this.activeWellNo]?.color
            // ? ""
            Date.now();
      }, 100);

      this.gridNumbering = this.puckData?.["well" + this.activeWellNo]
        ?.gridNumbering
        ? this.puckData["well" + this.activeWellNo].gridNumbering
        : 1;
      this.boxColor = this.puckData?.["well" + this.activeWellNo]?.color
        ? this.puckData["well" + this.activeWellNo].color
        : null;
      this.compBoxTitle = this.puckData?.["well" + this.activeWellNo]?.title
        ? this.puckData["well" + this.activeWellNo].title
        : "";
      this.compBoxId = this.puckData?.["well" + this.activeWellNo]?.customId
        ? this.puckData["well" + this.activeWellNo].customId
        : this.activeWellNo
        ? this.computedWellNo
        : "";
      this.compBoxDescription = this.puckData?.["well" + this.activeWellNo]
        ?.description
        ? this.puckData?.["well" + this.activeWellNo].description
        : "";

      // this.compBoxProject = this.puckData?.["well" + this.activeWellNo]?.project
      //   ? this.puckData?.["well" + this.activeWellNo].project
      //   : null;

      console.log(this.puckData?.["well" + this.activeWellNo]?.project);
      if (this.puckData?.["well" + this.activeWellNo]?.project)
        this.$store.state.projects.some((e, index) => {
          if (e._id == this.puckData?.["well" + this.activeWellNo]?.project) {
            console.log("select index: ", index);
            return (this.$refs.projectselect.selectedIndex = index + 1);
          }
        });
      else this.$refs.projectselect.selectedIndex = 0;

      // if (this.compBoxProject) {
      //   this.$store.state.projects.some((e, index) => {
      //     if (e._id == this.compBoxProject)
      //       return (this.$refs.projectselect.selectedIndex = index);
      //   });
      // } else this.$refs.projectselect.selectedIndex = 0;

      console.log("undo this", this.activeWellElem);
      if (this.activeWellElem && this.activeWellElem != wellElem) {
        this.activeWellElem.childNodes[1].style.strokeWidth = "0";
        if (this.activeWellElem.childNodes[0].classList.contains("strokerect"))
          this.activeWellElem.childNodes[0].style.strokeWidth = "0";
      }

      this.activeWellElem = wellElem;
      console.log(
        this.activeWellElem,
        this.activeWellElem.childNodes[0],
        this.activeWellElem.children
      );
      if (
        this.activeWellElem.childNodes?.[0]?.classList.contains("strokerect")
      ) {
        this.activeWellElem.childNodes[0].style.strokeWidth = "5";
        console.log("should make it here");
      } else {
        console.log("didntmake it");
        this.activeWellElem.childNodes[1].style.strokeWidth = "5";
      }
      console.log("this is what is getting stroked", wellElem);

      //this might be for mobile
      if (wellElem.childNodes[0].classList.contains("strokerect")) {
        if (this.puckData?.["well" + this.activeWellNo]?.boxType) {
          if (!this.showGridPanel) {
            console.log("showeed");
            this.showGridPanel = true;
            setTimeout(() => {
              console.log("SMOOTHING");
              window.scrollIntoView(this.$refs.coolgrids, {
                behavior: "smooth",
                scrollMode: "if-needed",
              });
            }, 500);
          }
        }
        //gdsdg
      } else {
        console.log("clearlyinthis");
        const cln = wellElem.querySelector(".boxonlysvg")?.cloneNode(true);
        document.querySelector(".panel3box").innerHTML = "";
        if (!cln.classList.contains("isanemptysvg")) {
          cln.style.width = "60px";
          cln.style.height = "60px";
          this.showGridPanel = true;
          document.querySelector(".panel3box").append(cln);
        } else {
          const container = document.createElement("div");

          container.style.width = "60px";
          container.style.height = "60px";
          container.style.border = "1px solid black";
          container.style.backgroundColor = "white";
          container.style.borderRadius = "50%";
          container.style.opacity = "50%";

          document.querySelector(".panel3box").append(container);
        }
      }
      this.setActivePuckPanel(false, 4);
      console.log(
        "hereplus",
        !this.$store.state.moveToForFollow,
        tempMoveFollow
      );

      //disable this
      // if (!tempMoveFollow)

      //   setTimeout(() => {
      //     if (this.$refs.coolgrids && !autoRefreshedPuck)
      //       window.scrollIntoView(this.$refs.coolgrids, {
      //         behavior: "smooth",
      //         scrollMode: "if-needed",
      //       });
      //   }, 500);
      // else {
      // window.scrollIntoView(this.$refs.leftpuckserialbottom, {
      //   behavior: "smooth",
      //   scrollMode: "if-needed",
      // });

      // }

      if (!fromgridupdate) {
        this.scrollToPosition();
      }

      const hasWellColorPicked =
        !!this.puckData?.["well" + this.activeWellNo]?.color;
      this.boxColorElemNum = null;

      console.log("box color", this.boxColorElemNum);
      this.editBoxPref = !hasWellColorPicked;
      // setTimeout(() => {
      console.log(this.$refs.editbox);

      if (this.$refs.editbox) this.$refs.editbox.checked = this.editBoxPref;
      // }, 500);
    },
    findPos(obj) {
      let curtop = 0;
      if (obj.offsetParent) {
        do {
          curtop += obj.offsetTop;
        } while ((obj = obj.offsetParent));
        return [curtop];
      }
    },
    scrollToPosition() {
      if (this.$refs.coolgrids)
        window.scrollIntoView(this.$refs.coolgrids, {
          behavior: "smooth",
          scrollMode: "if-needed",
        });
      // if (this.topRowHeight?.value) {
      //   console.log("in this one");
      //   const searchbarHeight =
      //     document.getElementById("searchbarheight").offsetHeight;
      //   window.scroll({
      //     left: 0,
      //     top:
      //       this.findPos(document.getElementById("pucksectionformain")) -
      //       parseInt(this.topRowHeight.value, 10) -
      //       searchbarHeight,
      //     behavior: "smooth",
      //   });
      // } else {
      else setTimeout(this.scrollToPosition, 100); // Try again after 100ms
    },
    loadPuck() {
      console.log("load pucked again");
      if (this.boxmoveinprogress) this.boxmoveinprogress = false;
      if (!this.activePuck) return;
      console.log(this.activePuck[0]);

      const cln = this.activePuck[0].cloneNode(true);
      //   const absPosSerialElem = this.$refs["leftpuckserial"];
      cln.classList.remove("cursor-pointer");
      if (cln.querySelector(".pucktitle"))
        cln.querySelector(".pucktitle").remove();

      // const serialNo = cln.firstChild.textContent;
      this.leftPuckSerial = this.puckData.serialNo;
      cln.firstChild.remove();
      const puckcontainer = document.getElementById("puckcontainer");
      const arrayOfWells = cln.querySelectorAll(".wellstrokeactive");

      //code for drag and dropping boxes in big puck svg

      puckcontainer.innerHTML = "";
      puckcontainer.appendChild(cln);
      console.log("quoi");
      arrayOfWells.forEach((element, index) => {
        // Get the g element and the foreignObject element
        //for safari

        var bbox = element.getBBox();
        console.log("bbox", bbox, element);
        if (this.puckData?.["well" + (index + 1)]?.title) {
          element.classList.add("relative");
          // const labelstring = `<text>${
          //   this.puckData?.["well" + index]?.title
          // }</text>`;
          const labelstring = `<foreignobject x="${bbox.x}" y="${
            bbox.y
          }" width="83" height="20" class="relative top-0 left-0">


          <div class="max-w-[max-content] mx-auto px-1 overflow-hidden text-xs border-black bg-white bg-opacity-75 text-center border ${
            "fowell" + (index + 1)
          }">
${this.puckData?.["well" + (index + 1)]?.title}

    </div>
      </foreignobject>`;

          element.insertAdjacentHTML("beforeend", labelstring);
          //annoying code for positioning box name in safari

          // Get the bounding box of the g element
        }

        //this is the element i need to make draggable with svg magic

        element.classList.add("well-number-" + (index + 1));
        // element.addEventListener("click", this.highlightWellCircle);
      });

      // const SVGDocument = evt.target.ownerDocument;
      this.SVGRoot = puckcontainer.querySelector("svg");

      this.SVGRoot.addEventListener("mousedown", this.forMoveGrabBox);

      if (!this.puckData.readOnly)
        this.SVGRoot.addEventListener("mousemove", this.forMoveDragBox);

      this.SVGRoot.addEventListener("mouseup", this.forMoveDropBox);

      // these svg points hold x and y values...
      //    very handy, but they do not display on the screen (just so you know)

      //   puckcontainer.appendChild(absPosSerialElem);
      this.checkReadOnly();
      this.checkReadOnly(true, true);
      this.selectedPuckProject = this.puckData.project || "";
    },

    notifyBoxSaved() {
      if (this.$refs.saveBoxChanges)
        this.$refs.saveBoxChanges.style.outline = "none";

      if (this.$refs.saveCreateBoxChanges)
        this.$refs.saveCreateBoxChanges.style.outline = "none";
      this.$store.state.unsavedChangesMPBox = false;
    },
    notifyBoxNotSaved() {
      if (this.$refs.saveBoxChanges)
        this.$refs.saveBoxChanges.style.outline = "2px red dashed";

      this.$store.state.unsavedChangesMPBox = true;
    },

    notifySaved() {
      this.$refs.saveUserChanges.style.outline = "none";
      this.$store.state.unsavedChangesMPGrid = false;
    },
    notifyNotSaved() {
      this.$refs.saveUserChanges.style.outline = "2px red dashed";
      this.$store.state.unsavedChangesMPGrid = true;
    },
    checkReadOnly(forGrid, boxareaofpuck) {
      // console.log(
      //   "checking if red only",
      //   this.puckData.readOnly,
      //   this.puckData,
      //   this.activeWellNo,
      //   this.puckData?.["well" + this.activeWellNo]?.readOnly
      // );
      let sectionName = "pucksectionforreadonly";
      if (forGrid) sectionName = "gridforreadonly";
      if (forGrid && boxareaofpuck) sectionName = "boxforreadonly";
      if (
        (this.puckData.readOnly && sectionName == "pucksectionforreadonly") ||
        (this.activeWellNo &&
          this.puckData?.["well" + this.activeWellNo]?.readOnly &&
          forGrid)
      ) {
        document
          .getElementById(sectionName)
          .querySelectorAll("input")
          .forEach((input) => {
            input.readOnly = true;
            input.disabled = true;
          });
        document
          .getElementById(sectionName)
          .querySelectorAll("select")
          .forEach((input) => {
            input.readOnly = true;
            input.disabled = true;
          });
        document
          .getElementById(sectionName)
          .querySelectorAll("textarea")
          .forEach((textarea) => {
            textarea.readOnly = true;
            textarea.disabled = true;
          });
      } else {
        if (document.getElementById(sectionName)) {
          document
            .getElementById(sectionName)
            .querySelectorAll("input")
            .forEach((input) => {
              input.readOnly = false;
              input.disabled = false;
            });
          document
            .getElementById(sectionName)
            .querySelectorAll("textarea")
            .forEach((textarea) => {
              textarea.readOnly = false;
              textarea.disabled = false;
            });
        }
      }
    },
  },

  mounted() {
    // console.log(
    //   "puckData is!",
    //   this.$store.state.dewarData[0].data[0].racks[this.dataChainForMove.rack]
    //     .pucks[this.activePuckIndex],
    //   this.puckData.isReadOnly
    // );

    window.onresize = () => {
      console.log("window resized");
    };

    console.log(this.selectedPuckProject);
    this.loadPuck();
    this.checkReadOnly();

    console.log("pucktype is", this.puckType);
    if (this.resetPuckSelected) {
      this.$emit("new-puck-selected");
    }

    if (this.autoclickpuck) {
      console.log(this.autoclickpuck);
      const sequence = async () => {
        await window.scrollIntoView(this.$refs.leftpuckserialbottom, {
          behavior: "smooth",
          scrollMode: "if-needed",
        });
        console.log("WOWSCROLL");
        this.$emit("resetautonavigate");
        this.endPuckNavigate();
      };
      sequence();
    }
  },
};
</script>
<style scoped>
.custom-select {
  position: relative;
  display: inline-block;
}

.selected-option {
  border: 1px solid #ccc;
  cursor: pointer;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.options {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 200px;
  cursor: pointer;
  overflow-y: auto;
  border: 1px solid #ccc;
  background-color: #fff;
}

.option {
  padding: 8px;
  cursor: pointer;
}
.puck-buttons div,
.puck-buttons-mobile div {
  cursor: pointer;
  background-color: rgb(47, 83, 125);
  border-radius: 4px;
  color: white;
  text-align: center;
  margin-right: 0.5rem;
  font-size: 0.9rem;
  font-weight: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid rgb(47, 83, 125);
  flex: 1 0 0;
  padding: 0.1rem 0;
}

/* @media (min-width: 640px) {
  .puck-buttons div:first-child {
    display: none;
  }
} */

.puck-buttons-mobile div:first-child,
.puck-buttons div:nth-child(2) {
  background-color: rgb(7, 130, 35);
}
.puck-buttons div:last-child,
.puck-buttons-mobile div:last-child,
.puck-buttons div:nth-last-child(2),
.puck-buttons-mobile div:nth-last-child(2) {
  margin-right: 0;
}

.panel1grid {
  grid-template-columns: calc(60% - 1rem) 40%;
}
.blueborder {
  border: 2px solid rgb(118, 155, 186);
  border-radius: 6px;
  padding: 0.3rem;

  font-size: 0.9rem;
}

.blueborder:focus {
  border-color: rgb(43, 94, 141);
}

.puckiddiv {
  border: 2px solid rgb(118, 155, 186);
  background-color: rgb(43, 94, 141);
}

label {
  top: -1px;
  position: relative;
  padding-left: 0.2rem;
}

::placeholder {
  color: darkgray;
}

.supersmall {
  font-size: 0.65rem;
}

.innermoveup {
  top: -1;
  left: 1;
}

.circlesize {
  width: 1.5rem;
  height: 1.5rem;
}

.gridtitles input {
  padding: 0;
  padding-left: 0.25rem;
  margin: 0;
  cursor: default;
  border: 1px solid black;
  font-size: 0.8rem;
}

.tailwindsucks input,
.tailwindsucks label {
  cursor: pointer;
}

.tailwindsucks div {
  padding-top: 0.25rem;
}
</style>
