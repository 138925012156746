<template>
  <div class="skyblue mt-4">
    <div
      class="bg-white p-2 grid grid-cols-2 gap-x-8 gridtemplate text-black gap-2 border"
    >
      <!-- all the pucks in a rack displayed-->

      {{ rackIsEmptyCompute }}
      <template v-for="(puck, index) in arrayOfPucks" :key="puck._id + '1'">
        <div class="flex flex-col font-bold">
          <div class="font-bold text-lg">
            {{ puck.customId ?? puck.serialNo }}
          </div>
          <div v-if="torackid" class="flex items-center">
            <input
              :id="'addpuck-' + index"
              class="w-4 h-4 cursor-pointer mb-0 mr-1"
              :disabled="disabled || torackid === true"
              type="checkbox"
              @click="addAPuckToRack($event, puck._id, torackid)"
            />
            <label
              :for="'addpuck-' + index"
              class="text-green-800 cursor-pointer text-sm"
              >Add Puck</label
            >
          </div>
          <div v-else class="flex items-center">
            <input
              :id="'removepuck-' + index"
              class="w-4 h-4 cursor-pointer mb-0 mr-1"
              type="checkbox"
              @click="addAPuckToRack($event, puck._id)"
            />
            <label
              :for="'removepuck-' + index"
              class="text-red-600 cursor-pointer text-sm"
              >Remove Puck</label
            >
          </div>
        </div>

        <div
          class="p-2 flex items-center"
          style="background-color:rgb(242, 242, 242);"
        >
          <div class="w-16">
            <div
              class="cursor-pointer"
              :ref="'puck-' + puck._id"
              @click="parentCall(puck)"
            >
              <div class="text-center"></div>
              <div
                style="background-image:url('assets/images/silver-puck-fade.svg'); background-repeat:no-repeat; background-position:center top"
                class=""
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  version="1.1"
                  :id="'pucksvg-' + side + '-' + index"
                  x="0px"
                  y="0px"
                  viewBox="0 0 407.54 435.08"
                  style="enable-background:new 0 0 407.54 435.08;"
                  xml:space="preserve"
                >
                  <svg:style>
                    #pucksvg-{{side}}-{{index}} .st0 {
                                                                         fill: {{$store.state.transformPuckColor(puck.color)}};
                                                                       }
                                                                       #pucksvg-{{side}}-{{index}} .st1 {
                                                                                    fill: {{$store.state.transformPuckColor(puck.color,true)}};

                                                                       }
                                                                       #pucksvg-{{side}}-{{index}} .st2 {
                                                                         fill: #ffffff;
                                                                       }
                                                                       #pucksvg-{{side}}-{{index}} .st3 {
                                                                         fill: none;
                                                                       }
                                                                              .wellstrokecircle {
                                                                                fill:none;
                                                                stroke:rgb(176, 176, 176); stroke-width:3; fill:none;!important;
                                                            }
                                                           .wellstrokeactive .strokecircle2 {
                                                             stroke:  rgb(247, 215, 86) !important; fill:none; stroke-width:0;
                                                            }
                  </svg:style>
                  <path
                    class="st0"
                    d="M396.39,213.01c-8.42-7.48-19.68,0.3-25.88-5.9c-6.21-6.21-11.58-56.84-17.13-74.56  c-5.55-17.72-16.33-11.93-25.59-13.62c-6.08-1.11-11.29-5.19-14.1-24.76c-3.53-24.59-9.52-36.2-9.52-36.2s-7.89-4.6-25.02-12.64  c-35.08-16.46-74.96-15.21-74.96-15.21h-0.15V30.1c-0.11,0-0.19,0-0.26,0.01c-0.06,0-0.14,0-0.26-0.01v0.01h-0.15  c0,0-39.88-1.25-74.96,15.21c-17.14,8.04-25.02,12.64-25.02,12.64s-5.99,11.61-9.52,36.2c-2.81,19.57-8.02,23.65-14.1,24.76  c-9.26,1.69-20.05-4.11-25.59,13.62c-5.55,17.72-10.92,68.35-17.13,74.56c-6.21,6.21-17.47-1.57-25.88,5.9  c-6.31,5.61-24.31,21.1,5.6,95.19c12.14,30.07,30.78,55.5,47.64,74.16c25.75,28.49,43.22,37.05,62.66,23.34  c11.33-7.99,28.9-9.35,36.52-3.25c6.56,5.25,5.94,13.11-1.74,13.92c-7.68,0.82-12.52,5.55-9.89,11.46  c2.66,5.99,19.68,7.44,29.6,7.23c10.04-0.21,14.3-3.16,14.3-3.16s0.15-15.18,2-21.78c1.16-4.16,5.16-4.22,5.16-4.22h0.54h0.51h0.54  c0,0,4,0.06,5.16,4.22c1.85,6.6,2,21.78,2,21.78s4.26,2.95,14.3,3.16c9.91,0.2,26.94-1.25,29.6-7.23  c2.63-5.91-2.22-10.64-9.89-11.46c-7.68-0.82-8.3-8.67-1.74-13.92c7.61-6.1,25.19-4.74,36.52,3.25  c19.43,13.71,36.91,5.15,62.66-23.34c16.86-18.66,35.5-44.09,47.64-74.16C420.7,234.1,402.7,218.62,396.39,213.01z"
                  />
                  <g>
                    <path
                      class="st1"
                      d="M153.31,131.42c17.65,0,32.73-10.96,38.83-26.44h23.24c6.1,15.48,21.18,26.44,38.83,26.44   c23.05,0,41.74-18.69,41.74-41.74s-18.69-41.74-41.74-41.74c-17.65,0-32.73,10.96-38.83,26.44h-23.24   c-6.1-15.48-21.18-26.44-38.83-26.44c-23.05,0-41.74,18.69-41.74,41.74C111.57,112.73,130.26,131.42,153.31,131.42z"
                    ></path>

                    <path
                      class="st1"
                      d="M347.3,218.42c-17.65,0-32.73,10.96-38.83,26.44h-18.24c-6.1-15.48-21.18-26.44-38.83-26.44   s-32.73,10.96-38.83,26.44h-17.56c-6.1-15.48-21.18-26.44-38.83-26.44s-32.73,10.96-38.83,26.44H99.08   c-6.1-15.48-21.18-26.44-38.83-26.44c-23.05,0-41.74,18.69-41.74,41.74S37.2,301.9,60.25,301.9c17.65,0,32.73-10.96,38.83-26.44   h18.24c6.1,15.48,21.18,26.44,38.83,26.44s32.73-10.96,38.83-26.44h17.56c6.1,15.48,21.18,26.44,38.83,26.44   s32.73-10.96,38.83-26.44h18.24c6.1,15.48,21.18,26.44,38.83,26.44c23.05,0,41.74-18.69,41.74-41.74   C389.04,237.11,370.35,218.42,347.3,218.42z"
                    />
                    <path
                      class="st1"
                      d="M105.25,216.66c17.65,0,32.73-10.96,38.83-26.44h20.85c6.1,15.48,21.18,26.44,38.83,26.44   s32.73-10.96,38.83-26.44h20.85c6.1,15.48,21.18,26.44,38.83,26.44c23.05,0,41.74-18.69,41.74-41.74s-18.69-41.74-41.74-41.74   c-17.65,0-32.73,10.96-38.83,26.44H242.6c-6.1-15.48-21.18-26.44-38.83-26.44s-32.73,10.96-38.83,26.44h-20.85   c-6.1-15.48-21.18-26.44-38.83-26.44c-23.05,0-41.74,18.69-41.74,41.74C63.51,197.97,82.2,216.66,105.25,216.66z"
                    />
                    <path
                      class="st1"
                      d="M302.29,303.66c-17.65,0-32.73,10.96-38.83,26.44H242.6c-6.1-15.48-21.18-26.44-38.83-26.44   s-32.73,10.96-38.83,26.44h-20.85c-6.1-15.48-21.18-26.44-38.83-26.44c-23.05,0-41.74,18.69-41.74,41.74s18.69,41.74,41.74,41.74   c17.65,0,32.73-10.96,38.83-26.44h20.85c6.1,15.48,21.18,26.44,38.83,26.44s32.73-10.96,38.83-26.44h20.85   c6.1,15.48,21.18,26.44,38.83,26.44c23.05,0,41.74-18.69,41.74-41.74C344.03,322.34,325.34,303.66,302.29,303.66z"
                    />
                  </g>
                  <g>
                    <path
                      class="st0"
                      d="M163.94,102.3v7.25h-21.58v-7.25h5.82V74.45h-6.21V67.2h16.52v35.1C158.49,102.3,163.94,102.3,163.94,102.3z"
                    />
                  </g>
                  <g>
                    <path
                      class="st0"
                      d="M270.58,78.36c0,2.15-0.48,4.03-1.45,5.62c-0.96,1.59-2.26,3.04-3.89,4.34s-4,2.89-7.11,4.76   c-5.06,3.11-7.86,6.18-8.4,9.22h12.79v-5.48h8.57l-0.51,12.73h-30.86c0-3.77,0.45-6.87,1.35-9.3c0.9-2.44,2.32-4.67,4.26-6.7   c1.94-2.03,4.79-4.44,8.56-7.24c1.99-1.46,3.5-2.88,4.54-4.24c1.04-1.37,1.56-2.77,1.56-4.22c0-1.42-0.41-2.5-1.24-3.23   c-0.82-0.73-2-1.1-3.54-1.1c-1.99,0-3.46,0.69-4.43,2.07s-1.54,3.62-1.73,6.73l-8.8-0.34c0.11-5.23,1.45-9.12,4.02-11.69   s6.41-3.85,11.52-3.85c4.59,0,8.2,1.05,10.83,3.16C269.26,71.71,270.58,74.63,270.58,78.36z"
                    />
                  </g>
                  <g>
                    <path
                      class="st0"
                      d="M118.56,163.26c0,4.25-2.13,7.37-6.38,9.36c5.32,1.82,7.98,5.18,7.98,10.09c0,2.53-0.68,4.77-2.04,6.73   c-1.36,1.96-3.25,3.47-5.66,4.54c-2.42,1.07-5.14,1.6-8.18,1.6c-10.14,0-15.57-4.62-16.3-13.85l9.22-0.76   c0.04,4.95,2.07,7.42,6.1,7.42c1.85,0,3.36-0.58,4.52-1.74s1.74-2.59,1.74-4.3c0-1.76-0.61-3.19-1.84-4.29   c-1.23-1.1-2.96-1.64-5.21-1.64c-0.36,0-1.14,0.05-2.36,0.14v-7.73c2.6-0.04,4.64-0.5,6.1-1.38s2.19-2.15,2.19-3.79   c0-1.31-0.49-2.38-1.48-3.2c-0.98-0.82-2.23-1.24-3.72-1.24c-3.37,0-5.06,1.88-5.06,5.65l-8.91-0.76c0.3-3.84,1.74-6.83,4.33-8.98   s5.99-3.22,10.23-3.22c4.48,0,8.05,1.02,10.72,3.05C117.23,156.99,118.56,159.76,118.56,163.26z"
                    />
                  </g>
                  <g>
                    <path
                      class="st0"
                      d="M218.46,176.61v6.88h-4.5v4.83h3.93v6.69h-18.77v-6.69h5.11v-4.83h-17.48c-0.94-2.6-1.71-4.48-2.3-5.62   l20.37-25.97h9.13v24.7L218.46,176.61L218.46,176.61z M194.46,176.61h9.78v-12.22L194.46,176.61z"
                    />
                  </g>
                  <g>
                    <path
                      class="st0"
                      d="M310.12,163.01l-0.76-3.09h-11.58l-0.84,8.96c2.34-1.22,4.81-1.83,7.42-1.83c3,0,5.63,0.6,7.91,1.81   c2.28,1.21,4.04,2.89,5.28,5.03c1.25,2.15,1.87,4.48,1.87,7.01c0,2.92-0.67,5.51-2.01,7.76s-3.25,3.96-5.72,5.14   s-5.36,1.77-8.66,1.77c-4.46,0-8.09-1.08-10.89-3.25c-2.8-2.16-4.47-5.18-5.02-9.06l9.05-1.1c0.66,4.1,2.73,6.15,6.21,6.15   c2.08,0,3.66-0.58,4.75-1.74s1.63-2.82,1.63-4.97c0-4.76-2.05-7.14-6.15-7.14c-2.42,0-4.45,1.13-6.1,3.4l-7.48-3.68l1.49-21.53   h26.78v10.34h-7.18L310.12,163.01L310.12,163.01z"
                    />
                  </g>
                  <g>
                    <path
                      class="st0"
                      d="M102.38,357.69v7.25H80.8v-7.25h5.82v-27.85h-6.21v-7.25h16.52v35.1H102.38z"
                    />
                    <path
                      class="st0"
                      d="M134.2,343.64c0,7.14-1.36,12.57-4.07,16.28c-2.72,3.72-6.63,5.58-11.75,5.58c-5.13,0-9.05-1.86-11.76-5.58   s-4.06-9.15-4.06-16.28c0-6.97,1.34-12.35,4.03-16.13s6.62-5.68,11.79-5.68c5.23,0,9.17,1.85,11.83,5.55S134.2,336.5,134.2,343.64z    M113.21,343.64c0,4.91,0.42,8.54,1.26,10.9c0.84,2.36,2.16,3.54,3.96,3.54s3.11-1.18,3.93-3.54c0.82-2.36,1.24-6,1.24-10.9   c0-9.67-1.72-14.5-5.17-14.5c-1.8,0-3.12,1.21-3.96,3.63C113.63,335.18,113.21,338.81,113.21,343.64z"
                    />
                  </g>
                  <g>
                    <path
                      class="st0"
                      d="M202.81,357.69v7.25h-21.58v-7.25h5.82v-27.85h-6.21v-7.25h16.52v35.1H202.81z"
                    />
                    <path
                      class="st0"
                      d="M228.7,357.69v7.25h-21.58v-7.25h5.82v-27.85h-6.21v-7.25h16.52v35.1H228.7z"
                    />
                  </g>
                  <g>
                    <path
                      class="st0"
                      d="M298.58,357.69v7.25H277v-7.25h5.82v-27.85h-6.21v-7.25h16.52v35.1H298.58z"
                    />
                    <path
                      class="st0"
                      d="M332.14,333.75c0,2.15-0.48,4.03-1.45,5.62s-2.26,3.04-3.89,4.34s-4,2.89-7.11,4.76   c-5.06,3.11-7.86,6.18-8.4,9.22h12.79v-5.48h8.57l-0.51,12.73h-30.86c0-3.77,0.45-6.87,1.35-9.3s2.32-4.67,4.26-6.7   s4.79-4.44,8.56-7.24c1.99-1.46,3.5-2.88,4.54-4.24c1.04-1.37,1.56-2.77,1.56-4.21c0-1.42-0.41-2.5-1.24-3.23   c-0.82-0.73-2-1.1-3.54-1.1c-1.99,0-3.46,0.69-4.43,2.07s-1.54,3.62-1.73,6.73l-8.8-0.34c0.11-5.23,1.45-9.12,4.02-11.69   c2.57-2.57,6.41-3.85,11.52-3.85c4.59,0,8.2,1.05,10.83,3.16C330.83,327.1,332.14,330.02,332.14,333.75z"
                    />
                  </g>
                  <g>
                    <path
                      class="st0"
                      d="M75.65,249.67l-8.35,0.73c-0.96-3.52-2.8-5.28-5.54-5.28c-2.02,0-3.65,0.93-4.88,2.8s-1.96,4.52-2.21,7.97   c2.27-1.46,4.88-2.19,7.84-2.19c2.68,0,5.05,0.59,7.12,1.76c2.07,1.17,3.68,2.76,4.83,4.78c1.15,2.01,1.73,4.29,1.73,6.81   c0,4.53-1.3,8.11-3.91,10.73c-2.6,2.62-6.19,3.93-10.76,3.93c-5.83,0-10.12-1.73-12.88-5.2c-2.76-3.47-4.15-8.84-4.15-16.13   c0-7.33,1.49-12.88,4.48-16.66s7.39-5.68,13.19-5.68C70.24,238.03,74.73,241.91,75.65,249.67z M54.63,262.79   c0,4.14,0.57,7.12,1.7,8.94s2.85,2.73,5.16,2.73c1.5,0,2.72-0.59,3.65-1.79c0.94-1.19,1.4-2.87,1.4-5.04   c0-2.04-0.52-3.62-1.57-4.74c-1.05-1.11-2.5-1.67-4.36-1.67C58.85,261.22,56.86,261.74,54.63,262.79z"
                    />
                  </g>
                  <g>
                    <path
                      class="st0"
                      d="M157.78,273.89h6.1v7.25h-22.62v-7.25h5.82c0.62-4.87,2.06-9.82,4.33-14.85s5.12-9.42,8.57-13.17h-13.94v5.84   h-7.76v-12.93h31.75v5.45c-2.17,2.08-4.19,4.82-6.06,8.23c-1.86,3.41-3.34,7.03-4.44,10.85   C158.44,267.15,157.85,270.67,157.78,273.89z"
                    />
                  </g>
                  <g class="relative">
                    <path
                      class="st0"
                      d="M266.11,249.13c0,4.07-2.07,7.31-6.21,9.72c4.98,2,7.48,5.48,7.48,10.43c0,3.95-1.45,7.01-4.34,9.17   s-6.8,3.25-11.7,3.25c-3.15,0-5.92-0.51-8.32-1.52s-4.25-2.47-5.55-4.37s-1.95-4.13-1.95-6.7c0-4.68,2.53-8.1,7.59-10.26   c-4.22-2.34-6.32-5.53-6.32-9.55c0-2.25,0.64-4.23,1.92-5.94s3.04-3.03,5.25-3.95c2.22-0.92,4.7-1.38,7.43-1.38   c2.79,0,5.31,0.45,7.56,1.35c2.25,0.9,4,2.18,5.27,3.85C265.47,244.9,266.11,246.87,266.11,249.13z M251.49,274.65   c1.72,0,3.13-0.59,4.23-1.78c1.1-1.19,1.64-2.67,1.64-4.45c0-1.76-0.56-3.25-1.67-4.48s-2.53-1.84-4.26-1.84   c-1.65,0-3.02,0.61-4.12,1.84c-1.1,1.23-1.64,2.72-1.64,4.48c0,1.78,0.55,3.26,1.64,4.45   C248.42,274.06,249.81,274.65,251.49,274.65z M251.38,255.71c1.54,0,2.76-0.48,3.68-1.45c0.92-0.96,1.38-2.27,1.38-3.92   c0-1.61-0.44-2.88-1.32-3.82s-2.15-1.41-3.79-1.41c-1.46,0-2.64,0.44-3.54,1.32s-1.35,2.22-1.35,4.02c0,1.54,0.46,2.8,1.38,3.78   C248.73,255.22,249.92,255.71,251.38,255.71z"
                    ></path>
                  </g>
                  <g>
                    <path
                      class="st0"
                      d="M366.19,259.78c0,14.61-5.77,21.92-17.31,21.92c-3.92,0-7.09-1.04-9.53-3.11c-2.44-2.07-3.79-4.96-4.07-8.67   l8.23-0.82c0.58,1.87,1.29,3.26,2.14,4.15c0.84,0.89,1.9,1.33,3.18,1.33c1.99,0,3.58-0.92,4.79-2.77c1.21-1.84,2.01-4.53,2.4-8.05   c-1.2,0.64-2.51,1.14-3.92,1.5s-2.85,0.55-4.31,0.55c-2.57,0-4.85-0.6-6.84-1.81c-2-1.21-3.55-2.84-4.66-4.89   c-1.12-2.05-1.67-4.27-1.67-6.65c0-2.79,0.63-5.28,1.9-7.47c1.26-2.19,3.04-3.9,5.33-5.13c2.29-1.23,4.93-1.84,7.92-1.84   C360.71,238.03,366.19,245.28,366.19,259.78z M350.09,258.52c1.72,0,3.74-0.55,6.04-1.66v-0.17c0-3.58-0.62-6.38-1.87-8.42   c-1.25-2.03-3-3.05-5.27-3.05c-1.35,0-2.49,0.61-3.43,1.84s-1.41,2.82-1.41,4.79c0,2.02,0.55,3.64,1.64,4.85   C346.9,257.92,348.33,258.52,350.09,258.52z"
                    />
                  </g>
                  <g>
                    <g>
                      <g>
                        <path
                          class="st2"
                          d="M157.34,147.56h-6.79v-1.78h2.15v-5.38h-2.15v-1.66c0.33,0,0.64-0.02,0.94-0.06c0.3-0.04,0.55-0.11,0.75-0.2     c0.24-0.11,0.41-0.26,0.53-0.44s0.19-0.41,0.21-0.68h2.26v8.43h2.1V147.56z"
                        />
                      </g>
                      <g>
                        <path
                          class="st2"
                          d="M256.86,147.56h-7.99v-1.68c0.61-0.44,1.22-0.91,1.84-1.41c0.61-0.5,1.1-0.92,1.47-1.29     c0.55-0.53,0.94-1,1.18-1.39s0.35-0.79,0.35-1.18c0-0.46-0.15-0.82-0.45-1.08c-0.3-0.25-0.73-0.38-1.29-0.38     c-0.42,0-0.86,0.09-1.32,0.26c-0.46,0.17-0.89,0.39-1.29,0.66h-0.22v-2.26c0.32-0.14,0.8-0.28,1.43-0.42     c0.63-0.14,1.26-0.21,1.9-0.21c1.28,0,2.25,0.27,2.92,0.8c0.67,0.54,1,1.29,1,2.27c0,0.64-0.16,1.25-0.48,1.83     s-0.81,1.18-1.47,1.79c-0.41,0.38-0.83,0.73-1.25,1.06c-0.42,0.32-0.72,0.54-0.9,0.67h4.57V147.56z"
                        />
                      </g>
                      <g>
                        <path
                          class="st2"
                          d="M108.66,228.14c0.22,0.19,0.4,0.42,0.53,0.67s0.2,0.59,0.2,1.02c0,0.48-0.09,0.92-0.28,1.34     s-0.48,0.77-0.87,1.07c-0.38,0.29-0.83,0.51-1.35,0.66c-0.52,0.15-1.15,0.23-1.88,0.23c-0.84,0-1.57-0.07-2.17-0.2     s-1.09-0.28-1.47-0.44v-2.24h0.27c0.39,0.24,0.86,0.44,1.4,0.62s1.04,0.26,1.49,0.26c0.26,0,0.55-0.02,0.86-0.06     c0.31-0.04,0.57-0.14,0.79-0.28c0.17-0.11,0.3-0.25,0.4-0.41c0.1-0.16,0.15-0.4,0.15-0.7c0-0.3-0.07-0.53-0.21-0.69     c-0.14-0.16-0.32-0.28-0.54-0.34c-0.22-0.07-0.49-0.11-0.81-0.12c-0.31-0.01-0.61-0.01-0.88-0.01h-0.56v-1.82h0.58     c0.36,0,0.67-0.01,0.94-0.03s0.51-0.08,0.7-0.16c0.2-0.09,0.35-0.2,0.45-0.35c0.1-0.14,0.15-0.35,0.15-0.63     c0-0.21-0.05-0.37-0.16-0.5c-0.1-0.13-0.24-0.22-0.4-0.3c-0.18-0.08-0.39-0.14-0.63-0.16s-0.45-0.04-0.62-0.04     c-0.43,0-0.89,0.07-1.39,0.22c-0.5,0.15-0.99,0.36-1.46,0.64h-0.25v-2.21c0.37-0.15,0.88-0.29,1.52-0.43     c0.64-0.14,1.29-0.21,1.96-0.21c0.64,0,1.21,0.06,1.69,0.17c0.48,0.11,0.88,0.26,1.2,0.45c0.37,0.22,0.65,0.49,0.83,0.81     s0.27,0.69,0.27,1.12c0,0.57-0.18,1.07-0.53,1.51c-0.35,0.44-0.81,0.73-1.39,0.85v0.1c0.23,0.03,0.48,0.1,0.74,0.19     S108.44,227.96,108.66,228.14z"
                        />
                      </g>
                      <g>
                        <path
                          class="st2"
                          d="M63.97,315.02c0,0.52-0.1,1.02-0.29,1.48s-0.46,0.85-0.81,1.16c-0.37,0.34-0.8,0.59-1.28,0.77     s-1.05,0.27-1.71,0.27c-0.61,0-1.17-0.08-1.67-0.25c-0.5-0.17-0.94-0.42-1.3-0.76c-0.41-0.39-0.73-0.9-0.95-1.52     s-0.33-1.36-0.33-2.22c0-0.89,0.1-1.69,0.31-2.38s0.54-1.31,1-1.84c0.45-0.51,1.03-0.91,1.74-1.19s1.56-0.42,2.55-0.42     c0.33,0,0.7,0.02,1.09,0.07s0.65,0.08,0.77,0.1v2h-0.26c-0.12-0.06-0.33-0.13-0.63-0.2c-0.29-0.07-0.63-0.11-1-0.11     c-0.88,0-1.56,0.21-2.04,0.64s-0.78,1.03-0.88,1.79c0.35-0.21,0.72-0.38,1.11-0.51c0.39-0.13,0.81-0.2,1.26-0.2     c0.4,0,0.76,0.05,1.1,0.14s0.66,0.24,0.95,0.44c0.38,0.26,0.68,0.62,0.91,1.07S63.97,314.35,63.97,315.02z M60.86,316.56     c0.14-0.16,0.25-0.34,0.34-0.55c0.08-0.21,0.13-0.5,0.13-0.86c0-0.33-0.05-0.6-0.14-0.82c-0.1-0.21-0.23-0.38-0.4-0.51     c-0.16-0.13-0.36-0.22-0.58-0.26c-0.22-0.05-0.45-0.07-0.69-0.07c-0.2,0-0.41,0.02-0.63,0.07s-0.42,0.1-0.6,0.17     c0,0.05,0,0.12-0.01,0.23s-0.01,0.24-0.01,0.4c0,0.56,0.06,1.02,0.17,1.38c0.11,0.36,0.26,0.64,0.45,0.83     c0.13,0.14,0.28,0.25,0.45,0.31c0.17,0.07,0.36,0.1,0.56,0.1c0.15,0,0.32-0.03,0.5-0.1S60.73,316.7,60.86,316.56z"
                        />
                      </g>
                      <g>
                        <path
                          class="st2"
                          d="M159.99,310.33l-4.61,8.16h-2.93l4.78-8.23h-5.23v-1.95h7.98V310.33z"
                        />
                      </g>
                      <g>
                        <path
                          class="st2"
                          d="M141.62,390.54h-7.42v-1.78h2.34v-5.38h-2.34v-1.66c0.36,0,0.7-0.02,1.03-0.06c0.33-0.04,0.6-0.11,0.82-0.2     c0.26-0.11,0.45-0.26,0.58-0.44c0.13-0.18,0.2-0.41,0.22-0.68h2.47v8.43h2.29V390.54z"
                        />
                        <path
                          class="st2"
                          d="M153.11,385.44c0,0.86-0.08,1.62-0.25,2.3c-0.17,0.68-0.43,1.23-0.79,1.67c-0.37,0.44-0.84,0.78-1.42,1     c-0.58,0.23-1.29,0.34-2.13,0.34c-0.83,0-1.54-0.11-2.13-0.34c-0.59-0.23-1.06-0.57-1.42-1.01c-0.37-0.45-0.63-1-0.8-1.67     c-0.16-0.66-0.25-1.42-0.25-2.28c0-0.88,0.08-1.65,0.25-2.3c0.17-0.65,0.44-1.2,0.81-1.66c0.37-0.45,0.85-0.78,1.43-1.01     s1.29-0.33,2.1-0.33c0.85,0,1.56,0.12,2.14,0.35s1.05,0.57,1.42,1.03c0.36,0.45,0.63,1,0.79,1.65     C153.03,383.83,153.11,384.58,153.11,385.44z M150.22,385.44c0-1.23-0.13-2.11-0.4-2.63c-0.26-0.52-0.7-0.78-1.31-0.78     s-1.05,0.26-1.31,0.78c-0.26,0.52-0.4,1.4-0.4,2.65c0,1.21,0.13,2.08,0.4,2.61c0.27,0.53,0.7,0.8,1.31,0.8     c0.6,0,1.04-0.27,1.31-0.8C150.09,387.53,150.22,386.66,150.22,385.44z"
                        />
                      </g>
                      <g>
                        <path
                          class="st2"
                          d="M203.7,401.39h-6.79v-1.78h2.15v-5.38h-2.15v-1.66c0.33,0,0.64-0.02,0.94-0.06c0.3-0.04,0.55-0.11,0.75-0.2     c0.24-0.11,0.41-0.26,0.53-0.44c0.12-0.18,0.19-0.41,0.21-0.68h2.26v8.43h2.1V401.39z"
                        />
                        <path
                          class="st2"
                          d="M213.09,401.39h-6.79v-1.78h2.15v-5.38h-2.15v-1.66c0.33,0,0.64-0.02,0.94-0.06c0.3-0.04,0.55-0.11,0.75-0.2     c0.24-0.11,0.42-0.26,0.53-0.44c0.12-0.18,0.19-0.41,0.21-0.68h2.26v8.43h2.1V401.39z"
                        />
                      </g>
                      <g>
                        <path
                          class="st2"
                          d="M259.55,389.87h-6.79v-1.78h2.15v-5.38h-2.15v-1.66c0.33,0,0.64-0.02,0.94-0.06c0.3-0.04,0.55-0.11,0.75-0.2     c0.24-0.11,0.41-0.26,0.53-0.44c0.12-0.18,0.19-0.41,0.21-0.68h2.26v8.43h2.1V389.87z"
                        />
                        <path
                          class="st2"
                          d="M270.15,389.87h-7.99v-1.68c0.61-0.44,1.22-0.91,1.83-1.41c0.61-0.5,1.1-0.92,1.47-1.29     c0.55-0.53,0.94-1,1.18-1.39s0.35-0.79,0.35-1.18c0-0.46-0.15-0.82-0.45-1.08c-0.3-0.25-0.73-0.38-1.29-0.38     c-0.42,0-0.86,0.09-1.32,0.26c-0.46,0.17-0.89,0.39-1.29,0.66h-0.22v-2.26c0.32-0.14,0.8-0.28,1.43-0.42     c0.63-0.14,1.26-0.21,1.9-0.21c1.28,0,2.25,0.27,2.92,0.8c0.67,0.54,1,1.29,1,2.27c0,0.64-0.16,1.25-0.48,1.83     s-0.81,1.18-1.47,1.79c-0.42,0.38-0.83,0.73-1.25,1.06c-0.42,0.32-0.72,0.54-0.9,0.67h4.57V389.87z"
                        />
                      </g>
                      <g>
                        <path
                          class="st2"
                          d="M256.44,315.65c0,0.9-0.38,1.64-1.15,2.21c-0.77,0.57-1.82,0.86-3.16,0.86c-0.75,0-1.4-0.08-1.93-0.23     c-0.54-0.16-0.98-0.37-1.33-0.64c-0.35-0.27-0.6-0.58-0.77-0.94c-0.17-0.36-0.25-0.75-0.25-1.16c0-0.51,0.15-0.96,0.45-1.36     c0.3-0.39,0.81-0.74,1.55-1.04v-0.04c-0.59-0.27-1.03-0.62-1.31-1.03c-0.28-0.42-0.42-0.9-0.42-1.44c0-0.81,0.37-1.47,1.12-1.98     c0.75-0.51,1.72-0.77,2.92-0.77c1.26,0,2.24,0.23,2.96,0.7c0.71,0.47,1.07,1.09,1.07,1.87c0,0.48-0.15,0.91-0.45,1.29     s-0.76,0.7-1.38,0.96v0.04c0.71,0.27,1.24,0.63,1.58,1.09S256.44,315.03,256.44,315.65z M253.82,315.76     c0-0.33-0.08-0.58-0.25-0.75s-0.5-0.36-1.01-0.58c-0.15-0.07-0.37-0.15-0.66-0.25s-0.53-0.19-0.72-0.26     c-0.2,0.18-0.37,0.39-0.53,0.65c-0.16,0.25-0.24,0.54-0.24,0.85c0,0.47,0.17,0.85,0.51,1.13c0.34,0.28,0.78,0.42,1.33,0.42     c0.15,0,0.32-0.02,0.51-0.06c0.2-0.04,0.37-0.11,0.51-0.2c0.16-0.1,0.3-0.23,0.4-0.37C253.77,316.19,253.82,316,253.82,315.76z      M253.61,310.94c0-0.35-0.13-0.62-0.4-0.83s-0.62-0.31-1.06-0.31c-0.16,0-0.33,0.02-0.5,0.06s-0.33,0.1-0.47,0.18     c-0.13,0.08-0.24,0.18-0.33,0.3c-0.09,0.13-0.13,0.27-0.13,0.43c0,0.27,0.08,0.48,0.23,0.63c0.15,0.15,0.4,0.3,0.75,0.45     c0.13,0.05,0.3,0.12,0.52,0.2c0.22,0.08,0.49,0.17,0.8,0.27c0.21-0.25,0.36-0.47,0.45-0.66     C253.57,311.47,253.61,311.23,253.61,310.94z"
                        />
                      </g>
                      <g>
                        <path
                          class="st2"
                          d="M352.41,312.83c0,0.85-0.11,1.65-0.33,2.38s-0.55,1.35-1,1.85c-0.48,0.54-1.08,0.95-1.8,1.22     c-0.71,0.27-1.55,0.41-2.5,0.41c-0.34,0-0.7-0.02-1.09-0.06c-0.39-0.04-0.65-0.08-0.77-0.11v-2h0.27     c0.14,0.06,0.33,0.13,0.58,0.21s0.6,0.11,1.04,0.11c0.36,0,0.7-0.04,1.05-0.13c0.34-0.09,0.64-0.23,0.88-0.42     c0.27-0.21,0.49-0.46,0.66-0.77c0.17-0.31,0.29-0.68,0.35-1.11c-0.4,0.23-0.76,0.4-1.1,0.52c-0.34,0.12-0.76,0.18-1.27,0.18     c-0.38,0-0.75-0.05-1.1-0.14c-0.35-0.09-0.67-0.24-0.95-0.43c-0.38-0.27-0.68-0.62-0.91-1.06s-0.34-1-0.34-1.67     c0-1.09,0.38-1.98,1.14-2.67s1.75-1.03,2.98-1.03c0.63,0,1.2,0.08,1.69,0.25c0.49,0.17,0.91,0.42,1.27,0.76     c0.42,0.39,0.73,0.89,0.94,1.5C352.31,311.22,352.41,311.95,352.41,312.83z M349.79,312.44c0-0.56-0.05-1.01-0.16-1.37     c-0.11-0.36-0.25-0.64-0.44-0.83c-0.13-0.15-0.28-0.25-0.46-0.32c-0.17-0.06-0.36-0.1-0.55-0.1c-0.18,0-0.35,0.03-0.51,0.09     c-0.16,0.06-0.32,0.17-0.47,0.32c-0.14,0.15-0.26,0.34-0.34,0.58s-0.13,0.52-0.13,0.85c0,0.32,0.05,0.59,0.14,0.8     s0.23,0.39,0.4,0.52c0.16,0.12,0.35,0.21,0.56,0.26c0.22,0.05,0.45,0.08,0.71,0.08c0.21,0,0.43-0.03,0.67-0.08     c0.24-0.05,0.43-0.11,0.56-0.16c0-0.05,0-0.12,0.01-0.22C349.79,312.77,349.79,312.63,349.79,312.44z"
                        />
                      </g>
                      <g>
                        <path
                          class="st2"
                          d="M208.67,230.57h-1.39v2.37h-2.51v-2.37h-4.93v-1.93l4.76-5.91h2.67v5.97h1.39V230.57z M204.78,228.71v-3.68     l-2.96,3.68H204.78z"
                        />
                      </g>
                      <g>
                        <path
                          class="st2"
                          d="M303.64,229.47c0,0.53-0.1,1.02-0.3,1.47c-0.2,0.45-0.49,0.84-0.87,1.17c-0.42,0.35-0.9,0.6-1.45,0.77     c-0.54,0.17-1.17,0.25-1.86,0.25c-0.82,0-1.5-0.07-2.07-0.19c-0.56-0.13-1.02-0.27-1.38-0.42v-2.25h0.29     c0.42,0.25,0.86,0.45,1.34,0.62s0.96,0.25,1.43,0.25c0.29,0,0.6-0.03,0.93-0.1c0.33-0.07,0.6-0.19,0.8-0.36     c0.16-0.14,0.27-0.29,0.35-0.43c0.08-0.15,0.12-0.37,0.12-0.68c0-0.24-0.05-0.44-0.16-0.61c-0.11-0.17-0.25-0.31-0.41-0.41     c-0.25-0.15-0.54-0.25-0.89-0.3c-0.35-0.05-0.66-0.07-0.94-0.07c-0.41,0-0.8,0.04-1.18,0.11c-0.38,0.07-0.71,0.14-0.99,0.21h-0.3     v-5.74h7.24v1.95h-4.77v1.66c0.14-0.01,0.32-0.02,0.54-0.02c0.22-0.01,0.41-0.01,0.57-0.01c0.56,0,1.06,0.05,1.5,0.16     c0.44,0.11,0.82,0.26,1.14,0.45c0.42,0.25,0.74,0.58,0.97,1C303.52,228.33,303.64,228.85,303.64,229.47z"
                        />
                      </g>
                      <g>
                        <rect
                          x="203.13"
                          y="111.03"
                          class="st2"
                          width="1.28"
                          height="15.02"
                        />
                        <g>
                          <polygon
                            class="st2"
                            points="198.55,115.26 199.49,116.13 203.77,111.52 208.05,116.13 208.99,115.26 203.77,109.64     "
                          />
                        </g>
                      </g>
                    </g>
                    <path
                      class="st3"
                      d="M396.37,213.02c-8.42-7.48-19.68,0.3-25.88-5.9c-6.21-6.21-11.58-56.84-17.13-74.56   c-5.55-17.72-16.33-11.93-25.59-13.62c-6.08-1.11-11.29-5.19-14.1-24.76c-3.53-24.59-9.52-36.2-9.52-36.2s-7.89-4.6-25.02-12.64   c-35.08-16.46-74.96-15.21-74.96-15.21h-0.15v-0.02c-0.11,0-0.19,0-0.26,0.01c-0.06,0-0.14,0-0.26-0.01v0.01h-0.15   c0,0-39.88-1.25-74.96,15.21c-17.14,8.04-25.02,12.64-25.02,12.64s-5.99,11.61-9.52,36.2c-2.81,19.57-8.02,23.65-14.1,24.76   c-9.26,1.69-20.05-4.11-25.59,13.62c-5.55,17.72-10.92,68.35-17.13,74.56c-6.21,6.21-17.47-1.57-25.88,5.9   c-6.31,5.61-24.31,21.1,5.6,95.19c12.14,30.07,30.78,55.5,47.64,74.16c25.75,28.49,43.22,37.05,62.66,23.34   c11.33-7.99,28.9-9.35,36.52-3.25c6.56,5.25,5.94,13.11-1.74,13.92c-7.68,0.82-12.52,5.55-9.89,11.46   c2.66,5.99,19.68,7.44,29.6,7.23c10.04-0.21,14.3-3.16,14.3-3.16s0.15-15.18,2-21.78c1.16-4.16,5.16-4.22,5.16-4.22h0.54h0.51h0.54   c0,0,4,0.06,5.16,4.22c1.85,6.6,2,21.78,2,21.78s4.26,2.95,14.3,3.16c9.91,0.2,26.94-1.25,29.6-7.23   c2.63-5.91-2.22-10.64-9.89-11.46c-7.68-0.82-8.3-8.67-1.74-13.92c7.61-6.1,25.19-4.74,36.52,3.25   c19.43,13.71,36.91,5.15,62.66-23.34c16.86-18.66,35.5-44.09,47.64-74.16C420.68,234.11,402.68,218.63,396.37,213.02z"
                    />
                  </g>
                  <g>
                    <g
                      v-for="n in 12"
                      :key="n"
                      class="cursor-pointer wellstrokeactive"
                    >
                      <circle
                        class="wellstrokecircle"
                        :cx="wellCoordinates[n - 1].x + 41.5"
                        :cy="wellCoordinates[n - 1].y + 42"
                        r="42.5"
                      />
                      <circle
                        class="strokecircle2"
                        :cx="wellCoordinates[n - 1].x + 41.5"
                        :cy="wellCoordinates[n - 1].y + 42"
                        r="42.5"
                      />
                      <clipped
                        v-if="
                          puck['well' + n] &&
                            puck['well' + n].boxType &&
                            puck['well' + n].boxType == 'Clipped'
                        "
                        :x="wellCoordinates[n - 1].x + 'px'"
                        :y="wellCoordinates[n - 1].y + 'px'"
                        width="83"
                        height="84"
                        :show-grid-clips="gridClips(index, n)"
                        :data-well-number1="n"
                        :well-number="n"
                        class="well-clipped"
                        :class="
                          `well-${puck['well' + n]?.color?.replace(
                            /\s+/g,
                            '-'
                          )}-clipped`
                        "
                      ></clipped>
                      <unclipped
                        v-else-if="
                          puck['well' + n] &&
                            puck['well' + n].boxType &&
                            puck['well' + n].boxType == 'Unclipped'
                        "
                        :show-grid-clips="gridClips(index, n)"
                        :x="wellCoordinates[n - 1].x + 'px'"
                        :y="wellCoordinates[n - 1].y + 'px'"
                        width="83"
                        height="84"
                        :well-number="n"
                        :data-well-number1="n"
                        class="well-unclipped"
                        :class="
                          `well-${puck['well' + n].color.replace(
                            /\s+/g,
                            '-'
                          )}-unclipped`
                        "
                      ></unclipped>

                      <image
                        v-else
                        :x="wellCoordinates[n - 1].x + 'px'"
                        :y="wellCoordinates[n - 1].y + 'px'"
                        width="83"
                        height="84"
                        :data-well-number1="n"
                        class="boxonlysvg isanemptysvg"
                        href="assets/images/emptybox.svg"
                      />
                    </g>
                  </g>
                </svg>
              </div>
            </div>
          </div>
          <div class="ml-4 text-xs">
            Title: {{ puck.title }}<br />Details: {{ puck.details }}
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Unclipped from "./manage-products-sections/unclipped.vue";
import Clipped from "./manage-products-sections/clipped.vue";
export default {
  props: [
    "arrayOfPucks",
    "torackid",
    "disabled",
    "side",
    "leftHasSelectedRack",
    "rightHasSelectedRack",
  ],
  data() {
    return {
      wellCoordinates: [
        { x: 111, y: 47 },
        { x: 213, y: 47 },

        { x: 63, y: 132 },
        { x: 162, y: 132 },
        { x: 261, y: 132 },

        { x: 18, y: 218 },
        { x: 114, y: 218 },
        { x: 210, y: 218 },
        { x: 306, y: 218 },

        { x: 63, y: 303 },
        { x: 162, y: 303 },
        { x: 261, y: 303 },
      ],
    };
  },
  mounted() {
    console.log(this.arrayOfPucks, "array of pucks");
    this.addCssForBoxColors();
  },
  computed: {
    activePuckData() {
      return this.arrayOfPucks;
    },
    rackIsEmptyCompute() {
      if (
        this.side == "left" &&
        this.leftHasSelectedRack &&
        this.arrayOfPucks.length == 0
      )
        return "Empty Rack";
      else if (
        this.side == "right" &&
        this.rightHasSelectedRack &&
        this.arrayOfPucks.length == 0
      )
        return "Empty Rack";
      else if (this.side == "left" && !this.leftHasSelectedRack)
        return "Select a transport rack above.";
      else if (this.side == "right" && !this.rightHasSelectedRack)
        return "Select a rack above.";
      else return "";
    },
  },
  components: { Unclipped, Clipped },
  methods: {
    addCssForBoxColors() {
      if (!document.getElementById("singletonBoxColors")) {
        const styleElem = document.createElement("style");
        document.head.append(styleElem);
        let css = "";
        styleElem.id = "singletonBoxColors";
        styleElem.type = "text/css";

        this.$store.state.boxColors.forEach((color) => {
          if (color == "white") {
            css += `.well-white-clipped .st2 {
           stroke:${this.$store.state.transformBoxColor(
             color,
             true
           )} !important;}`;
          }

          css += `.well-${color.replace(/\s+/g, "-")}-clipped .st0 {
        fill:${this.$store.state.transformBoxColor(color)} !important;
        }
        .well-${color.replace(/\s+/g, "-")}-clipped .st1 {
        fill:${this.$store.state.transformBoxColor(color, true)} !important;
        }

        `;
        });

        this.$store.state.boxColors.forEach((color) => {
          if (color == "white") {
            css += `.well-white-unclipped .st2 {
           stroke:${this.$store.state.transformBoxColor(
             color,
             true
           )} !important;}`;
          }
          css += `.well-${color.replace(/\s+/g, "-")}-unclipped .st0 {
        fill:${this.$store.state.transformBoxColor(color, true)} !important;
        }
        .well-${color.replace(/\s+/g, "-")}-unclipped .st1 {
        fill:${this.$store.state.transformBoxColor(color)} !important;
        }

        `;
        });

        if (styleElem.styleSheet) {
          // This is required for IE8 and below.
          styleElem.styleSheet.cssText = css;
        } else {
          styleElem.appendChild(document.createTextNode(css));
        }
      }
    },
    gridClips(puckIndex, wellNum) {
      return [
        this.activePuckData[puckIndex]["well" + wellNum]?.gridTopLeft?.name,
        this.activePuckData[puckIndex]["well" + wellNum]?.gridTopRight?.name,
        this.activePuckData[puckIndex]["well" + wellNum]?.gridBottomLeft?.name,
        this.activePuckData[puckIndex]["well" + wellNum]?.gridBottomRight?.name,
      ];
    },
    parentCall(puck) {
      const puckref = this.$refs["puck-" + puck._id];
      console.log(puckref);
      this.$emit("show-puck", puck, puckref, this.side);
    },

    addAPuckToRack(event, puckid, rackid) {
      if (!rackid) {
        const unassignPuck = confirm(
          "Are you sure you want to unassign this puck?"
        );
        if (!unassignPuck) {
          event.target.checked = false;
          return;
        }
      }

      this.$store.dispatch("pushPuckToRack", {
        puckid,
        rackid,
        fromtransport: true,
      });
    },
  },
};
</script>

<style scoped>
.gridtemplate {
  grid-template-columns: max-content 1fr;
}
</style>
