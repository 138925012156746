import { io } from "socket.io-client";

let URL = process.env.VUE_APP_HOST;
//only set for local server
console.log(URL);

// if (!URL && window.location.hostname != "devtest.subangstrom.com")
//   URL = "https://node.alienfiesta.com";
// else if (!URL) URL = "https://nodedev.alienfiesta.com";

const socket = io(URL, {
  autoConnect: false,
});
console.log(socket);

// socket.onAny((event, ...args) => {
//   console.log(event, args);
//   console.log("hello this is from socket client: ", socket.id);
// });

export default socket;
