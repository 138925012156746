<template>
  <div :class="[noimport ? '' : 'max-h-screen']" class="flex justify-center">
    <div
      :class="[noimport ? '' : 'max-h-screen']"
      class="cursor-auto shadow-lg flex flex-col"
      style="width: max-content"
    >
      <div
        class="bg-sky-900 flex-0 mx-auto text-xl pt-4 pb-4 w-full px-3 flex flex-col items-center relative text-white font-semibold"
      >
        {{ noimport ? "Manage Archives" : "Import Grid From Archive" }}
      </div>
      <div class="flex-0 basis-full overflow-auto">
        <div class="bg-white p-4" v-if="gridArchives.length == 0">
          <div
            class="gap-px bg-black invisible h-0"
            :class="[
              noimport ? 'grid-cols-7' : 'grid-cols-8',
              noimport ? 'grid6' : 'grid7',
            ]"
          >
            <div>Grid Name</div>
            <div>Description</div>
            <div>Image</div>
            <div>Grid Type</div>
            <div>Date Created</div>
            <div>Date Archived</div>
            <div>Delete Archive</div>
            <div v-if="!noimport">Import Archive</div>
          </div>

          No grid archives created.
        </div>
        <div
          v-else
          class="gap-px grid bg-black relative"
          :class="[
            noimport && $store.state.activeUser.role != 'lab-admin'
              ? 'grid-cols-6'
              : noimport
              ? 'grid-cols-7'
              : 'grid-cols-8',
            noimport && $store.state.activeUser.role != 'lab-admin'
              ? 'grid5'
              : noimport
              ? 'grid6'
              : 'grid7',
          ]"
        >
          <div class="sticky top-0">Grid Name</div>
          <div class="sticky top-0 z-10">Description</div>
          <div class="sticky top-0">Image</div>
          <div class="sticky top-0">Grid Type</div>
          <div class="sticky top-0">Date Created</div>
          <div class="sticky top-0">Date Archived</div>
          <div
            v-if="$store.state.activeUser.role == 'lab-admin'"
            class="sticky top-0"
          >
            Delete Archive
          </div>
          <div class="sticky top-0" v-if="!noimport">Import Archive</div>

          <template v-for="(grid, index) in gridArchives" :key="grid._id">
            <div :class="[index % 2 == 0 ? 'bg-gray-100' : 'bg-gray-300']">
              {{ grid.name }}
            </div>
            <div
              class="relative"
              :class="[index % 2 == 0 ? 'bg-gray-100' : 'bg-gray-300']"
              @mouseover="showFullDescript($event)"
              @mouseleave="hideFullDescript($event)"
            >
              <div class="text-sm descripthide">
                {{ grid.description }}
              </div>
              <!--order matters using lastelemchild-->
              <div
                class="absolute top-0 left-0 hidden bg-white rounded border-black border p-2 m-1 shadow-md text-left z-10"
                style="max-height: 300px; overflow-y: scroll"
              >
                {{ grid.description }}
              </div>
            </div>
            <div :class="[index % 2 == 0 ? 'bg-gray-100' : 'bg-gray-300']">
              <a
                :href="grid.imageurl"
                target="_blank"
                class="underline"
                :class="[grid.imageurl ? '' : 'hidden']"
                >View Image</a
              >
            </div>

            <div
              v-if="
                Array.isArray(grid.gridType) &&
                typeof grid.gridType !== 'string'
              "
              class="flex flex-col items-center justify-center"
              :class="[index % 2 == 0 ? 'bg-gray-100' : 'bg-gray-300']"
            >
              <div>
                {{
                  grid.gridType[0] && grid.gridType[0] != "Not Screened"
                    ? "Screened"
                    : ""
                }}
              </div>
              <div>{{ grid.gridType[1] ? "Imaged" : "" }}</div>

              {{ grid.gridType[2] ? "Tossed" : "" }}
            </div>
            <div
              v-else
              :class="[index % 2 == 0 ? 'bg-gray-100' : 'bg-gray-300']"
            >
              {{ grid.gridType == "Screened" ? grid.gridType : "" }}
            </div>

            <div :class="[index % 2 == 0 ? 'bg-gray-100' : 'bg-gray-300']">
              {{ $store.state.cleanDate(grid.trueDateCreated, true, true) }}
            </div>
            <div :class="[index % 2 == 0 ? 'bg-gray-100' : 'bg-gray-300']">
              {{ $store.state.cleanDate(grid.dateCreated, true, true) }}
            </div>
            <div
              v-if="$store.state.activeUser.role == 'lab-admin'"
              :class="[index % 2 == 0 ? 'bg-gray-100' : 'bg-gray-300']"
            >
              <button
                @click="deleteArchive(grid._id)"
                class="bg-red-700 rounded-md px-2 text-white"
              >
                Delete
              </button>
            </div>
            <div
              v-if="!noimport"
              :class="[index % 2 == 0 ? 'bg-gray-100' : 'bg-gray-300']"
            >
              <button
                @click="
                  bringInGrid(
                    grid.name,
                    grid.description,
                    grid.gridType,
                    grid.imageurl
                  )
                "
                class="bg-green-700 rounded-md px-2 text-white"
              >
                Import
              </button>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["noimport"],
  data() {
    return {
      gridArchives: [],
    };
  },
  methods: {
    getGridArchives() {
      this.$store
        .dispatch("getArchives", { importType: "grid" })
        .then((data) => {
          console.log(data);
          this.gridArchives = data;
        })
        .catch((err) => console.log("error from the import-inner.vue " + err));
    },
    deleteArchive(archiveid) {
      const deleteUser = confirm(
        "Are you sure you want to delete this archive?"
      );
      if (!deleteUser) {
        return;
      }
      this.$store
        .dispatch("deleteItem", {
          item: "gridarchives",
          objid: archiveid,
        })
        .then(() => {
          this.getGridArchives();
        });
    },
    showFullDescript(ev) {
      if (ev.target.classList.contains("absolute")) return;

      if (!ev.target.lastElementChild.textContent) return;

      ev.target.lastElementChild.style.width = "300px";
      ev.target.lastElementChild.classList.remove("hidden");
    },
    hideFullDescript(ev) {
      ev.target.lastElementChild.classList.add("hidden");
    },
    bringInGrid(name, description, type, imageurl) {
      if (type[0] == "Screened") type = [1, 0, 0];
      if (!type || type[0] == "Not Screened") type = [0, 0, 0];
      console.log(type);
      this.$emit("importGrid", {
        gridName: name,
        gridDescription: description,
        gridType: type,
        gridImage: imageurl,
      });
    },
  },

  mounted() {
    console.log("uh this was mounted");
    this.getGridArchives();
    this.$store.state.importModalMounted = true;
  },
  unmounted() {
    this.$store.state.importModalMounted = false;
    window.setTimeout(() => {
      if (document.getElementById("searchbarforloggedin"))
        document.getElementById("searchbarforloggedin").focus();
    }, 100);
  },
};
</script>

<style scoped>
.grid {
  align-items: stretch;
}
.grid6 {
  grid-template-columns: min-content minmax(min-content, min-content) max-content max-content max-content max-content max-content;
}
.grid5 {
  grid-template-columns: min-content minmax(min-content, min-content) max-content max-content max-content max-content;
}
.grid4 {
  grid-template-columns: min-content minmax(min-content, min-content) max-content max-content max-content;
}

.grid > div {
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.descripthide {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.grid7 > div:nth-child(-n + 8),
.grid6 > div:nth-child(-n + 7) {
  font-weight: 500;
  cursor: pointer;
  background-color: goldenrod;
}
.grid5 > div:nth-child(-n + 6) {
  font-weight: 500;
  cursor: pointer;
  background-color: goldenrod;
}
</style>
