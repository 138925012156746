<template>
  <div
    class="fixed top-0 left-0 z-50 w-screen h-screen bg-sky-900 bg-opacity-50 text-center cursor-pointer "
    @click="manualbgremoval"
  >
    <div
      @click="cancelParentRid($event)"
      class="inline-block relative -translate-y-1/2 top-1/2 max-h-screen"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: ["disablebg"],
  methods: {
    cancelParentRid(ev) {
      ev.stopPropagation();
    },
    manualbgremoval() {
      if (!this.disablebg) this.$emit("ridfeedback");
    },
  },
  beforeMount() {
    document.body.style.overflow = "hidden";
  },
  unmounted() {
    document.body.style.overflow = "visible";
  },
};
</script>
