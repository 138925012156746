<template>
  <div>
    <div
      v-if="settings != 'analytics'"
      class="relative bg-white h-20 w-[min-content] text-center"
    >
      {{ nickname }}
      <Doughnut id="my-chart-id" :options="chartOptions" :data="chartData" />
      <div
        class="absolute bot-0 -translate-y-full left-0 w-full text-center leading-none text-xl font-semibold"
      >
        {{ valueComputed }}%
      </div>
      <div class="absolute top-0 right-0 -translate-x-full flex flex-col">
        <span
          @click="setSettingsFor('settings')"
          class="material-symbols-outlined text-2xl text-gray-900 mr-3 cursor-pointer hover:text-green-800 relative -top-[4px]"
        >
          settings
        </span>
        <span
          @click="setSettingsFor('analytics')"
          class="material-symbols-outlined text-2xl text-gray-900 mr-3 cursor-pointer hover:text-green-800 relative -top-[4px]"
        >
          analytics
        </span>
      </div>
    </div>
    <div v-if="settings == 'settings'">
      <DisplayModal
        @ridfeedback="
          () => {
            const yes = win.confirm('Close without saving?');
            if (!yes) return;
            settings = false;
          }
        "
        ><div class="bg-white rounded cursor-auto min-w-[300px]">
          <div class="grid grid-cols-1 items-center py-4 px-4 w-full">
            <div>Nickname</div>
            <input type="text" v-model="localNickname" />
            <!-- <div>Min Calibration</div>
            <input class="w-20" type="number" v-model="localMinCalibration" />
            <div>Max Calibration</div>
            <input class="w-20" type="number" v-model="localMaxCalibration" />
           -->
            <div class="mt-4">Email Notifications</div>
            <div class="flex text-sm">
              Percent Below Threshold :
              <input
                @input="
                  (ev) => {
                    const value = ev.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
                    ev.target.value = value.slice(0, 2); // Limit to 2 characters
                  }
                "
                v-model="localAlertPercent"
                type="text"
                class="ml-2 w-12"
              />
            </div>
            <textarea
              v-model="localEmailNotifications"
              placeholder="Comma or new line separated emails (10 email limit)"
              rows="10"
              class="border text-xs px-1"
            ></textarea>
          </div>

          <button
            @click="saveSensor"
            class="inline-block bg-emerald-600 px-4 rounded py-1 mb-4 text-white"
          >
            Save
          </button>
        </div></DisplayModal
      >
    </div>
    <div v-else-if="settings == 'analytics'">
      <!-- <DisplayModal
        @ridfeedback="
          () => {
            const yes = win.confirm('Close without saving?');
            if (!yes) return;
            settings = false;
            resetValues();
          }
        "
        ><div class="bg-white rounded cursor-auto min-w-[300px] w-full"> -->

      <span
        @click="
          settings = false;
          resetValues();
        "
        class="text-sky-900 text-2xl cursor-pointer mb-4 font-semibold block"
        ><< BACK</span
      >

      <div>{{ localNickname }}</div>
      <div class="bg-white flex w-full items-center">
        Time Frame
        <select class="w-[min-content] ml-2">
          <option>Today</option>
        </select>
      </div>

      <div><SensorGraph :allsensordata="allsensordata" /></div>
    </div>
  </div>
</template>
<script>
import DisplayModal from "./DisplayModal.vue";
import SensorGraph from "./SensorGraph.vue";
import { Chart, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "vue-chartjs";
Chart.register(ArcElement, Tooltip, Legend);
export default {
  data() {
    return {
      settings: false,
      // localMaxCalibration: this.maxCalibration,
      // localMinCalibration: this.minCalibration,
      localEmailNotifications: "",
      localNickname: "",
      localAlertPercent: "",
    };
  },

  mounted() {
    this.resetValues();
  },
  methods: {
    resetValues() {
      this.localEmailNotifications = this.emailNotifications?.join("\n");
      this.localNickname = this.nickname;
      this.localAlertPercent = this.alertPercent;
    },
    setSettingsFor(id) {
      this.settings = id;
    },
    async saveSensor(id) {
      let emailsToAlert = this.localEmailNotifications
        .replace(/\r\n?/g, "\n") // Convert \r\n and \r to \n
        .split(/,\s*|\n+/) // Split by comma (with optional following spaces) or newline
        .filter((email) => email.trim() !== "")
        .map((email) => email.trim());

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      // Check each email for validity
      let invalidEmails = emailsToAlert.filter(
        (email) => !emailRegex.test(email)
      );

      // If there are invalid emails, alert and handle accordingly
      if (invalidEmails.length > 0) {
        return alert(
          "The following emails are invalid: " + invalidEmails.join(", ")
        );
        // Handle the invalid email scenario (e.g., stop the process, ask for re-entry, etc.)
      } else if (emailsToAlert.length > 10)
        return alert("10 email limit exceeded");

      const data = await this.$store.dispatch("updateItem", {
        objid: this.id,
        item: "sensors",
        body: {
          nickname: this.localNickname,
          emailsToAlert,
          alertPercent: this.localAlertPercent,
          // minCalibration: this.localMinCalibration,
          // maxCalibration: this.localMaxCalibration,
        },
      });
      console.log(data);
      if (data != "ok") alert("Error saving!");
      else this.settings = false;
    },
  },
  props: [
    "value",
    "nickname",
    "id",
    "allsensordata",
    "minCalibration",
    "maxCalibration",
    "emailNotifications",
    "alertPercent",
  ],
  components: { Doughnut, DisplayModal, SensorGraph },
  computed: {
    valueComputed() {
      let volt = this.value * (5.0 / 1024.0);
      let shunt_resistor = 660;
      let current = (volt / shunt_resistor) * 1000;

      //     sensorValue = analogRead(analogInPin);
      // volt = sensorValue * (5.0 / 1024.0);
      // current = (volt / shunt_resistor) * 1000;

      // float lowerVoltage = 2.4;
      // float upperVoltage = 4.92;
      // percentage = (volt - lowerVoltage)*100L / (upperVoltage-lowerVoltage);
      console.log(this.volt, this.minCalibration, this.maxCalibration);
      return (
        ((volt - this.minCalibration) * 100) /
        (this.maxCalibration - this.minCalibration)
      );
    },

    chartData() {
      return {
        datasets: [
          {
            data: [this.value, 100 - this.value], // Adjust based on value prop for dynamic changes
            backgroundColor: [
              this.value <= 33 ? "red" : this.value > 66 ? "green" : "gold", // For the "Low" segment
              "#ccc",
            ],
            borderWidth: 0, // No borders
          },
        ],
        labels: ["Low", "Medium", "High"],
      };
    },
    chartOptions() {
      return {
        rotation: 270, // Start the chart from the top
        circumference: 180, // Make the chart a semi-circle
        cutout: "80%", // New property name for cutoutPercentage in Chart.js 3.x
        plugins: {
          legend: {
            display: false, // Hide legend
          },
          tooltip: {
            enabled: false, // Disable tooltips
          },
        },
        // animation: {
        //   animateRotate: true,
        //   animateScale: true,
        // },
        responsive: true,
        maintainAspectRatio: false,
      };
    },
  },
};
</script>

<style scoped>
input {
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 0;
  padding-top: 0;
}
</style>
