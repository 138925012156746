<template>
  <main class="flex absolute inset-0 z-10 w-full bluebg" ref="mainContainer">
    <div id="inner" class="z-30 relative" ref="innerContainer">
      <div v-if="!signUpFinish">
        <div class="text-3xl text-center font-bold text-sky-900 m-4">
          Reset Password
        </div>
        <form
          @submit.prevent="onSubmit($event)"
          autocomplete="off"
          class="text-sm"
        >
          <div class="text-xs hidden errortext">
            Minimum password length: 8 characters
          </div>
          <input
            @focus="removeBadBorder($event)"
            type="password"
            placeholder="Password*"
            ref="password1"
          />
          <div class="text-xs hidden errortext">
            Confirmation password does not match
          </div>
          <input
            @focus="removeBadBorder($event)"
            type="password"
            placeholder="Confirm Password*"
            ref="password2"
          />

          <div
            class="w-full flex flex-col justify-center items-center text-lg mt-3 mb-2"
          >
            <button ref="submitterbutton" type="submit" class="relative">
              <button-inner>Reset</button-inner>
            </button>
            <div class="fadeoutbutton">Success!</div>
          </div>
        </form>

        <div class="text-xs pl-2 pb-2">*Required Field</div>
      </div>
    </div>
  </main>
</template>

<script>
import { ref, onMounted, inject, watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

export default {
  setup() {
    const store = useStore();
    const route = useRoute();
    const password1 = ref(null);
    const password2 = ref(null);
    const submitterbutton = ref(null);

    const groupType = ref(null);
    const mainContainer = ref(null);
    const innerContainer = ref(null);
    const groupNameInput = ref(null);
    const selectMenu = ref(null);
    const signUpFinish = ref(false);
    const signUpFailed = ref(false);
    const doShowForm = inject("doShowForm");
    const swapShowForm = inject("swapShowForm");

    watch(groupType, function() {
      if (groupType.value == "select") {
        groupNameInput.value.classList.remove("visible");
        groupNameInput.value.classList.add("invisible");
        selectMenu.value.classList.remove("invisible");

        selectMenu.value.classList.add("visible");
      } else {
        selectMenu.value.classList.remove("visible");
        selectMenu.value.classList.add("invisible");
        groupNameInput.value.classList.remove("invisible");
        groupNameInput.value.classList.add("visible");
      }
    });

    function reportWindowSize() {
      if (innerContainer.value) {
        if (innerContainer.value.offsetHeight < window.innerHeight * 0.8) {
          mainContainer.value.style.alignItems = "flex-start";
          innerContainer.value.style.marginTop = "10vh";
        } else {
          innerContainer.value.style.marginTop = "auto";
          mainContainer.value.style.alignItems = "center";
        }

        if (innerContainer.value.offsetHeight > window.innerHeight)
          mainContainer.value.style.height =
            innerContainer.value.offsetHeight + "px";
        else mainContainer.value.style.height = window.innerHeight + "px";
      }
    }
    window.addEventListener("resize", reportWindowSize);

    onMounted(reportWindowSize);

    function removeBadBorder(ev) {
      if (ev.target.previousElementSibling.classList.contains("errortext"))
        ev.target.previousElementSibling.classList.add("hidden");
      if (ev.target.classList.contains("bad-border")) {
        ev.target.value = "";
        ev.target.classList.remove("bad-border");
      }
    }

    function onSubmit() {
      let validEntries = true;

      if (password1.value.value.length < 8) {
        password1.value.classList.add("bad-border");
        password1.value.value = "";
        password2.value.value = "";
        password1.value.previousElementSibling.classList.remove("hidden");
        validEntries = false;
      }
      if (password1.value.value !== password2.value.value) {
        password1.value.classList.add("bad-border");
        password2.value.classList.add("bad-border");
        password2.value.previousElementSibling.classList.remove("hidden");
        validEntries = false;
      }

      if (!validEntries) return;
      const button = submitterbutton.value;

      button.disabled = "true";

      button.children[1].style.display = "none";
      button.children[0].style.display = "flex";

      let data = {
        password: password1.value.value,
        passwordConfirm: password2.value.value,
      };

      console.log(route);
      const url =
        store.state.hostname +
        "/api/v1/users/resetPassword/" +
        route.params.code;
      const request = new Request(url, {
        credentials: "include",
        method: "PATCH",
        body: JSON.stringify(data),

        headers: { "content-type": "application/json" },
      });
      fetch(request)
        .then((resp) => {
          //   console.log(resp);

          //   //this is the one that actually catches the error
          //   if (!resp.ok) {
          //     throw new Error("Error");
          //   }
          return resp.json();
        })
        .then(function(data) {
          if (data.status == "fail" || data.status == "error") {
            if (data.message == "Reset link is invalid or has expired.")
              store.state.buttonFX(button, data.message);
          } else {
            store.state.buttonFX(button, "Success!", true);
          }
          //store.dispatch("login");
        })
        .catch((err) => {
          console.log(err);
        });
    }

    function fadeOut() {
      mainContainer.value.classList.add("bluebgreverse");
      mainContainer.value.addEventListener("animationend", doShowForm);
    }

    return {
      removeBadBorder,
      mainContainer,
      doShowForm,
      submitterbutton,
      innerContainer,
      fadeOut,
      groupType,
      groupNameInput,
      selectMenu,
      swapShowForm,
      onSubmit,

      password1,
      password2,
      signUpFinish,
      signUpFailed,
    };
  },
};
</script>

<style scoped>
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(69, 69, 69);
  opacity: 1; /* Firefox */
}

input,
select {
  border-style: solid;
  border-width: 2px;
  border-radius: 6px;
  padding: 0.6rem 0.4rem;
  width: 100%;

  margin-bottom: 0.5rem;
  border-color: rgb(43, 94, 141, 0.7);
  display: block;
}

input[type="radio"] {
  padding: 0;
  margin: 0;
  width: auto;
}

input:focus {
  outline: none;
  border-color: rgb(43, 94, 141);
  color: rgb(43, 94, 141);
}
form {
  position: relative;
  width: 80%;
  margin: 0 auto;
}
main {
  align-items: center;
  font-family: Montserrat;
}
#inner {
  color: rgb(69, 69, 69);
  max-width: 524px;
  border-style: solid;
  border-width: 1vh 0 0 0;
  border-color: rgb(43, 94, 141);
  margin: auto;
  width: 100%;
  background: white;
}

button {
  margin: auto;
  cursor: pointer;
  background: none rgba(51, 92, 139, 0.76);
  border: 2px solid rgba(47, 83, 125, 0.77);
  text-align: center;
  min-height: 2rem;
  max-height: 45px;
  height: 5vh;
  max-width: 250px;
  width: 100%;
  color: rgb(255, 255, 255);

  font-family: Montserrat;
  border-radius: 26px;
  padding: auto 0;
}

button {
  margin: auto;
  cursor: pointer;
  background: none rgba(51, 92, 139);
  border: 2px solid rgba(47, 83, 125);

  text-align: center;
  margin-bottom: 1rem;
  min-height: min-content;
  max-height: 39px;
  height: 6vh;
  max-width: 162px;
  width: 100%;
  color: rgb(255, 255, 255);

  font-family: Montserrat;
  border-radius: 26px;
}

.bad-border {
  border-color: red;
}

button:hover {
  background: none rgba(55, 101, 151);
}
</style>
