<template>
  <display-modal
    disablebg="true"
    v-if="findingPuckSerial"
    @ridfeedback="findingPuckSerial = null"
  >
    <finding-puck>
      <div class="font-semibold">Finding puck '{{ findingPuckSerial }}'</div>
      {{ showFindingPuckMessage }}</finding-puck
    >
  </display-modal>

  <display-modal disablebg="true" v-if="$store.state.promptUnsaveModal">
    <unsaved-changes> </unsaved-changes>
  </display-modal>

  <search-bar :size="'w-10/12'" @pasted="startAutoNavigate"></search-bar>

  <dewar-section
    :firstdewardata="firstdewardataloaded"
    :autoclick="autoclick"
  ></dewar-section>
</template>
<script>
import SearchBar from "../../ui/SearchBar.vue";

import UnsavedChanges from "../../modal-inners/unsaved-changes.vue";
import FindingPuck from "../../modal-inners/finding-puck.vue";
import DewarSection from "./manage-products-sections/DewarSection.vue";
import { computed } from "vue";

// import { useStore } from "vuex";
// import { useRouter } from "vue-router";

export default {
  provide() {
    return {
      endPuckNavigate: this.endPuckNavigate,
      getCopiedGrid: computed(() => ({ ...this.copiedGrid })),
      aboxmoveinprogressfrommp: computed(() => this.boxmoveinprogressfrommp),
      resetaboxmoveinprogressfrommp: this.setboxmovefalse,
      setCopiedGrid: this.setCopiedGrid,
      getCopiedBox: computed(() => ({ ...this.copiedBox })),
      setCopiedBox: this.setCopiedBox,
      resetUnsavedData: this.resetUnsavedData,

      startAutoNavigate: this.startAutoNavigate,
    };
  },
  props: ["changePage"],
  components: {
    SearchBar,
    UnsavedChanges,
    FindingPuck,
    DewarSection,
  },

  data() {
    return {
      firstdewardataloaded: false,
      autoclick: false,
      showFindingPuckMessage: null,
      findingPuckSerial: null,
      copiedGrid: null,
      copiedBox: null,
      boxmoveinprogressfrommp: false,
    };
  },
  created() {
    if (this.$route.query?.search) {
      this.findingPuckSerial = this.$route.query?.search;
    }
  },
  mounted() {
    this.$store.dispatch("updateDewarData").then(() => {
      this.firstdewardataloaded = true;
      if (this.$route.query?.search) {
        document.getElementById("searchbarforloggedin").value =
          this.$route.query?.search;

        this.startAutoNavigate(this.$route.query?.search);
      } else if (this.$route.query?.navigation) {
        console.log("special param");
        const obj = JSON.parse(this.$route.query?.navigation);

        this.startAutoNavigate(obj.puck, obj.well, true);
      }
    });
  },

  methods: {
    setboxmovefalse() {
      this.boxmoveinprogressfrommp = false;
    },
    resetUnsavedData() {
      this.$store.state.unsavedChangesMPGrid = false;
      this.$store.state.unsavedChangesMPBox = false;

      if (document.getElementById("saveUserChanges")) {
        document.getElementById("saveUserChanges").style.outline = "none";
      }
      if (document.getElementById("saveBoxChanges")) {
        document.getElementById("saveBoxChanges").style.outline = "none";
      }
      if (document.getElementById("saveCreateBoxChanges")) {
        document.getElementById("saveCreateBoxChanges").style.outline = "none";
      }
    },
    setCopiedGrid(obj) {
      this.copiedGrid = obj;
    },
    setCopiedBox(obj) {
      this.copiedBox = obj;
      console.log(this.copiedBox);
    },

    endPuckNavigate() {
      this.findingPuckSerial = null;
      this.autoclick = false;
    },
    runlog() {
      console.log(this.$refs);
    },
    resetFindingData() {
      this.findingPuckSerial = null;
      this.showFindingPuckMessage = "Finding Puck " + this.findingPuckSerial;
    },
    async startAutoNavigate(serial, highlightbox, opengrids) {
      console.log("yooo", serial, highlightbox, opengrids);
      if (
        this.$store.state.unsavedChangesMPBox ||
        this.$store.state.unsavedChangesMPGrid
      ) {
        this.$store.state.promptUnsaveModal = true;

        let answer;
        try {
          answer = await this.$store.state.promptAnswerPromise();
        } catch {
          //rejected

          answer = null;
        }

        this.$store.state.promptUnsaveModal = false;
        if (!answer) {
          return;
        } else {
          this.resetUnsavedData();
        }
      }

      const resetMessage = () => {
        this.showFindingPuckMessage = "No puck found with that serial number.";
        setTimeout(() => {
          this.resetFindingData();
        }, 1000);
      };

      this.findingPuckSerial = serial;

      const allLabs = this.$store.state.dewarData;
      let activedewar;
      let activerack;
      let activepuck;
      for (const lab of allLabs) {
        if (!lab.data.length > 0) {
          continue;
        }

        for (const dewar of lab.data) {
          activedewar = dewar._id;
          console.log(activedewar);
          if (!dewar.racks.length > 0) {
            continue;
          }
          for (const [rackindex, rack] of dewar.racks.entries()) {
            activerack = "papaBgChange3-" + rackindex;

            if (!rack.pucks.length > 0) {
              continue;
            }

            for (const puck of rack.pucks) {
              activepuck = "puck-" + puck._id;
              if (
                puck.serialNo.trim().toUpperCase() !=
                this.findingPuckSerial.trim().toUpperCase()
              ) {
                continue;
              } else {
                this.showFindingPuckMessage = `Puck ${this.findingPuckSerial} found. Navigating to puck.`;

                this.autoclick = {
                  dewar: activedewar,
                  rack: activerack,
                  puck: activepuck,
                  opengrids,
                };
                if (highlightbox) {
                  this.$store.state.moveToForFollow = highlightbox;
                  this.boxmoveinprogressfrommp = true;
                }

                return;
              }
            }
          }
        }
      }
      resetMessage();
    },
  },
};
</script>
<style scoped></style>
