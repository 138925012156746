<template>
  <div :key="sensor._id" v-for="(sensor, index) in $store.state.sensors">
    <gauge
      :value="sensor.valuesForDay[sensor.valuesForDay.length - 1].number"
      :allsensordata="sensor.valuesForDay"
      :id="sensor._id"
      :nickname="sensor.nickname"
      @update="
        ($event) => {
          $store.state.sensors[index].nickname = $event.nickname;
          $store.state.sensors[index].minCalibration = $event.minCalibration;
          $store.state.sensors[index].maxCalibration = $event.maxCalibration;
          $store.state.sensors[index].alertPercent = $event.alertPercent;
          $store.state.sensors[index].emailsToAlert = $event.emailsToAlert;
        }
      "
      :emailNotifications="sensor.emailsToAlert"
      :minCalibration="sensor.minCalibration"
      :maxCalibration="sensor.maxCalibration"
      :alertPercent="sensor.alertPercent"
    />
  </div>
</template>
<script>
import Gauge from "../../ui/GaugeComp.vue";
export default {
  components: { Gauge },
  data() {
    return {};
  },
  computed: {},
  methods: {
    getSensors() {
      const url = this.$store.state.hostname + "/api/v1/sensors";

      const request = new Request(url, {
        credentials: "include",
        method: "GET",

        headers: { "content-type": "application/json" },
      });
      fetch(request)
        .then((resp) => resp.json())
        .then((data) => {
          if (data.status == "fail") throw new Error(data.message);
          else {
            console.log("data is", data.data.data);
            this.$store.state.sensors = data.data.data;
            // console.log(this.noAdminUsers);

            //arrayOfDewars.value = data.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },

  //   created() {
  //     this.socket = io(this.$store.state.hostname);

  //     this.socket.on("connect", () => {
  //       console.log("Connected to server");
  //       // Send the labId to the server to start listening for changes
  //       this.socket.emit("startListening", {
  //         labId: this.$store.state.activeUser.labId._id,
  //       });
  //     });

  //     this.socket.on("documentUpdate", (data) => {
  //       console.log("Updated document:", data);
  //       // Handle the real-time update here (e.g., update your data or UI)
  //     });
  //   },
  //   unmounted() {
  //     this.socket.off("documentUpdate");
  //     this.socket.disconnect();
  //   },

  mounted() {
    this.getSensors();
  },
};
</script>
