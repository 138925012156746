<template>
  <div class="flex justify-between">
    <div class="flex">
      <input
        type="radio"
        :name="itemType + 'Custom' + index"
        value="readwrite"
        :checked="selected == 'readwrite'"
        @change="$emit('val-change', $event)"
        @input="$emit('update:customAccess', $event.target.value)"
        class="mb-0"
        :id="itemType + 'ReadWrite' + index"
      />
      <label :for="itemType + 'ReadWrite' + index">R+W</label>
    </div>
    <div class="flex">
      <input
        type="radio"
        :name="itemType + 'Custom' + index"
        value="readonly"
        @change="$emit('val-change', $event)"
        :checked="selected == 'readonly'"
        @input="$emit('update:customAccess', $event.target.value)"
        class="mb-0"
        :id="itemType + 'Read' + index"
      />
      <label :for="itemType + 'Read' + index">Read</label>
    </div>
    <div class="flex">
      <input
        type="radio"
        :name="itemType + 'Custom' + index"
        value="delete"
        :checked="selected == 'noaccess'"
        @change="$emit('val-change', $event)"
        @input="$emit('update:customAccess', $event.target.value)"
        class="mb-0 mr-1"
        :id="itemType + 'Del' + index"
      />
      <label :for="itemType + 'Del' + index" class="text-red-600">DEL</label>
    </div>
  </div>
</template>

<script>
export default {
  props: ["itemType", "index", "selected"],
  emits: ["update:itemAccess", "val-change"],
  methods: {
    revokeCustom() {
      confirm("Are you sure you want to revoke this access?");
    },
  },
};
</script>

<style scoped>
input,
label {
  cursor: pointer;
}
input {
}
</style>
