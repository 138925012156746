<template>
  <main
    @mousedown="fadeOut"
    class="flex absolute inset-0 bluebg z-10 w-full"
    ref="mainContainer"
  >
    <div
      id="inner"
      class="z-30 relative"
      @mousedown="stopChildClick"
      ref="innerContainer"
    >
      <div v-show="!signUpFinish">
        <div class="text-3xl text-center font-bold text-sky-900 m-4">
          Signup
        </div>
        <form
          @submit.prevent="onSubmit($event)"
          autocomplete="off"
          class="text-sm"
        >
          <input
            @focus="removeBadBorder($event)"
            type="text"
            placeholder="First Name*"
            ref="first"
          />
          <input
            @focus="removeBadBorder($event)"
            type="text"
            placeholder="Last Name*"
            ref="last"
          />
          <div class="text-xs hidden errortext">Invalid email</div>
          <input
            @focus="removeBadBorder($event)"
            type="email"
            placeholder="Email*"
            ref="email"
          />
          <input type="tel" placeholder="Phone" ref="phone" />
          <div class="text-xs hidden errortext">
            Minimum password length: 8 characters
          </div>
          <input
            @focus="removeBadBorder($event)"
            type="password"
            placeholder="Password*"
            ref="password1"
          />
          <div class="text-xs hidden errortext">
            Confirmation password does not match
          </div>
          <input
            @focus="removeBadBorder($event)"
            type="password"
            placeholder="Confirm Password*"
            ref="password2"
          />
          <div class="text-xs hidden errortext">Invalid lab code</div>
          <input
            @focus="removeBadBorder($event)"
            type="text"
            placeholder="Lab Access Code*"
            ref="accesscode"
          />
          <!-- <div class="flex py-2 justify-evenly w-11/12 m-auto">
          <div class="flex items-center">
            <input
              v-model="groupType"
              class="flex-initial cursor-pointer"
              type="radio"
              value="select"
              id="select-radio"
              name="group-type"
            />
            <label class="cursor-pointer flex-1" for="select-radio"
              >&nbsp;Select Group</label
            >
          </div>
          <div class="flex items-center">
            <input
              v-model="groupType"
              class="flex-initial"
              type="radio"
              value="create"
              name="group-type"
              id="create-radio"
            /><label for="create-radio" class="cursor-pointer"
              >&nbsp;Create a New Group</label
            >
          </div>
        </div> -->
          <!-- <select class="absolute invisible" ref="selectMenu"
          ><option value="" disabled selected
            >Select Institution*</option
          ></select
        >
        <input
          class="invisible"
          type="text"
          placeholder="New Group Name*"
          ref="groupNameInput"
        /> -->

          <div class="w-full flex justify-center text-lg mt-3 mb-2">
            <button ref="submitterbutton" type="submit" class="relative">
              <button-inner>Signup</button-inner>
            </button>
          </div>
        </form>
        <div class="flex justify-center pb-1">
          <span class="text-sm leading-6 mr-1">Already Have an Account?</span
          ><span
            @click="swapShowForm"
            class="text-md text-green-800 font-bold cursor-pointer"
            >Login</span
          >
        </div>
        <div class="text-xs pl-2 pb-2">*Required Field</div>
      </div>
      <div v-show="signUpFinish">
        <sign-up-thanks :failed="signUpFailed"></sign-up-thanks>
      </div>
    </div>
  </main>
</template>

<script>
import { ref, onMounted, inject, watch } from "vue";
import { useStore } from "vuex";
import SignUpThanks from "./SignUpThanks.vue";

export default {
  components: { SignUpThanks },
  setup() {
    const store = useStore();
    function stopChildClick(event) {
      console.log("gree");
      event.stopPropagation();
    }
    const removeBadBorder = inject("removeBadBorder");
    const first = ref(null);
    const last = ref(null);
    const accesscode = ref(null);
    const phone = ref(null);
    const email = ref(null);
    const password1 = ref(null);
    const password2 = ref(null);

    const submitterbutton = ref(null);

    const groupType = ref(null);
    const mainContainer = ref(null);
    const innerContainer = ref(null);
    const groupNameInput = ref(null);
    const selectMenu = ref(null);
    const signUpFinish = ref(false);
    const signUpFailed = ref(false);
    const doShowForm = inject("doShowForm");
    const swapShowForm = inject("swapShowForm");

    watch(groupType, function() {
      if (groupType.value == "select") {
        groupNameInput.value.classList.remove("visible");
        groupNameInput.value.classList.add("invisible");
        selectMenu.value.classList.remove("invisible");

        selectMenu.value.classList.add("visible");
      } else {
        selectMenu.value.classList.remove("visible");
        selectMenu.value.classList.add("invisible");
        groupNameInput.value.classList.remove("invisible");
        groupNameInput.value.classList.add("visible");
      }
    });

    function reportWindowSize() {
      if (innerContainer.value) {
        if (innerContainer.value.offsetHeight < window.innerHeight * 0.8) {
          mainContainer.value.style.alignfItems = "flex-start";
          innerContainer.value.style.marginTop = "10vh";
        } else {
          innerContainer.value.style.marginTop = "auto";
          mainContainer.value.style.alignItems = "center";
        }

        if (innerContainer.value.offsetHeight > window.innerHeight)
          mainContainer.value.style.height =
            innerContainer.value.offsetHeight + "px";
        else mainContainer.value.style.height = window.innerHeight + "px";
      }
    }
    window.addEventListener("resize", reportWindowSize);

    onMounted(reportWindowSize);

    function validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }
    function onSubmit() {
      let validEntries = true;
      if (!first.value.value) {
        first.value.classList.add("bad-border");
        validEntries = false;
      }
      if (!last.value.value) {
        last.value.classList.add("bad-border");
        validEntries = false;
      }
      if (!email.value.value || !validateEmail(email.value.value)) {
        email.value.classList.add("bad-border");
        email.value.previousElementSibling.classList.remove("hidden");
        email.value.value = "";
        validEntries = false;
      }
      if (password1.value.value.length < 8) {
        password1.value.classList.add("bad-border");
        password1.value.value = "";
        password2.value.value = "";
        password1.value.previousElementSibling.classList.remove("hidden");
        validEntries = false;
      }
      if (password1.value.value !== password2.value.value) {
        password1.value.classList.add("bad-border");
        password2.value.classList.add("bad-border");
        password2.value.previousElementSibling.classList.remove("hidden");
        validEntries = false;
      }

      if (!accesscode.value.value) {
        accesscode.value.classList.add("bad-border");
        validEntries = false;
      }

      if (!validEntries) return;

      const button = submitterbutton.value;

      button.disabled = "true";

      button.children[1].style.display = "none";
      button.children[0].style.display = "flex";

      let data = {
        name: first.value.value + " " + last.value.value,
        email: email.value.value,
        password: password1.value.value,
        passwordConfirm: password2.value.value,
        accesscode: accesscode.value.value.toUpperCase(),
        phone: phone.value.value,
      };

      const url = store.state.hostname + "/api/v1/users/signup";
      const request = new Request(url, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(data),

        headers: { "content-type": "application/json" },
      });
      fetch(request)
        .then((resp) => {
          return resp.json();
        })
        .then(function(data) {
          if (data.status == "fail" || data.status == "error") {
            store.state.buttonFX(button);
            throw data;
          } else {
            store.state.buttonFX(button);
            signUpFailed.value = false;
            signUpFinish.value = true;
          }
          //store.dispatch("login");
        })
        .catch((err) => {
          console.log(err);
          store.state.buttonFX(button);
          if (err.status) {
            console.log(err.status);
            signUpFailed.value = err.message;
          } else {
            signUpFailed.value = "nointernet";
          }
          signUpFinish.value = true;
        });
    }

    function fadeOut() {
      mainContainer.value.addEventListener(
        "animationend",
        () => {
          doShowForm(null, mainContainer.value);
        },
        { once: true }
      );

      if (mainContainer.value.classList.contains("bluebg")) {
        mainContainer.value.classList.remove("bluebg");
        mainContainer.value.classList.add("bluebgreverse");
      }
      setTimeout(() => {
        signUpFinish.value = false;
      }, 500);
    }

    return {
      removeBadBorder,
      mainContainer,
      doShowForm,
      stopChildClick,
      innerContainer,
      fadeOut,
      groupType,
      groupNameInput,
      selectMenu,
      swapShowForm,
      onSubmit,
      first,
      last,
      phone,
      email,
      accesscode,
      password1,
      password2,
      signUpFinish,
      signUpFailed,
      submitterbutton,
    };
  },
};
</script>

<style scoped>
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(69, 69, 69);
  opacity: 1; /* Firefox */
}

input,
select {
  border-style: solid;
  border-width: 2px;
  border-radius: 6px;
  padding: 0.6rem 0.4rem;
  width: 100%;

  margin-bottom: 0.5rem;
  border-color: rgb(43, 94, 141, 0.7);
  display: block;
}

input[type="radio"] {
  padding: 0;
  margin: 0;
  width: auto;
}

input:focus {
  outline: none;
  border-color: rgb(43, 94, 141);
  color: rgb(43, 94, 141);
}
form {
  position: relative;
  width: 80%;
  margin: 0 auto;
}
main {
  align-items: center;
  font-family: Montserrat;
}
#inner {
  color: rgb(69, 69, 69);
  max-width: 524px;
  border-style: solid;
  border-width: 1vh 0 0 0;
  border-color: rgb(43, 94, 141);
  margin: auto;
  width: 100%;
  background: white;
}

button {
  margin: auto;
  cursor: pointer;
  background: none rgba(51, 92, 139, 0.76);
  border: 2px solid rgba(47, 83, 125, 0.77);
  text-align: center;
  min-height: 2rem;
  max-height: 45px;
  height: 5vh;
  max-width: 250px;
  width: 100%;
  color: rgb(255, 255, 255);

  font-family: Montserrat;
  border-radius: 26px;
  padding: auto 0;
}

button {
  margin: auto;
  cursor: pointer;
  background: none rgba(51, 92, 139);
  border: 2px solid rgba(47, 83, 125);

  text-align: center;
  margin-bottom: 1rem;
  min-height: min-content;
  max-height: 39px;
  height: 6vh;
  max-width: 162px;
  width: 100%;
  color: rgb(255, 255, 255);

  font-family: Montserrat;
  border-radius: 26px;
}

.bad-border {
  border-color: red;
}

button:hover {
  background: none rgba(55, 101, 151);
}
</style>
