<template>
  <div class="flex justify-center w-full">
    <div
      class="grid grid-cols-5 mx-4 admintable"
      v-if="$store.state.activeUser.role == 'lab-admin'"
    >
      <div>Name</div>
      <div>Email</div>
      <div>Phone Number</div>
      <div class="text-center">Online Status</div>
      <div class="text-center">Message</div>
      <template v-for="(user, index) in adminUsers" :key="user._id">
        <div
          :class="[index % 2 == 0 ? 'bg-gray-100' : 'bg-gray-300']"
          class="px-6"
        >
          {{ user.name }}
          <span
            v-if="user.role == 'lab-admin'"
            class="rounded text-xs bg-yellow-500 p-1 font-semibold"
          >
            {{ user.isLabSuper ? "Primary " : "" }}Admin</span
          >
        </div>
        <div
          :class="[index % 2 == 0 ? 'bg-gray-100' : 'bg-gray-300']"
          class="px-6"
        >
          {{ user.email }}
        </div>
        <div
          :class="[index % 2 == 0 ? 'bg-gray-100' : 'bg-gray-300']"
          class="px-6"
        >
          {{ user.phone }}
        </div>
        <div
          :class="[index % 2 == 0 ? 'bg-gray-100' : 'bg-gray-300']"
          class="flex justify-center items-center flex-0"
        >
          <div
            :class="[
              $store.state.activeUser._id == user._id ||
              $store.state.usersOnline.has(user._id)
                ? 'bg-green-500 '
                : 'bg-gray-500',
            ]"
            class="rounded-full w-3 h-3 onlinecircle"
          ></div>
        </div>
        <div
          :class="[index % 2 == 0 ? 'bg-gray-100' : 'bg-gray-300']"
          class="flex justify-center items-center"
        >
          <i
            class="fas fa-envelope text-2xl text-sky-900 cursor-pointer hover:text-green-800"
            data-v-4f22f8d6=""
            aria-hidden="true"
          ></i>
        </div>
      </template>
    </div>
    <div
      class="grid grid-cols-3 mx-4 noadmintable"
      v-if="$store.state.activeUser.role != 'lab-admin'"
    >
      <div>Name</div>

      <div class="text-center">Online Status</div>
      <div class="text-center">Message</div>
      <template
        v-for="(user, index) in $store.state.noAdminUsers"
        :key="user._id"
      >
        <div
          :class="[index % 2 == 0 ? 'bg-gray-100' : 'bg-gray-300']"
          class="px-6"
        >
          {{ user.name }}
          <span
            v-if="user.role == 'lab-admin'"
            class="rounded text-xs bg-yellow-500 p-1 font-semibold"
          >
            {{ user.isLabSuper ? "Primary " : "" }}Admin</span
          >
        </div>

        <div
          :class="[index % 2 == 0 ? 'bg-gray-100' : 'bg-gray-300']"
          class="flex justify-center items-center flex-0"
        >
          <div
            :class="[
              $store.state.activeUser._id == user._id ||
              $store.state.usersOnline.has(user._id)
                ? 'bg-green-500 '
                : 'bg-gray-500',
            ]"
            class="rounded-full w-3 h-3 onlinecircle"
          ></div>
        </div>
        <div
          :class="[index % 2 == 0 ? 'bg-gray-100' : 'bg-gray-300']"
          class="flex justify-center items-center"
        >
          <i
            class="fas fa-envelope text-2xl text-sky-900 cursor-pointer hover:text-green-800"
            data-v-4f22f8d6=""
            aria-hidden="true"
          ></i>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return { noAdminUsers: [] };
  },
  computed: {
    adminUsers() {
      console.log(
        this.$store.state.users.filter((user) => user.role != "temp")
      );
      return this.$store.state.users.filter((user) => user.role != "temp");
    },
  },
  methods: {
    getNoAdminUsers() {
      const url =
        this.$store.state.hostname +
        "/api/v1/users/noadmin?labid=" +
        this.$store.state.activeUser.labId._id +
        "&fields=name,role,isLabSuper";

      const request = new Request(url, {
        credentials: "include",
        method: "GET",

        headers: { "content-type": "application/json" },
      });
      fetch(request)
        .then((resp) => resp.json())
        .then((data) => {
          if (data.status == "fail") throw new Error(data.message);
          else {
            console.log("data is", data.data.data);
            this.$store.state.noAdminUsers = data.data.data;
            // console.log(this.noAdminUsers);

            //arrayOfDewars.value = data.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.getNoAdminUsers();
  },
};
</script>
<style scoped>
.admintable > div:nth-child(-n + 5) {
  background-color: #2c5282;
  padding: 0.5rem 1rem;
  font-weight: 500;
  color: white;
}
.noadmintable > div:nth-child(-n + 3) {
  background-color: #2c5282;
  padding: 0.5rem 1rem;
  font-weight: 500;
  color: white;
}

.admintable {
  grid-template-columns: max-content max-content max-content max-content max-content;
}

div {
  padding: 0.5rem 1rem;
}
.onlinecircle {
  padding: 0 0;
}
</style>
