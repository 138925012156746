<template>
  <div class="w-full flex justify-center">
    <div class="cursor-auto shadow-lg" style="width: max-content">
      <div class="bg-sky-900 mx-auto text-xl pt-6 pb-5 w-full relative">
        <div
          v-if="
            $store.state.activeUser.labId.hasDataExport &&
            $store.state.activeUser.role == 'lab-admin'
          "
          class="absolute z-10 cursor-pointer ml-4"
          style="top: 0.5rem"
        >
          <a
            ref="gridexport"
            download="gridexport.csv"
            @click="downloadGridData()"
            ><button
              class="border-white border-2 px-2 cursor-pointer rounded-xl text-white"
            >
              Export Data
            </button></a
          >
          <div class="flex mt-2 items-center relative">
            <input
              @change="updateEmailWeekly"
              ref="emailweekly"
              class="h-4 w-4 mb-0 cursor-pointer"
              type="checkbox"
              :checked="$store.state.activeUser.emailweeklyexport"
              id="emailweekly"
            />
            <label
              for="emailweekly"
              style="top: 1px"
              class="leading-none text-sm text-white cursor-pointer relative"
              >Email me weekly exports</label
            >
          </div>
        </div>
        <div
          class="px-3 flex flex-col items-center relative text-white font-semibold"
        >
          Active Grids Overview
        </div>
      </div>
      <div class="bg-white p-4" v-if="gridArchives.length == 0">
        <div
          class="gap-px bg-black invisible h-0"
          :class="[
            noimport ? 'grid-cols-7' : 'grid-cols-8',
            noimport ? 'grid6' : 'grid7',
          ]"
        >
          <div>Grid Name</div>
          <div>Description</div>
          <div>Image</div>
          <div>Grid Type</div>

          <div>Date Created</div>
          <div>Box Project</div>
          <div>Navigate</div>
        </div>

        No grid archives created.
      </div>
      <div
        v-else
        class="gap-px grid bg-black"
        :class="[
          noimport ? 'grid-cols-6' : 'grid-cols-7',
          noimport ? 'grid6' : 'grid7',
        ]"
      >
        <div @click="sortByName()">
          Grid Name <span v-if="currentSort == 'ascname'">&#9650;</span
          ><span v-if="currentSort == 'dscname'">&#9660;</span>
        </div>
        <div>Description</div>
        <div>Image</div>

        <div>Grid Type</div>
        <div @click="sortByDate()">
          Date Created <span v-if="currentSort == 'ascdate'">&#9650;</span
          ><span v-if="currentSort == 'dscdate'">&#9660;</span>
        </div>
        <div @click="sortByBox()">
          Box Project <span v-if="currentSort == 'ascbox'">&#9650;</span
          ><span v-if="currentSort == 'dscbox'">&#9660;</span>
        </div>
        <div>Navigate</div>

        <template v-for="(grid, index) in gridArchives" :key="grid._id">
          <div :class="[index % 2 == 0 ? 'bg-gray-100' : 'bg-gray-300']">
            {{ grid.name }}
          </div>
          <div
            class="relative"
            :class="[index % 2 == 0 ? 'bg-gray-100' : 'bg-gray-300']"
            @mouseover="showFullDescript($event)"
            @mouseleave="hideFullDescript($event)"
          >
            <div class="text-sm descripthide">
              {{ grid.description }}
            </div>
            <!--order matters using lastelemchild-->
            <div
              class="absolute top-0 left-0 hidden bg-white rounded border-black border p-2 m-1 shadow-md text-left z-10"
              style="max-height: 300px; overflow-y: scroll"
            >
              {{ grid.description }}
            </div>
          </div>
          <div :class="[index % 2 == 0 ? 'bg-gray-100' : 'bg-gray-300']">
            <a
              :href="grid.imageurl"
              target="_blank"
              class="underline"
              :class="[grid.imageurl ? '' : 'hidden']"
              >View Image</a
            >
          </div>

          <div
            v-if="
              Array.isArray(grid.gridType) && typeof grid.gridType !== 'string'
            "
            class="flex flex-col items-center justify-center"
            :class="[index % 2 == 0 ? 'bg-gray-100' : 'bg-gray-300']"
          >
            <div>
              {{
                grid.gridType[0] && grid.gridType[0] != "Not Screened"
                  ? "Screened"
                  : ""
              }}
            </div>
            <div>{{ grid.gridType[1] ? "Imaged" : "" }}</div>

            {{ grid.gridType[2] ? "Tossed" : "" }}
          </div>
          <div v-else :class="[index % 2 == 0 ? 'bg-gray-100' : 'bg-gray-300']">
            {{ grid.gridType == "Screened" ? grid.gridType : "" }}
          </div>

          <div :class="[index % 2 == 0 ? 'bg-gray-100' : 'bg-gray-300']">
            {{ $store.state.cleanDate(grid.trueDateCreated, true, true) }}
          </div>
          <div :class="[index % 2 == 0 ? 'bg-gray-100' : 'bg-gray-300']">
            {{ grid.boxProject }}
          </div>

          <div :class="[index % 2 == 0 ? 'bg-gray-100' : 'bg-gray-300']">
            <button
              @click="goToGrid(grid)"
              class="bg-green-700 rounded-md px-2 text-white"
            >
              Go to Grid
            </button>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import ManageProducts from "./ManageProducts.vue";

export default {
  props: ["noimport"],
  data() {
    return {
      currentSort: "ascdate",
      gridArchives: [],
    };
  },
  methods: {
    goToGrid(grid) {
      console.log(grid);

      const specialNavigation = {
        puck: grid.boxPuckSerialNo,
        well: grid.boxWellNo,
      };
      this.$router.push({
        path: "/manage-products",
        query: { navigation: JSON.stringify(specialNavigation) },
      });
    },
    sortByName() {
      if (this.currentSort == "ascname") {
        this.gridArchives.sort((a, b) => (a.name < b.name ? 1 : -1));
        this.currentSort = "dscname";
      } else {
        this.gridArchives.sort((a, b) => (a.name > b.name ? 1 : -1));
        this.currentSort = "ascname";
      }
    },
    sortByDate() {
      if (this.currentSort == "ascdate") {
        this.gridArchives.sort((a, b) =>
          a.trueDateCreated < b.trueDateCreated ? 1 : -1
        );
        this.currentSort = "dscdate";
      } else {
        this.gridArchives.sort((a, b) =>
          a.trueDateCreated > b.trueDateCreated ? 1 : -1
        );
        this.currentSort = "ascdate";
      }
    },
    sortByBox() {
      if (this.currentSort == "ascbox") {
        this.gridArchives.sort((a, b) =>
          a.project?.name < b.project?.name ? 1 : -1
        );
        this.currentSort = "dscbox";
      } else {
        this.gridArchives.sort((a, b) =>
          a.project?.name > b.project?.name ? 1 : -1
        );
        this.currentSort = "ascbox";
      }
    },
    async getGridArchives() {
      const activeGridsArray = [];
      await this.$store.dispatch("updateDewarData");

      const dewars = this.$store.state.dewarData[0].data;
      console.log(dewars, this.$store.state.projects);
      for (let i = 0; i < dewars.length; i++) {
        const racks = dewars[i].racks;
        if (racks.length == 0) break;

        for (let j = 0; j < racks.length; j++) {
          const pucks = racks[j].pucks;

          if (pucks.length == 0) break;

          for (let k = 0; k < pucks.length; k++) {
            for (let i2 = 1; i2 <= 12; i2++) {
              if (pucks[k]["well" + i2]) {
                let project;
                if (pucks[k]["well" + i2].project) {
                  project = this.$store.state.projects.find((obj) => {
                    return obj._id === pucks[k]["well" + i2].project;
                  });
                }
                if (pucks[k]["well" + i2].gridTopLeft?.name) {
                  const obj = pucks[k]["well" + i2].gridTopLeft;
                  obj.boxProject = project?.name;
                  obj.boxPuckSerialNo = pucks[k].serialNo;
                  obj.boxWellNo = i2;
                  activeGridsArray.push(obj);
                }
                if (pucks[k]["well" + i2].gridTopRight?.name) {
                  const obj = pucks[k]["well" + i2].gridTopRight;
                  obj.boxProject = project?.name;
                  obj.boxPuckSerialNo = pucks[k].serialNo;
                  obj.boxWellNo = i2;
                  activeGridsArray.push(obj);
                }
                if (pucks[k]["well" + i2].gridBottomLeft?.name) {
                  const obj = pucks[k]["well" + i2].gridBottomLeft;
                  obj.boxProject = project?.name;
                  obj.boxPuckSerialNo = pucks[k].serialNo;
                  obj.boxWellNo = i2;
                  activeGridsArray.push(obj);
                }
                if (pucks[k]["well" + i2].gridBottomRight?.name) {
                  const obj = pucks[k]["well" + i2].gridBottomRight;
                  obj.boxProject = project?.name;
                  obj.boxPuckSerialNo = pucks[k].serialNo;
                  obj.boxWellNo = i2;
                  activeGridsArray.push(obj);
                }
              }
            }
          }
        }
      }

      this.gridArchives = activeGridsArray;
      // this.$store
      //   .dispatch("getArchives", { importType: "grid" })
      //   .then((data) => {
      //     console.log(data);

      //     this.gridArchives = data;
      //     this.sortByDate();
      //   })
      //   .catch((err) => console.log("error from the import-inner.vue " + err));
    },

    updateEmailWeekly() {
      const url = this.$store.state.hostname + "/api/v1/users/updateMe";
      const form = new FormData();

      if (this.$refs.emailweekly.checked) {
        form.append("emailweeklyexport", true);
      } else {
        form.append("emailweeklyexport", false);
      }

      const request = new Request(url, {
        credentials: "include",
        method: "PATCH",
        body: form,
      });
      fetch(request)
        .then((resp) => resp.json())
        .then((data) => {
          if (data.status == "fail") throw new Error(data.message);
          else {
            console.log(data);
          }
        })
        .catch((err) => console.log(err));
    },
    deleteArchive(archiveid) {
      const deleteUser = confirm(
        "Are you sure you want to delete this archive?"
      );
      if (!deleteUser) {
        return;
      }
      this.$store
        .dispatch("deleteItem", {
          item: "gridarchives",
          objid: archiveid,
        })
        .then(() => {
          this.getGridArchives();
        });
    },
    showFullDescript(ev) {
      if (ev.target.classList.contains("absolute")) return;

      if (!ev.target.lastElementChild?.textContent) return;

      ev.target.lastElementChild.style.width = "300px";
      ev.target.lastElementChild.classList.remove("hidden");
    },
    hideFullDescript(ev) {
      ev.target.lastElementChild.classList.add("hidden");
    },
    bringInGrid(name, description, type) {
      if (type[0] == "Screened") type = [1, 0, 0];
      if (!type || type[0] == "Not Screened") type = [0, 0, 0];
      console.log(type);
      this.$emit("importGrid", {
        gridName: name,
        gridDescription: description,
        gridType: type,
      });
    },
    downloadGridData() {
      // const data = [
      //       {
      //           "model": "Samsung S7",
      //           "chargers": "55",
      //           "cases": "56",
      //           "earphones": "57",
      //           "scratched": "2"
      //       },
      //       {
      //           "model": "Pixel XL",
      //           "chargers": "77",
      //           "cases": "78",
      //           "earphones": "79",
      //           "scratched": "4"
      //       },
      //       {
      //           "model": "iPhone 7",
      //           "chargers": "88",
      //           "cases": "89",
      //           "earphones": "90",
      //           "scratched": "6"
      //       }
      //   ];

      // const keys = Object.keys(data[0]);
      const keys = [
        "Grid Name",
        "Description",
        "Grid Type",
        "Date Created",
        "Box Project",
      ];
      const keyprops = [
        "name",
        "description",
        "type",
        "trueDateCreated",
        "boxProject",
      ];

      // const commaSeparatedString = [keys.join(",") , data.map(row => keys.map(key => row[key]).join(",")).join("\n")].join("\n")

      const commaSeparatedString = [
        keys.join(","),
        this.gridArchives
          .map((grid) =>
            keyprops
              .map((keyprop) => {
                if (keyprop == "trueDateCreated") {
                  const clean = this.$store.state.cleanDate(
                    grid.trueDateCreated,
                    true,
                    true
                  );

                  // return clean.replace(",", "replaceme123");
                  return '"' + clean + '"';
                  // return clean.slice(0,clean.indexOf(","))+'/'+clean.slice(clean.indexOf(","));
                } else if (keyprop == "type") {
                  if (
                    Array.isArray(grid.gridType) &&
                    typeof grid.gridType !== "string"
                  ) {
                    let thetypes =
                      grid.gridType[0] && grid.gridType[0] != "Not Screened, "
                        ? "Screened, "
                        : "";
                    thetypes += grid.gridType[1] ? "Imaged, " : "";

                    thetypes += grid.gridType[2] ? "Tossed" : "";
                    return '"' + thetypes + '"';
                  } else
                    return grid.gridType == "Screened" ? grid.gridType : "";
                } else return grid[keyprop] ? '"' + grid[keyprop] + '"' : "";
              })
              .join(",")
          )

          .join("\n"),
      ].join("\n");

      const csvBlob = new Blob(["\ufeff", commaSeparatedString]);

      const a2 = this.$refs.gridexport;

      a2.href = URL.createObjectURL(csvBlob);
    },
  },

  mounted() {
    // console.log("uh this was mounted");
    this.getGridArchives();

    // this.$store.state.importModalMounted = true;
  },
  unmounted() {
    this.$store.state.importModalMounted = false;
    window.setTimeout(() => {
      if (document.getElementById("searchbarforloggedin"))
        document.getElementById("searchbarforloggedin").focus();
    }, 100);
  },
};
</script>

<style scoped>
.grid {
  align-items: stretch;
}
.grid6 {
  grid-template-columns: min-content minmax(min-content, min-content) max-content max-content max-content max-content;
}
.grid5 {
  grid-template-columns: min-content minmax(min-content, min-content) max-content max-content max-content max-content;
}

.grid > div {
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.descripthide {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.grid7 > div:nth-child(-n + 7),
.grid6 > div:nth-child(-n + 6) {
  font-weight: 500;
  cursor: pointer;
  background-color: goldenrod;
}
</style>
