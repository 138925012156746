<template>
  <div v-show="showForm && !isLoggedIn">
    <sign-up v-show="showForm && showForm == 'blue'"></sign-up>
    <log-in v-if="showForm == 'green'"></log-in>
  </div>

  <LoggedinHome v-if="isLoggedIn" />
  <LoggedoutHome
    v-if="!isLoggedIn && $route.name != 'reset-password'"
    :is-fixed="isFixed"
  />
  <router-view v-if="$route.name == 'reset-password'"></router-view>
</template>

<script>
import LoggedoutHome from "./components/LoggedoutHome.vue";
import socket from "./socket";
import LoggedinHome from "./components/LoggedinHome.vue";
import LogIn from "./components/loggedouthomecomponents/LogIn.vue";
import SignUp from "./components/loggedouthomecomponents/SignUp.vue";
// import badBorder from "./mixins/redErrorInputBorder.js";
import { ref, computed, provide, watch, onUnmounted } from "vue";
import { useStore } from "vuex";

export default {
  name: "App",
  components: {
    LoggedoutHome,
    LogIn,
    LoggedinHome,
    SignUp,
  },
  // mixins: [badBorder],
  setup() {
    const store = useStore();

    const isLoggedIn = computed(function () {
      return store.getters.isAuthenticated;
    });

    store
      .dispatch("getAdminStuff", {
        url: "getdate",
      })

      .then((data) => {
        console.log("date: ", data);
        store.state.serverdate = data;
      });

    onUnmounted(() => {
      // console.log("clean up socket listener");
      // socket.off("connect_error");
    });

    const userEmail = computed(() => {
      return store.state.activeUser?.email;
    });
    let socketusers = ref([]);
    watch(userEmail, (newValue) => {
      console.log("logged in with new value", newValue);
      if (newValue) {
        socket.auth = {
          userid: store.state.activeUser._id,
          labid: store.state.activeUser.labId._id,
        };
        socket.connect();

        socket.emit(
          "join-room",
          store.state.activeUser.labId._id,
          (currentlyConnectedSockets) => {
            currentlyConnectedSockets.map((userid) => {
              store.state.usersOnline.add(userid);
            });
          }
        );
      }
    });

    socket.on("member-online", (labmemberid) => {
      store.state.usersOnline.add(labmemberid);
    });

    socket.on("sensorupdate", (sensor) => {
      console.log(sensor);
      store.dispatch("addSensor", sensor);
    });

    socket.on("member-offline", (labmemberid) => {
      store.state.usersOnline.delete(labmemberid);
    });
    // const sessionID = localStorage.getItem("sessionID");
    // console.log("session id is, ", sessionID);
    // if (sessionID) {
    //   socket.auth = { sessionID };
    //   socket.connect();
    // } else {
    //   // socket.auth = {
    //   //   username: store.state.activeUser.email,
    //   //   name: store.state.activeUser.name,
    //   //   photo: store.state.activeUser.photo,
    //   // };
    //   socket.auth = {
    //     username: store.state.activeUser.email,
    //     labid: store.state.activeUser.labId._id,
    //   };
    //   socket.connect();
    // }

    //this is a cusotm event i made on the backend
    // socket.on("session", ({ sessionID }) => {
    //   // attach the session ID to the next reconnection attempts
    //   socket.auth = { sessionID };
    //   // store it in the localStorage
    //   localStorage.setItem("sessionID", sessionID);
    //   // save the ID of the user
    // });

    // socket.on("session", ({ sessionID, userID }) => {
    //   // attach the session ID to the next reconnection attempts
    //   socket.auth = { sessionID };
    //   // store it in the localStorage
    //   localStorage.setItem("sessionID", sessionID);
    //   // save the ID of the user. this is a random number
    //   socket.userID = userID;
    // });

    // socket.on("connect_error", (err) => {
    //   console.log(err, "err");

    //   if (err.message === "invalid session") {
    //     socket.auth = {
    //       username: store.state.activeUser.email,
    //       labid: store.state.activeUser.labId._id,
    //       // name: store.state.activeUser.name,
    //       // photo: store.state.activeUser.photo,
    //     };

    //     socket.auth.sessionID = false;
    //     socket.connect();
    //   }
    // });
    // }

    // const initReactiveProperties = (user) => {
    //   user.hasNewMessages = false;
    // };

    //when a socket connects to the server, the user connected event emitted passing an object with username prop
    //currently all sockets. not separated by lab.
    //   socket.on("user connected", (user) => {
    //     //check if the user already exists
    //     for (let i = 0; i < socketusers.value.length; i++) {
    //       const existingUser = socketusers.value[i];
    //       if (existingUser.username === user.username) {
    //         console.log("user already exists");
    //         existingUser.connected = true;
    //         return;
    //       }
    //     }
    //     console.log("user does not already exist");
    //     initReactiveProperties(user);
    //     socketusers.value.push(user);
    //   });

    //   //join lab room when connected
    //   socket.on("connect", () => {
    //     socket.emit(
    //       "join-room",
    //       store.state.activeUser.labId._id,
    //       (message, room) => {
    //         console.log(message, room);
    //       }
    //     );
    //   });

    //   //on socket.connect find the user.self and set the connected value to true
    //   socket.on("connect", () => {
    //     socketusers.value.forEach((user) => {
    //       if (user.self) {
    //         user.connected = true;
    //       }
    //     });
    //   });

    //   //on socket.disconnect find the user.self and set the connected value to falses
    //   socket.on("disconnect", () => {
    //     socketusers.value.forEach((user) => {
    //       if (user.self) {
    //         user.connected = false;
    //       }
    //     });
    //   });
    // });

    const showForm = ref(false);
    const isFixed = ref("relative");
    const con = computed(function () {
      return window.console;
    });

    function attemptLogin() {
      const url = store.state.hostname + "/api/v1/users/cookieLogin";
      const request = new Request(url, {
        credentials: "include",
        method: "POST",

        headers: { "content-type": "application/json" },
      });

      fetch(request)
        .then((resp) => resp.json())
        .then(function (data) {
          if (data.status == "fail" || data.status == "error")
            throw new Error(data.message);
          else {
            console.log(data);
          }
          if (data.role == "mark") {
            store.state.isMark = true;
          }
          store.dispatch("login");
        })
        .catch((err) => console.log(err));
    }

    attemptLogin();

    function swapShowForm() {
      console.log("swapped", showForm.value);
      if (showForm.value == "blue") showForm.value = "green";
      else showForm.value = "blue";
    }
    function doShowForm(color, mainContainer) {
      if (mainContainer) {
        if (mainContainer.classList.contains("bluebgreverse")) {
          mainContainer.classList.remove("bluebgreverse");
          mainContainer.classList.add("bluebg");
          //mainContainer.removeEventListener("animationend", () => {});
        }
      }

      if (showForm.value) {
        isFixed.value = "relative";
        showForm.value = false;
        // if (typeof color != "string")
        //   color.srcElement.removeEventListener("animationend", doShowForm);
        return;
      } else isFixed.value = "fixed";

      showForm.value = color;
    }

    //mixin via the inject way.
    function removeBadBorder(ev) {
      if (ev.target.previousElementSibling?.classList.contains("errortext"))
        ev.target.previousElementSibling.classList.add("hidden");
      if (ev.target.classList.contains("bad-border")) {
        ev.target.value = "";
        ev.target.classList.remove("bad-border");
      }
    }

    provide("removeBadBorder", removeBadBorder);

    provide("doShowForm", doShowForm);
    provide("swapShowForm", swapShowForm);
    provide("socketusers", socketusers.value);

    return { showForm, con, isFixed, isLoggedIn };
  },
};
</script>

<style>
.whitehooknostroke {
  fill: #fff;
}
.whitehookstroke {
  fill: #fff;
  stroke: rgb(175, 175, 175);
}

.bad-border {
  border-color: red;
}
.fadeinbutton {
  visibility: visible;
  opacity: 1;
  transition: opacity 1s ease-out;
}
.fadeoutbutton {
  opacity: 0;
  transition: opacity 1s linear;
}
.buttondisabled {
  background: darkgrey;
  border-color: grey;
  color: white;
  cursor: not-allowed;
}
.buttondisabled:hover {
  background: darkgrey;
  border-color: grey;
  color: white;
  cursor: not-allowed;
}
textarea:focus {
  outline: none;
  border-color: rgb(43, 94, 141);
  color: rgb(43, 94, 141);
}
textarea {
  border-color: rgb(118, 155, 186);
  height: 100%;
  width: 100%;
  resize: none;
}
.custom-gray-color {
  background: rgb(222, 222, 222);
}
.bluebg {
  min-height: 100%;
  animation: fadein 0.5s forwards;
}
@keyframes fadein {
  from {
    background-color: rgb(255, 255, 255, 0);
  }
  to {
    background-color: rgba(40, 104, 168, 0.64);
  }
}

.bluebgreversebg {
  min-height: 100%;
  animation: fadeoutbg 0.5s forwards;
}
@keyframes fadeoutbg {
  from {
    background-color: rgba(40, 104, 168, 0.64);
  }
  to {
    background-color: rgb(255, 255, 255, 0);
  }
}
.bluebgreverse {
  min-height: 100%;
  animation: fadeout 0.5s forwards;
}
@keyframes fadeout {
  to {
    opacity: 0;
  }
  from {
    opacity: 1;
  }
}
*,
:not(input),
:not(select),
:not(textarea) {
  font-size: clamp(10px, 16px, 22px);
}
input,
textarea,
select {
  font-size: clamp(16px, 16px, 22px);
}
@media screen and (orientation: portrait) {
  *,
  :not(input),
  :not(select),
  :not(textarea) {
    font-size: clamp(10px, 1.5vw, 22px);
  }
  input,
  select,
  textarea {
    font-size: clamp(16px, 1.5vw, 22px);
  }
}

@media screen and (max-width: 800px) {
  *,
  :not(input),
  :not(select),
  :not(textarea) {
    font-size: clamp(10px, 1.75vw, 22px);
  }
  input,
  select,
  textarea {
    font-size: clamp(16px, 1.75vw, 22px);
  }
}

@media screen and (max-width: 640px) {
  *,
  :not(input),
  :not(select),
  :not(textarea) {
    font-size: clamp(10px, 3vw, 22px);
  }
  input,
  select,
  textarea {
    font-size: clamp(16px, 3vw, 22px);
  }
}

@media screen and (max-width: 400px) {
  *,
  :not(input),
  :not(select),
  :not(textarea) {
    font-size: clamp(10px, 4vw, 22px);
  }
  input,
  select,
  textarea {
    font-size: clamp(16px, 4vw, 22px);
  }
}

button:focus {
  outline: none;
  color: white;
}

.useraccessbottomtab {
  background-image: linear-gradient(
    to top,
    rgba(203, 213, 224),
    rgba(203, 213, 224) 70%,
    transparent 70%,
    transparent 100%
  );
}
</style>
