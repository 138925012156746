<template>
  <div class="bg-sky-900 p-1" v-if="$store.state.activeUser.labId.premium">
    <div class="p-1 text-yellow-500 font-semibold">
      Premium Subscription
    </div>
    <div class="bg-white mb-1 p-1">
      Start Date:
      {{
        $store.state.cleanDate(
          $store.state.activeUser.labId.premiumStart,
          false,
          true
        )
      }}
    </div>
    <div class="bg-white p-1">
      End Date:
      {{
        $store.state.cleanDate(
          $store.state.activeUser.labId.premiumEnd,
          false,
          true
        )
      }}
    </div>
  </div>
  <div
    v-else
    class="text-2xl text-center bg-yellow-500 border-blue-800 border-2 py-2"
  >
    Premium gives you access to all your racks and pucks, plus unlimited users.
    <br /><a href="mailto:info@subangstrom.com" class="text-sky-900 underline"
      >Contact us to learn more.</a
    >
  </div>
</template>
