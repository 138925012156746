<template>
  <div class="bg-sky-900 p-1">
    <div class="p-1 text-white font-semibold">Data Settings</div>
  </div>
  <!-- <div class="my-4">
    <button class="bg-emerald-600 text-white p-4 text-xl rounded-xl">
      Download .CSV of my product data
    </button>
  </div>
  <div class="border-emerald-600 border rounded w-[max-content]">
    <div class="px-2 bg-emerald-600 text-white w-[max-content] rounded-br">
      .CSV Email Schedule
    </div>
    <div class="px-4 pb-4 pt-3">
      <div class="flex items-end mb-4">
        <span class="min-w-[max-content] mr-2">Email to:</span
        ><select class="m-0 max-w-[max-content]">
          <option disabled selected>select lab admin</option>
        </select>
      </div>
      <div class="flex items-end">
        <span class="min-w-[max-content] mr-2">Frequency:</span
        ><select class="m-0 max-w-[max-content]">
          <option>none</option>
          <option>weekly</option>
          <option>monthly</option>
        </select>
      </div>
    </div>
  </div> -->
  <div class="border-sky-900 border rounded w-[max-content] mt-4">
    <div class="px-2 bg-sky-900 text-white w-[max-content] rounded-br">
      Developer API
    </div>
    <div class="px-4 pb-4 pt-3">
      <div>
        <span class="font-semibold">URL</span>
        <div class="border-emerald-600 border rounded p-2">
          GET
          https://api.subangstrom.com/v1/productdata?api_key=YOUR_API_KEY_HERE
        </div>
      </div>

      <button
        class="mt-4 bg-sky-900 text-white rounded px-2 py-1"
        @click="createkey = true"
      >
        Create new API key
      </button>
      <div class="mt-4">
        <div class="font-semibold">API Keys</div>
        <div
          class="bg-black gap-px p-px inline-grid grid-cols-[repeat(5,max-content)] somepadding"
        >
          <div class="bg-gray-300">API Key</div>
          <div class="bg-gray-300">Created By</div>
          <div class="bg-gray-300">Last Used</div>
          <div class="bg-gray-300">IP Restriction</div>
          <div class="bg-gray-300">Delete</div>
          <template :key="index" v-for="(key, index) in labKeys">
            <div class="bg-white flex items-end">
              <span v-if="!visible[index]">
                {{ asteriskMiddle(key.apikey) }}
              </span>
              <span v-else> {{ key.apikey }} </span>

              <span
                v-if="!visible[index]"
                class="ml-2 cursor-pointer material-symbols-outlined text-emerald-600"
                @click="visible[index] = true"
              >
                visibility
              </span>
              <span
                v-else
                class="ml-2 cursor-pointer material-symbols-outlined text-gray-500"
                @click="visible[index] = false"
              >
                visibility_off
              </span>
            </div>
            <div class="bg-white">{{ key.createdBy }}</div>
            <div v-if="key.lastUsedTime" class="bg-white">
              {{ $store.state.cleanDate(key.lastUsedTime, true) }}
              <span v-if="key.lastUsedIp" class="rounded bg-gray-300 px-1">
                {{ key.lastUsedIp }}</span
              >
            </div>
            <div v-else class="bg-white">Never</div>
            <div class="bg-white">{{ key.ipRestricted || "None" }}</div>
            <div class="bg-white">
              <button
                class="bg-red-700 text-white rounded px-1"
                @click="deleteKey($event, key._id)"
              >
                Delete
              </button>
            </div>
          </template>
        </div>
        <!-- <div></div>
        <div v-if="showKey" class="border-emerald-600 border rounded p-2">
          12345
        </div>
        <div
          v-else
          class="border-emerald-600 border rounded p-2 cursor-pointer italic"
          @click="showKey = true"
        >
          CLICK TO SHOW KEY
        </div> -->
      </div>
    </div>
    <div v-if="createkey">
      <DisplayModal @ridfeedback="createkey = false">
        <div class="bg-white rounded shadow p-4">
          Provide a network IP address allowed to use this key. Leave empty if
          you do not want to restrict access.
          <input type="text" />

          <button
            class="bg-emerald-600 text-white rounded px-2 py-1 inline-block"
            @click="
              (ev) => createKey(ev, ev.currentTarget.previousSibling.value)
            "
          >
            Create Key
          </button>
        </div>
      </DisplayModal>
    </div>
  </div>
</template>
<script>
export default {
  mounted() {
    this.getLabKeys();
  },
  methods: {
    async deleteKey(ev, keyId) {
      const deleteit = confirm("Are you sure you want to delete this api key?");
      if (!deleteit) return;
      const button = ev.currentTarget;
      button.disabled = true;
      await this.$store.dispatch("deleteItem", {
        item: "keys",
        objid: keyId,
      });

      this.getLabKeys();
      button.disabled = false;
      this.createkey = false;
    },

    async createKey(ev, ipLock) {
      const button = ev.currentTarget;
      button.disabled = true;
      await this.$store.dispatch("createItem", {
        item: "keys",
        body: {
          ipLock,
        },
      });

      this.getLabKeys();
      button.disabled = false;
      this.createkey = false;
    },
    async getLabKeys() {
      const url = `${this.$store.state.hostname}/api/v1/keys`;

      const request = new Request(url, {
        credentials: "include",
        method: "GET",
        headers: { "content-type": "application/json" },
      });
      const data = await fetch(request);
      const jsondata = await data.json();
      if (jsondata.data?.data) {
        this.labKeys = jsondata.data.data;
      }
    },
    asteriskMiddle(string) {
      if (string.length === 30) {
        const middleStart = 5;
        const middleEnd = 25;
        const asterisks = "*".repeat(20);
        return (
          string.slice(0, middleStart) + asterisks + string.slice(middleEnd)
        );
      } else {
        return string.length;
      }
    },
  },
  data() {
    return {
      createkey: false,
      visible: [],
      showKey: false,
      labKeys: [],
    };
  },
};
</script>
<style scoped>
.somepadding > div {
  padding: 0.3rem 0.5rem;
}
</style>
