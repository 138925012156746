<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 41.1 41.1"
    style="enable-background:new 0 0 41.1 41.1;"
    xml:space="preserve"
    class="boxonlysvg"
  >
    <svg:style type="text/css" scoped>

      .well-clipped .st2 {
        fill: #ffffff;<!-- {{(color=='white'?'stroke:'+darkColor+';': "")}} -->}
                                          .well-clipped .st3 {
                                            fill: none;
                                          }
    </svg:style>
    <g class="megacontainer">
      <path
        class="st0"
        d="M25.8,0.7c0,0.3,0.1,0.6,0.1,0.9c0,2.9-2.4,5.3-5.3,5.3s-5.3-2.4-5.3-5.3c0-0.3,0-0.6,0.1-0.9   C6.5,3,0,11,0,20.5C0,31.8,9.2,41,20.5,41S41,31.8,41,20.5C41.1,11,34.6,3,25.8,0.7z"
      />
      <g>
        <circle class="st1" cx="12.9" cy="4.6" r="1.3" />
        <circle class="st1" cx="28.3" cy="4.6" r="1.3" />
        <circle class="st1" cx="36.7" cy="20.2" r="1.3" />
        <circle class="st1" cx="4.1" cy="20.2" r="1.3" />
        <circle class="st1" cx="20.6" cy="36.4" r="1.3" />
      </g>
      <path
        class="st2"
        d="M17.6,10.6h-0.7c-0.3,0-0.6,0.3-0.6,0.6v0.3h-2v-1.4c0-0.7-0.5-1.2-1.2-1.2h-3c-0.7,0-1.2,0.6-1.2,1.2v1.4h-2   v-0.3c0-0.3-0.3-0.6-0.6-0.6H5.6c-0.3,0-0.6,0.3-0.6,0.6v2.6c0,0.3,0.3,0.6,0.6,0.6h0.7c0.3,0,0.6-0.3,0.6-0.6v-0.2h2V15   c0,0.7,0.6,1.2,1.2,1.2h3c0.7,0,1.2-0.5,1.2-1.2v-1.4h2v0.2c0,0.3,0.3,0.6,0.6,0.6h0.7c0.3,0,0.6-0.3,0.6-0.6v-2.6   C18.2,10.9,17.9,10.6,17.6,10.6z"
      />
      <path
        class="st2"
        d="M35.2,10.6h-0.7c-0.3,0-0.6,0.3-0.6,0.6v0.3h-2v-1.4c0-0.7-0.5-1.2-1.2-1.2h-3c-0.7,0-1.2,0.6-1.2,1.2v1.4h-2   v-0.3c0-0.3-0.3-0.6-0.6-0.6h-0.7c-0.3,0-0.6,0.3-0.6,0.6v2.6c0,0.3,0.3,0.6,0.6,0.6h0.7c0.3,0,0.6-0.3,0.6-0.6v-0.2h2V15   c0,0.7,0.5,1.2,1.2,1.2h3c0.7,0,1.2-0.5,1.2-1.2v-1.4h2v0.2c0,0.3,0.3,0.6,0.6,0.6h0.7c0.3,0,0.6-0.3,0.6-0.6v-2.6   C35.8,10.9,35.5,10.6,35.2,10.6z"
      />
      <path
        class="st2"
        d="M17.6,27.6h-0.7c-0.3,0-0.6,0.3-0.6,0.6v0.3h-2v-1.4c0-0.7-0.5-1.2-1.2-1.2h-3c-0.7,0-1.2,0.5-1.2,1.2v1.4h-2   v-0.3c0-0.3-0.3-0.6-0.6-0.6H5.6c-0.3,0-0.6,0.3-0.6,0.6v2.6c0,0.3,0.3,0.6,0.6,0.6h0.7c0.3,0,0.6-0.3,0.6-0.6v-0.2h2V32   c0,0.7,0.6,1.2,1.2,1.2h3c0.7,0,1.2-0.5,1.2-1.2v-1.4h2v0.2c0,0.3,0.3,0.6,0.6,0.6h0.7c0.3,0,0.6-0.3,0.6-0.6v-2.6   C18.2,27.9,17.9,27.6,17.6,27.6z"
      />
      <path
        class="st2"
        d="M35.2,27.6h-0.7c-0.3,0-0.6,0.3-0.6,0.6v0.3h-2v-1.4c0-0.7-0.5-1.2-1.2-1.2h-3c-0.7,0-1.2,0.5-1.2,1.2v1.4h-2   v-0.3c0-0.3-0.3-0.6-0.6-0.6h-0.7c-0.3,0-0.6,0.3-0.6,0.6v2.6c0,0.3,0.3,0.6,0.6,0.6h0.7c0.3,0,0.6-0.3,0.6-0.6v-0.2h2V32   c0,0.7,0.5,1.2,1.2,1.2h3c0.7,0,1.2-0.5,1.2-1.2v-1.4h2v0.2c0,0.3,0.3,0.6,0.6,0.6h0.7c0.3,0,0.6-0.3,0.6-0.6v-2.6   C35.8,27.9,35.5,27.6,35.2,27.6z"
      />
      <g class="gridpieces">
        <ellipse
          :class="{ hidden: !showGridClips[0] }"
          xmlns="http://www.w3.org/2000/svg"
          class="stblack"
          cx="11.7"
          cy="12.5"
          rx="6.4"
          ry="1.1"
        />
        <ellipse
          :class="{ hidden: !showGridClips[1] }"
          xmlns="http://www.w3.org/2000/svg"
          class="stblack"
          cx="29.2"
          cy="12.5"
          rx="6.4"
          ry="1.1"
        />
        <ellipse
          :class="{ hidden: !showGridClips[2] }"
          xmlns="http://www.w3.org/2000/svg"
          class="stblack"
          cx="11.7"
          cy="29.7"
          rx="6.4"
          ry="1.1"
        />
        <ellipse
          :class="{ hidden: !showGridClips[3] }"
          xmlns="http://www.w3.org/2000/svg"
          class="stblack"
          cx="29.2"
          cy="29.7"
          rx="6.4"
          ry="1.1"
        />
      </g>
      <circle class="st1" cx="20.5" cy="20.5" r="4.3" />
      <circle class="st3" cx="20.5" cy="20.5" r="20.5" />
    </g>
  </svg>
</template>
<script>
export default {
  props: ["color", "darkColor", "wellNumber", "showGridClips"],
};
</script>
