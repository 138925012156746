<template>
  <div class="text-3xl text-center font-bold text-sky-900 m-4">
    Account Login
  </div>
  <form @submit.prevent="onSubmit($event)" class="text-sm">
    <div class="text-xs hidden errortext">Invalid email</div>
    <input
      @focus="removeBadBorder($event)"
      type="email"
      placeholder="Email"
      ref="email"
    />
    <div class="text-xs hidden errortext">Invalid password</div>
    <input
      @focus="removeBadBorder($event)"
      type="password"
      placeholder="Password"
      ref="password"
    />

    <div class="w-full flex justify-center text-lg mt-3 flex-col items-center">
      <button ref="submitterbutton" type="submit" class="relative">
        <button-inner>Login</button-inner>
      </button>
      <div class="fadeoutbutton">Success!</div>
    </div>
  </form>
  <div class="flex justify-center pb-1">
    <span
      @click="showForgot"
      class="text-sm leading-6 mr-1 mb-1 cursor-pointer underline"
      >Forgot Password</span
    >
  </div>
  <div class="flex justify-center pb-1">
    <span
      @click="swapShowForm"
      class="cursor-pointer text-sm leading-6 mr-1 mb-3 underline text-green-800"
      >Don't have an account? Signup Now</span
    >
  </div>
</template>
<script>
import { ref, inject } from "vue";
import { useStore } from "vuex";
export default {
  props: ["showForgot"],
  setup() {
    function validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }
    const store = useStore();

    const email = ref(null);
    const password = ref(null);
    const submitterbutton = ref(null);

    const swapShowForm = inject("swapShowForm");

    const removeBadBorder = inject("removeBadBorder");

    function onSubmit() {
      let validEntries = true;
      if (!email.value.value || !validateEmail(email.value.value)) {
        email.value.classList.add("bad-border");
        email.value.previousElementSibling.classList.remove("hidden");
        email.value.value = "";
        validEntries = false;
      }
      if (!password.value.value || password.value.value.length < 8) {
        password.value.classList.add("bad-border");
        password.value.previousElementSibling.classList.remove("hidden");
        password.value.value = "";
        validEntries = false;
      }

      if (!validEntries) return;

      const button = submitterbutton.value;

      button.disabled = true;

      button.children[1].style.display = "none";
      button.children[0].style.display = "flex";

      let data = {
        email: email.value.value,
        password: password.value.value,
      };

      const url = store.state.hostname + "/api/v1/users/login";
      const request = new Request(url, {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(data),

        headers: { "content-type": "application/json" },
      });
      fetch(request)
        .then((resp) => resp.json())
        .then(function(data) {
          if (data.status == "fail") throw new Error(data.message);
          else {
            console.log(data);
          }
          store.state.buttonFX(button, "Success!");
          store.dispatch("login");
        })
        .catch((err) => {
          console.log(err);
          store.state.buttonFX(button, "Login failed");
        });
    }

    return {
      swapShowForm,
      onSubmit,
      email,
      password,
      removeBadBorder,
      submitterbutton,
    };
  },
};
</script>
<style scoped>
button {
  margin: auto;
  cursor: pointer;
  background: none rgba(51, 92, 139);
  border: 2px solid rgba(47, 83, 125);

  text-align: center;
  margin-bottom: 0rem;
  min-height: min-content;
  max-height: 39px;
  height: 6vh;
  max-width: 162px;
  width: 100%;
  color: rgb(255, 255, 255);

  font-family: Montserrat;
  border-radius: 26px;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(69, 69, 69);
  opacity: 1; /* Firefox */
}

input,
select {
  border-style: solid;
  border-width: 2px;
  border-radius: 6px;
  padding: 0.6rem 0.4rem;
  width: 100%;

  margin-bottom: 0.5rem;
  border-color: rgb(43, 94, 141, 0.7);
  display: block;
}
.bad-border {
  border-color: red;
}

input:focus {
  outline: none;
  border-color: rgb(43, 94, 141);
  color: rgb(43, 94, 141);
}
</style>
