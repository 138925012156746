<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 41.1 41.1"
    style="enable-background:new 0 0 41.1 41.1;"
    xml:space="preserve"
    class="boxonlysvg"
  >
    <svg:style type="text/css" scoped>
      .stblack {
        fill: #000000 !important;
      }

      .well-unclipped .st2 {
        fill: #ffffff;
      }
      .well-unclipped .stblack {
        fill: #000000;
      }
      .well-unclipped .st3 {
        fill: url(#SVGID_PLUSSIGN) !important;
        stroke: #ffffff;
        stroke-width: 0.25;
        stroke-miterlimit: 10;
      }
    </svg:style>
    <g>
      <path
        class="st0"
        d="M25.8,0.7c0,0.3,0.1,0.6,0.1,0.9c0,2.9-2.4,5.3-5.3,5.3s-5.3-2.4-5.3-5.3c0-0.3,0-0.6,0.1-0.9
		C6.5,3,0,11,0,20.5c0,11.3,9.2,20.5,20.5,20.5s20.5-9.2,20.5-20.5C41.1,11,34.6,3,25.8,0.7z"
      />
      <path
        class="st1"
        d="M25.8,0.7c0,0.3,0.1,0.6,0.1,0.9c0,2.9-2.4,5.3-5.3,5.3s-5.3-2.4-5.3-5.3c0-0.3,0-0.6,0.1-0.9   C6.5,3,0,11,0,20.5C0,31.8,9.2,41,20.5,41S41,31.8,41,20.5C41.1,11,34.6,3,25.8,0.7z"
      ></path>
      <polygon class="st2" points="7.8,7.5 7.7,14.8 14.6,17.6 14.6,10.3 	" />
      <polygon class="st2" points="26.1,7.5 26.1,15 33,17.8 33,10.3 	" />
      <polygon class="st2" points="7.7,23.7 7.5,31.2 14.4,34 14.6,26.5 	" />
      <polygon class="st2" points="27,23.6 27,31.1 33.9,33.7 34.1,26.4 	" />
      <circle class="st2" cx="20.5" cy="20.5" r="3.9" />

      <path
        class="st3"
        d="M20.5,16.3c-2.4,0-4.2,1.9-4.2,4.2s1.9,4.2,4.2,4.2s4.2-1.9,4.2-4.2S22.9,16.3,20.5,16.3z M20.9,21V24h-0.8V21
		h-3v-0.8h3v-3.1h0.8v3.1h3V21H20.9z"
      />
      <g class="gridpieces">
        <ellipse
          :class="{ hidden: !showGridClips[0] }"
          xmlns="http://www.w3.org/2000/svg"
          transform="matrix(0.8204 -0.5718 0.5718 0.8204 -5.1713 8.6935)"
          class="stblack"
          cx="11.3"
          cy="12.6"
          rx="1.4"
          ry="5.1"
        />
        <ellipse
          :class="{ hidden: !showGridClips[1] }"
          xmlns="http://www.w3.org/2000/svg"
          transform="matrix(0.8204 -0.5718 0.5718 0.8204 -1.88 19.1681)"
          class="stblack"
          cx="29.6"
          cy="12.6"
          rx="1.4"
          ry="5.1"
        />
        <ellipse
          :class="{ hidden: !showGridClips[2] }"
          xmlns="http://www.w3.org/2000/svg"
          transform="matrix(0.8287 -0.5597 0.5597 0.8287 -14.2433 11.1645)"
          class="stblack"
          cx="11.1"
          cy="28.9"
          rx="1.4"
          ry="5.1"
        />
        <ellipse
          :class="{ hidden: !showGridClips[3] }"
          xmlns="http://www.w3.org/2000/svg"
          transform="matrix(0.8287 -0.5597 0.5597 0.8287 -10.9052 22.0804)"
          class="stblack"
          cx="30.6"
          cy="28.9"
          rx="1.4"
          ry="5.1"
        />
      </g>
      <radialGradient
        id="SVGID_PLUSSIGN"
        cx="22.1032"
        cy="1511.963"
        r="4.8437"
        gradientTransform="matrix(1 0 0 1 0 -1492.9199)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" style="stop-color:#ECECEC" />
        <stop offset="0.3528" style="stop-color:#BEBEBE" />
        <stop offset="1" style="stop-color:#626060" />
      </radialGradient>
    </g>
  </svg>
</template>
<script>
export default {
  props: ["color", "darkColor", "wellNumber", "showGridClips"],
};
</script>
