<template>
  <div>
    <input
      @change="$emit('change', $event)"
      class="cursor-pointer w-4 h-full block mb-0 pb-0"
      type="checkbox"
      :disabled="isDisabled"
      :id="inputid"
      :checked="isChecked"
    />
  </div>
</template>

<script>
export default {
  props: ["isChecked", "inputid", "isDisabled"],
};
</script>
