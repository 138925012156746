<template>
  <div
    class="flex flex-col border-gray-700 border px-2 py-1 "
    :class="[itemAccess == '2ndnull' ? 'bg-pink-300' : 'bg-white']"
  >
    <div class="font-bold mb-2"><slot /></div>
    <div class="flex flex-col text-sm">
      <div class="flex items-center">
        <input
          @input="$emit('update:itemAccess', $event.target.value)"
          :name="'access' + id"
          :id="'readwrite' + id"
          :disabled="disabled"
          @change="$emit('valchange2')"
          :checked="selected == 'readwrite'"
          value="readwrite"
          type="radio"
        />
        <label :for="'readwrite' + id">Read and Write</label>
      </div>
      <div class="flex items-center">
        <input
          @input="$emit('update:itemAccess', $event.target.value)"
          :name="'access' + id"
          :id="'readonly' + id"
          :disabled="disabled"
          :checked="selected == 'readonly'"
          value="readonly"
          @change="$emit('valchange2')"
          type="radio"
        />
        <label :for="'readonly' + id">Read Only</label>
      </div>
      <div class="flex items-center">
        <input
          value="noaccess"
          :disabled="disabled"
          :checked="selected == 'noaccess'"
          :id="'noaccess' + id"
          @change="$emit('valchange2')"
          @input="$emit('update:itemAccess', $event.target.value)"
          :name="'access' + id"
          type="radio"
        />

        <label :for="'noaccess' + id">Custom Access Only</label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["isDewar", "id", "itemAccess", "selected", "disabled"],
  emits: ["update:itemAccess", "valchange2"],

  watch: {
    itemAccess() {
      console.log(this.itemAccess);
    },
  },
};
</script>

<style scoped>
input,
label {
  cursor: pointer;
}
input {
  margin-right: 0.2rem;
}
</style>
