import { createRouter, createWebHistory } from "vue-router";

import Home from "./components/router-pages/loggedin/Home.vue";
import GroupAdminPage from "./components/router-pages/loggedin/GroupAdminPage.vue";
import SuperAdminPage from "./components/router-pages/loggedin/SuperAdminPage.vue";
import MyProfile from "./components/router-pages/loggedin/MyProfile.vue";
import TeamMembers from "./components/router-pages/loggedin/TeamMembers.vue";
import Messages from "./components/router-pages/loggedin/Messages.vue";
import Sensors from "./components/router-pages/loggedin/Sensors.vue";
import ChangeLog from "./components/router-pages/loggedin/ChangeLog.vue";
import ProjectManager from "./components/router-pages/loggedin/ProjectManager.vue";
import Archives from "./components/router-pages/loggedin/Archives.vue";
import SharedProducts from "./components/router-pages/loggedin/SharedProducts.vue";
import ManageProducts from "./components/router-pages/loggedin/ManageProducts.vue";
import TransportRacks from "./components/router-pages/loggedin/TransportRacks.vue";
import TransportRacksOld from "./components/router-pages/loggedin/TransportRacksOld.vue";
import ResetPassword from "./components/router-pages/ResetPassword.vue";
import Billing from "./components/router-pages/loggedin/Billing.vue";
import DataSettings from "./components/router-pages/loggedin/DataSettings.vue";

import NotFound from "./components/router-pages/NotFound.vue";

import store from "./store/index.js";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: "/", redirect: "/home" },
    {
      name: "home",
      path: "/home",
      meta: { needsAuth: true },
      component: Home,
    },
    {
      name: "my-group",
      path: "/group-admin-page",
      meta: { needsAuth: true },
      component: GroupAdminPage,
      beforeEnter(to, from, next) {
        if (store.state.userDataAlreadyLoaded) {
          if (store.state.activeUser.role == "lab-admin") next();
          else next("/error");
        } else
          store
            .dispatch("userDataGot", { labadmincheck: true })
            .then(() => {
              next();
            })
            .catch(() => {
              next("/error");
            });
      },
    },
    {
      name: "reset-password",
      path: "/reset-password/:code",
      meta: { needsAuth: false },
      component: ResetPassword,
    },
    {
      name: "admin",
      path: "/admin",
      meta: { needsAuth: true },
      component: SuperAdminPage,
      beforeEnter(to, from, next) {
        if (store.state.userDataAlreadyLoaded) {
          if (store.state.isMark) next();
          else next("/error");
        } else
          store
            .dispatch("userDataGot")
            .then(() => {
              console.log("hrrrm");
              next();
            })
            .catch(() => {
              console.log("fail");
              next("/error");
            });
      },
    },
    {
      name: "billing",
      path: "/billing",
      meta: { needsAuth: true },
      component: Billing,
      beforeEnter(to, from, next) {
        if (store.state.userDataAlreadyLoaded) {
          if (store.state.isLabSuper) next();
          else next("/error");
        } else
          store
            .dispatch("userDataGot", { labsuper: true })
            .then(() => {
              console.log("hrrrm");
              next();
            })
            .catch(() => {
              console.log("fail");
              next("/error");
            });
      },
    },
    {
      name: "my-profile",
      path: "/my-profile",
      meta: { needsAuth: true },
      component: MyProfile,
    },
    {
      name: "changelog",
      path: "/changelog",
      meta: { needsAuth: true },
      component: ChangeLog,
    },
    {
      name: "team-members",
      path: "/team-members",
      meta: { needsAuth: true },
      component: TeamMembers,
    },
    {
      name: "messages",
      path: "/messages",
      meta: { needsAuth: true },
      component: Messages,
    },
    {
      name: "sensors",
      path: "/sensors",
      meta: { needsAuth: true },
      component: Sensors,
    },
    {
      name: "project-manager",
      path: "/project-manager",
      meta: { needsAuth: true },
      component: ProjectManager,
      beforeEnter(to, from, next) {
        if (store.state.userDataAlreadyLoaded) {
          if (store.state.activeUser.role == "lab-admin") next();
          else next("/error");
        } else
          store
            .dispatch("userDataGot", { labadmincheck: true })
            .then(() => {
              next();
            })
            .catch((err) => {
              console.log(err);
              next("/error");
            });
      },
    },
    {
      name: "archives",
      path: "/archives",
      meta: { needsAuth: true },
      component: Archives,
    },
    {
      name: "data-settings",
      path: "/data-settings",
      meta: { needsAuth: true },
      component: DataSettings,
      beforeEnter(to, from, next) {
        if (store.state.userDataAlreadyLoaded) {
          if (store.state.activeUser.role == "lab-admin") next();
          else next("/error");
        } else
          store
            .dispatch("userDataGot", { labadmincheck: true })
            .then(() => {
              next();
            })
            .catch((err) => {
              console.log(err);
              next("/error");
            });
      },
    },
    {
      name: "shared-information",
      path: "/shared-products",
      meta: { needsAuth: true },
      component: SharedProducts,
      beforeEnter(to, from, next) {
        if (store.state.userDataAlreadyLoaded) {
          if (store.state.activeUser.role == "lab-admin") next();
          else next("/error");
        } else
          store
            .dispatch("userDataGot", { labadmincheck: true })
            .then(() => {
              next();
            })
            .catch((err) => {
              console.log(err);
              next("/error");
            });
      },
    },
    {
      name: "transport-racks",
      path: "/transport-racks",
      meta: { needsAuth: true },
      component: TransportRacks,
      beforeEnter(to, from, next) {
        if (store.state.userDataAlreadyLoaded) {
          if (store.state.activeUser.role == "lab-admin") next();
          else next("/error");
        } else
          store
            .dispatch("userDataGot", { labadmincheck: true })
            .then(() => {
              next();
            })
            .catch(() => {
              next("/error");
            });
      },
    },
    {
      name: "transport-racks-old",
      path: "/transport-racks-old",
      meta: { needsAuth: true },
      component: TransportRacksOld,
      beforeEnter(to, from, next) {
        if (store.state.userDataAlreadyLoaded) {
          if (store.state.activeUser.role == "lab-admin") next();
          else next("/error");
        } else
          store
            .dispatch("userDataGot", { labadmincheck: true })
            .then(() => {
              next();
            })
            .catch(() => {
              next("/error");
            });
      },
    },
    {
      name: "manage-products",
      path: "/manage-products",
      meta: { needsAuth: true },
      component: ManageProducts,
    },

    { path: "/:notFound(.*)", component: NotFound },
  ],
  scrollBehavior(_, _2, savedPosition) {
    // console.log(to, from, savedPosition);
    if (savedPosition) {
      return savedPosition;
    }
    return { left: 0, top: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  if (
    (store.state.unsavedChangesMPGrid || store.state.unsavedChangesMPBox) &&
    from.name == "manage-products"
  ) {
    store.state.promptUnsaveModal = true;

    let answer;
    try {
      answer = await store.state.promptAnswerPromise();
    } catch {
      //rejected

      answer = null;
    }

    store.state.promptUnsaveModal = false;
    if (!answer) {
      return next(false);
    } else {
      store.state.unsavedChangesMPGrid = false;
      store.state.unsavedChangesMPBox = false;
      //absolute weirdness if u add in a param but if u dont the route name will be missing in the next page that needs it
      return next();
    }
  } else return next();
});

// router.beforeEach(function(to, from, next) {
//   console.log("Global beforeEach");
//   console.log(to, from);
//   if (to.meta.needsAuth) {
//     console.log("Needs auth!");
//     next();
//   } else {
//     next();
//   }
//   // if (to.name === 'team-members') {
//   //   next();
//   // } else {
//   //   next({ name: 'team-members', params: { teamId: 't2' } });
//   // }
//   // next();
// });

// router.afterEach(function(to, from) {
//   // sending analytics data
//   console.log("Global afterEach");
//   console.log(to, from);
// });

export default router;
