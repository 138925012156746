<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 41.1 41.1"
    style="enable-background:new 0 0 41.1 41.1;"
    xml:space="preserve"
    class="boxonlysvg"
  >
    <svg:style type="text/css" scoped>
      .stblack {
        fill: #000000 !important;
      }

      .well-unclipped .st2 {
        fill: #ffffff;
      }
      .well-unclipped .stblack {
        fill: #000000;
      }
      .well-unclipped .st3 {
        fill: url(#SVGID_PLUSSIGN) !important;
        stroke: #ffffff;
        stroke-width: 0.25;
        stroke-miterlimit: 10;
      }
    </svg:style>
    <g>
      <path
        class="st0"
        d="M25.8,0.7c0,0.3,0.1,0.6,0.1,0.9c0,2.9-2.4,5.3-5.3,5.3s-5.3-2.4-5.3-5.3c0-0.3,0-0.6,0.1-0.9
		C6.5,3,0,11,0,20.5c0,11.3,9.2,20.5,20.5,20.5s20.5-9.2,20.5-20.5C41.1,11,34.6,3,25.8,0.7z"
      />
      <path
        class="st1"
        d="M25.8,0.7c0,0.3,0.1,0.6,0.1,0.9c0,2.9-2.4,5.3-5.3,5.3s-5.3-2.4-5.3-5.3c0-0.3,0-0.6,0.1-0.9   C6.5,3,0,11,0,20.5C0,31.8,9.2,41,20.5,41S41,31.8,41,20.5C41.1,11,34.6,3,25.8,0.7z"
      ></path>
      <circle class="st2" cx="20.5" cy="20.5" r="3.9" />

      <path
        class="st3"
        d="M20.5,16.3c-2.4,0-4.2,1.9-4.2,4.2s1.9,4.2,4.2,4.2s4.2-1.9,4.2-4.2S22.9,16.3,20.5,16.3z M20.9,21V24h-0.8V21
		h-3v-0.8h3v-3.1h0.8v3.1h3V21H20.9z"
      />

      <radialGradient
        id="SVGID_PLUSSIGN"
        cx="22.1032"
        cy="1511.963"
        r="4.8437"
        gradientTransform="matrix(1 0 0 1 0 -1492.9199)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" style="stop-color:#ECECEC" />
        <stop offset="0.3528" style="stop-color:#BEBEBE" />
        <stop offset="1" style="stop-color:#626060" />
      </radialGradient>
    </g>
  </svg>
</template>
<script>
export default {
  props: ["color", "darkColor", "wellNumber", "showGridClips"],
};
</script>
